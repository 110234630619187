import { Link } from "react-router-dom";
import { Button, CardBody } from "reactstrap";
import { Row, Col, Container, Card } from "reactstrap";
import map from "../assets/img/map.png";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Banner_SA from '../assets/img/Banner_SA.jpg';
import Banner_PT from '../assets/img/Banner_PT.jpg';
import Banner_PT2 from '../assets/img/Banner_PT2.jpg';
import WhatsApp from '../assets/img/WhatsApp.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import banner1 from "../assets/img/banner1.png";
import banner2 from "../assets/img/banner2.png";
import banner3 from "../assets/img/banner3.png";
import banner4 from "../assets/img/banner4.png";
import OurSolutions from "../components/OurSolutions";
import OnlineBookingPlatform from "../components/OnlineBookingPlatform";

import Button_Ship from "../assets/img/Button_Ship.png";
import Button_Truck from "../assets/img/Button_Truck.png";
import Button_Airplane from "../assets/img/Button_Airplane.png";
import Bounce from "react-reveal/Bounce";

import ICON_1 from "../assets/img/ICON_1.png";
import ICON_2 from "../assets/img/ICON_2.png";
import ICON_3 from "../assets/img/ICON_3.png";
import OurPartners from "../components/OurPartners";
import savingyou from "../assets/img/savingyou.png";
import account from "../assets/img/account.png";
import multiplequotes from "../assets/img/multiplequotes.png";

import WeightCalculator from "../components/WeightCalculator";
import WhyShipMyStuff from "../components/WhyShipMyStuff";
import PackagingSolution from "../components/PackagingSolution";

import banner1withoutext from "../assets/img/Withouttext/banner1withoutext.jpg";
import banner1newwithouttext from "../assets/img/Withouttext/banner1newwithouttext.jpeg";
import banner2withouttext from "../assets/img/Withouttext/banner2withouttext.jpg";
import banner3withouttext from "../assets/img/Withouttext/banner3withouttext.png";

import Art from "../assets/img/Withouttext/Art.png";
import ContainerImage from "../assets/img/Withouttext/Container.png";
import Documents from "../assets/img/Withouttext/Documents.png";
import House from "../assets/img/Withouttext/House.png";
import Motorcycle from "../assets/img/Withouttext/Motorcycle.png";
import Movecube from "../assets/img/Withouttext/moveCube.png";
import ParcelBoxes from "../assets/img/Withouttext/ParcelBoxes.png";
import Tick from "../assets/img/Withouttext/tick.png";
import QuickQuote from "./QuickQoute";

import International from "./International";
import { useDispatch, useSelector } from "react-redux";
import SeaFreight from "../components/SeaFreight/SeaFreight";
import { onServiceChange } from "../store/actions/serviceActions";
import { scroller } from "react-scroll";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Main = (props) => {
  const history=useHistory();
  const { selectedService } = useSelector((state) => state.service);
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry,"selectedCountry");
  const [localFirstPage, setLocalFirstPage] = useState({
    fromRegion: "",
    toRegion: "",
    fromSuburbPost: "",
    toSuburbPost: "",
  });

  const dispatch = useDispatch();

  const [localCountrySelection, setLocalCountrySelection] = useState({
    fromRegion: "",
    toRegion: "",
    toLocalArea: "",
    fromLocalArea: "",
    fromCode: "",
    toCode: "",
    fromStreet: "",
    toStreet: "",
    fromSuburb: "",
    toSuburb: "",
    opt_in_time_based_rates: [],
    opt_in_rates: [],

    deliveryType: "residential",
  });

  const [insuranceData, setInsuranceData] = useState({
    insurance: false,
    insuranceDesc: "",
    insuranceValue: "",
  });
  const [intlInsurance, setIntlInsurance] = useState({
    insurance: false,
    insuranceValue: "",
  });

  const [localSecondPage, setLocalSecondPage] = useState({
    description: "",
    totalWeight: "",
    volumetricWeight: "",
    packageType: "",
    itemDetails: [
      { description: "", weight: "", height: "", length: "", width: "" },
    ],
    // parcels: [
    // 	{
    // 		parcel_description: '',
    // 		submitted_weight_kg: '',
    // 		submitted_height_cm: '',
    // 		submitted_length_cm: '',
    // 		submitted_width_cm: '',
    // 	},
    // ],
  });
  const [localThirdPage, setLocalThirdPage] = useState({
    type: "economy",
    serviceType: "",
    selectedCourier: {
      BaseRate: 0,
      SurchargeRate: 0,
      TotalRate: 0,
      Name: "",
    },
  });
  const [localFourthPage, setLocalFourthPage] = useState({ startDate: "" });
  const [localFifthPage, setLocalFifthPage] = useState({
    sendingName: "",
    sendingSurName: "",
    sendingContact: "",
    sendingComplexNo: "",
    sendingCity: "",
    sendingAddress2: "",
    sendingAddress1: "",
    RecievingSurName: "",
    RecievingName: "",
    RecievingContact: "",
    RecievingAddress1: "",
    RecievingAddress2: "",
    RecievingComplexNo: "",
    RecievingCity: "",
  });
  const [localSixthPage, setLocalSixthPage] = useState({ email: "" });
  const [localSventhPage, setLocalSeventhPage] = useState({ url: "" });
  const [paymentPage, setPaymentPage] = useState({
    paymentRef: { REFERENCE: "" },
    redirectParams: { CHECKSUM: "", PAY_REQUEST_ID: "" },
    redirectUri: "",
  });

  //International Default States
  const [intlFirstPage, setintlFirstPage] = useState({
    fromCountry: "",
    toCountry: "",
    import: "true",
  });
  const [intlCountrySelection, setIntlCountrySelection] = useState({
    fromCountry: "",
    toCountry: "",
    fromState: "",
    toState: "",
    fromCity: "",
    toCity: "",
    fromCode: "",
    toCode: "",
    deliveryType: "residential",
  });

  const [intlSecondPage, setintlSecondPage] = useState({
    totalWeight: "",
    volumetricWeight: "",
    packageType: "",
    itemDetails: [
      {
        length: "",
        width: "",
        height: "",
        weight: "",
      },
    ],
  });
  const [intlThirdPage, setintlThirdPage] = useState({
    rate: 0,
    serviceType: "",
    type: "",
  });
  const [intlFourthPage, setintlFourthPage] = useState({
    date: "",
    text: "",
  });
  const [intlFifthPage, setintlFifthPage] = useState({
    sendingName: "",
    idNubmer: "",
    sendingSurName: "",
    sendingContact: "",
    sendingComplex: "",
    sendingCity: "",
    sendingAddress2: "",
    sendingAddress1: "",
    sendingSuburb: "",
    sendingPostal: "",
    ReceivingSurName: "",
    ReceivingName: "",
    ReceivingContact: "",
    ReceivingAddress1: "",
    ReceivingAddress2: "",
    ReceivingComplex: "",
    ReceivingCity: "",
    ReceivingSuburb: "",
    ReceivingPostal: "",
  });
  const [intlSixthPage, setintlSixthPage] = useState({
    declarationItems: [
      {
        country: "",
        quantity: "",
        description: "",
        type: "",
        unitValue: "",
        totalValue: "",
      },
    ],
    reason: "",
    totalShipmentValue: 0,
  });
  const [intlDeclaration, setIntlDeclaration] = useState({
    type: "personal",
    file: "",
  });
  const [intlSeventhPage, setintlSeventhPage] = useState({
    billingEmail: "",
    billingContactNo: "",
    billingName: "",
    billingAddress1: "",
    billingAddress2: "",
    billingComplexNo: "",
    billingSuburb: "",
    billingCountry: "",
    billingPostalCode: "",
    billingVAT: "",
  });
  const [paymentPageIn, setPaymentPageIn] = useState({
    paymentRef: { REFERENCE: "" },
    redirectParams: { CHECKSUM: "", PAY_REQUEST_ID: "" },
    redirectUri: "",
  });
  const [intlEighthPage, setintlEighthPage] = useState({ url: "" });
  const [intlCommercialPage, setintlCommercialPage] = useState({ url: "" });

  //Local Storage For Quote
  let localItem1 = JSON.parse(localStorage.getItem("local_first_page"));
  let localItem2 = JSON.parse(localStorage.getItem("local_second_page"));
  let localItem3 = JSON.parse(localStorage.getItem("local_third_page"));
  let localItem4 = JSON.parse(localStorage.getItem("local_fourth_page"));
  let localItem5 = JSON.parse(localStorage.getItem("local_fifth_page"));
  let localItem6 = JSON.parse(localStorage.getItem("local_sixth_page"));
  let localItem7 = JSON.parse(localStorage.getItem("local_seventh_page"));
  let localPayment6 = JSON.parse(localStorage.getItem("payment"));
  let localInsuranceData = JSON.parse(
    localStorage.getItem("local_insurance_data")
  );

  let localCountryItem = JSON.parse(
    localStorage.getItem("local_country_selection")
  );

  //localPayment For International Payment

  let intlCountry = JSON.parse(localStorage.getItem("intl_country_selection"));
  let intlItem1 = JSON.parse(localStorage.getItem("intl_first_page"));
  let intlItem2 = JSON.parse(localStorage.getItem("intl_second_page"));
  let intlItem3 = JSON.parse(localStorage.getItem("intl_third_page"));
  let intlItem4 = JSON.parse(localStorage.getItem("intl_fourth_page"));
  let intlItem5 = JSON.parse(localStorage.getItem("intl_fifth_page"));
  let intlItem6 = JSON.parse(localStorage.getItem("intl_sixth_page"));
  let intlItem7 = JSON.parse(localStorage.getItem("intl_seventh_page"));
  let intlItem8 = JSON.parse(localStorage.getItem("intl_eighth_page"));
  let intlPayment = JSON.parse(localStorage.getItem("intl_payment"));
  let intlCommercial = JSON.parse(localStorage.getItem("intl_commercial_page"));
  let intlInsuranceData = JSON.parse(
    localStorage.getItem("intl_insurance_data")
  );

  let intl_declaration = localStorage.getItem("intl_declaration");

  //Scroll To Top
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2000);
  }, []);

  useEffect(() => {
    if (localItem1 == null || localItem1 == {}) {
      localStorage.setItem("local_first_page", JSON.stringify(localFirstPage));
    }
    if (localItem2 == null || localItem2 == {}) {
      localStorage.setItem(
        "local_second_page",
        JSON.stringify(localSecondPage)
      );
    }
    if (localItem3 == null || localItem3 == {}) {
      localStorage.setItem("local_third_page", JSON.stringify(localThirdPage));
    }
    if (localItem4 == null || localItem4 == {}) {
      localStorage.setItem(
        "local_fourth_page",
        JSON.stringify(localFourthPage)
      );
    }
    if (localItem5 == null || localItem5 == {}) {
      localStorage.setItem("local_fifth_page", JSON.stringify(localFifthPage));
    }
    if (localItem6 == null || localItem6 == {}) {
      localStorage.setItem("local_sixth_page", JSON.stringify(localSixthPage));
    }
    if (localPayment6 == null || localPayment6 == {}) {
      localStorage.setItem("payment", JSON.stringify(paymentPage));
    }
    if (localItem7 == null || localItem7 == {}) {
      localStorage.setItem(
        "local_seventh_page",
        JSON.stringify(localSventhPage)
      );
    }
    if (localInsuranceData == null || localInsuranceData == {}) {
      localStorage.setItem(
        "local_insurance_data",
        JSON.stringify(insuranceData)
      );
    }

    // Local New Setting

    if (localCountryItem == null || localCountryItem == {}) {
      localStorage.setItem(
        "local_country_selection",
        JSON.stringify(localCountrySelection)
      );
    }

    //Interntional Setting
    if (intlItem1 == null || intlItem1 == {}) {
      localStorage.setItem("intl_first_page", JSON.stringify(intlFirstPage));
    }
    if (intlInsuranceData == null || intlInsuranceData == {}) {
      localStorage.setItem(
        "intl_insurance_data",
        JSON.stringify(intlInsurance)
      );
    }

    if (intlCountry == null || intlCountry == {}) {
      localStorage.setItem(
        "intl_country_selection",
        JSON.stringify(intlCountrySelection)
      );
    }
    if (intlItem2 == null || intlItem2 == {}) {
      localStorage.setItem("intl_second_page", JSON.stringify(intlSecondPage));
    }
    if (intlItem3 == null || intlItem3 == {}) {
      localStorage.setItem("intl_third_page", JSON.stringify(intlThirdPage));
    }
    if (intlItem4 == null || intlItem4 == {}) {
      localStorage.setItem("intl_fourth_page", JSON.stringify(intlFourthPage));
    }
    if (intlItem5 == null || intlItem5 == {}) {
      localStorage.setItem("intl_fifth_page", JSON.stringify(intlFifthPage));
    }
    if (intlItem6 == null || intlItem6 == {}) {
      localStorage.setItem("intl_sixth_page", JSON.stringify(intlSixthPage));
    }
    if (intlItem7 == null || intlItem7 == {}) {
      localStorage.setItem(
        "intl_seventh_page",
        JSON.stringify(intlSeventhPage)
      );
    }
    if (intlItem8 == null || intlItem8 == {}) {
      localStorage.setItem("intl_eighth_page", JSON.stringify(intlEighthPage));
    }
    if (intlPayment == null || intlPayment == {}) {
      localStorage.setItem("intl_payment", JSON.stringify(paymentPageIn));
    }
    if (intlCommercial == null || intlCommercial == {}) {
      localStorage.setItem(
        "intl_commercial_page",
        JSON.stringify(intlCommercialPage)
      );
    }
    // if (intl_declaration == null || intl_declaration == '') {
    // 	localStorage.setItem('intl_declaration', null);
    // }
  }, []);
  const handleEmailClick = () => {
    window.location.href = 'mailto:info@shipmystuff.pt';
  };
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  return (
    <>
    <div className="banner_carousal">
      <Carousel
        responsive={responsive}
        // interval={2500}
        // arrows={false}
        autoPlay={true}
        infinite={true}
        className="carousel-parent "
        // style={{ width: '100%' }}
        autoPlaySpeed={2500}
        
        transitionDuration={2500}
      >

        <div
          className="main-banner "
          // style={{ backgroundImage: `url(${background})` }}
        >
          <div className="main-banner__header ">
            <p className="main-banner__heading">
              We Ship <span className="text-secondary">Almost</span> Anything
            </p>
          </div>

          <Row className="px-md-5 px-3 mb-3 mb-md-4">
            <Col
              md="2"
              xs="4"
              className="d-flex justify-content-between align-items-center flex-column"
            >
              <img className="img-fluid main-banner__img" src={Documents} />
              <p className="mb-0 main-banner__sub-heading">Documents</p>
              <img className="img-fluid main-banner__tick" src={Tick} />
            </Col>
            <Col
              md="2"
              xs="4"
              className="d-flex justify-content-between align-items-center flex-column"
            >
              <img className="img-fluid main-banner__img" src={ParcelBoxes} />
              <p className="mb-0 main-banner__sub-heading">Parcels</p>
              <img className="img-fluid main-banner__tick" src={Tick} />
            </Col>
            <Col
              md="2"
              xs="4"
              className="d-flex justify-content-between align-items-center flex-column"
            >
              <img className="img-fluid main-banner__img" src={Movecube} />
              <p className="mb-0 main-banner__sub-heading">
                Moving <br /> Cubes
              </p>
              <img className="img-fluid main-banner__tick" src={Tick} />
            </Col>
            <Col
              md="2"
              xs="4"
              className="d-flex justify-content-between align-items-center flex-column mt-md-0 mt-3"
            >
              <img className="img-fluid main-banner__img" src={Motorcycle} />
              <img className="img-fluid main-banner__img mt-2" src={Art} />
              <p className="mb-0 main-banner__sub-heading">
                Specialized <br /> Cargo
              </p>
              <img className="img-fluid main-banner__tick" src={Tick} />
            </Col>
            <Col
              md="2"
              xs="4"
              className="d-flex justify-content-between align-items-center flex-column mt-md-0 mt-3"
            >
              <img className="img-fluid main-banner__img" src={House} />
              <p className="mb-0 main-banner__sub-heading">
                Personal <br /> Effects
              </p>
              <img className="img-fluid main-banner__tick" src={Tick} />
            </Col>
            <Col
              md="2"
              xs="4"
              className="d-flex justify-content-between align-items-center flex-column mt-md-0 mt-3"
            >
              <img
                className="img-fluid main-banner__img"
                src={ContainerImage}
              />
              <p className="mb-0 main-banner__sub-heading">
                Containers <br /> (personal & commercial)
              </p>
              <img className="img-fluid main-banner__tick" src={Tick} />
            </Col>
          </Row>

          {/* <div className="d-flex w-100 main-banner__item">
            <span className="main-banner__sub-heading main-banner__sub-1">
              Documents
            </span>
            <span className="main-banner__sub-heading main-banner__sub-2">
              Parcels
            </span>
            <span className="main-banner__sub-heading main-banner__sub-3">
              Moving <br /> Cubes
            </span>
            <span className="main-banner__sub-heading main-banner__sub-4">
              Specialized <br /> Cargo
            </span>
            <span className="main-banner__sub-heading main-banner__sub-5">
              Personal <br /> Effects
            </span>

            <span className="main-banner__sub-heading main-banner__sub-6">
              Containers <br /> <span>(personal & commercial)</span>
            </span>
          </div> */}
        </div>

        {/* <div className="banner_item">
          <img src={banner1} className="img-fluid" />
          <Link to="/contactus">
            <Button className="banner_btn_2 banner_btn" size="lg">
              ENQUIRE TODAY
            </Button>
          </Link>
        </div> */}
         <div className="banner_item ">
        {selectedCountry=='PT'? <img src={Banner_PT} className="img-fluid" />:<img src={Banner_SA} className="img-fluid" />}

          <div className="banner_item-section2 position-absolute">
            <p className="mb-0 banner_item-section1__heading">
              Send your stuff, <span className="text-primary">FAST!</span>
            </p>
            <p className="mb-0 banner_item-section1__sub-heading">
              International Courier Service, Simplified!
            </p>
          </div>

          {/* <Link to="/international">
            <Button className="banner_btn_1 banner_btn" size="lg">
              INSTANT QUOTE{" "}
            </Button>
          </Link> */}
        </div>

        <div className="banner_item">
         {selectedCountry==='PT'?  <img src={Banner_PT2} className="img-fluid" />:
          <img src={banner3withouttext} className="img-fluid" />}
          {/* {country=='PT'? */}
          <div className="banner_item-section1 position-absolute">
          <p className="mb-0 banner_item-section1__heading">
            Got Cargo? We will <span className="text-primary">Ship</span> it!
          </p>
          <p className="mb-0 banner_item-section1__sub-heading">
          Door to door solutions for commercial <span className="text-primary">|</span>{" "}
          personal effects
          </p>
        </div>
          {/* :
          <div className="banner_item-section1 position-absolute">
            <p className="mb-0 banner_item-section1__heading">
              Got Cargo? We will <span className="text-primary">Ship</span> it!
            </p>
            <p className="mb-0 banner_item-section1__sub-heading">
              Commercial Cargo <span className="text-primary">|</span>{" "}
              International Relocations
            </p>
          </div>
} */}
          {/* <Link to="/contactus">
            <Button className="banner_btn_2 banner_btn" size="lg">
              ENQUIRE TODAY
            </Button>
          </Link> */}
        </div>

        {/* <div className="banner_item">
          <img src={banner4} className="img-fluid" />
          <Link to="/quickquote">
            <Button className="banner_btn_1 banner_btn" size="lg">
              INSTANT QUOTE{" "}
            </Button>
          </Link>
        </div> */}
        {/* <div className="banner_item">
          <img src={banner2} className="img-fluid" />
          <Link to="/international">
            <Button className="banner_btn_1 banner_btn" size="lg">
              INSTANT QUOTE{" "}
            </Button>
          </Link>
        </div> */}
      </Carousel>
      </div>
      
      <div id="selectedCourierSection" className="mt-5">
        {selectedService == "local" && <QuickQuote />}

        {selectedService == "international" && <International />}

        {selectedService == "sea" && <SeaFreight />}
      </div>
      <Row className="no-gutters usp_row usp_section  ">
        <Col
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={account} />
          </div>
          <span className="text-white text-center">
            <strong>NO MORE</strong>
            <br />
            Courier Accounts
          </span>
        </Col>
        <Col
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={multiplequotes} />
          </div>
          <span className="text-white text-center">
            Multiple Quotes
            <br />
            <strong>INSTANTLY</strong>
          </span>
        </Col>
        <Col
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={savingyou} />
          </div>
          <span className="text-white text-center">
            <strong>SAVING YOU</strong>
            <br />
            Time and Money
          </span>
        </Col>
      </Row>
      {/* <Row className="no-gutters usp_row usp_section  ">
        <Col
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={account} />
          </div>
          <span className="text-white text-center">
            <strong>NO MORE</strong>
            <br />
            Courier Accounts
          </span>
        </Col>
        <Col
        //  sm='12'
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={multiplequotes} />
          </div>
          <span className="text-white text-center">
            Multiple Quotes
            <br />
            <strong>INSTANTLY</strong>
          </span>
        </Col>
        <Col
        //  sm='12'
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={savingyou} />
          </div>
          <span className="text-white text-center">
            <strong>SAVING YOU</strong>
            <br />
            Time and Money
          </span>
        </Col>
      </Row> */}

      {/* <Row className="no-gutters usp_row usp_section ">
        <Col
          xs="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={ICON_1} />
          </div>
        </Col>
        <Col
          xs="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={ICON_2} />
          </div>
        </Col>
        <Col
          xs="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={ICON_3} />
          </div>
        </Col>
      </Row> */}

      <div id="oursolutions ">
        <OurSolutions />
      </div>

      <OnlineBookingPlatform />
       {selectedCountry!=='PT'?
      <PackagingSolution />:<><br/><br/><br/></>}

      <WhyShipMyStuff />
      {/* <Row className="no-gutters usp_row usp_section ">
        <Col
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={account} />
          </div>
          <span className="text-white text-center">
            <strong>NO MORE</strong>
            <br />
            Courier Accounts
          </span>
        </Col>
        <Col
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={multiplequotes} />
          </div>
          <span className="text-white text-center">
            Multiple Quotes
            <br />
            <strong>INSTANTLY</strong>
          </span>
        </Col>
        <Col
          xs="12"
          md="4"
          className="d-flex justify-content-center align-items-center h-100"
        >
          <div className="usp_col">
            <img src={savingyou} />
          </div>
          <span className="text-white text-center">
            <strong>SAVING YOU</strong>
            <br />
            Time and Money
          </span>
        </Col>
      </Row> */}
      <Row className="no-gutters mb-5 mt-120 ">
        <Col
          xs="12"
          xl="4"
          className="courier_row"
          // className="d-flex justify-content-center align-items-center courier_col_left "
        >
          <Row noGutters className="courier_row__sub-item">
            <Col
              xs="12"
              className=" d-flex align-items-center justify-content-center courier_row__sub-item__border-left courier_row__sub-item__heading-col"
            >
              <span className="text-primary    text-center">
                DOMESTIC COURIER
              </span>
            </Col>
            <Col
              xs="12"
              className="d-flex justify-content-center align-items-center courier_row__sub-item__button-col courier_col_left "
            >
              {/* <Link to={"/quickquote"}>
                <Bounce duration={1000}>
                  <Button className=" d-flex align-items-center mt-3 mb-3 ">
                    GET QUOTE
                    <img
                      className="img-fluid ml-3"
                      src={Button_Truck}
                      width="40"
                    />
                  </Button>
                </Bounce>
              </Link> */}
            </Col>

            <Col xs="12 d-flex justify-content-center align-items-end">
              <div className="w-100">
                {/* <Link to={"/quickquote"} className="w-100"> */}
                <Bounce duration={1000}>
                  <Button 
                    className=" d-flex justify-content-center align-items-center w-100 btn-border-right"
                    onClick={ selectedCountry=='PT' 
                    ? handleEmailClick:() => {
                      dispatch(onServiceChange("local"));
                      scroller.scrollTo("selectedCourierSection", {
                        // duration: 500,
                        smooth: true,
                        offset: -85,
                      });
                    }}
                  >
                    INSTANT QUOTE
                    <img
                      className="img-fluid ml-3"
                      src={Button_Truck}
                      width="40"
                    />
                  </Button>
                </Bounce>
              </div>
              {/* </Link> */}
            </Col>
          </Row>
        </Col>
        <Col
          xs="12"
          xl="4"
          className="courier_row"
          // className="d-flex justify-content-center align-items-center courier_col_right "
        >
          <Row noGutters className="courier_row__sub-item">
            <Col
              xs="12"
              className=" d-flex justify-content-center align-items-center courier_row__sub-item__heading-col courier_row__sub-item__border-right"
            >
              <span className="text-primary   text-center">
                SEA FREIGHT /<br />
                INTERNATIONAL RELOCATIONS
              </span>
            </Col>
            <Col
              xs="12"
              className="d-flex justify-content-center align-items-center courier_col_center courier_row__sub-item__button-col"
            >
              {/* <Link to={"/seafreight"}>
                <Bounce duration={1000}>
                  <Button className="px-3 d-flex align-items-center mt-3 mb-3">
                    ENQUIRE TODAY
                   
                  </Button>
                </Bounce>
              </Link> */}
            </Col>

            <Col xs="12 d-flex justify-content-center align-items-end">
              <Row noGutters className="w-100">
                <Col xs="6">
                  {/* <Link to={"/seafreight"} className="w-100 "> */}
                  <div className="w-100">
                    <Bounce duration={1000}>
                      <Button
                        className="px-3 d-flex align-items-center justify-content-center w-100 btn-border-right"
                        onClick={() => {
                          dispatch(onServiceChange("sea"));
                          scroller.scrollTo("selectedCourierSection", {
                            // duration: 500,
                            smooth: true,
                            offset: -85,
                          });
                        }}
                      >
                        COMMERCIAL
                      </Button>
                    </Bounce>
                  </div>
                  {/* </Link> */}
                </Col>
                <Col xs="6">
                  {/* <Link to={"/internationalrelocation"} className="w-100"> */}
                  <div className="w-100">
                    <Bounce duration={1000}>
                      <Button
                        className="px-3 d-flex align-items-center justify-content-center w-100 btn-border-right"
                        onClick={() => {
                          dispatch(onServiceChange("sea"));
                          scroller.scrollTo("selectedCourierSection", {
                            // duration: 500,
                            smooth: true,
                            offset: -85,
                          });
                        }}
                      >
                        INTERNATIONAL RELOCATION
                      </Button>
                    </Bounce>
                  </div>
                  {/* </Link> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs="12" xl="4" className="courier_row">
          <Row noGutters className="courier_row__sub-item">
            <Col
              xs="12"
              className=" d-flex justify-content-center align-items-center courier_row__sub-item__heading-col courier_row__sub-item__border-right"
            >
              <span className="text-primary    text-center">
                INTERNATIONAL COURIER
              </span>
            </Col>
            <Col
              xs="12"
              className="d-flex justify-content-center align-items-center courier_col_right courier_row__sub-item__button-col"
            >
              {/* <Link to={"/international"}>
                <Bounce duration={1000}>
                  <Button className="px-3 d-flex align-items-center mt-3 mb-3">
                    GET QUOTE
                    <img
                      className="img-fluid ml-3"
                      src={Button_Airplane}
                      width="40"
                    />
                  </Button>
                </Bounce>
              </Link> */}
            </Col>

            <Col  xs="12 d-flex justify-content-center align-items-end">
                  <div className="w-100">
                <Bounce duration={1000}>
                  <Button
                    className="d-flex justify-content-center align-items-center w-100 btn-border-right "
                    onClick={() => {
                      dispatch(onServiceChange("international"));
                      scroller.scrollTo("selectedCourierSection", {
                        // duration: 500,
                        smooth: true,
                        offset: -85,
                      });
                    }}
                  >
                    INSTANT QUOTE
                    <img
                      className="img-fluid ml-3"
                      src={Button_Airplane}
                      width="40"
                    />
                  </Button>
                </Bounce>
              </div>
            </Col>
          </Row>

          {/* <Button className='px-4 py-1'>SKY CARGO QUOTE</Button> */}
        </Col>
      </Row>

      <div id="weightcalculator">
        <WeightCalculator />
      </div>
      {/* <OurPartners /> */}
      {/* <Container fluid className='main-container'>
				<Row className='justify-content-center '>
					<Col md='5'>
						<div className='p-3'>
							<span className='text-primary '>
								Instant Quote and Booking <br /> Service in
							</span>{' '}
							<span className='text-danger main-danger-span'>
								4 EASY STEPS.
							</span>
						</div>

						<Row>
							<Col className='text-right'>
								<img className='map-img' src={MultipleQuote} />
							</Col>
							<Col className='text-left mt-4'>
								<span className='text-primary'>
									{' '}
									Multiple Quotes,
								</span>
								<span className='text-secondary'>Fast</span>
							</Col>
						</Row>
						<Row className='justify-content-center mt-3'>
							<Col className='text-right'>
								<img className='map-img' src={NoAccount_v2} />
							</Col>
							<Col className='text-left mt-4'>
								<span className='text-primary'>
									{' '}
									No Account Needed
								</span>
							</Col>
						</Row>
						<Row className='justify-content-center mt-3'>
							<Col className='text-right '>
								<img className='map-img' src={StopWatch} />
							</Col>
							<Col className='text-left mt-4'>
								<span className='text-primary'>
									No More Waiting
								</span>
							</Col>
						</Row>
						<Row className='justify-content-cener mb-5'>
							<Col className='text-right'>
								<span className='text-primary'>
									Fast Quote, Fast Delivery
								</span>
							</Col>
						</Row>
					</Col>
					<Col md='7' className='text-center'>
						<img src={map} className='img-fluid main-map-img' />
					</Col>
				</Row>
				<Link to={'/quickquote'}>
					<div className='text-center'>
						<Button className='px-5 py-3 mt-4 mb-4 main-btn text-center'>
							<span className='book-now'>Quick Quote</span>
						</Button>
					</div>
				</Link>
				<Row className='mb-5 mt-5'>
					<Col md='6'>
						<Card>
							<CardBody className='text-center'>
								<img
									className='mt-3'
									src={Courierguy}
									alt='courier guy'
									width='200px'
									height='80px'
								/>
								<div className='d-flex justify-content-center'>
									<img
										src={Courierit}
										alt='courierit'
										width='200px'
										height='80px'
									/>
									<img
										src={Dawnwing}
										alt='dawnwing'
										width='200px'
										height='80px'
									/>
								</div>
								<Link to={'/quickquote'}>
									<Button className='btn-quick-quote '>
										QUICK <br /> Courier Quote
									</Button>
								</Link>
							</CardBody>
						</Card>
					</Col>
					<Col md='6' className='mt-5 mt-md-0'>
						<Card>
							<CardBody className='text-center'>
								<img
									className='mt-3'
									src={Courierguy}
									alt='courier guy'
									width='200px'
									height='80px'
								/>
								<div className='d-flex justify-content-center'>
									<img
										src={Courierit}
										alt='courierit'
										width='200px'
										height='80px'
									/>
									<img
										src={Dawnwing}
										alt='dawnwing'
										width='200px'
										height='80px'
									/>
								</div>
								<Link to={'/international'}>
									<Button className='btn-quick-quote'>
										International <br /> Shipment Quote
									</Button>
								</Link>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container> */}
    </>
  );
};

export default Main;
