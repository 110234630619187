const initState = {
	optRates: [],
	optTimeBaseRates: [],
	courierGuyRates: [],
	dawnwingRates: [],
	optRatesLoading: false,
	token: '',
	counters: [],
};

const localReducer = (state = initState, { payload, type }) => {
	switch (type) {
		case 'GET_LOCAL_OPT_RATES':
			return {
				...state,
				optRates: payload.opt_in_rates,
				optTimeBaseRates: payload.opt_in_time_based_rates,
			};
		case 'SET_OPT_RATES_LOADING':
			return { ...state, optRatesLoading: payload };
		case 'GET_LOCAL_RATES':
			return {
				...state,
				courierGuyRates: payload,
			};
		case 'GET_LOCAL_DAWNWING_RATES':
			return {
				...state,
				dawnwingRates: payload,
			};
		case 'CLEAR_LOCAL_RATES':
			return {
				...state,
				optRates: [],
				optTimeBaseRates: [],
			};
		case 'CLEAR_COURIER_GUY_RATES':
			return {
				...state,
				courierGuyRates: [],
			};
		case 'CLEAR_DAWNING_RATES':
			return {
				...state,
				dawnwingRates: [],
			};
		case 'SET_TOKEN':
			return {
				...state,
				token: payload,
			};
		case 'GET_COUNTERS':
			return {
				...state,
				counters: payload,
			};
		default:
			return state;
	}
};

export default localReducer;
