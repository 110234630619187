import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SectionTitle from '../components/SectionTitle';
import ContactOne from '../components/ContactOne';
import Services from '../components/Services';
import BookingPlatform from './BookingPlatform';

function OnlineBookingPlatform() {
	return (
		
		<div className='section_gap booking_section how_it_works'>
			<Container>
				<Row>
					<Col lg='12' className='mb--40'>
						<SectionTitle
							textAlign='text-center'
							title='HOW IT WORKS
                            '
							description='Let us find the best rate for you, compare multiple courier quotes, instantly!'
						/>
					</Col>
				</Row>
				<BookingPlatform textAlign='text-center' />
		</Container>
		</div>
	);
}

export default OnlineBookingPlatform;
