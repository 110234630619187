import React, { useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FooterOne from "../components/FooterOne";
import HeaderOne from "../components/HeaderOne";
import HeaderTop from "../components/HeaderTop";
import { getCountry } from "../store/actions/getCountries";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import FacebookPixel from "../components/FacebookPixel";
function Content(props) {
  const dispatch = useDispatch();
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  const [showNav, setShowNav] = useState(true);

  const hideNav = () => {
    if (window.scrollY >= 50) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };

  const serviceRef = useRef(null);

  const scrollService = () => {
    if (serviceRef.current != null) {
      serviceRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  useEffect(async () => {
    //  await globalAPI(dispatch)
    dispatch(getCountry());
  }, []);
  return (
    <div
      className="main-content-layout"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {/* <Header /> */}
      <FacebookPixel />
      <HeaderTop showNav={showNav} executeScroll={executeScroll} />
      <HeaderOne showNav={showNav} scrollService={scrollService} />
      {/* <div
				className='d-flex align-items-start justify-content-center w-100'
				style={{ minHeight: 'calc(100vh - 84px - 170px) ' }}
			> */}
      <div className="w-100" style={{ flexGrow: 1 }}>
        {React.cloneElement(props.children, { serviceRef })}
      </div>
      {/* </div> */}
      <FooterOne />
    </div>
  );
}

export default Content;
