import { combineReducers } from "redux";
import localReducer from "./localReducer";
import intlReducer from "./intlReducer";
import serviceReducer from "./serviceReducer";
import countriesReducer from "./countriesReducer";
export const rootReducer = combineReducers({
  local: localReducer,
  intl: intlReducer,
  service: serviceReducer,
  countries:countriesReducer
});

export default rootReducer;
