import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import routes from './routes';
import Main from './layout/Main';
import Content from './layout/Content';
import LocalInvoice from './components/QuickQoute/LocalInvoice';
import DeclarationInvoice from './components/International/DeclarationInvoice';
import DeclarationInvoice_pt from './components/International/DeclarationInvoice_pt';
import Invoice from './components/International/Invoice';
import Invoice_pt from './components/International/Invoice_pt';
import { useEffect,useState } from 'react';
import useGtag from './config/gtag';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
	gtmId: 'G-82WCP0MWEG'
  }
  TagManager.initialize(tagManagerArgs)
/*
 *Responsiveness Branch Created
 *6/27/2022
 */

function App() {
	const [selectedCountry, setSelectedCountry] = useState('');
	console.log(window,"windowObject>>>>>>>>>>>>>>>>");
	console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');
  
	useEffect(() => {
	  const country = JSON.parse(localStorage.getItem('Country'));
	  if (country) {
		setSelectedCountry(country);
	  }
	}, []);
	function setCookie(key, value, expiry) {
		var expires = new Date();
		expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
		document.cookie =
			key + '=' + value + ';expires=' + expires.toUTCString();
	}
	useGtag('G-82WCP0MWEG');

	// Triggered when a user visits a page on your website
	useEffect(() => {
	  if (window.gtag) {
		window.gtag('event', 'pageview');
	  }
	}, []);
  	// window.dataLayer.push({
	// 	event: 'pageview'
	// });

	return (
		<>
			<BrowserRouter>
				<Switch>
					{routes.map(route => {
						switch (route.layout) {
							case 'main':
								return (
									<Route exact path={route.path}>
										<Main>
											<route.component />
										</Main>
									</Route>
								);
							case 'content':
								return (
									<Route exact path={route.path}>
										<Content>
											<route.component />
										</Content>
									</Route>
								);
						}
					})}
					<Route path='/local-invoice/:id' component={LocalInvoice} />
					<Route
						path='/international-declaration/:id'
						component={DeclarationInvoice}
					/>
					<Route
						path='/international-declaration-pt/:id'
						component={DeclarationInvoice_pt}
					/>
					<Route
					
						path='/international-invoice/:id'
						component={Invoice}
					/>
					<Route
					
					path='/international-invoice-pt/:id'
					component={Invoice_pt}
				/>
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
