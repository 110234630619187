import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import SectionTitle from "./SectionTitle";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Fedex from "../assets/img/Fedex_approved_logo.png";
import Dpd from "../assets/img/Approved_Logo_Dpd_Laser.png";
import Dhl from "../assets/img/DHL_Logo.png";
import Courierit from "../assets/img/Courierit.png";
import Courierguy from "../assets/img/Approved_Logo_TheCourierGuy.png";
import Ups from "../assets/img/UPS.png";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsivePT = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function OurPartners() {
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);

  const isPortugal = selectedCountry === "PT";

  if (isPortugal) {
    return (
      <div className="section_gap booking_section ">
        <Container>
          <Row>
            <Col lg="12" className="mb--40">
              <SectionTitle textAlign="text-center" title="OUR PARTNERS" />
            </Col>
          </Row>

          <Carousel
            responsive={responsivePT}
            autoPlay={true}
            arrows={false}
            infinite={true}
            className="ourpartners__carousel_pt"
            loop={true}
          >
            <div className="ourpartners__carousel__item ">
              <img src={Fedex} className="logo_img" />
            </div>
            <div className="ourpartners__carousel__item ">
              <img src={Dhl} className="logo_img" />
            </div>
            <div className="ourpartners__carousel__item  partner-carousal-section_pt">
              <img src={Ups} className="ups_img" />
            </div>
          </Carousel>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="section_gap booking_section ">
        <Container lg="fluid">
          <Row>
            <Col lg="12" className="mb--40">
              <SectionTitle textAlign="text-center" title="OUR PARTNERS" />
            </Col>
          </Row>

          <Carousel
            responsive={responsive}
            autoPlay={true}
            arrows={false}
            infinite={true}
            className="ourpartners__carousel "
            loop={true}
            draggable={true}
          >
            <div className="ourpartners__carousel__item">
              <img src={Courierguy} className="logo_img" />
            </div>
            <div className="ourpartners__carousel__item ">
              <img src={Dpd} className=" logo_img" />
            </div>
            <div className="ourpartners__carousel__item  ">
              <img src={Courierit} className="logo_img" />
            </div>

            <div className="ourpartners__carousel__item ">
              <img src={Fedex} className="logo_img" />
            </div>
            <div className="ourpartners__carousel__item ">
              <img src={Dhl} className="logo_img" />
            </div>
            <div className="ourpartners__carousel__item  partner-carousal-section">
              <img src={Ups} className="ups_img" />
            </div>
          </Carousel>
        </Container>
      </div>
    );
  }
}

export default OurPartners;
