import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Fade from 'react-reveal/Fade';

const SectionTitle = ({ title, textAlign, description }) => {
	return (
		<div className={`section-title ${textAlign} mb-4`}>
			<Fade bottom>
				<h1 className='title w-600 mb--20'>{title}</h1>
			</Fade>
			<Fade bottom>
				<p className='description b1 mb-0'>{description}</p>
			</Fade>
		</div>
	);
};
export default SectionTitle;
