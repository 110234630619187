import { Col, Row, Button, Container } from "react-bootstrap";
import TickImg from "../assets/img/tick-green.png";
import Tabs from "../components/Tabs";
import LCLcontainer from "../assets/img/LCLcontainer.jpg";
import Twentyftcontainer from "../assets/img/20ftcontainer.png";
import Fortyftcontainer from "../assets/img/40ftContainer.png";
import HighCube from "../assets/img/HighCube.png";
import twentyftreefer from "../assets/img/20ftreefer.png";
import Fortyftreefer from "../assets/img/40ftreefer.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const SeaFreight = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="pagesHeaderWrapper">
        <h1 className="Shipping-Stuff-heading">
          SHIPPING LARGE, <sapn style={{ color: "#00b7ae" }}> STUFF</sapn>
        </h1>

        <Container className="d-flex justify-content-around flex-wrap">
          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3 className="my-2 pagesHeaderText">Commercial</h3>
          </div>

          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3 className="my-2 pagesHeaderText">Personal Effects</h3>
          </div>

          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3 className="my-2 pagesHeaderText">Home Relocations</h3>
          </div>
          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3 className="my-2 pagesHeaderText">Customs Clearance</h3>
          </div>
        </Container>
      </div>

      <h2 className="font-weight-normal my-3 internationalHeading">
        Cost-effective shipping,
        <sapn style={{ color: "#00b7ae" }}> globally</sapn>
      </h2>

      <Container className="my-3">
        <Row>
          <Col lg={4} md={6}>
            <div className={`cardServices  my-3 `}>
              <div className="imageHeader">
                <img src={LCLcontainer} className="img-fluid" />
              </div>
              <div className="servicescontent">
                <h1 className="servicesHeading">
                  {" "}
                  Less than Container Load (LCL)
                </h1>
                <table class="table table-bordered">
                  {/* <thead className="table-bordered">
                    <tr>
                      <th className="servicesHeading" scope="col" colSpan={2}>
                        Less than Container Load (LCL)
                      </th>
                    </tr>
                  </thead> */}
                  <tbody className="table-bordered">
                    <tr>
                      <td>Tare Weight:</td>
                      <td>10 kg / 22,04 lbs </td>
                    </tr>
                    <tr>
                      <td>Payload Capacity:</td>
                      <td>1000 kg / 22,04.62 lbs </td>
                    </tr>
                    <tr>
                      <td>Cubic Capacity:</td>
                      <td>1 m3 </td>
                    </tr>

                    <tr>
                      <td>Internal Length:</td>
                      <td>1 m / 3.28 ft </td>
                    </tr>

                    <tr>
                      <td>Internal Height:</td>
                      <td>1 m / 3.28 ft </td>
                    </tr>

                    <tr>
                      <td>Internal Width:</td>
                      <td>1 m / 3.28 ft </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col lg={4} md={6}>
            <div className={`cardServices  my-3 `}>
              <div className="imageHeader">
                <img src={Twentyftcontainer} className="img-fluid" />
              </div>
              <div className="servicescontent">
                <h1 className="servicesHeading"> 20' Dry Container</h1>
                <table class="table table-bordered">
                  {/* <thead className="table-bordered">
                    <tr>
                      <th className="servicesHeading" scope="col" colSpan={2}>
                        20' Dry Container
                      </th>
                    </tr>
                  </thead> */}
                  <tbody className="table-bordered">
                    <tr>
                      <td>Tare Weight:</td>
                      <td>2230 kg / 5,071.5 lbs </td>
                    </tr>
                    <tr>
                      <td>Payload Capacity:</td>
                      <td>25,000 kg / 55,126.9 lbs</td>
                    </tr>
                    <tr>
                      <td>Cubic Capacity:</td>
                      <td>33.2 m3</td>
                    </tr>

                    <tr>
                      <td>Internal Length:</td>
                      <td>5.89 m / 19.4 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Height:</td>
                      <td>2.39 m / 7.9 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Width:</td>
                      <td>2.35 m / 7.8 ft</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col lg={4} md={6}>
            <div className={`cardServices  my-3 `}>
              <div className="imageHeader">
                <img src={Fortyftcontainer} className="img-fluid" />
              </div>
              <div className="servicescontent">
                <h1 className="servicesHeading"> 40' Dry Container</h1>
                <table class="table table-bordered">
                  {/* <thead className="table-bordered">
                    <tr>
                      <th className="servicesHeading" scope="col" colSpan={2}>
                        40' Dry Container
                      </th>
                    </tr>
                  </thead> */}
                  <tbody className="table-bordered">
                    <tr>
                      <td>Tare Weight:</td>
                      <td>3750 kg / 8,268.8 lbs</td>
                    </tr>
                    <tr>
                      <td>Payload Capacity:</td>
                      <td>27,600 kg / 61,200 lbs</td>
                    </tr>
                    <tr>
                      <td>Cubic Capacity:</td>
                      <td>67.7 m3</td>
                    </tr>

                    <tr>
                      <td>Internal Length:</td>
                      <td>12.03 m / 39.5 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Height:</td>
                      <td>2.39 m / 7.9 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Width:</td>
                      <td>2.39 m / 7.9 ft</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col lg={4} md={6}>
            <div className={`cardServices  my-3 `}>
              <div className="imageHeader">
                <img src={HighCube} className="img-fluid" />
              </div>
              <div className="servicescontent">
                <h1 className="servicesHeading"> 40' High Cube</h1>
                <table class="table table-bordered">
                  {/* <thead className="table-bordered">
                    <tr>
                      <th className="servicesHeading" scope="col" colSpan={2}>
                        40' High Cube
                      </th>
                    </tr>
                  </thead> */}
                  <tbody className="table-bordered">
                    <tr>
                      <td>Tare Weight:</td>
                      <td>4020 kg / 8268.8 lbs</td>
                    </tr>
                    <tr>
                      <td>Payload Capacity:</td>
                      <td>26,600 kg / 61,200 lbs</td>
                    </tr>
                    <tr>
                      <td>Cubic Capacity:</td>
                      <td>76 m3</td>
                    </tr>

                    <tr>
                      <td>Internal Length:</td>
                      <td>12.03 m / 3.95 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Height:</td>
                      <td>2.84 m / 9.3 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Width:</td>
                      <td>2.35 m / 7.8 ft</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col lg={4} md={6}>
            <div className={`cardServices  my-3 `}>
              <div className="imageHeader">
                <img
                  style={{ width: "230px" }}
                  src={twentyftreefer}
                  className="img-fluid"
                />
              </div>
              <div className="servicescontent">
                <h1 className="servicesHeading"> 20' Reefer</h1>
                <table class="table table-bordered">
                  {/* <thead className="table-bordered">
                    <tr>
                      <th className="servicesHeading" scope="col" colSpan={2}>
                        20' Reefer
                      </th>
                    </tr>
                  </thead> */}
                  <tbody className="table-bordered">
                    <tr>
                      <td>Tare Weight:</td>
                      <td>2480 kg / 5467,464 lbs</td>
                    </tr>
                    <tr>
                      <td>Payload Capacity:</td>
                      <td>27,600 kg / 61,200 lbs</td>
                    </tr>
                    <tr>
                      <td>Cubic Capacity:</td>
                      <td>37.3 m3</td>
                    </tr>

                    <tr>
                      <td>Internal Length:</td>
                      <td>5.34 m / 17.5 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Height:</td>
                      <td>2.54 m / 7.9 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Width:</td>
                      <td>2.35 m / 7.8 ft</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>

          <Col lg={4} md={6}>
            <div className={`cardServices  my-3 `}>
              <div className="imageHeader">
                <img
                  style={{ width: "250px" }}
                  src={Fortyftreefer}
                  className="img-fluid"
                />
              </div>
              <div className="servicescontent">
                <h1 className="servicesHeading"> 40' Reefer</h1>
                <table class="table table-bordered">
                  {/* <thead className="table-bordered">
                    <tr>
                      <th className="servicesHeading" scope="col" colSpan={2}>
                        40' Reefer
                      </th>
                    </tr>
                  </thead> */}
                  <tbody className="table-bordered">
                    <tr>
                      <td>Tare Weight:</td>
                      <td>4660 kg / 8,268.8 lbs</td>
                    </tr>
                    <tr>
                      <td>Payload Capacity:</td>
                      <td>29,600 kg / 61,200 lbs</td>
                    </tr>
                    <tr>
                      <td>Cubic Capacity:</td>
                      <td>58.7 m3</td>
                    </tr>

                    <tr>
                      <td>Internal Length:</td>
                      <td>12.19 m / 39.5 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Height:</td>
                      <td>2.54 m / 7.4 ft</td>
                    </tr>

                    <tr>
                      <td>Internal Width:</td>
                      <td>2.28 m / 7.5 ft</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        style={{ background: "#404b51" }}
        className="buttonWrapper py-2 d-flex  justify-content-center align-items-center"
      >
        <Link to="/contactus">
          <Button
            style={{ fontSize: "25px", padding: "4px 40px" }}
            className="font-weight-bold"
          >
            ENQUIRE TODAY!
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SeaFreight;
