import React, { useState, useEffect, useRef } from "react";
import Logo3 from "../assets/img/Logo3.png";
import Logo_Optimised from "../assets/img/Logo_Optimised.png";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "reactstrap";
import New from "../assets/img/new.svg";
import South from "../assets/img/south.svg";
import Portugal from "../assets/img/portugal.svg";
import { onServiceChange } from "../store/actions/serviceActions";
import { HashLink } from "react-router-hash-link";
import {
  Col,
  Row,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCountry } from "../store/actions/getCountries";
function HeaderOne({ scrollService, showNav, type }) {
  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen((prevState) => !prevState);
  const chooseLanguage = () => {};
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const clickHandle = (url) => {
    history.push(url);
    toggle();
  };
  const scrollToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: 'smooth',
		});
	}
  const ScrollTolocal=()=>{
    const currentUrl=window.location.href;
      const pathSegments = currentUrl.split("/");
      const pageroute= pathSegments[pathSegments.length - 1];
      console.log(pageroute,"currentURL>>>>>");
      if(pageroute!=='/'){
        history.push('/');
      dispatch(onServiceChange("local"));
      scrollToTop();
    }
  }
  const ScrollToInternational = () => {
    const currentUrl = window.location.href;
    const pathSegments = currentUrl.split("/");
    const pageroute = pathSegments[pathSegments.length - 1];
    console.log(pageroute, "currentURL>>>>>");
    if (pageroute !== "/") {
      history.push("/");
      dispatch(onServiceChange("international"));
      scrollToTop();
    }
  };
  const [showServices, setShowServices] = useState(false);
  const [showResources, setShoResources] = useState(false);

  const [selectedOption, setSelectedOption] = useState(selectedCountry);
  useEffect(() => {
    setSelectedOption(selectedCountry);
  }, [selectedCountry]);
  console.log(selectedOption, "selectedOption");
  const handleDropdownChange = async (value) => {
    setSelectedOption(value);
    dispatch(setCountry(value));
    history.push("/");
  };
  const renderImage = () => {
    if (selectedOption === "PT") {
      return (
        <img
          src={Portugal}
          alt="Portugal Flag"
          width={32}
          height={28}
          style={{ borderRadius: "5px" }}
        />
      );
    } else if (selectedOption === "ZA") {
      return (
        <img
          src={South}
          alt="South Africa Flag"
          width={32}
          height={28}
          style={{ borderRadius: "5px" }}
        />
      );
    } else {
      return;
    }
  };
  if (windowsize >= 1024) {
    return (
      <>
        <header className={"main__header sticky"}>
          <div className="position-relative ">
            <Navbar light expand="lg" className=" bg-white">
              <Container className="d-flex w-100 justify-content-between align-items-center ">
                <div>
                  <NavbarBrand href="/">
                    <img
                      src={Logo_Optimised}
                      width={180}
                      height={200}
                      className="main__logo mr-5"
                    />
                  </NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <div className=" w-100 d-flex justify-content-center align-items-center " >
                    <Nav
                      className=" d-flex justify-content-center align-items-center "
                      navbar
                    >
                      <NavItem>
                        <NavLink
                          className="NavLinks"
                          tag={Link}
                          onClick={() => {
                            toggle();
                          }}
                          to="/main"
                          style={{ margin: "0" }}
                        >
                          Home
                        </NavLink>
                      </NavItem>
                      <UncontrolledDropdown
                        onMouseEnter={() => {
                          setShowServices(true);
                        }}
                        onMouseLeave={() => {
                          setShowServices(false);
                        }}
                        onClick={() => {
                          setShowServices(true);
                        }}
                        inNavbar
                        nav
                      >
                        <DropdownToggle caret nav>
                          Services
                        </DropdownToggle>
                        {showServices && (
                          <DropdownMenu className="header__dropdown dropdown_services">
                            <DropdownItem
                              onClick={() => clickHandle("/courierandfreight")}
                            >
                              Int. Courier & Air Freight
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => clickHandle("/seafreight")}
                            >
                              Sea Freight
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                clickHandle("/internationalrelocation")
                              }
                            >
                              International Relocations
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => clickHandle("/shippinglarge")}
                            >
                              Packaging Solutions
                            </DropdownItem>
                            <DropdownItem
                          
                          onClick={() => selectedCountry==='PT'? ScrollToInternational():ScrollTolocal()}
                        >
                       {selectedCountry==='PT'? "Express / Courier Services":"Domestic Courier"}   
                        </DropdownItem>
                          </DropdownMenu>
                        )}
                      </UncontrolledDropdown>

                      <UncontrolledDropdown
                        onMouseEnter={() => {
                          setShoResources(true);
                        }}
                        onMouseLeave={() => {
                          setShoResources(false);
                        }}
                        onClick={() => {
                          setShoResources(true);
                        }}
                        inNavbar
                        nav
                      >
                        <DropdownToggle
                          style={{ position: "relative" }}
                          caret
                          nav
                        >
                          Resources
                        </DropdownToggle>
                        {showResources && (
                          <DropdownMenu className="dropdown_resources">
                            <DropdownItem className="dItem">
                              <h3>GENERAL</h3>
                              <ul>
                                <li
                                  onClick={() =>
                                    clickHandle("/globally-prohibited-items")
                                  }
                                >
                                  PROHIBITED ITEMS
                                </li>
                                <li
                                  onClick={() =>
                                    clickHandle("/packing-guidelines")
                                  }
                                >
                                  PACKING GUIDE
                                </li>
                                <li onClick={() => clickHandle("/faq")}>
                                  FAQs
                                </li>
                              </ul>
                            </DropdownItem>
                            <DropdownItem className="dItem">
                              <h3>TERMS</h3>
                              <ul>
                                <li
                                  onClick={() =>
                                    clickHandle("/terms-and-conditions")
                                  }
                                >
                                  TERMS & CONDITIONS
                                </li>
                                <li
                                  onClick={() => clickHandle("/privacy-policy")}
                                >
                                  PRIVACY POLICY
                                </li>
                              </ul>
                            </DropdownItem>
                            <DropdownItem className="dItem">
                              <h3>MORE ABOUT US</h3>
                              <ul>
                                <li
                                  onClick={() => {
                                    clickHandle("/contactus");
                                  }}
                                >
                                  CONTACT US
                                </li>
                              </ul>
                            </DropdownItem>
                          </DropdownMenu>
                        )}
                      </UncontrolledDropdown>
                      <NavItem>
                        <NavLink
                          className="NavLinks"
                          tag={Link}
                          onClick={() => toggle()}
                          to="/contactus"
                          style={{ margin: "0" }}
                        >
                          Contact Us
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className="NavLinks"
                          tag={Link}
                          onClick={() => toggle()}
                          to="/trackparcel"
                          style={{ margin: "5px" }}
                        >
                          Track Parcel
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  {/* </Collapse> */}
                  <div className="w-lg-25 mr-lg-5  ml-4 ">
                    <Nav
                      className="d-flex justify-content-end align-items-center  mr-lg-3   "
                      navbar
                    >
                      <NavItem>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown}>
                          <DropdownToggle caret color="white">
                            {renderImage()}
                          </DropdownToggle>
                          <DropdownMenu className=" mt-4 my-dropdown-menu">
                            <DropdownItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px",
                              }}
                              onClick={() => handleDropdownChange("ZA")}
                            >
                              <img
                                src={South}
                                height={28}
                                width={32}
                                style={{ borderRadius: "5px" }}
                                alt="South flag"
                              />
                            </DropdownItem>
                            <DropdownItem
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "5px",
                              }}
                              onClick={() => handleDropdownChange("PT")}
                            >
                              <img
                                src={Portugal}
                                height={28}
                                width={32}
                                style={{ borderRadius: "5px" }}
                                alt="Portugal Flag"
                              />
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </NavItem>
                    </Nav>
                  </div>
                </Collapse>
              </Container>
            </Navbar>
          </div>
        </header>
      </>
    );
  } else {
    return (
      <>
        <header className={"main__header sticky"}>
          <div className="position-relative ">
            <Navbar light expand="lg" className=" bg-white">
              <Container className="d-flex flex-nowrap w-100 justify-content-between align-items-center ">
                <div>
                  <NavbarBrand href="/">
                    <img
                      src={Logo_Optimised}
                      width={180}
                      height={200}
                      className="main__logo mr-5"
                    />
                  </NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle} />
              </Container>
              <Collapse isOpen={isOpen} navbar>
                <div className=" w-100 d-flex justify-content-center align-items-center  ">
                  <Nav
                    className=" d-flex justify-content-center align-items-center  "
                    navbar
                  >
                    <NavItem>
                      <NavLink
                        className="NavLinks"
                        tag={Link}
                        onClick={() => {
                          toggle();
                        }}
                        to="/main"
                        style={{ margin: "0" }}
                      >
                        Home
                      </NavLink>
                    </NavItem>
                    <UncontrolledDropdown
                      onMouseEnter={() => {
                        setShowServices(true);
                      }}
                      onMouseLeave={() => {
                        setShowServices(false);
                      }}
                      onClick={() => {
                        setShowServices(true);
                      }}
                      inNavbar
                      nav
                    >
                      <DropdownToggle caret nav>
                        Services
                      </DropdownToggle>
                      {showServices && (
                        <DropdownMenu className="header__dropdown dropdown_services">
                          <DropdownItem
                            onClick={() => clickHandle("/courierandfreight")}
                          >
                            Int. Courier & Air Freight
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => clickHandle("/seafreight")}
                          >
                            Sea Freight
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              clickHandle("/internationalrelocation")
                            }
                          >
                            International Relocations
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => clickHandle("/shippinglarge")}
                          >
                            Packaging Solutions
                          </DropdownItem>
                          <DropdownItem
                          
                            onClick={() => selectedCountry==='PT'? ScrollToInternational():ScrollTolocal()}
                          >
                         {selectedCountry==='PT'? "Express / Courier Services":"Domestic Courier"}   
                          </DropdownItem>
                        </DropdownMenu>
                      )}
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                      onMouseEnter={() => {
                        setShoResources(true);
                      }}
                      onMouseLeave={() => {
                        setShoResources(false);
                      }}
                      onClick={() => {
                        setShoResources(true);
                      }}
                      inNavbar
                      nav
                    >
                      <DropdownToggle
                        style={{ position: "relative" }}
                        caret
                        nav
                      >
                        Resources
                      </DropdownToggle>
                      {showResources && (
                        <DropdownMenu className="dropdown_resources">
                          <DropdownItem className="dItem">
                            <h3>GENERAL</h3>
                            <ul>
                              <li
                                onClick={() =>
                                  clickHandle("/globally-prohibited-items")
                                }
                              >
                                PROHIBITED ITEMS
                              </li>
                              <li
                                onClick={() =>
                                  clickHandle("/packing-guidelines")
                                }
                              >
                                PACKING GUIDE
                              </li>
                              <li onClick={() => clickHandle("/faq")}>FAQs</li>
                            </ul>
                          </DropdownItem>
                          <DropdownItem className="dItem">
                            <h3>TERMS</h3>
                            <ul>
                              <li
                                onClick={() =>
                                  clickHandle("/terms-and-conditions")
                                }
                              >
                                TERMS & CONDITIONS
                              </li>
                              <li
                                onClick={() => clickHandle("/privacy-policy")}
                              >
                                PRIVACY POLICY
                              </li>
                            </ul>
                          </DropdownItem>
                          <DropdownItem className="dItem">
                            <h3>MORE ABOUT US</h3>
                            <ul>
                              <li
                                onClick={() => {
                                  clickHandle("/contactus");
                                }}
                              >
                                CONTACT US
                              </li>
                            </ul>
                          </DropdownItem>
                        </DropdownMenu>
                      )}
                    </UncontrolledDropdown>
                    <NavItem>
                      <NavLink
                        className="NavLinks "
                        tag={Link}
                        onClick={() => toggle()}
                        to="/contactus"
                        style={{ margin: "0" }}
                      >
                        Contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className="NavLinks"
                        tag={Link}
                        onClick={() => toggle()}
                        to="/trackparcel"
                        style={{ margin: "0" }}
                      >
                        Track Parcel
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                {/* </Collapse> */}
                <div className="w-lg-25 mr-lg-5 ">
                  <Nav
                    className="d-flex justify-content-end align-items-center mr-lg-3 "
                    navbar
                  >
                    <NavItem>
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropDown}
                        className=" country-dropdown"
                      >
                        <DropdownToggle caret color="white">
                          {renderImage()}
                        </DropdownToggle>
                        <DropdownMenu className=" mt-4 my-dropdown-menu">
                          <DropdownItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px",
                            }}
                            onClick={() => handleDropdownChange("ZA")}
                          >
                            <img
                              src={South}
                              height={28}
                              width={32}
                              style={{ borderRadius: "5px" }}
                              alt="South flag"
                            />
                          </DropdownItem>
                          <DropdownItem
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px",
                            }}
                            onClick={() => handleDropdownChange("PT")}
                          >
                            <img
                              src={Portugal}
                              height={28}
                              width={32}
                              style={{ borderRadius: "5px" }}
                              alt="Portugal Flag"
                            />
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </NavItem>
                  </Nav>
                </div>
              </Collapse>
            </Navbar>
          </div>
        </header>
      </>
    );
  }
}

export default HeaderOne;
