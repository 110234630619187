import React, { useState, useEffect } from "react";
import QuoteCountrySelection from "../components/QuickQoute/QouteCountrySelection";
import { Container, Row, Col, Button, Spinner, Form } from "reactstrap";
import qs from "query-string";
import QuoteItemDetails from "../components/QuickQoute/QuoteItemDetails";
import QuoteEstimates from "../components/QuickQoute/QuotEstimates";
import QuoteCalender from "../components/QuickQoute/QuoteCalender";
import AddressDetails from "../components/QuickQoute/AddressDetails";
import ConfirmBooking from "../components/QuickQoute/ConfirmBooking";
import Footer from "../components/Footer";
import QuoteInvoice from "../components/QuickQoute/QuoteInvoice";
import QuotePaymentUI from "../components/QuickQoute/QuotePaymentUI";
import { NavLink } from "react-router-dom";
import axios from "axios";
import IncompleteTransaction from "../components/QuickQoute/Incompleteransaction";
import ThemeButton from "../components/ThemeButton";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import firebase from "../config/firebase";

import dawnwingOptionalRates from "../config/dawnwingOptionalRates.json";

import { useSelector, useDispatch } from "react-redux";
import {
  getCourierRates,
  getDawningToken,
  getDawningRates,
  getCounters,
} from "../store/actions/localActions";
import { paperClasses } from "@mui/material";
// import { getCourierRates } from '../config/dbConnection';
import CountrySelection from "../components/International/CountrySelection";
import { scroller } from "react-scroll";

function QuickQuote() {
  let [activeTab, setActiveTab] = useState(1);
  const [reference, setReference] = useState("");
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [firstPage, setFirstPage] = useState([]);
  const [secondPage, setSecondPage] = useState([
    { description: "", weight: "", height: "", length: "", width: "" },
  ]);
  const triggerGtagEvent = (eventName, eventParams) => {
    if (window.gtag) {
      // window.gtag("event_name", eventName, eventParams);
      window.gtag("event", eventName, {
        ...eventParams,
        debug_mode: true, // Add debug_mode=true for DebugView
      });
    }
  };
  const [opt_in_rates, setOptInRates] = useState([]);
  const [opt_in_time_based_rates, setOptTimeBaseRates] = useState([]);

  const optRateChange = (val) => {
    if (val == "") {
      setOptInRates([]);
    } else {
      setOptInRates([val]);
    }
  };

  const clearRates = () => {
    setOptInRates([]);
    setOptTimeBaseRates([]);
  };

  const optTimeRateChange = (val, name) => {
    if (opt_in_time_based_rates.length > 0) {
      let newArr = [...opt_in_time_based_rates];
      let obj = opt_in_time_based_rates.find((rt) => rt == val);
      if (obj) {
        let filteredData = newArr.filter((rt) => rt != val);
        setOptTimeBaseRates(filteredData);
      } else if (newArr.find((ar) => ar.type == name)) {
        let filteredData = newArr.filter((rt) => rt.type != name);
        setOptTimeBaseRates([...filteredData, val]);
      } else {
        setOptTimeBaseRates([...newArr, val]);
      }
    } else {
      setOptTimeBaseRates([...opt_in_time_based_rates, val]);
    }
    // setOptTimeBaseRates({ ...opt_in_time_based_rates, [name]: val });
    // let newArr = [...opt_in_time_based_rates];
    // let obj = newArr.find(arr => arr == val);
    // if (obj) {
    // 	let filteredArr = newArr.filter(arr => arr != val);
    // 	setOptTimeBaseRates(filteredArr);
    // } else {
    // 	setOptTimeBaseRates([...newArr, val]);
    // }
  };

  const dispatch = useDispatch();

  const { optRates, optTimeBaseRates, rates, counters } = useSelector(
    (state) => state.local
  );

  const [secondPageLoader, setSecondPageLoader] = useState(false);
  const [thirdPage, setThirdPage] = useState([]);
  const [fourthPage, setFourthPage] = useState([]);
  const [fifthPage, setFifthPage] = useState([]);
  const [sixthPage, setSixthPage] = useState([]);
  const [queryData, setQueryData] = useState([]);
  const [firstCompleted, setFirstCompleted] = useState(false);
  const [secondCompleted, setSecondCompleted] = useState(false);
  const [thirdCompleted, setThirdCompleted] = useState(false);
  const [fourthCompleted, setFourthCompleted] = useState(false);
  const [insuranceData, setInsuranceData] = useState({
    insurance: false,
    insuranceDesc: "",
    insuranceValue: "",
  });

  const [deliveryType, setDeliveryType] = useState("residential");

  const [packageType, setPackageType] = useState("non-document");

  const packageTypeChange = (value) => {
    if (value == "document") {
      setSecondPage([
        {
          length: "40",
          width: "35",
          height: "2",
          description: "",
          weight: "",
        },
      ]);
      setPackageType(value);
    } else {
      setSecondPage([
        {
          length: "",
          width: "",
          height: "",
          description: "",
          weight: "",
        },
      ]);
      setPackageType(value);
    }
  };
  const insuranceOnChange = (name, val) => {
    if (name == "insurance" && val == false) {
      setInsuranceData({
        insurance: false,
        insuranceValue: "",
        insuranceDesc: "",
      });
    } else {
      setInsuranceData({ ...insuranceData, [name]: val });
    }
  };

  // const insuranceOnChange = val => {
  // 	if (val.insurance) {
  // 		setInsuranceData(val);
  // 	} else {
  // 		setInsuranceData({
  // 			insurance: false,
  // 			insuranceDesc: '',
  // 			insuranceValue: '',
  // 		});
  // 	}
  // };

  const deliveryTypeChange = (val) => {
    setDeliveryType(val);
  };

  const [bool, setBool] = useState(false);
  const [paymentData, setPaymentData] = useState({
    paymentRef: { REFERENCE: "" },
    redirectUri: "",
    redirectParams: {
      PAY_REQUEST_ID: "",
      CHECKSUM: "",
    },
  });

  //Scroll To Top
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);

  // const localfirstPage = JSON.parse(localStorage.getItem('local_first_page'));
  const localfirstPage = JSON.parse(
    localStorage.getItem("local_country_selection")
  );

  const getTotalSurcharge = () => {
    let surcharge = 0;
    localfirstPage.opt_in_time_based_rates.forEach(
      (rate) => (surcharge += Number(rate.charge_value))
    );
    localfirstPage.opt_in_rates.forEach(
      (rate) => (surcharge += Number(rate.charge_value))
    );
    return surcharge;
  };

  const localsecondPage = JSON.parse(localStorage.getItem("local_second_page"));
  const localthirdPage = JSON.parse(localStorage.getItem("local_third_page"));

  const localfourthPage = JSON.parse(localStorage.getItem("local_fourth_page"));

  const localInsuranceData = JSON.parse(
    localStorage.getItem("local_insurance_data")
  );

  const localfifthPage = JSON.parse(localStorage.getItem("local_fifth_page"));
  const localsixthPage = JSON.parse(localStorage.getItem("local_sixth_page"));
  const localSeventhPage = JSON.parse(
    localStorage.getItem("local_seventh_page")
  );

  const paymentPage = JSON.parse(localStorage.getItem("payment"));

  const handleFirstPage = (obj) => {
    setFirstPage((prevState) => [...prevState, obj]);
  };

  const [localCountrySelection, setLocalCountrySelection] = useState({
    fromRegion: "",
    toRegion: "",
    toLocalArea: "",
    fromLocalArea: "",
    fromCode: "",
    toCode: "",
    fromStreet: "",
    toStreet: "",
    fromSuburb: "",
    toSuburb: "",
  });

  const [collection_address, setCollectionAddress] = useState({});
  const [delivery_address, setDeliveryAddress] = useState({});

  const countrySelectionChange = (name, val) => {
    if (name == "fromRegion") {
      setLocalCountrySelection((prevState) => ({
        ...prevState,
        [name]: val,
        fromLocalArea: "",
        fromCode: "",
      }));
    } else if (name == "toRegion") {
      setLocalCountrySelection((prevState) => ({
        ...prevState,
        [name]: val,
        toLocalArea: "",
        toCode: "",
      }));
    } else {
      setLocalCountrySelection((prevState) => ({
        ...prevState,
        [name]: val,
      }));
    }
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...secondPage];
    list.splice(index, 1);
    setSecondPage(list);
  };
  // handle click event of the Add button

  const handleAddClick = () => {
    setSecondPage([
      ...secondPage,

      { description: "", weight: "", height: "", length: "", width: "" },
    ]);
  };

  // const handleSecondPage = obj => {
  // 	setSecondPage(prevState => [...prevState, obj]);
  // };

  const handleSecondPage = (idx, key, val) => {
    let newArr = [...secondPage];

    if (key != "description") {
      if (val) {
        newArr[idx][key] = parseFloat(val);
      } else {
        newArr[idx][key] = val;
      }
    } else {
      newArr[idx][key] = val;
    }
    setSecondPage(newArr);
  };

  const [localThirdPage, setLocalThirdPage] = useState({
    type: "economy",
    selectedCourier: {
      BaseRate: 0,
      SurchargeRate: 0,
      TotalRate: 0,
      Name: "",
    },
  });

  const handleThirdPage = (obj) => {
    setLocalThirdPage((prevState) => ({
      ...prevState,
      selectedCourier: obj,
    }));
  };

  const handelTypeChange = (val) => {
    setLocalThirdPage((prevState) => ({
      ...prevState,
      type: val,
    }));
  };
  const handleFourthPage = (obj) => {
    setFourthPage((prevState) => [...prevState, obj]);
  };
  const handleFifthPage = (obj) => {
    setFifthPage((prevState) => [...prevState, obj]);
  };
  const handleSixthPage = (obj) => {
    setSixthPage((prevState) => [...prevState, obj]);
  };

  const handleSubmit1 = () => {
    setActiveTab(1);
  };
  const handleSubmit2 = () => {
    if (firstCompleted) {
      setActiveTab(2);
    }
  };
  const handleSubmit3 = () => {
    if (secondCompleted) {
      setActiveTab(3);
    } else {
    }
  };
  const handleSubmit4 = () => {
    if (thirdCompleted) {
      setActiveTab(4);
    }
  };
  const getResultData = async (obj) => {
    setSecondPageLoader(true);
    const response = await axios.post(
      `${process.env.REACT_APP_DEVELOPMENT}/courier`,
      obj
    );
    setQueryData(response.data);
    setSecondPageLoader(false);
  };
  const [loader, setLoader] = useState(false);

  const { courierGuyRates, dawnwingRates } = useSelector(
    (state) => state.local
  );
  useEffect(() => {
    if (counters && counters.length == 0) {
      dispatch(getCounters(process.env.REACT_APP_COUNTERS));
    }
  }, []);

  // Local Initial Data

  const [localFirstPage, setLocalFirstPage] = useState({
    fromRegion: "",
    toRegion: "",
    fromSuburbPost: "",
    toSuburbPost: "",
  });

  const [localinsuranceData, setLocalinsuranceData] = useState({
    insurance: false,
    insuranceDesc: "",
    insuranceValue: "",
  });

  const [localSecondPage, setLocalSecondPage] = useState({
    description: "",
    totalWeight: "",
    volumetricWeight: "",
    itemDetails: [{ weight: "", height: "", length: "", width: "" }],
  });

  const [localFourthPage, setLocalFourthPage] = useState({ startDate: "" });
  const [localFifthPage, setLocalFifthPage] = useState({
    sendingName: "",
    sendingSurName: "",
    sendingContact: "",
    sendingComplexNo: "",
    sendingCity: "",
    sendingAddress2: "",
    sendingAddress1: "",
    RecievingSurName: "",
    RecievingName: "",
    RecievingContact: "",
    RecievingAddress1: "",
    RecievingAddress2: "",
    RecievingComplexNo: "",
    RecievingCity: "",
  });
  const [localSixthPage, setLocalSixthPage] = useState({ email: "" });
  const [localSventhPage, setLocalSeventhPage] = useState({ url: "" });
  const [localPayment, setLocalPayment] = useState({
    paymentRef: { REFERENCE: "" },
    redirectParams: { CHECKSUM: "", PAY_REQUEST_ID: "" },
    redirectUri: "",
  });

  const clearStorage = () => {
    localStorage.setItem("local_first_page", JSON.stringify(localFirstPage));

    localStorage.setItem("local_second_page", JSON.stringify(localSecondPage));

    localStorage.setItem("local_third_page", JSON.stringify(localThirdPage));

    localStorage.setItem("local_fourth_page", JSON.stringify(localFourthPage));

    localStorage.setItem("local_fifth_page", JSON.stringify(localFifthPage));

    localStorage.setItem("local_sixth_page", JSON.stringify(localSixthPage));

    localStorage.setItem("payment", JSON.stringify(localPayment));

    localStorage.setItem("local_seventh_page", JSON.stringify(localSventhPage));

    localStorage.setItem(
      "local_insurance_data",
      JSON.stringify(localinsuranceData)
    );
  };

  const checkValues = () => {
    if (
      localCountrySelection.fromRegion == "" ||
      localCountrySelection.toRegion == "" ||
      localCountrySelection.toCode == "" ||
      localCountrySelection.fromCode == "" ||
      localCountrySelection.fromStreet == "" ||
      localCountrySelection.toStreet == "" ||
      localCountrySelection.fromLocalArea == "" ||
      localCountrySelection.toLocalArea == ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Clear Data Section

  const clearItemDetails = () => {
    setSecondPage([
      {
        length: "",
        width: "",
        height: "",
        description: "",
        weight: "",
      },
    ]);
    setPackageType("non-document");
    setInsuranceData({
      insurance: false,
      insuranceDesc: "",
      insuranceValue: "",
    });
  };

  const checkTotalWeight = () => {
    let totalWeight = 0;
    secondPage.map((il) => {
      if (il.weight != "") {
        totalWeight += Number(il.weight);
      }
    });
    if (totalWeight != 0) {
      return totalWeight.toFixed(1);
    } else {
      return totalWeight;
    }
  };

  const checkVolumetricWeight = () => {
    let totalVol = 0;
    secondPage.map((vol) => {
      totalVol += (vol.length * vol.width * vol.height) / 5000;
    });

    if (totalVol >= 0.1) {
      return totalVol.toFixed(1);
    } else if (totalVol > 0 && totalVol < 0.1) {
      return totalVol.toFixed(3);
    } else {
      return totalVol;
    }
  };

  useEffect(() => {
    const ref = qs.parse(window.location.search);

    if (Object.keys(ref).length) {
      setBool(true);
      setReference(ref.REFERENCE);
    } else {
      setLoader(false);
    }

    if (bool) {
      setLoader(true);
      axios
        .post(`${process.env.REACT_APP_DEVELOPMENT}/payment/status`, {
          paymentRef: paymentPage.paymentRef,
        })
        .then(async (res) => {
          if (res.data.success) {
            if (res.data.data.TRANSACTION_STATUS == "1") {
              // await clearStorage();
              setLoader(false);
              console.log("Active__Tab run...8");
              setActiveTab("8");
            } else {
              await clearStorage();
              setLoader(false);
              setActiveTab("1");
            }
          } else {
            await clearStorage();
            setLoader(false);
            setActiveTab("1");
          }
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [window.location.search, bool]);

  console.log("Payment....", paymentPage);

  useEffect(() => {
    scroller.scrollTo("selectedCourierSection", {
      smooth: true,
      offset: -85,
    });
  }, [activeTab]);

  return (
    <>
      <Container fluid>
        {loader ? (
          <div className="d-flex justify-content-center align-items-center py-5">
            <Spinner size="lg" />
          </div>
        ) : (
          <>
            {/* <Row
              className={`justify-content-center mt-2 ${
                activeTab == 8 || activeTab == 9 ? "d-none" : ""
              }`}
            >
              <Col align="center">
                <div className="d-flex main_stepper ">
                  <div
                    onClick={handleSubmit1}
                    className={`${
                      activeTab == 1 ? "active" : "inactive"
                    } span__text `}
                  >
                    <span>1.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Origin & Destination
                    </p>
                  </div>
                  <div
                    onClick={handleSubmit2}
                    className={`${
                      activeTab == 2 ? "active" : "inactive"
                    } span__text `}
                  >
                    <span>2.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Shipment Details
                    </p>
                  </div>
                  <div
                    onClick={handleSubmit3}
                    className={`${
                      activeTab == 3 ? "active" : "inactive"
                    } span__text `}
                  >
                    <span> 3.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Quote Estimates
                    </p>
                  </div>
                  <div
                    onClick={handleSubmit4}
                    className={`${
                      activeTab == 4 || activeTab == 5 || activeTab == 6
                        ? "active"
                        : "inactive"
                    } span__text`}
                  >
                    <span>4.</span>
                    <p className="d-none d-sm-block text-uppercase">
                      Confirm Booking
                    </p>
                  </div>
                </div>
              </Col>

              
            </Row> */}

            <Row className="justify-content-center ">
              <Col lg="12" align="center">
                {loader ? (
                  <Spinner size="lg" />
                ) : (
                  <Form autoComplete="off">
                    <div
                      className={`${
                        activeTab == "1"
                          ? "d-block main_card country-step d-flex justify-content-center align-items-center"
                          : "d-none"
                      }`}
                      id="step1"
                    >
                      <QuoteCountrySelection
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        clearRates={clearRates}
                        value={localCountrySelection}
                        onChange={countrySelectionChange}
                        checkValues={checkValues}
                        optRateChange={optRateChange}
                        opt_in_rates={opt_in_rates}
                        opt_in_time_based_rates={opt_in_time_based_rates}
                        collectionAddress={collection_address}
                        deliveryAddress={collection_address}
                        optTimeRateChange={optTimeRateChange}
                        setDeliveryAddress={setDeliveryAddress}
                        setCollectionAddress={setCollectionAddress}
                        deliveryTypeChange={deliveryTypeChange}
                      />

                      <Button
                        color="secondary"
                        onClick={() => {
                          triggerGtagEvent("Domestic_Shipment_Details", {
                            step: 1,
                          });
                          // const obj = Object.assign(
                          // 	{},
                          // 	...firstPage
                          // );

                          if (checkValues() == false) {
                            alert("Please select all fields!");
                          } else {
                            let newArr = [];
                            if (opt_in_rates.length > 0) {
                              let obj = optRates.find(
                                (rate) => rate.id == opt_in_rates[0]
                              );
                              if (obj) {
                                newArr.push(obj);
                              }
                            }
                            localStorage.setItem(
                              "local_country_selection",
                              JSON.stringify({
                                ...localCountrySelection,
                                fromCode: localCountrySelection.fromCode
                                  .split(",")[1]
                                  .trim(),
                                toCode: localCountrySelection.toCode
                                  .split(",")[1]
                                  .trim(),
                                deliveryType,
                                opt_in_rates: newArr,
                                opt_in_time_based_rates,
                              })
                            );
                            setFirstCompleted(true);
                            setActiveTab("2");
                          }
                        }}
                        className="step1-btn mr-5"
                        size="lg"
                      >
                        <i className="fas fa-arrow-right"></i>
                      </Button>
                    </div>
                  </Form>
                )}

                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    triggerGtagEvent("Domestic_Item_Details", {
                      step: 2,
                    });
                    let courierGuyPayload = {};
                    let dawnwingPayload = {};

                    let firstPageData = JSON.parse(
                      localStorage.getItem("local_country_selection")
                    );
                    let courierParcels = [];
                    let dawningParcels = [];
                    let timeRates = [];
                    let optionalRates = [];
                    let dawningSurcharges = [];

                    firstPageData.opt_in_rates.forEach((rate) => {
                      optionalRates.push(rate.id);
                      let obj = dawnwingOptionalRates.opt_in.find(
                        (rt) => rt.name.toLowerCase() == rate.name.toLowerCase()
                      );
                      if (obj) {
                        dawningSurcharges.push({
                          code: obj.code,
                        });
                      }
                    });
                    firstPageData.opt_in_time_based_rates.forEach((rate) => {
                      timeRates.push(rate.id);
                      let obj = dawnwingOptionalRates.opt_time_based.find(
                        (rt) => rt.name.toLowerCase() == rate.name.toLowerCase()
                      );
                      if (obj) {
                        dawningSurcharges.push({
                          code: obj.code,
                        });
                      }
                    });

                    secondPage.forEach((data) => {
                      courierParcels.push({
                        parcel_description: data.description,
                        submitted_weight_kg: data.weight,
                        submitted_height_cm: data.height,
                        submitted_length_cm: data.length,
                        submitted_width_cm: data.width,
                      });
                      dawningParcels.push({
                        mass: data.weight,
                        length: data.length,
                        breadth: data.width,
                        height: data.height,
                        units: 1,
                      });
                    });

                    if (insuranceData.insurance) {
                      courierGuyPayload = {
                        parcels: courierParcels,
                        delivery_address,
                        collection_address,
                        packageType,
                        totalWeight: 0,
                        volumetricWeight: 0,
                        opt_in_rates: optionalRates,
                        opt_in_time_based_rates: timeRates,
                        declared_value: Number(insuranceData.insuranceValue),
                      };

                      dawnwingPayload = {
                        receiverSite: "",
                        receiverProvince: firstPageData.toRegion,
                        senderUnit: "",
                        senderStreet: firstPageData.fromStreet,
                        senderSite: "",
                        senderProvince: firstPageData.fromRegion,
                        senderSuburb: firstPageData.fromLocalArea,
                        senderCity: firstPageData.fromLocalArea,
                        senderPostCode: firstPageData.fromCode,
                        senderContact: "Paul",
                        sender: "Dawnwnig",
                        senderCell: "0871234567",
                        senderTel: "000000000",
                        senderEmail: "receiver@mail.co.za",
                        timeReady: "08:00:00",
                        timeClosed: "17:00:00",
                        receiverEmail: "me@mail.co.za",
                        receiverTel: "0987654321",
                        accountName: "WRDPP038",
                        accountNo: "WRDPP038",
                        collectionDate: new Date().toISOString(),
                        parcels: dawningParcels,
                        surcharges: dawningSurcharges,
                        mass: 0,
                        insuranceRequired: true,
                        insuranceValue: insuranceData.insuranceValue,
                        cod: 0,
                        deliveryType: "D/D",
                        invoiceValue: 0,
                        receiverUnit: "1",
                        receiverStreet: firstPageData.toStreet,
                        receiverSuburb: firstPageData.toLocalArea,
                        receiverCity: firstPageData.toLocalArea,
                        receiverPostCode: firstPageData.toCode,
                        receiverContact: "",
                        receiver: "",
                        receiverCell: "",
                      };
                    } else {
                      courierGuyPayload = {
                        parcels: courierParcels,
                        delivery_address,
                        collection_address,
                        packageType,
                        totalWeight: 0,
                        volumetricWeight: 0,
                        opt_in_rates: optionalRates,
                        opt_in_time_based_rates: timeRates,
                      };

                      dawnwingPayload = {
                        receiverSite: "",
                        receiverProvince: firstPageData.toRegion,
                        senderUnit: "",
                        senderStreet: firstPageData.fromStreet,
                        senderSite: "",
                        senderProvince: firstPageData.fromRegion,
                        senderSuburb: firstPageData.fromLocalArea,
                        senderCity: firstPageData.fromLocalArea,
                        senderPostCode: firstPageData.fromCode,
                        senderContact: "Paul",
                        sender: "Dawnwnig",
                        senderCell: "0871234567",
                        senderTel: "000000000",
                        senderEmail: "receiver@mail.co.za",
                        timeReady: "08:00:00",
                        timeClosed: "17:00:00",
                        receiverEmail: "me@mail.co.za",
                        receiverTel: "0987654321",
                        accountName: "WRDPP038",
                        accountNo: "WRDPP038",
                        collectionDate: new Date().toISOString(),
                        parcels: dawningParcels,
                        surcharges: dawningSurcharges,
                        mass: 0,
                        insuranceRequired: false,
                        insuranceValue: 0,
                        cod: 0,
                        deliveryType: "D/D",
                        invoiceValue: 0,
                        receiverUnit: "1",
                        receiverStreet: firstPageData.toStreet,
                        receiverSuburb: firstPageData.toLocalArea,
                        receiverCity: firstPageData.toLocalArea,
                        receiverPostCode: firstPageData.toCode,
                        receiverContact: "",
                        receiver: "",
                        receiverCell: "",
                      };
                    }

                    let totalWeight = checkTotalWeight();
                    // let totalweightArray =
                    // 	courierGuyPayload.parcels.map(
                    // 		id => {
                    // 			totalWeight =
                    // 				totalWeight +
                    // 				Number(
                    // 					id.submitted_weight_kg
                    // 				);
                    // 		}
                    // 	);

                    let totalVolumetricWeight = checkVolumetricWeight();

                    // courierGuyPayload.parcels.map(id => {
                    // 	totalVolumetricWeight +=
                    // 		(id.submitted_height_cm *
                    // 			id.submitted_length_cm *
                    // 			id.submitted_width_cm) /
                    // 		5000;
                    // });
                    courierGuyPayload.volumetricWeight = totalVolumetricWeight;

                    courierGuyPayload.totalWeight = totalWeight;
                    let weightObj = {
                      total_weight: Number(totalWeight),
                      vol_weight: Number(totalVolumetricWeight),
                    };
                    let courierSurcharges = getTotalSurcharge();

                    dawnwingPayload.mass = totalWeight;

                    setSecondPageLoader(true);
                    let doc_id = uuidv4();
                    await dispatch(
                      getCourierRates(
                        courierGuyPayload,
                        courierSurcharges,
                        weightObj,
                        doc_id,
                        (courierData) => {
                          dispatch(
                            getDawningRates(
                              dawnwingPayload,
                              weightObj,
                              doc_id,
                              process.env.REACT_APP_DEVELOPMENT,
                              (dawnwingData) => {
                                localStorage.setItem(
                                  "local_second_page",
                                  JSON.stringify({
                                    itemDetails: secondPage,
                                    packageType: packageType,
                                    totalWeight: courierGuyPayload.totalWeight,
                                    volumetricWeight:
                                      courierGuyPayload.volumetricWeight,
                                  })
                                );
                                localStorage.setItem(
                                  "local_insurance_data",
                                  JSON.stringify(insuranceData)
                                );
                                setSecondPageLoader(false);
                                setSecondCompleted(true);

                                setActiveTab("3");

                                if (
                                  (courierData.uiPrices &&
                                    courierData.uiPrices.length > 0) ||
                                  (dawnwingData.uiPrices &&
                                    dawnwingData.uiPrices.length > 0)
                                ) {
                                  let courierEconomy = [];
                                  let courierEconomyApi = [];
                                  let courierOverNight = [];
                                  let courierOverNightApi = [];
                                  let courierSameDayExpress = [];
                                  let courierSameDayExpressApi = [];
                                  let courierOverNightFlyer = [];
                                  let courierOverNightFlyerApi = [];

                                  let dpdEconomy = [];
                                  let dpdEconomyApi = [];
                                  let dpdOverNight = [];
                                  let dpdOverNightApi = [];

                                  let dpdSameDayExpress = [];
                                  let dpdOverNightFlyer = [];

                                  courierData?.uiPrices?.forEach((courier) => {
                                    if (
                                      courier.code == "ECO" ||
                                      courier.code == "ECON"
                                    ) {
                                      courierEconomy.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (
                                      courier.code == "ONX" ||
                                      courier.code == "LOX"
                                    ) {
                                      courierOverNight.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (courier.code == "LSE") {
                                      courierSameDayExpress.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (courier.code == "LOF") {
                                      courierOverNightFlyer.push({
                                        totalRate: courier.rate,
                                      });
                                    }
                                  });

                                  courierData?.apiPrices?.forEach((courier) => {
                                    if (
                                      courier.service_level.code == "ECO" ||
                                      courier.service_level.code == "ECON"
                                    ) {
                                      courierEconomyApi.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (
                                      courier.service_level.code == "ONX" ||
                                      courier.service_level.code == "LOX"
                                    ) {
                                      courierOverNightApi.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (
                                      courier.service_level.code == "LSE"
                                    ) {
                                      courierSameDayExpressApi.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (
                                      courier.service_level.code == "LOF"
                                    ) {
                                      courierOverNightFlyerApi.push({
                                        totalRate: courier.rate,
                                      });
                                    }
                                  });

                                  dawnwingData?.uiPrices?.forEach((courier) => {
                                    if (
                                      courier.code == "ECO" ||
                                      courier.code == "ECON"
                                    ) {
                                      dpdEconomy.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (
                                      courier.code == "ONX" ||
                                      courier.code == "LOX"
                                    ) {
                                      dpdOverNight.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (courier.code == "LSE") {
                                      dpdSameDayExpress.push({
                                        totalRate: courier.rate,
                                      });
                                    } else if (courier.code == "LOF") {
                                      dpdOverNightFlyer.push({
                                        totalRate: courier.rate,
                                      });
                                    }
                                  });

                                  dawnwingData?.apiPrices?.forEach(
                                    (courier) => {
                                      if (
                                        courier.service == "ECO" ||
                                        courier.service == "ECON"
                                      ) {
                                        dpdEconomyApi.push({
                                          totalRate: courier.total,
                                        });
                                      } else if (
                                        courier.service == "ONX" ||
                                        courier.service == "LOX"
                                      ) {
                                        dpdOverNightApi.push({
                                          totalRate: courier.total,
                                        });
                                      }
                                    }
                                  );

                                  let quoteId = 100;

                                  if (
                                    counters.length > 0 &&
                                    counters[0].quote_counter
                                  ) {
                                    quoteId = counters[0].quote_counter + 1;
                                  }
                                  if (
                                    courierEconomy.length > 0 ||
                                    courierOverNightFlyer.length > 0 ||
                                    courierOverNight.length > 0 ||
                                    courierSameDayExpress.length > 0 ||
                                    dpdEconomy.length > 0 ||
                                    dpdEconomyApi.length > 0 ||
                                    dpdOverNightApi.length > 0 ||
                                    dpdOverNight.length > 0
                                  ) {
                                    let quoteTableData = {
                                      QuoteId: "qte-" + quoteId,

                                      QuoteDate: moment().toISOString(),
                                      QuoteType: "domestic",
                                      ShipperCountry: "South Africa",
                                      ShipperSuburb: localfirstPage.fromSuburb,
                                      ShipperPostalCode:
                                        localfirstPage.fromCode,
                                      ConsigneeCountry: "South Africa",
                                      ConsigneeSuburb: localfirstPage.toSuburb,
                                      ConsigneePostalCode:
                                        localfirstPage.toCode,
                                      ItemDetails: {
                                        TotalWeight:
                                          courierGuyPayload.totalWeight,
                                        TotalVolWeight:
                                          courierGuyPayload.volumetricWeight,
                                      },
                                      QuotedPrice: {
                                        CourierGuy: {
                                          UIEconomy:
                                            courierEconomy.length > 0
                                              ? courierEconomy[0].totalRate
                                              : "",
                                          UIOvernight:
                                            courierOverNight.length > 0
                                              ? courierOverNight[0].totalRate
                                              : "",
                                          UISamedayExpress:
                                            courierSameDayExpress.length > 0
                                              ? courierSameDayExpress[0]
                                                  .totalRate
                                              : "",
                                          UILocalOvernightFlyer:
                                            courierOverNightFlyer.length > 0
                                              ? courierOverNightFlyer[0]
                                                  .totalRate
                                              : "",

                                          COGSEconomy:
                                            courierEconomyApi.length > 0
                                              ? courierEconomyApi[0].totalRate
                                              : "",
                                          COGSOvernight:
                                            courierOverNightApi.length > 0
                                              ? courierOverNightApi[0].totalRate
                                              : "",
                                          COGSSamedayExpress:
                                            courierSameDayExpressApi.length > 0
                                              ? courierSameDayExpressApi[0]
                                                  .totalRate
                                              : "",
                                          COGSLocalOvernightFlyer:
                                            courierOverNightFlyerApi.length > 0
                                              ? courierOverNightFlyerApi[0]
                                                  .totalRate
                                              : "",
                                        },
                                        DpdLaser: {
                                          UIEconomy:
                                            dpdEconomy.length > 0
                                              ? dpdEconomy[0].totalRate
                                              : "",
                                          UIOvernight:
                                            dpdOverNight.length > 0
                                              ? dpdOverNight[0].totalRate
                                              : "",
                                          COGSEconomy:
                                            dpdEconomyApi.length > 0
                                              ? dpdEconomyApi[0].totalRate
                                              : "",
                                          COGSOvernight:
                                            dpdOverNightApi.length > 0
                                              ? dpdOverNightApi[0].totalRate
                                              : "",

                                          // SamedayExpress:
                                          // 	dpdSameDayExpress,
                                          // LocalOvernightFlyer:
                                          // 	dpdOverNightFlyer,
                                        },
                                      },
                                      Currency: "ZAR",
                                    };
                                    console.log(
                                      "Quote table data",
                                      quoteTableData
                                    );

                                    console.log(
                                      "PRocess,",
                                      process.env.REACT_APP_QUOTE_TABLE
                                    );

                                    firebase
                                      .firestore()
                                      .collection(
                                        `${process.env.REACT_APP_QUOTE_TABLE}`
                                      )
                                      .add(quoteTableData);
                                  }
                                }
                              }
                            )
                          );
                        }
                      )
                    );

                    // obj.volumetricWeight =
                    // 	Number(volumetricWeight).toFixed(2);
                    // localStorage.setItem(
                    // 	'local_second_page',
                    // 	JSON.stringify(obj)
                    // );
                    // localStorage.setItem(
                    // 	'local_insurance_data',
                    // 	JSON.stringify(insuranceData)
                    // );

                    // let firstPageData = JSON.parse(
                    // 	localStorage.getItem(
                    // 		'local_first_page'
                    // 	)
                    // );

                    // let newData = {
                    // 	weight: obj.totalWeight,
                    // 	fromPost:
                    // 		firstPageData.fromSuburbPost
                    // 			.split(',')[1]
                    // 			.trim(),
                    // 	toPost: firstPageData.toSuburbPost
                    // 		.split(',')[1]
                    // 		.trim(),
                    // 	first: firstPageData.first,
                    // 	second: firstPageData.second,
                    // 	third: firstPageData.third,
                    // 	fourth: firstPageData.fourth,
                    // 	fifth: firstPageData.fifth,
                    // };

                    // setSecondPageLoader(true);
                    // const response = await axios.post(
                    // 	'https://ship-my-stuff-live.herokuapp.com/courier',
                    // 	newData
                    // );
                    // await setQueryData(response.data.data);
                    // setSecondPageLoader(false);
                    // localStorage.setItem(
                    // 	'local_query',
                    // 	JSON.stringify(response.data.data)
                    // );
                    // setSecondCompleted(true);
                    // setActiveTab('3');
                  }}
                >
                  <div
                    className={`${
                      activeTab == "2"
                        ? "d-block country-step main_card"
                        : "d-none"
                    }`}
                    id="step1"
                  >
                    <Button
                      color="secondary"
                      className="prev_btn ml-5"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        clearItemDetails();
                        handleSubmit1();
                      }}
                      disabled={secondPageLoader}
                    >
                      <i className="fas fa-arrow-left " />
                    </Button>
                    <QuoteItemDetails
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      value={secondPage}
                      checkTotalWeight={checkTotalWeight}
                      checkVolumetricWeight={checkVolumetricWeight}
                      handleAddClick={handleAddClick}
                      handleRemoveClick={handleRemoveClick}
                      packageTypeChange={packageTypeChange}
                      packageType={packageType}
                      onChange={handleSecondPage}
                      insuranceData={insuranceData}
                      insuranceChange={insuranceOnChange}
                    />
                    <div>
                      <Button
                        color="secondary"
                        type="submit"
                        className="step1-btn mr-md-5 mr-4"
                        disabled={secondPageLoader}
                      >
                        {secondPageLoader ? (
                          <Spinner />
                        ) : (
                          <i className="fas fa-arrow-right " />
                        )}
                      </Button>
                    </div>
                  </div>
                </Form>

                <div
                  className={`${
                    activeTab == "3"
                      ? "d-block country-step main_card"
                      : "d-none"
                  }`}
                >
                  <QuoteEstimates
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleThirdPage={handleThirdPage}
                    handelTypeChange={handelTypeChange}
                    firstPageData={localfirstPage}
                  />
                  <div>
                    <Button
                      color="secondary"
                      onClick={() => {
                        // const obj = Object.assign(
                        // 	{},
                        // 	...thirdPage
                        // );
                        triggerGtagEvent("Domestic_Quote_Estimates", {
                          step: 3,
                        });
                        if (
                          localThirdPage.type != "" &&
                          localThirdPage.selectedCourier
                        ) {
                          localStorage.setItem(
                            "local_third_page",
                            JSON.stringify(localThirdPage)
                          );
                          setActiveTab("4");
                        } else {
                          alert("Selecte a courier service");
                        }
                        // const obj = localThirdPage;
                        // if (obj.selectedCourier) {
                        // 	obj.type = 'economy';
                        // 	obj.selectedCourier.TotalRate =
                        // 		obj.selectedCourier
                        // 			.TotalRate
                        // 			? obj
                        // 					.selectedCourier
                        // 					.BaseRate +
                        // 			  obj
                        // 					.selectedCourier
                        // 					.SurchargeRate
                        // 			: obj
                        // 					.selectedCourier
                        // 					.BaseRate;
                        // 	localStorage.setItem(
                        // 		'local_third_page',
                        // 		JSON.stringify(obj)
                        // 	);
                        // 	setActiveTab('4');
                        // } else if (
                        // 	queryData.length > 0
                        // ) {
                        // 	let newArray =
                        // 		queryData.sort(
                        // 			(a, b) => {
                        // 				return (
                        // 					a.BaseRate -
                        // 					b.BaseRate
                        // 				);
                        // 			}
                        // 		);
                        // 	let newObject = {
                        // 		type: 'economy',
                        // 		selectedCourier:
                        // 			newArray[0],
                        // 		name: 1,
                        // 	};
                        // 	newObject.selectedCourier.TotalRate =
                        // 		newObject
                        // 			.selectedCourier
                        // 			.TotalRate
                        // 			? newObject
                        // 					.selectedCourier
                        // 					.BaseRate +
                        // 			  newObject
                        // 					.selectedCourier
                        // 					.SurchargeRate
                        // 			: newObject
                        // 					.selectedCourier
                        // 					.BaseRate;
                        // 	localStorage.setItem(
                        // 		'local_third_page',
                        // 		JSON.stringify(
                        // 			newObject
                        // 		)
                        // 	);
                        // 	setThirdCompleted(true);
                        // 	setActiveTab('4');
                        // } else {
                        // 	alert(
                        // 		'Please Select a Courier Service'
                        // 	);

                        // }
                      }}
                      className="step1-btn  mr-md-5 mr-4"
                    >
                      <i className="fas fa-arrow-right " />
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="secondary"
                      className="prev_btn ml-lg-5 ml-4"
                      onClick={handleSubmit2}
                    >
                      <i className="fas fa-arrow-left " />
                    </Button>
                  </div>
                </div>

                <div
                  className={`${
                    activeTab == "4"
                      ? "d-block country-step main_card"
                      : "d-none"
                  }`}
                >
                  <QuoteCalender
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    value={fourthPage}
                    onChange={handleFourthPage}
                  />
                  <div>
                    <Button
                      color="secondary"
                      onClick={() => {
                        triggerGtagEvent("Domestic_Quote_Calender", {
                          step: 4,
                        });
                        const obj = Object.assign({}, ...fourthPage);

                        if (obj.startDate) {
                          localStorage.setItem(
                            "local_fourth_page",
                            JSON.stringify(obj)
                          );
                          setActiveTab("5");
                        } else {
                          alert("Please Add a Date");
                        }
                      }}
                      className="step1-btn  mr-md-5 mr-4"
                    >
                      <i className="fas fa-arrow-right text-white fa-1x" />
                    </Button>
                  </div>

                  <div>
                    <Button
                      color="secondary"
                      className="prev_btn ml-lg-5 ml-4"
                      onClick={handleSubmit3}
                    >
                      <i className="fas fa-arrow-left " />
                    </Button>
                  </div>
                </div>

                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    triggerGtagEvent("Domestic_Address_Details", {
                      step: 5,
                    });
                    const obj = Object.assign({}, ...fifthPage);

                    if (
                      obj.sendingName &&
                      obj.sendingSurName &&
                      obj.sendingContact &&
                      // obj.sendingComplexNo &&
                      obj.sendingEmail &&
                      // obj.sendingCity &&
                      obj.sendingAddress1 &&
                      // obj.sendingAddress2 &&
                      obj.RecievingName &&
                      obj.RecievingSurName &&
                      obj.RecievingContact &&
                      obj.RecievingEmail &&
                      // obj.RecievingComplexNo &&
                      // obj.RecievingCity &&
                      obj.RecievingAddress1
                      // &&
                      // obj.RecievingAddress2
                    ) {
                      await localStorage.setItem(
                        "local_fifth_page",
                        JSON.stringify(obj)
                      );
                      setPaymentLoader(true);
                      let ref = 1000;
                      if (counters.length > 0 && counters[0].local_counter) {
                        ref = counters[0].local_counter + 1;
                      }

                      try {
                        const response = await axios.post(
                          `${process.env.REACT_APP_DEVELOPMENT}/payment`,
                          {
                            amount: Math.floor(
                              localthirdPage.selectedCourier.TotalRate
                            ),
                            email: obj.sendingEmail,
                            reference: "sms-" + ref,
                            url: `${process.env.REACT_APP_DEPLOY}/quickquote/confirmation`,
                            // url: `${process.env.REACT_APP_DEPLOY}${window.location.pathname}`,
                            // url: `https://db-testing.web.app/${window.location.pathname}`,
                          }
                        );

                        if (response?.data?.data) {
                          await setPaymentData(response.data.data);

                          setPaymentLoader(false);

                          localStorage.setItem(
                            "payment",
                            JSON.stringify(response.data.data)
                          );
                          triggerGtagEvent("Domestic_Confirm_Booking", {
                            step: 6,
                          });
                          setActiveTab("6");
                        } else {
                          setPaymentLoader(false);

                          alert("Kindly provide valid information");
                        }
                      } catch (error) {
                        setPaymentLoader(false);

                        alert("Kindly provide valid information");
                      }
                    } else {
                      alert("Please Fill All Details");
                    }
                  }}
                >
                  <div
                    className={`${
                      activeTab == "5"
                        ? "d-block country-step main_card"
                        : "d-none"
                    }`}
                  >
                    {/* <div>
								<Button
									color='secondary'
									onClick={() => {
										setActiveTab('4');
									}}
									className='step1-sub-left-btn  mr-5'
								>
									<i className='fas fa-arrow-left text-white fa-1x' />
								</Button>
							</div> */}
                    <AddressDetails
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      value={fifthPage}
                      onChange={handleFifthPage}
                    />
                    <div>
                      <Button
                        color="secondary"
                        className="step1-btn  mr-md-5 mr-4"
                        type="submit"
                      >
                        {paymentLoader ? (
                          <Spinner />
                        ) : (
                          <i className="fas fa-arrow-right text-white fa-1x" />
                        )}
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="secondary"
                        className="prev_btn ml-lg-5 ml-4"
                        onClick={() => {
                          setActiveTab("4");
                        }}
                      >
                        <i className="fas fa-arrow-left " />
                      </Button>
                    </div>
                  </div>
                </Form>

                <div
                  className={`${
                    activeTab == "6"
                      ? "d-block country-step main_card"
                      : "d-none"
                  }`}
                >
                  {/* <div>
								<Button
									color='secondary'
									onClick={() => {
										setActiveTab('5');
									}}
									className='step1-sub-left-btn  mr-5'
								>
									<i className='fas fa-arrow-left text-white fa-1x' />
								</Button>
							</div> */}
                  <ConfirmBooking
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    value={sixthPage}
                    payment={paymentData}
                    countryDetails={localfirstPage}
                    itemDetails={localsecondPage}
                    totalDetails={localthirdPage}
                    insuranceDetails={localInsuranceData}
                    onChange={handleSixthPage}
                  />
                  <div>
                    <div>
                      <Button
                        color="secondary"
                        className="prev_btn ml-lg-5 ml-4"
                        onClick={() => {
                          setActiveTab("5");
                        }}
                      >
                        <i className="fas fa-arrow-left " />
                      </Button>
                    </div>
                    {/* <Button
                  color="secondary"
                  onClick={() => {
                    const obj = Object.assign({}, ...sixthPage);
                    if (obj.email) {
                      setActiveTab("7");
                      localStorage.setItem(
                        "local_sixth_page",
                        JSON.stringify(obj)
                      );
                    } else {
                      alert("Please Add a Email");
                    }
                  }}
                  className="step1-btn  mr-5"
                >
                  Next
                </Button> */}
                  </div>
                </div>

                <div
                  className={`${
                    activeTab == "7" ? "d-block country-steps " : "d-none"
                  }`}
                >
                  <QuoteInvoice
                    first={localfirstPage}
                    second={localsecondPage}
                    third={localthirdPage}
                    fourth={localfourthPage}
                    fifth={localfifthPage}
                    sixth={localsixthPage}
                    payment={paymentData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  {/* <QuotePaymentUI /> */}
                  <div></div>
                </div>
                {/* <div className={`${activeTab == "8" ? "d-block" : "d-none"}`}>
                  <QuotePaymentUI
                    first={localfirstPage}
                    second={localsecondPage}
                    third={localthirdPage}
                    fourth={localfourthPage}
                    fifth={localfifthPage}
                    sixth={localsixthPage}
                    seventh={localSeventhPage}
                    insuranceDetails={localInsuranceData}
                    payment={paymentPage}
                    reference={reference}
                    activeTab={activeTab}
                    bool={bool}
                    setBool={setBool}
                    setActiveTab={setActiveTab}
                  />
                  <div></div>
                </div> */}
                <div className={`${activeTab == "9" ? "d-block" : "d-none"}`}>
                  <IncompleteTransaction />
                  <div></div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

export default QuickQuote;
