import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SectionTitle from '../components/SectionTitle';
import ContactOne from '../components/ContactOne';

function ContactUs() {
	//Scroll To Top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className='section_gap'>
			<Container>
				<Row>
					<Col lg='12' className='mb--40'>
						<SectionTitle
							textAlign='text-center'
							title='GOT QUESTIONS?'
						/>
					</Col>
				</Row>
				<ContactOne />
			</Container>
		</div>
	);
}

export default ContactUs;
