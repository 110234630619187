import Home from "./views/Home";
import International from "./views/International";
import QuickQuote from "./views/QuickQoute";
import Main from "./views/main";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Guidelines from "./views/Guidelines";
import TermsAndConditions from "./views/TermsAndConditions";
import Prohibited from "./views/Prohibited";
import CommercialInvoice from "./components/International/CommercialInvoice";
import AboutUs from "./views/AboutUs";
import ContactUs from "./views/ContactUs";
import TrackYourParcel from "./views/TrackYourParcel";
import Faq from "./views/Faq";
import CourierAndFreight from "./views/CourierAndFreight";
import SeaFreight from "./views/SeaFreight";
import InternationalRelocation from "./views/InternationalRelocation";
import ShippingLarge from "./views/ShippingLarge";
import ThankYou from "./components/SeaFreight/ThankYou";
import InternationalThankyou from "./views/InternationalThankyou";
import LocalThankyou from "./views/LocalThankyou";
import ThankYouPortugal from "./views/ThankYouPortugal";
import IntlQuoteThankyou from "./views/IntlQuoteThankyou";

let routes = [
  {
    path: "/",
    component: Main,
    layout: "main",
  },
  {
    path: "/faq",
    component: Faq,
    layout: "main",
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
    layout: "main",
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    layout: "main",
  },
  {
    path: "/aboutus",
    component: AboutUs,
    layout: "main",
  },
  {
    path: "/contactus",
    component: ContactUs,
    layout: "main",
  },
  {
    path: "/trackparcel",
    component: TrackYourParcel,
    layout: "main",
  },
  {
    path: "/packing-guidelines",
    component: Guidelines,
    layout: "main",
  },
  {
    path: "/globally-prohibited-items",
    component: Prohibited,
    layout: "main",
  },
  {
    path: "/main",
    component: Main,
    layout: "main",
  },
  {
    path: "/international",
    component: International,
    layout: "content",
  },
  {
    path: "/international/commercial",
    component: CommercialInvoice,
    layout: "content",
  },
  {
    path: "/quickquote",
    component: QuickQuote,
    layout: "content",
  },
  {
    path: "/courierandfreight",
    component: CourierAndFreight,
    layout: "content",
  },

  {
    path: "/seafreight",
    component: SeaFreight,
    layout: "content",
  },
  {
    path: "/internationalrelocation",
    component: InternationalRelocation,
    layout: "content",
  },
  {
    path: "/shippinglarge",
    component: ShippingLarge,
    layout: "content",
  },
  { path: "/seafreight/confirmation", component: ThankYou, layout: "content" },
  {
    path: "/international/confirmation",
    component: InternationalThankyou,
    layout: "content",
  },
  {
    path: "/quickquote/confirmation",
    component: LocalThankyou,
    layout: "content",
  },
  {
    path: "/international/thankyou-portugal",
    component: ThankYouPortugal,
    layout: "content",
  },
  {
    path: "/international-pt-quote",
    component: IntlQuoteThankyou,
    layout: "content",
  },
];
export default routes;
