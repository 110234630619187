import React, { useEffect, useState } from "react";
import { Row, Col, Label, Table } from "reactstrap";
import { useSelector} from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Approved_Logo_Fedex from "../../assets/img/Fedex_approved_logo.png";
import DHL from "../../assets/img/DHL_Logo.png";
import ServicesHeader from "../ServicesHeader";
import InternationalActiveTab from "./InternationalActiveTab";
function Rates(props) {
  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry, "selectedCountry");
  const [fedexData, setFedexData] = useState({});
  const [dhlData, setDhlData] = useState({});
  let { fedexRates, dhlRates } = useSelector((state) => state.intl);
  const setFedexServicesData = () => {
    if (fedexRates?.output?.rateReplyDetails?.length > 0) {
      let newArr = {};
      let totWeight = Number(props.secondIntlPage.totalWeight);
      let volWeight = Number(props.secondIntlPage.volumetricWeight);
      fedexRates.output.rateReplyDetails.map((itm) => {
        if (itm.ratedShipmentDetails && itm.ratedShipmentDetails.length > 0) {
          let tax = 0;

          if (totWeight >= volWeight) {
            if (totWeight >= 0.01 && totWeight <= 10) {
              tax = (itm.ratedShipmentDetails[0].totalNetCharge * 16) / 100;
            } else if (totWeight > 10 && totWeight <= 20) {
              tax = (itm.ratedShipmentDetails[0].totalNetCharge * 18) / 100;
            } else {
              tax = (itm.ratedShipmentDetails[0].totalNetCharge * 22) / 100;
            }
          } else {
            if (volWeight >= 0.01 && volWeight <= 10) {
              tax = (itm.ratedShipmentDetails[0].totalNetCharge * 16) / 100;
            } else if (volWeight > 10 && volWeight <= 20) {
              tax = (itm.ratedShipmentDetails[0].totalNetCharge * 18) / 100;
            } else {
              tax = (itm.ratedShipmentDetails[0].totalNetCharge * 22) / 100;
            }
          }
          // (itm.ratedShipmentDetails[0].totalNetCharge * 12) / 100;
          if (itm.serviceType == "FEDEX_INTERNATIONAL_PRIORITY") {
            newArr = {
              ...newArr,
              priority: {
                type: "Priority",
                id: uuidv4(),
                rate: (
                  itm.ratedShipmentDetails[0].totalNetCharge + tax
                ).toFixed("2"),
              },
            };
          } else if (itm.serviceType == "INTERNATIONAL_ECONOMY") {
            newArr = {
              ...newArr,
              economy: {
                type: "Economy",
                id: uuidv4(),
                rate: (
                  itm.ratedShipmentDetails[0].totalNetCharge + tax
                ).toFixed(2),
              },
            };
            // newArr.push({
            // 	type: 'Economy',
            // 	id: uuidv4(),
            // 	rate: itm.ratedShipmentDetails[0].totalNetCharge,
            // });
          }
          itm.ratedShipmentDetails.push({});
          // itm.ratedShipmentDetails.forEach(rate => {
          // 	newArr.push({
          // 		exl_insurance: {
          // 			id: uuidv4(),
          // 			rate: rate.totalBaseCharge,
          // 		},
          // 		incl_insurance: {
          // 			id: uuidv4(),
          // 			rate: rate.totalNetCharge,
          // 		},
          // 	});
          // });
        }
      });
      setFedexData(newArr);
    } else {
      setFedexData({});
    }
  };

  const setDhlServicesData = () => {
    let totWeight = Number(props.secondIntlPage.totalWeight);
    let volWeight = Number(props.secondIntlPage.volumetricWeight);
    if (
      dhlRates &&
      dhlRates.products &&
      dhlRates.products.length > 0 &&
      dhlRates.products[0].totalPrice &&
      dhlRates.products[0].totalPrice.length > 0
    ) {
      let tax = 0;
      if (totWeight >= volWeight) {
        if (totWeight >= 0.01 && totWeight <= 10) {
          tax = (dhlRates.products[0].totalPrice[0].price * 16) / 100;
        } else if (totWeight > 10 && totWeight <= 20) {
          tax = (dhlRates.products[0].totalPrice[0].price * 18) / 100;
        } else {
          tax = (dhlRates.products[0].totalPrice[0].price * 22) / 100;
        }
      } else {
        if (volWeight >= 0.01 && volWeight <= 10) {
          tax = (dhlRates.products[0].totalPrice[0].price * 16) / 100;
        } else if (volWeight > 10 && volWeight <= 20) {
          tax = (dhlRates.products[0].totalPrice[0].price * 18) / 100;
        } else {
          tax = (dhlRates.products[0].totalPrice[0].price * 22) / 100;
        }
      }
      setDhlData({
        economy: {
          type: "Economy",
          id: uuidv4(),
          rate: (dhlRates.products[0].totalPrice[0].price + tax).toFixed(2),
        },
      });
    } else {
      setDhlData({});
    }
  };

  const checkGirth = () => {
    let check = false;
    props.secondIntlPage?.itemDetails.forEach((item) => {
      let girth =
        Number(item.length) + 2 * Number(item.width) + 2 * Number(item.height);

      if (girth > 330) {
        check = true;
      }
    });
    return check;
  };

  useEffect(() => {
    if (Object.keys(fedexRates).length > 0) {
      if (fedexRates.economy) {
        props.onChange({
          id: fedexRates.economy.id,
          rate: fedexRates.economy.rate,
          serviceType: "Fedex",
          type: "economy",
        });
      }
    } else {
      props.onChange({});
    }
  }, [fedexRates]);

  useEffect(() => {
    // setFedexServicesData();
  }, [fedexRates]);

  useEffect(() => {
    // setDhlServicesData();
  }, [dhlRates]);
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  return (
    <>
      <div className="rates-component my-3 mx-4">
        <ServicesHeader value="INTERNATIONAL COURIER" />
        <InternationalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
        <Row className="justify-content-center mx-lg-3">
          <Col
            xs="12"
            md="4"
            className="d-flex flex-column justify-content-center align-items-center mb-3 mb-md-0"
          >
            <Row className="justify-content-center">
              <Col xs="7" className="text-right pr-0">
                <span className="origin-destination">ORIGIN: </span>
              </Col>
              <Col xs="5" className="text-left">
                <p>{props.value?.fromCountry}</p>
              </Col>

              <Col xs="7" className="text-right pr-0">
                <span className="origin-destination">DESTINATION: </span>
              </Col>
              <Col xs="5" className="text-left">
                <p className="distinationText">{props.value?.toCountry}</p>
              </Col>
            </Row>
          </Col>

          <Col
            xs="12"
            md="8"
            className="origin-quote d-flex flex-column justify-content-center"
          >
            <div className="text-left">
              <Label className="mb-2">
                <span className="text-danger add-delivery-opt">
                  *IMPORTANT INFORMATION
                </span>
              </Label>
              <ul>
                <li className="text-white">
                  International Economy service lead time 6-9 days
                </li>
                <li className="text-white">
                  International Priority service lead time 4-7 days
                </li>
                <li className="text-white">
                  All quotes exclude import taxes, customs inspections and
                  storage.
                </li>
                <li className="text-white">
                  The quotes are based on non-hazardous cargo.
                </li>
                <li className="text-white">
                  Prices are based on the actual or volumetric weight.
                  <br />
                  (whichever one is higher)
                </li>
                <li className="text-white">
                  If special permits are required for your shipment, please
                  email
                  <br />
                  <a
                    className="text-primary"
                    href={
                      selectedCountry === "PT"
                        ? "mailto:support@shipmystuff.pt"
                        : "mailto:support@shipmystuff.co.za"
                    }
                  >
                    {selectedCountry === "PT"
                      ? "support@shipmystuff.pt"
                      : "support@shipmystuff.co.za"}
                  </a>{" "}
                  for further assistance.
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <hr className="add_hr" />
        <Row className=" justify-content-center mx-lg-3">
          <Table className="estimates-table intl__table">
            <tbody>
              {checkGirth() ||
              (Object.keys(dhlRates) == 0 && Object.keys(fedexRates) == 0) ? (
                <tr className="table__row">
                  {Object.keys(dhlRates) == 0 &&
                  Object.keys(fedexRates) == 0 ? (
                    <td className="align__middle px-2 py-2 text-center">
                      Seems like we could not get a rate for you, send your
                      <br /> shipments details to{" "}
                      <a
                        className="text-primary"
                        style={{ fontWeight: "600" }}
                        href={
                          selectedCountry == "PT"
                            ? "mailto:support@shipmystuff.pt"
                            : "mailto:support@shipmystuff.co.za"
                        }
                      >
                        {selectedCountry == "PT"
                          ? "support@shipmystuff.pt"
                          : "support@shipmystuff.co.za"}
                      </a>{" "}
                      for cost-effective pricing.
                    </td>
                  ) : (
                    <td className="align__middle px-2 py-2 text-center">
                      Seems like your shipment has large/unique dimensions, send
                      your
                      <br /> shipments details to{" "}
                      <a
                        className="text-primary"
                        style={{ fontWeight: "600" }}
                        href={
                          selectedCountry == "PT"
                            ? "mailto:support@shipmystuff.pt"
                            : "mailto:support@shipmystuff.co.za"
                        }
                      >
                        {selectedCountry == "PT"
                          ? "support@shipmystuff.pt"
                          : "support@shipmystuff.co.za"}
                      </a>{" "}
                      for cost-effective pricing.
                    </td>
                  )}
                </tr>
              ) : (
                <>
                  {Object.keys(fedexRates) != 0 && (
                    <tr className="table__row">
                      <td className="align__middle ratefirstspacing p-0 py-2">
                        <Row>
                          <Col
                            className="d-flex justify-content-center align-items-center mb-lg-3 mb-sm-0"
                            xs="12"
                            sm="4"
                            md="4"
                          >
                            <img
                              src={Approved_Logo_Fedex}
                              height="40px"
                              width="100px"
                              alt="FedEx Logo"
                            />
                          </Col>

                          {fedexRates.economy && (
                            <Col
                              xs="12"
                              sm="4"
                              md="4"
                              className="px-3 d-flex justify-content-start align-items-center mb-3 mb-sm-0"
                            >
                              <div className="d-flex mr-lg-2 justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  name="query"
                                  checked={
                                    props.ratesData.id == fedexRates.economy.id
                                  }
                                  onChange={(e) =>
                                    props.onChange({
                                      id: fedexRates.economy.id,
                                      rate: fedexRates.economy.rate,
                                      serviceType: "Fedex",
                                      type: "economy",
                                    })
                                  }
                                  className="custom__input__radio mr-lg-3 mr-2"
                                />
                              </div>
                              <div>
                                <p className="text-primary text-start estimates-amount mb-0">
                                  Economy
                                </p>
                                <span className="estimates_amount_heading mr-1 text-start">
                                  RATE
                                </span>
                                <span className="estimates-tax mr-1">
                                  {selectedCountry === "PT" ? "€" : "R"}
                                  {fedexRates.economy &&
                                    fedexRates.economy.rate}
                                </span>
                              </div>
                            </Col>
                          )}

                          {fedexRates.priority && (
                            <Col
                              xs="12"
                              sm="4"
                              md="4"
                              className="px-3 mt-1 mt-sm-0 d-flex justify-content-start align-items-center table__row__border__left"
                            >
                              <div className="d-flex mr-lg-2 justify-content-start align-items-center">
                                <input
                                  type="radio"
                                  name="query"
                                  checked={
                                    props.ratesData.id == fedexRates.priority.id
                                  }
                                  onChange={(e) =>
                                    props.onChange({
                                      id: fedexRates.priority.id,
                                      rate: fedexRates.priority.rate,
                                      serviceType: "Fedex",
                                      type: "priority",
                                    })
                                  }
                                  className="custom__input__radio mr-lg-3 mr-2"
                                />
                              </div>
                              <div>
                                <p className="text-primary estimates-amount text-start mb-0">
                                  Priority
                                </p>
                                <span className="estimates_amount_heading mr-1 text-start">
                                  RATE
                                </span>
                                <span className="estimates-tax mr-1">
                                  {selectedCountry === "PT" ? "€" : "R"}
                                  {fedexRates.priority &&
                                    fedexRates.priority.rate}
                                </span>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </td>
                    </tr>
                  )}

                  {Object.keys(dhlRates) != 0 &&
                    props.value.fromCountry == "ZA" && (
                      <tr className="table__row">
                        <td className="align__middle p-0 py-2">
                          <Row>
                            <Col
                              className="d-flex justify-content-center align-items-center"
                              xs="12"
                              sm="4"
                            >
                              <img src={DHL} height="40px" width="100px" />
                            </Col>
                            {dhlRates.economy && (
                              <Col
                                xs="12"
                                sm="4"
                                className=" ratesecondspacing d-flex  justify-content-start align-items-center"
                              >
                                <div className="d-flex mr-lg-2 justify-content-start align-items-center">
                                  <input
                                    type="radio"
                                    name="query"
                                    checked={
                                      props.ratesData.id == dhlRates.economy.id
                                    }
                                    onChange={(e) =>
                                      props.onChange({
                                        id: dhlRates.economy.id,

                                        rate: dhlRates.economy.rate,
                                        serviceType: "Dhl",
                                        type: "Worldwide Express",
                                      })
                                    }
                                    className="custom__input__radio  mr-lg-3 mr-2"
                                  />
                                </div>
                                <div className="text-left">
                                  <p className="text-primary estimates-amount mb-0">
                                    Worldwide Express
                                  </p>
                                  <span className="estimates_amount_heading  mr-1">
                                    RATE
                                  </span>
                                  <span className="estimates-tax mr-1">
                                    R{dhlRates.economy && dhlRates.economy.rate}
                                  </span>
                                </div>
                              </Col>
                            )}
                          </Row>
                        </td>
                      </tr>
                    )}
                </>
              )}
            </tbody>
          </Table>
        </Row>
        <hr className="add_hr" />
        <Row className="p-lg-3 p-md-3 justify-content-start">
          <Col
            md="6"
            sm="6"
            xs="12"
            align="left"
            className={windowsize>281?"text-white d-flex flex-column justify-content-end align-items-end":""}
          >
            <div className="row">
            <div className="col-lg-12 col-md-8 col-sm-10 col-xs-10 mx-auto mx-md-0">
              <Label className="mb-lg-2 mb-md-2">
                <span className="text-danger add-delivery-opt">
                  *IMPORTANT INFORMATION
                </span>
              </Label>
              {/* <span className='imp-info text-danger'>
							*IMPORTANT INFORMATION
						</span>{' '} */}
              <ul>
                <li className="rates-link">
                  <a
                    // href={Globally_Prohibited_Items}
                    href="/globally-prohibited-items"
                    target="_blank"
                    // className="text-white"
                    // download
                  >
                    Prohibited Items
                  </a>
                </li>
                <li className="rates-link">
                  <a
                    // href={Packaging_Guidelines}
                    href="/packing-guidelines"
                    target="_blank"
                    // download
                  >
                    Packing Guide
                  </a>
                </li>{" "}
              </ul>
            </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Rates;
