import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import StopWatch from "../assets/img/StopWatch.png";
import Airfreight from "../assets/img/Airfreight.png";
import Consulting from "../assets/img/Consulting.png";
import Courier from "../assets/img/Courier.png";
import Roadfreight from "../assets/img/Roadfreight.png";
import Shipping from "../assets/img/Shipping.png";
import Packaging from "../assets/img/Packaging.jpeg";
import Slide from "react-reveal/Slide";
import { useState,useEffect } from "react";

import Button_Ship from "../assets/img/Button_Ship.png";
import Button_Truck from "../assets/img/Button_Truck.png";
import Button_Airplane from "../assets/img/Button_Airplane.png";
import { HashLink } from "react-router-hash-link";
import { useDispatch } from "react-redux";
import { onServiceChange } from "../store/actions/serviceActions";
import { scroller } from "react-scroll";
import { useSelector } from "react-redux";
function Services({ textAlign }) {
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry,"selectedCountry");
  const dispatch = useDispatch();
  return (
    <>
    {selectedCountry==='PT'?
    <Row className="row--15 services_main">
    <Col lg="4" className="mb-2 mt-3">
      <Slide left>
        <div className={`service  ${textAlign}`}>
          <div className="image">
            <img src={Courier} alt="" className="img-fluid" />
          </div>
          <div className="content mt-1">
            <div>
              <h4 className="w-600 text-center">DOMESTIC COURIER</h4>

              <p className="">
                {/* Compare multiple courier quotes, Instantly! */}
                {/* <br /> */}
                Compare and book your shipments online, instantly!
                {/* Book your shipment online */}
              </p>
            </div>
            <br />
            <div className="bottom_section">
              <Row className="justify-content-center">
                <Col md="6">
                  <hr />
                </Col>
              </Row>

              <Row className="justify-content-start">
  <Col  md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start mb-2 mb-md-0">
    <p className="sub__text">
      <i className="fa fa-check"></i>
      No accounts required
    </p>
  </Col>
  <Col   md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start">
    <p className="sub__text">
      <i className="fa fa-check"></i>
      No second-guessing
    </p>
  </Col>
</Row>
              <Row className="justify-content-start">
                <Col  md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start">
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    No time wasted
                  </p>
                </Col>
                <Col  md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start">
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Instant online quotes
                  </p>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md="7">
                  {/* <Link to="/quickquote"> */}
                  <Button
                    className="service_btn "
                    onClick={() => {
                      dispatch(onServiceChange("local"));
                      scroller.scrollTo("selectedCourierSection", {
                        // duration: 500,
                        smooth: true,
                        offset: -85,
                      });
                    }}
                  >
                    INSTANT QUOTE{" "}
                    <img
                      src={Button_Truck}
                      className="img-fluid ml-auto"
                      width="25"
                    />
                  </Button>
                  {/* </Link> */}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Slide>
    </Col>
    <Col lg="4" className="mb-2 mt-3">
      <Slide right>
        <div className={`service  ${textAlign}`}>
          <div className="image">
            <img src={Airfreight} alt="" className="img-fluid" />
          </div>
          <div className="content mt-1">
            <div>
              <h4 className=" w-600 text-center">INTERNATIONAL COURIER</h4>

              <p className="mb--0">
                Quote and book your international shipments, today.
                <br />
                Big or Small, we fly them all!
              </p>
            </div>

            <Row className="justify-content-center">
              <Col md="6">
                <hr />
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col  md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start">
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Commercial
                </p>
              </Col>
              <Col  md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start">
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Personal effects
                </p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col  md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start">
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  No hidden costs
                </p>
              </Col>
              <Col  md="6"
                lg="12"
                xl="6" className="d-flex justify-content-start ">
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Instant online quotes
                </p>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col  md="7">
                {/* <Link to="/international"> */}
                <Button
                  className="service_btn"
                  onClick={() => {
                    dispatch(onServiceChange("international"));
                    scroller.scrollTo("selectedCourierSection", {
                      // duration: 500,
                      smooth: true,
                      offset: -85,
                    });
                  }}
                >
                  INSTANT QUOTE
                  <img
                    src={Button_Airplane}
                    className="img-fluid ml-auto"
                    width="25"
                  />
                </Button>
                {/* </Link> */}
              </Col>
            </Row>

            {/* <p className='sub__text'>
              <i className='fa fa-check'></i>
              Courier and Freight
              <br />
              <i className='fa fa-check ml-1'></i>Commercial
              and Personal Shipments
            </p> */}
          </div>
        </div>
      </Slide>
    </Col>
    <Col lg="4" className="mb-2 mb-lg-0 mt-3">
      <Slide left>
        <div className={`service  ${textAlign}`}>
          <div className="image">
            <img src={Shipping} alt="" className="img-fluid" />
          </div>

          <div className="content">
            <Link
              to="/contactus"
              className="d-flex justify-content-center align-items-center"
            >
              <h4 className=" w-600">SEA FREIGHT</h4>
            </Link>
            <p className=" mb--0">
              Need to import or export, large stuff?
              <br />
              Inquire today about our solutions.
            </p>
            <Row className="justify-content-center">
              <Col md="6">
                <hr />
              </Col>
            </Row>

            <Row className="justify-content-start">
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Commercial
                </p>
              </Col>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Personal effects
                </p>
              </Col>
            </Row>

            <Row>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Home relocations
                </p>
              </Col>

              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  FCL & LCL
                </p>
              </Col>
            </Row>
            {/* <br /> */}
            <Row sm="12" className="mt-2 justify-content-center">
              <Col md="12" className="d-flex justify-content-center">
                {/* <Link to="/seafreight"> */}
                <p
                  className="sub__text2 text-nowrap cursor-pointer"
                  onClick={() => {
                    dispatch(onServiceChange("sea"));
                    scroller.scrollTo("selectedCourierSection", {
                      // duration: 500,
                      smooth: true,
                      offset: -85,
                    });
                  }}
                >
                  {"Inquire sea freight >>"}
                </p>
                {/* </Link> */}
              </Col>
              <Col md="12" className="d-flex justify-content-center">
                {/* <Link to="/internationalrelocation" className=""> */}
                <p
                  className="sub__text2 text-nowrap cursor-pointer"
                  onClick={() => {
                    dispatch(onServiceChange("sea"));
                    scroller.scrollTo("selectedCourierSection", {
                      // duration: 500,
                      smooth: true,
                      offset: -85,
                    });
                  }}
                >
                  {"International Relocations >>"}
                </p>
                {/* </Link> */}
              </Col>
            </Row>

            {/* <p className='sub__text'>
              <i className='fa fa-check'></i>
              Commercial Cargo <br />{' '}
              <i className='fa fa-check'></i>
              Personal Cargo
              <br />
              <i className='fa fa-check'></i>Home Relocations
            </p> */}
          </div>
        </div>
      </Slide>
    </Col>
    {/* <Col lg="4" className="mb-2 mb-lg-0 mt-3">
      <Slide bottom>
        <div className={`service  ${textAlign}`}>
          <div className="image">
            <img src={Packaging} className="img-fluid" />
          </div>
          <div className="content">
            <Link
              to="/contactus"
              className="d-flex justify-content-center align-items-center"
            >
              <h4 className="title w-600">PACKAGING & HANDLING</h4>
            </Link>

            <p className="mb--0">
              Land, sea and air packaging solutions, covered.
            </p>
            <br className="line__break" />

            <Row className="justify-content-center">
              <Col md="6">
                <hr />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <br />
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Move cube
                </p>
              </Col>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Crating & packing
                </p>
              </Col>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Container loading
                </p>
              </Col>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Hazardous packaging
                </p>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="d-flex justify-content-center">
                <HashLink
                  to="/main#packaging-section"
                  scroll={(el) => {
                    el.scrollIntoView({
                      behavior: "auto",
                      block: "start",
                    });
                  }}
                >
                  <p className="sub__text2">
                    {"View our packaging solutions  >>"}
                  </p>
                </HashLink>
              </Col>
            </Row>
          </div>
        </div>
      </Slide>
    </Col> */}
{/* 
    <Col lg="4" className="mb-2 mb-lg-0 mt-3">
      <Slide right>
        <div className={`service  ${textAlign}`}>
          <div className="image">
            <img src={Consulting} className="img-fluid" />
          </div>
          <div className="content">
            <Link
              to="/contactus"
              className="d-flex justify-content-center align-items-center"
            >
              <h4 className="title w-600">CONSULTING</h4>
            </Link>
            <p className=" mb--0">
              Book a free consultation today!
              <br />
              Discover how we can help you.
            </p>
            <Row className="justify-content-center">
              <Col md="6">
                <hr />
              </Col>
            </Row>
            <Row className="justify-content-start">
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <br />
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Process optimisation
                </p>
              </Col>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i> Business intelligence
                </p>
              </Col>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i> Supply chain consulting
                </p>
              </Col>
              <Col
                md="6"
                lg="12"
                xl="6"
                className="d-flex justify-content-start align-items-start"
              >
                <p className="sub__text">
                  <i className="fa fa-check"></i>
                  Transport consulting
                </p>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="d-flex justify-content-center">
                <Link to="/contactus">
                  <p className="sub__text2">
                    {"Book your free consultation >>"}
                  </p>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </Slide>
    </Col> */}
  </Row>
    
:
<Row className="row--15 services_main">
      <Col lg="6" className="mb-2 mt-3">
        <Slide left>
          <div className={`service  ${textAlign}`}>
            <div className="image">
              <img src={Courier} alt="" className="img-fluid" />
            </div>
            <div className="content  mt-1">
              <div>
                <h4 className="w-600 text-center">DOMESTIC COURIER</h4>

                <p className="">
                  {/* Compare multiple courier quotes, Instantly! */}
                  {/* <br /> */}
                  Compare and book your shipments online, instantly!
                  {/* Book your shipment online */}
                </p>
              </div>
              <br />
              <div className="bottom_section">
                <Row className="justify-content-center">
                  <Col md="6">
                    <hr />
                  </Col>
                </Row>

                <Row className="justify-content-start">
                  <Col md="6" className="d-flex justify-content-start">
                    <p className="sub__text">
                      <i className="fa fa-check"></i>
                      No accounts required
                    </p>
                  </Col>
                  <Col md="6" className="d-flex justify-content-start">
                    <p className="sub__text">
                      <i className="fa fa-check"></i>
                      No second-guessing
                    </p>
                  </Col>
                </Row>

                <Row className="justify-content-start">
                  <Col md="6" className="d-flex justify-content-start">
                    <p className="sub__text">
                      <i className="fa fa-check"></i>
                      No time wasted
                    </p>
                  </Col>
                  <Col md="6" className="d-flex justify-content-start">
                    <p className="sub__text">
                      <i className="fa fa-check"></i>
                      Instant online quotes
                    </p>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col md='6'>
                    {/* <Link to="/quickquote"> */}
                    <Button
                      className="service_btn "
                      onClick={() => {
                        dispatch(onServiceChange("local"));
                        scroller.scrollTo("selectedCourierSection", {
                          // duration: 500,
                          smooth: true,
                          offset: -85,
                        });
                      }}
                    >
                      INSTANT QUOTE{" "}
                      <img
                        src={Button_Truck}
                        className="img-fluid ml-auto"
                        width="25"
                      />
                    </Button>
                    {/* </Link> */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Slide>
      </Col>
      <Col lg="6" className="mb-2 mt-3">
        <Slide right>
          <div className={`service  ${textAlign}`}>
            <div className="image">
              <img src={Airfreight} alt="" className="img-fluid" />
            </div>
            <div className="content mt-1">
              <div>
                <h4 className=" w-600 text-center">INTERNATIONAL COURIER</h4>

                <p className="mb--0">
                  Quote and book your international shipments, today.
                  <br />
                  Big or Small, we fly them all!
                </p>
              </div>

              <Row className="justify-content-center">
                <Col md="6">
                  <hr />
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md="6" className="d-flex justify-content-start">
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Commercial
                  </p>
                </Col>
                <Col md="6" className="d-flex justify-content-start">
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Personal effects
                  </p>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md="6" className="d-flex justify-content-start">
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    No hidden costs
                  </p>
                </Col>
                <Col md="6" className="d-flex justify-content-start ">
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Instant online quotes
                  </p>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col  md="6">
                  {/* <Link to="/international"> */}
                  <Button
                    className="service_btn inline w-100"
                    onClick={() => {
                      dispatch(onServiceChange("international"));
                      scroller.scrollTo("selectedCourierSection", {
                        // duration: 500,
                        smooth: true,
                        offset: -85,
                      });
                    }}
                  >
                    INSTANT QUOTE
                    <img
                      src={Button_Airplane}
                      className="img-fluid ml-auto"
                      width="25"
                    />
                  </Button>
                  {/* </Link> */}
                </Col>
              </Row>

              {/* <p className='sub__text'>
								<i className='fa fa-check'></i>
								Courier and Freight
								<br />
								<i className='fa fa-check ml-1'></i>Commercial
								and Personal Shipments
							</p> */}
            </div>
          </div>
        </Slide>
      </Col>
      <Col lg="4" className="mb-2 mb-lg-0 mt-3">
        <Slide left>
          <div className={`service  ${textAlign}`}>
            <div className="image">
              <img src={Shipping} alt="" className="img-fluid" />
            </div>

            <div className="content">
              <Link
                to="/contactus"
                className="d-flex justify-content-center align-items-center"
              >
                <h4 className=" w-600">SEA FREIGHT</h4>
              </Link>
              <p className=" mb--0">
                Need to import or export, large stuff?
                <br />
                Inquire today about our solutions.
              </p>
              <Row className="justify-content-center">
                <Col md="6">
                  <hr />
                </Col>
              </Row>

              <Row className="justify-content-start">
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Commercial
                  </p>
                </Col>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Personal effects
                  </p>
                </Col>
              </Row>

              <Row>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Home relocations
                  </p>
                </Col>

                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    FCL & LCL
                  </p>
                </Col>
              </Row>
              {/* <br /> */}
              <Row sm="12" className="mt-2 justify-content-center">
                <Col md="12" className="d-flex justify-content-center">
                  {/* <Link to="/seafreight"> */}
                  <p
                    className="sub__text2 text-nowrap cursor-pointer"
                    onClick={() => {
                      dispatch(onServiceChange("sea"));
                      scroller.scrollTo("selectedCourierSection", {
                        // duration: 500,
                        smooth: true,
                        offset: -85,
                      });
                    }}
                  >
                    {"Inquire sea freight >>"}
                  </p>
                  {/* </Link> */}
                </Col>
                <Col md="12" className="d-flex justify-content-center">
                  {/* <Link to="/internationalrelocation" className=""> */}
                  <p
                    className="sub__text2 text-nowrap cursor-pointer"
                    onClick={() => {
                      dispatch(onServiceChange("sea"));
                      scroller.scrollTo("selectedCourierSection", {
                        // duration: 500,
                        smooth: true,
                        offset: -85,
                      });
                    }}
                  >
                    {"International Relocations >>"}
                  </p>
                  {/* </Link> */}
                </Col>
              </Row>

              {/* <p className='sub__text'>
								<i className='fa fa-check'></i>
								Commercial Cargo <br />{' '}
								<i className='fa fa-check'></i>
								Personal Cargo
								<br />
								<i className='fa fa-check'></i>Home Relocations
							</p> */}
            </div>
          </div>
        </Slide>
      </Col>
      <Col lg="4" className="mb-2 mb-lg-0 mt-3">
        <Slide bottom>
          <div className={`service  ${textAlign}`}>
            <div className="image">
              <img src={Packaging} className="img-fluid" />
            </div>
            <div className="content">
              <Link
                to="/contactus"
                className="d-flex justify-content-center align-items-center"
              >
                <h4 className="title w-600">PACKAGING & HANDLING</h4>
              </Link>

              <p className="mb--0">
                Land, sea and air packaging solutions, covered.
              </p>
              <br className="line__break" />

              <Row className="justify-content-center">
                <Col md="6">
                  <hr />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <br />
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Move cube
                  </p>
                </Col>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Crating & packing
                  </p>
                </Col>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Container loading
                  </p>
                </Col>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Hazardous packaging
                  </p>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col className="d-flex justify-content-center">
                  <HashLink
                    to="/main#packaging-section"
                    scroll={(el) => {
                      el.scrollIntoView({
                        behavior: "auto",
                        block: "start",
                      });
                    }}
                  >
                    <p className="sub__text2">
                      {"View our packaging solutions  >>"}
                    </p>
                  </HashLink>
                </Col>
              </Row>
              {/* <p className='sub__text'>
								<i className='fa fa-check'></i>Domestic
								Transport Solutions
								<br />
								<i className='fa fa-check'></i>Cross Border
							</p> */}
            </div>
          </div>
        </Slide>
      </Col>

      <Col lg="4" className="mb-2 mb-lg-0 mt-3">
        <Slide right>
          <div className={`service  ${textAlign}`}>
            <div className="image">
              <img src={Consulting} className="img-fluid" />
            </div>
            <div className="content">
              <Link
                to="/contactus"
                className="d-flex justify-content-center align-items-center"
              >
                <h4 className="title w-600">CONSULTING</h4>
              </Link>
              <p className=" mb--0">
                Book a free consultation today!
                <br />
                Discover how we can help you.
              </p>
              {/* <br /> */}
              <Row className="justify-content-center">
                <Col md="6">
                  <hr />
                </Col>
              </Row>
              <Row className="justify-content-start">
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <br />
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Process optimisation
                  </p>
                </Col>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i> Business intelligence
                  </p>
                </Col>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i> Supply chain consulting
                  </p>
                </Col>
                <Col
                  md="6"
                  lg="12"
                  xl="6"
                  className="d-flex justify-content-start align-items-start"
                >
                  <p className="sub__text">
                    <i className="fa fa-check"></i>
                    Transport consulting
                  </p>
                </Col>
              </Row>
              {/* <br /> */}

              <Row className="mt-2">
                <Col className="d-flex justify-content-center">
                  <Link to="/contactus">
                    <p className="sub__text2">
                      {"Book your free consultation >>"}
                    </p>
                  </Link>
                </Col>
              </Row>
              {/* <p className='sub__text'>
								<i className='fa fa-check'></i>Domestic
								Transport Solutions
								<br />
								<i className='fa fa-check'></i>Cross Border
							</p> */}
            </div>
          </div>
        </Slide>
      </Col>
    </Row>
    
            }
            </>
  );
}

export default Services;
