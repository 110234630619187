import React from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import SectionTitle from "./SectionTitle";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import tealBackground1 from "./../assets/img/Teal Background/Asset 155.png";
import tealBackground2 from "./../assets/img/Teal Background/Asset 144.png";
import tealBackground3 from "./../assets/img/Teal Background/Asset 152.png";
import tealBackground4 from "./../assets/img/Teal Background/Asset 145.png";
import tealBackground5 from "./../assets/img/Teal Background/Asset 156.png";
import tealBackground6 from "./../assets/img/Teal Background/Asset 146.png";
import tealBackground7 from "./../assets/img/Teal Background/Asset 141.png";
import tealBackground8 from "./../assets/img/Teal Background/Asset 157.png";

//white background images
import whiteBackground1 from "./../assets/img/White Background/Asset 104.png";
import whiteBackground2 from "./../assets/img/White Background/Asset 105.png";
import whiteBackground3 from "./../assets/img/White Background/Asset 107.png";
import whiteBackground4 from "./../assets/img/White Background/Asset 114.png";
import whiteBackground5 from "./../assets/img/White Background/Asset 118.png";
import whiteBackground6 from "./../assets/img/White Background/Asset 117.png";
import whiteBackground7 from "./../assets/img/White Background/Asset 110.png";
import whiteBackground8 from "./../assets/img/White Background/Asset 121.png";

const WhyShipMyStuff = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
      <div className="section_gap_whyShip text-center" id="why-ship-section">
        <Container>
          <Row>
            <Col>
              <SectionTitle textAlign="text-center" title="WHY SHIP MY STUFF" />
            </Col>
          </Row>
        </Container>
        {/* Imported code */}
        <Container fluid className="px-0" style={{ overflow: "hidden" }}>
          <div data-aos="fade-right" className="row2">
            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground4} />{" "}
              </div>
              <h3 className="text">
                Instant Online
                <br /> Quotes
              </h3>
            </div>

            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground4} />{" "}
              </div>
              <h3 className="text">
                Parcel
                <br /> Protection
              </h3>
            </div>

            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground2} />{" "}
              </div>
              <h3 className="text">
                International
                <br /> Courier
              </h3>
            </div>

            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground6} />{" "}
              </div>
              <h3 className="text">
                No Hidden <br /> Fees
              </h3>
            </div>

            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground5} />{" "}
              </div>
              <h3 className="text">
                Packaging <br /> Solutions
              </h3>
            </div>

            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground3} />{" "}
              </div>
              <h3 className="text">
                Sea Freight
                <br />
                (LCL & HCL)
              </h3>
            </div>

            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground6} />{" "}
              </div>
              <h3 className="text">Expert advice</h3>
            </div>

            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground8} />{" "}
              </div>
              <h3 className="text">
                Marine
                <br />
                insurance
              </h3>
            </div>
          </div>
          <div data-aos="fade-left" className="row3">
            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground2} />{" "}
              </div>
              <h3 className="text">
                Saving You <br /> Time
              </h3>
            </div>

            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground7} />{" "}
              </div>
              <h3 className="text">
                Domestic <br /> courier
              </h3>
            </div>

            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground7} />{" "}
              </div>
              <h3 className="text">
                Door to door
                <br /> service
              </h3>
            </div>

            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground3} />{" "}
              </div>
              <h3 className="text">
                24/7
                <br /> support
              </h3>
            </div>

            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground1} />{" "}
              </div>
              <h3 className="text">
                Secure <br /> payment
              </h3>
            </div>

            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground1} />{" "}
              </div>
              <h3 className="text">
                International <br /> service
              </h3>
            </div>

            <div className="image tealBackground">
              <div>
                {" "}
                <img src={tealBackground5} />{" "}
              </div>
              <h3 className="text">
                Cost-effective <br />
                solutions
              </h3>
            </div>

            <div className="image whiteBackground">
              <div>
                {" "}
                <img src={whiteBackground8} />{" "}
              </div>
              <h3 className="text">
                Parcel <br />
                Tracking
              </h3>
            </div>
          </div>
          {/* Imported code */}
        </Container>
      </div>
    </>
  );
};

export default WhyShipMyStuff;
