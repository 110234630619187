const initState = {
  selectedService: "international",
};

const serviceReducer = (state = initState, { payload, type }) => {
  switch (type) {
    case "SET_SELECTED_SERVICE":
      return {
        ...state,
        selectedService: payload,
      };

    default:
      return state;
  }
};

export default serviceReducer;
