import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import MoveCabImg from '../assets/img/Movecubes-min.png';
import CratingCustomImg from '../assets/img/Custom-min.png';
import MotorisedImg from '../assets/img/Motorised-min.png';
import HarazdousImg from '../assets/img/hazardous.png';

import Crating from './Crating';
import Hazardous from './Hazardous';
import Motorized from './Motorized';
import MoveCube from './MoveCube';

const tabs = {
	1: MoveCube,
	2: Crating,
	3: Motorized,
	4: Hazardous,
};

const Tabs = () => {
	/*
	 *State to Handle Current Tab
	 */
	const [tab, setTab] = useState(1);
	const Tab = tabs[tab];

	/*
	 *State for Active Tab
	 */
	const [activeTab, setActiveTab] = useState({
		cubes: true,
		crating: false,
		motorized: false,
		hazardous: false,
	});

	return (
		<Container fluid className='packaging-solutions px-0'>
			<Row className='packagin-main text-center '>
				<Col className='d-flex justify-content-center mb-2 my-md-0 ' md='3' sm='6'>
					<div
						onClick={() => {
							setActiveTab({
								cubes: true,
								crating: false,
								motorized: false,
								hazardous: false,
							});
							setTab(1);
						}}
						className={`${
							activeTab.cubes && 'active-bg'
						}  packages-items rounded-lg p-4  testing`}
					>
						<h4 className='mb-3 packages-items-name'>Cube</h4>
						<img height={'80'} width={'100'} src={MoveCabImg}></img>
					</div>
				</Col>
				<Col className='d-flex justify-content-center mb-2 my-md-0 ' md='3' sm='6'>
					<div
						className={`${
							activeTab.crating && 'active-bg'
						} rounded-lg p-4 packages-items`}
						onClick={() => {
							setActiveTab({
								cubes: false,
								crating: true,
								motorized: false,
								hazardous: false,
							});
							setTab(2);
						}}
					>
						<h4 className='mb-3 packages-items-name'>Crating</h4>
						<img
							height={'80'}
							width={'100'}
							src={CratingCustomImg}
						></img>
					</div>
				</Col>
				<Col className='d-flex justify-content-center mb-2 my-md-0 ' md='3' sm='6'>
					<div
						className={`${
							activeTab.motorized && 'active-bg'
						}  rounded-lg p-4 packages-items`}
						onClick={() => {
							setActiveTab({
								cubes: false,
								crating: false,
								motorized: true,
								hazardous: false,
							});
							setTab(3);
						}}
					>
						<h4 className='mb-3 packages-items-name'>Motorised</h4>
						<img
							height={'80'}
							width={'100'}
							src={MotorisedImg}
						></img>
					</div>
				</Col>
				<Col className='d-flex justify-content-center mb-2 my-md-0 ' md='3' sm='6'>
					<div
						className={`${
							activeTab.hazardous && 'active-bg'
						}  rounded-lg p-4 packages-items-hazardous`}
						onClick={() => {
							setActiveTab({
								cubes: false,
								crating: false,
								motorized: false,
								hazardous: true,
							});
							setTab(4);
						}}
					>
						<h4 className='mb-3 packages-items-name'>Hazardous</h4>
						<img
							height={'80'}
							width={'100'}
							src={HarazdousImg}
						></img>
					</div>
				</Col>
			</Row>
			<hr />
			<Tab changeActiveTab={setActiveTab} clickHandle={setTab} />
		</Container>
	);
};

export default Tabs;
