import { Col, Row, Button, Container } from 'react-bootstrap';
import TickImg from '../assets/img/tick-green.png';
import GlobeImg from '../assets/img/globe.jpg';
import TickWhiteImg from '../assets/img/tick.png';
import { Link } from 'react-router-dom';

const CourierAndFreight = () => {
	return (
		<div>
			<div className='pagesHeaderWrapper'>
				<h1 className='shiping-stuff-fast-heading'>
					SHIPING STUFF,{' '}
					<sapn style={{ color: '#00b7ae' }}> FAST</sapn>
				</h1>

				<Container className='d-flex justify-content-around flex-wrap'>
					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>Documents</h3>
					</div>

					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>Parcels/Boxes</h3>
					</div>

					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>
							Personal Luggage
						</h3>
					</div>
					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>Commercial</h3>
					</div>
				</Container>
			</div>

			<h2 className='font-weight-normal my-3 internationalHeading maxWidthWrapper'>
				Need to send your commercial or personal cargo via{' '}
				<sapn style={{ color: '#00b7ae' }}>
					AIR? We cover more than 132 countries
				</sapn>
			</h2>

			<Container>
				<Row className='globeWrapper d-flex align-items-center'>
					<Col className='d-flex justify-content-center  justify-content-md-end'>
						<img
							src={GlobeImg}
							alt='airfreight'
							style={{ width: '250px' }}
						/>
					</Col>
					<Col>
						<div className='d-flex justify-content-md-start justify-content-sm-center  align-items-center my-1'>
							<img src={TickWhiteImg} alt='tick-gray' />
							<p className='tickText'>Door to Door</p>
						</div>

						<div className='d-flex justify-content-md-start justify-content-sm-center align-items-center my-1'>
							<img src={TickWhiteImg} alt='tick-gray' />
							<p className='tickText'>4 - 10 days lead time</p>
						</div>

						<div className='d-flex justify-content-md-start justify-content-sm-center align-items-center my-1'>
							<img src={TickWhiteImg} alt='tick-gray' />
							<p className='tickText'>Instant Quote and Book</p>
						</div>

						<div className='d-flex justify-content-md-start justify-content-sm-center align-items-center my-1'>
							<img src={TickWhiteImg} alt='tick-gray' />
							<p className='tickText'>Parcel Insurance</p>
						</div>

						<div className='d-flex justify-content-md-start justify-content-sm-center align-items-center my-1'>
							<img src={TickWhiteImg} alt='tick-gray' />
							<p className='tickText'>Live Tracking</p>
						</div>
					</Col>
				</Row>
			</Container>

			<div
				style={{ background: '#404b51' }}
				className='buttonWrapper py-3  d-flex  justify-content-center align-items-center'
			>
				<Link to='/international'>
					<Button
						style={{ fontSize: '25px', padding: '4px 40px' }}
						className='font-weight-bold'
					>
						GET QUOTE
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default CourierAndFreight;
