import React, { useState } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Table,
  Badge,
  Card,
} from "reactstrap";
import Scale from "../../assets/img/Scale.png";
import MeasuringTape from "../../assets/img/MeasuringTape.png";
import { Link } from "react-router-dom";
import Globally_Prohibited_Items from "../../assets/docs/Globally_Prohibited_Items.pdf";
import LossOrDamage from "../../assets/docs/Insurance_Items_Not_Covered.pdf";
import ServicesHeader from "../ServicesHeader";
import { LocalActiveTab } from "./LocalActiveTab";

function QuoteItemDetails(props) {
  const [inputList, setInputList] = useState([
    { description: "", weight: "", height: "", length: "", width: "" },
  ]);

  const [insurance, setInsurance] = useState(false);
  const [insuranceDesc, setInsuranceDesc] = useState("");
  const [insuranceValue, setInsuranceValue] = useState("");

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    props.onChange({ itemDetails: list });
    setInputList(list);
  };

  let [isChecked, setIsChecked] = useState(false);
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  return (
    <>
      <div className="mx-3">
        <ServicesHeader value="DOMESTIC COURIER" />
        <LocalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
      </div>

      <Row className="itemdetails-component">
        <Col>
          {/* <Row noGutters>
            <Col xs="3" align="center">
              <div className="text-center">
                <i className="color__change mt-3 pt-1 fas fa-info-circle fa-5x"></i>
              </div>
              <hr className="w-75" />
            </Col>
            <Col xs="2">
              <div className="scale__img">
                <img src={Scale} className="img-fluid" />
              </div>
              <hr className="w-50" />
            </Col>
            <Col xs="7">
              <div className="tape__img">
                <img src={MeasuringTape} className="img-fluid" />
              </div>
              <hr className="w-50" />
            </Col>
          </Row> */}
          {/* <Row className="document__row no-gutters justify-content-center">
            <Col
              md="2"
              className="d-flex justify-content-start align-items-center"
            >
              <Label className="m-0 p-0">COMMODITY TYPE:</Label>
            </Col>
            <Col
              md="3"
              className="d-flex justify-content-start align-items-center"
            >
              <Button
                className={`${
                  props.packageType == "document" ? "active_btn" : ""
                }`}
                onClick={() => props.packageTypeChange("document")}
              >
                Document
              </Button>
            </Col>
            <Col
              md="3"
              className="d-flex justify-content-start align-items-center"
            >
              <Button
                className={`${
                  props.packageType == "non-document" ? "active_btn" : ""
                }`}
                onClick={() => props.packageTypeChange("non-document")}
              >
                Non-Document
              </Button>
            </Col>
          </Row> */}
          {/* {props.packageType == "document" && (
            <Row className="document__row no-gutters">
              <Col
                md="4"
                className="d-flex justify-content-start align-items-center"
              ></Col>
              <Col
                md="8"
                className="d-flex justify-content-start align-items-center"
              >
                <p className="m-0 text-white  p-0">
                  Flyer bags will be supplied by our partners
                </p>
              </Col>
            </Row>
          )} */}

          <Row className="justify-content-center">
            <Col>
              <Table responsive className="borderless quote-itemdetails-table">
                <thead className="text-center text-uppercase">
                  <tr>
                    <th className="border-0">
                      <div className="text-center">
                        <i className="color__change mt-3 pt-1 fas fa-info-circle fa-5x"></i>
                      </div>
                      <hr />
                    </th>
                    <th className="border-0">
                      <div className="text-center scale__img">
                        <img src={Scale} className="img-fluid" width="85px" />
                      </div>
                      <hr />
                    </th>
                    <th colSpan={4} className="border-0">
                      <div className="tape__img text-center">
                        <img
                          src={MeasuringTape}
                          className="img-fluid"
                          width="70px"
                        />
                      </div>
                      <hr className="w-90" />
                    </th>
                    <th></th>
                  </tr>
                  <tr>
                    <th className="text-center">
                      <Label for="country">Item Description </Label>
                    </th>
                    <th>
                      <Label for="country">Weight </Label>
                    </th>
                    <th>
                      <Label for="country">Length</Label>
                    </th>
                    <th>
                      <Label for="country">Width </Label>
                    </th>
                    <th>
                      <Label for="country">Height</Label>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.value &&
                    props.value.map((inp, idx) => (
                      <tr>
                        <th scope="row">
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                placeholder="E.G Shoes"
                                type="text"
                                className="increase__height item-desc"
                                name="parcel_description"
                                required
                                value={inp.description}
                                onChange={(e) =>
                                  props.onChange(
                                    idx,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </InputGroup>
                          </FormGroup>
                        </th>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                placeholder={"Min 0.3kg"}
                                type="number"
                                name="submitted_weight_kg"
                                required
                                className="increase__height item-detail"
                                min={0.3}
                                pattern="[+-]?\d+(?:[.,]\d+)?"
                                step={0.1}
                                value={inp.weight}
                                onChange={(e) =>
                                  props.onChange(idx, "weight", e.target.value)
                                }
                              />
                              <InputGroupText>kg</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                placeholder="Min 1cm"
                                type="number"
                                name="length"
                                required
                                className="increase__height item-detail"
                                min={1}
                                value={inp.length}
                                onChange={(e) =>
                                  props.onChange(idx, "length", e.target.value)
                                }
                              />
                              <InputGroupText>cm</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                placeholder="Min 1cm"
                                type="number"
                                min={1}
                                required
                                value={inp.width}
                                className="increase__height item-detail"
                                name="submitted_width_cm"
                                onChange={(e) =>
                                  props.onChange(idx, "width", e.target.value)
                                }
                                // onChange={(e) => {
                                //   setWidth(e.target.value);
                                //   props.onChange({ width: e.target.value });
                                // }}
                              />
                              <InputGroupText>cm</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                placeholder="Min 1cm"
                                type="number"
                                min={1}
                                required
                                name="height"
                                className="increase__height item-detail"
                                value={inp.height}
                                onChange={(e) =>
                                  props.onChange(idx, "height", e.target.value)
                                }
                                // onChange={(e) => {
                                //   setHeight(e.target.value);
                                //   props.onChange({ height: e.target.value });
                                // }}
                              />
                              <InputGroupText height="35px">cm</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <div className="increase__button_height">
                            <div>
                              <Button
                                onClick={props.handleAddClick}
                                className="btn-plus  mt-md-1"
                                size="sm"
                              >
                                <i class="fas fa-plus"></i>
                              </Button>
                            </div>
                            <div>
                              <Button
                                disabled={props.value.length == 1}
                                onClick={() => props.handleRemoveClick(idx)}
                                className="btn-minus"
                                size="sm"
                              >
                                <i class="fas fa-minus"></i>
                              </Button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row
            className={`${
              !props.insuranceData.insurance
                ? "mb-5 justify-content-start ml-lg-1"
                : "justify-content-start ml-lg-1"
            }`}
          >
            <Col className="text-left ">
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={props.insuranceData.insurance}
                  onChange={async (e) => {
                    props.insuranceChange("insurance", e.target.checked);
                  }}
                />
                <span className="text-white text-uppercase quote-item-details-span font-weight-bold">
                  Insure your parcel/cargo against
                </span>
                <br />
                <span className="text-primary text-uppercase quote-item-details-span font-weight-bold">
                  theft, loss or damage
                </span>
                {/* <br />
								<span className='text-danger ml-3 pointer font-weight-bold'>
									<a
										href={Globally_Prohibited_Items}
										download
									>
										* Prohibited goods
									</a>
								</span> */}
                {/* <br />
								<span className='text-danger ml-3  font-weight-bold'>
									<a href={LossOrDamage} download>
										* Items not insured for loss or damage
									</a>
								</span> */}
              </FormGroup>
            </Col>
            <Col
              md="4"
              align="right"
              className="text-left mb-3 weight_col_card"
            >
              {/* <FormGroup check> */}
              {/* <Input
                  type='checkbox'
                  onChange={() => setIsChecked(!isChecked)}
                /> */}
              <Card className="p-2 item-detail-Quote-weight">
                <span>Total Weight: {props.checkTotalWeight()} kg</span>
                <span>
                  Total Vol. Weight: {props.checkVolumetricWeight()} Kg
                </span>
                <span>Total Items: {props.value.length}</span>
              </Card>
              {/* <br />
                <span className='text-danger'>
                  * Items not insured for loss or damage
                </span> */}
              {/* </FormGroup> */}
            </Col>
          </Row>
          <div
            className={`${
              props.insuranceData.insurance ? "d-block mb-5" : "d-none"
            }`}
          >
            <Row className="m-2 ">
              {/* <Col md='4' className='text-left '>
								<FormGroup>
									<InputGroup>
										<Input
											placeholder='Item description'
											className='route-item-description'
											name='insurace desc'
											value={insuranceDesc}
											onChange={async e => {
												await setInsuranceDesc(
													e.target.value
												);
												props.insuranceChange({
													insurance: insurance,
													insuranceDesc:
														e.target.value,
													insuranceValue,
												});
											}}
											required={insurance}
										/>
									</InputGroup>
								</FormGroup>
							</Col> */}
              <Col md="3" className="text-left ">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Insurance value R*"
                      className="route-item-insurance"
                      name="insurace value"
                      value={props.insuranceData.insuranceValue}
                      type="number"
                      onChange={async (e) => {
                        props.insuranceChange("insuranceValue", e.target.value);
                      }}
                      required={props.insuranceData.insurance}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row className="justify-content-start m-2 ">
              <Col className="text-left" md="7">
                <span className="text-sm text-white font-weight-bold">
                  Indicate your shipment value for insurance purposes.
                  <br />
                  Any value over R30,000 contact us at{" "}
                  <a
                    href="mailto:support@shipmystuff.co.za"
                    className="text-primary"
                  >
                    support@shipmystuff.co.za
                  </a>
                  {/* Indicate the rand value of your package for
								insurance purposes? (e.g: 1234 or 1234.00
								Maximum R50000) Contact support for values over
								R50000 */}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default QuoteItemDetails;
