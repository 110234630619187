const initState = {
	fedexRates: {},
	dhlRates: {},
	intlLoading: false,
};

const intlReducer = (state = initState, { payload, type }) => {
	switch (type) {
		case 'GET_INTL_FEDEX_RATES':
			return {
				...state,
				fedexRates: payload,
			};
		case 'SET_INTL_LOADING':
			return {
				...state,
				intlLoaded: payload,
			};
		case 'GET_INTL_DHL_RATES':
			return {
				...state,
				dhlRates: payload,
			};
		default:
			return state;
	}
};

export default intlReducer;
