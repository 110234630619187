import axios from 'axios';

import { aws4Interceptor } from 'aws4-axios';

const baseURL = `https://api.shiplogic.com`;

const client = axios.create({ baseURL });
const interceptor = aws4Interceptor(
	{
		region: 'af-south-1',
		service: 'execute-api',
	},
	{
		accessKeyId: 'AKIA55D5DNTBDLANERPM',
		secretAccessKey: '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',
	}
	// {
	// 	accessKeyId: 'AKIA55D5DNTBPQ25INCH',
	// 	secretAccessKey: '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',
	// }
);
client.interceptors.request.use(interceptor);
export default client;
