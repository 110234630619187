import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import Packaging_Guidelines from "../assets/docs/Packaging_Guidelines.pdf";
import Globally_Prohibited_Items from "../assets/docs/Globally_Prohibited_Items.pdf";
import footer_img from "../assets/img/footer_img.png";
import Payment_Partners from "../assets/img/Payment_Partners.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { onServiceChange } from "../store/actions/serviceActions";
function FooterOne() {
  const history = useHistory();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  const ScrollToSeaFright = () => {
    const currentUrl = window.location.href;
    const pathSegments = currentUrl.split("/");
    const pageroute = pathSegments[pathSegments.length - 1];
    console.log(pageroute, "currentURL>>>>>");
    if (pageroute !== "/") {
      history.push("/");
      dispatch(onServiceChange("sea"));
      scrollToTop();
    }
  };
  const ScrollTolocal = () => {
    const currentUrl = window.location.href;
    const pathSegments = currentUrl.split("/");
    const pageroute = pathSegments[pathSegments.length - 1];
    console.log(pageroute, "currentURL>>>>>");
    if (pageroute !== "/") {
      history.push("/");
      dispatch(onServiceChange("local"));
      scrollToTop();
    }
  };
  const ScrollToInternational = () => {
    const currentUrl = window.location.href;
    const pathSegments = currentUrl.split("/");
    const pageroute = pathSegments[pathSegments.length - 1];
    console.log(pageroute, "currentURL>>>>>");
    if (pageroute !== "/") {
      history.push("/");
      dispatch(onServiceChange("international"));
      scrollToTop();
    }
  };
  return (
    <div className="banner_carousal">
      <footer>
        <div className="footer_top py-4">
          <Container>
            <Row>
              <Col lg="3">
                <h4 className="text-big">NEED HELP?</h4>
                <div className="inner">
                  <ul className="footer-link link-hover">
                    <li>
                      <a href="/contactus">Contact Us</a>
                    </li>
                    <li>
                      <a
                        href={
                          selectedCountry == "PT"
                            ? "mailto:info@shipmystuff.pt"
                            : "mailto:info@shipmystuff.co.za"
                        }
                      >
                        <i className="far fa-envelope fa-1x mr-2"></i>{" "}
                        {selectedCountry == "PT"
                          ? "info@shipmystuff.pt"
                          : "info@shipmystuff.co.za"}
                      </a>
                    </li>
                  </ul>
                </div>
                <h4 className="text-big">FOLLOW US</h4>
                <div className="inner">
                  <ul className="footer-link link-hover">
                    <li>
                      <a
                        href={
                          selectedCountry == "PT"
                            ? "https://www.facebook.com/shipmystuffPT/"
                            : "https://www.facebook.com/shipmystuffZA/"
                        }
                        target="_blank"
                      >
                        <i className="fab fa-facebook fa-1x mr-3"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/ship_my_stuff_solutions/"
                        target="_blank"
                      >
                        <i className="fab fa-instagram fa-1x mr-3"></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/shipmystuff/"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin fa-1x mr-3"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="3">
                <h4 className="text-big">QUOTING SERVICES</h4>
                <div className="inner">
                  <ul className="footer-link link-hover">
                    <li>
                      {selectedCountry == "PT" ? (
                        <button className="p-0" onClick={() => ScrollToSeaFright()}>
                          Sea Freight
                        </button>
                      ) : (
                        <button className="p-0" onClick={() => ScrollTolocal()}>
                          Domestic Courier Quote
                        </button>
                      )}
                    </li>
                    <li>
                      {selectedCountry == "PT" ? (
                        <button className="p-0" onClick={() => ScrollToInternational()}>
                          Express / Courier Quote
                        </button>
                      ) : (
                        <button className="p-0" onClick={() => ScrollToInternational()}>
                          International Courier Quote
                        </button>
                      )}
                    </li>
                    {selectedCountry === "ZA" && (
                      <li>
                        <button className="p-0" onClick={() => ScrollToSeaFright()}>
                          Sea Freight
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </Col>
              <Col lg="3">
                <h4 className="text-big">QUICK LINKS</h4>
                <div className="inner">
                  <ul className="footer-link link-hover">
                    <li>
                      <a href="/trackparcel">Track Parcel</a>
                    </li>
                    <li>
                      {/* <a href={Packaging_Guidelines} download>
											Packing Guide
										</a> */}
                      <a href="/packing-guidelines">Packing Guide</a>
                    </li>
                    <li>
                      <a href="/globally-prohibited-items">Prohibited Items</a>
                      {/* <a
											href={Globally_Prohibited_Items}
											download
										>
											Prohibited Items
										</a> */}
                    </li>
                    <li>
                      <a href="/faq">FAQ</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg="3">
                <h4 className="text-big">PAYMENT PARTNERS</h4>
                <ul className="footer-link link-hover">
                  <li>
                    <img src={Payment_Partners} width="170px" height="117px" />
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="footer_top__bottom">
              <Col>
                <div className="d-flex justify-content-center">
                  <a href="/main" className="">
                    Home
                  </a>
                  <a
                    href="/terms-and-conditions"
                    className="footer_link_bottom ml-3 pl-3 "
                  >
                    Terms & Conditions{" "}
                  </a>
                  <a
                    href="/privacy-policy"
                    className="footer_link_bottom ml-3 pl-3"
                  >
                    Privacy Policy{" "}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="d-flex justify-content-center text-center align-items-center py-2">
          <span className="copyright_text">
            Copyright @ 2022 Ship My Stuff | All Rights Reserved
          </span>
        </div>
      </footer>
    </div>
  );
}

export default FooterOne;
