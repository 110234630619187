import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  FormGroup,
  Input,
} from "reactstrap";

function TrackYourParcel() {
  const [active, setActive] = useState("1");
  const [windowsize,setwindowsize]=useState(window.innerWidth);
  console.log(windowsize,"windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
        setwindowsize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, [window]);
  //Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // 	window.dataLayer.push({
	// 	event: 'pageview'
	// });
  // window.dataLayer.push({
  //   event: 'pageview',
  //   page: {
  //     url: 'https://ship-my-stuff-staging.web.app/trackparcel',
  //     title: "Track Parcel"
  //   }
  // });
  return (
    <div className="section_gap track_parcel_section">
      <Container>
        {windowsize<765? ( 
          <>
          <Row>
          {/* <Row>
          <Col
           lg="6"
           md="6"
           sm="12"
           xs="12"
            className="mb--40 d-flex justify-content-center justify-content-sm-end md-align-items-center"
            align="end"
          > */}
          <Col
           lg="6"
           md="6"
           sm="12"
          xs="12"
          className="d-flex justify-content-center align-items-center"
          >
            <Button
              className={` btn_track py-2  my-1 ${
                active == "1" ? "bg-primary" : "bg-secondary"
              }`}
              onClick={() => setActive("1")}
            >
              DOMESTIC {" "}
              TRACKING
            </Button>
            </Col>
          {/* </Col>
          <Col
           lg="6"
           md="6"
           sm="12"
          xs="12"
          className="mb--40 d-flex justify-content-center justify-content-sm-start align-items-center"
           align="start"
          > */}
           <Col
           lg="6"
           md="6"
           sm="12"
          xs="12"
          className="d-flex justify-content-center align-items-center"
          >
            <Button
              className={`btn_track py-2 my-1  ${
                active == "2" ? "bg-primary" : "bg-secondary"
              }`}
              onClick={() => setActive("2")}
            >
              INTERNATIONAL {" "}
              TRACKING
            </Button>
            </Col>
            </Row>
         {/* </Col>
        </Row> */}
        </>
      ):(
        <Row>
          <Col
           lg="6"
           md="6"
           sm="12"
           xs="12"
            className="mb--40 d-flex justify-content-center justify-content-sm-end md-align-items-center"
            align="end"
          >
            <Button
              className={` btn_track my-1 ${
                active == "1" ? "bg-primary" : "bg-secondary"
              }`}
              onClick={() => setActive("1")}
            >
              DOMESTIC 
              <br /> 
              TRACKING
            </Button>
          </Col>
          <Col
             lg="6"
             md="6"
             sm="12"
             xs="12"
            className="mb--40 d-flex justify-content-center justify-content-sm-start align-items-center"
            align="start"
          >
            <Button
              className={`btn_track my-1  ${
                active == "2" ? "bg-primary" : "bg-secondary"
              }`}
              onClick={() => setActive("2")}
            >
              INTERNATIONAL 
              <br />
              TRACKING
            </Button>
          </Col>
        </Row>
            )}
        <Row className="mt-4 justify-content-center">
          <Col md="11">
            {active == "1" ? (
              <Card className="track_parcel_card">
                <p className="track_p">SELECT COURIER:</p>
                <Row noGutters className="track__row justify-content-end">
                  <Col
                    md="3"
                    className="p-2 d-flex  justify-content-center align-items-center flex-column"
                  >
                    <p className="track_p1">The Courier Guy</p>

                    <Input type="radio" />
                  </Col>
                  <Col
                    md="3"
                    className="border__left p-2 d-flex  justify-content-center align-items-center flex-column"
                  >
                    <p className="track_p1">Dawnwing</p>

                    <Input type="radio" />
                  </Col>
                  <Col
                    md="3"
                    className="border__left p-2 d-flex  justify-content-center align-items-center flex-column"
                  >
                    <p className="track_p1">CourierIt </p>

                    <Input type="radio" />
                  </Col>
                  <Col
                    md="3"
                    className="border__left p-2 d-flex  justify-content-center align-items-center flex-column"
                  >
                    <p className="track_p1">Globeflight</p>

                    <Input type="radio" />
                  </Col>
                </Row>

                <Row noGutters className="mt-4">
                  <Col md="6" className=" track_input">
                    <Input
                      className="w-100"
                      type="text"
                      placeholder="ENTER TRACKING NUMBER"
                    />
                    {/* <p className='track_sub_p'>
										ENTER TRACKING NUMBER
									</p> */}
                  </Col>
                  <Col md="6" className="p-2 px-3">
                    <p className="track_p2">
                      * The tracking number can be found on your invoice
                    </p>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-between">
                  <Col md="3">
                    <Button className="track_sub_btn">TRACK</Button>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-end">
                  <Col md="9">
                    <Card className="track_output_card">
                      <p></p>
                    </Card>
                  </Col>
                </Row>
                {/* <Row className='mt-4 justify-content-between'>
									<Col md='2'>
										<Button className='track_sub_btn'>
											TRACK
										</Button>
									</Col>
									<Col md='9'>
										<Card className='track_output_card'>
											<p></p>
										</Card>
									</Col>
								</Row> */}
              </Card>
            ) : (
              <Card className="track_parcel_card">
                <p className="track_p">TRACK BY REFERENCE / WAYBILL NUMBER:</p>
                <Row noGutters className="justify-content-start mt-2 mb-lg-4 mb-md-4 mb-1">
                  <Col md="4">
                    <p className="track_p2">Enter up to 30 digits</p>
                  </Col>
                </Row>

                <Row noGutters className="mt-lg-4 mt-md-4">
                  <Col md="6" sm="12" className=" track_input">
                    <Input className="w-100" type="text" placeholder="ENTER TRACKING NUMBER" />
                    {/* <p className='track_sub_p'>
										ENTER TRACKING NUMBER
									</p> */}
                  </Col>
                  <Col md="6" className="p-2 px-3">
                    <p className="track_p2">
                      * The tracking number can be found on your invoice
                    </p>
                  </Col>
                </Row>
                <Row className="mt-lg-4 mt-md-4 justify-content-between">
                  <Col md="3">
                    <Button className="track_sub_btn">TRACK</Button>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-end">
                  <Col md="9">
                    <Card className="track_output_card">
                      <p></p>
                    </Card>
                  </Col>
                </Row>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TrackYourParcel;
