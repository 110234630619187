import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  CardSubtitle,
  Label,
  Input,
  FormGroup,
  CardFooter,
} from "reactstrap";
import ProcessList from "../ProcessList";

import Payment from "../../assets/img/payment.png";
import Calendar from "../../assets/img/Calendar.png";
import DeliveryDestination from "../../assets/img/DeliveryDestination.png";
import Globally_Prohibited_Items from "../../assets/docs/Globally_Prohibited_Items.pdf";
import moment from "moment";
import { Link } from "react-router-dom";
import Packaging_Guidelines from "../../assets/docs/Packaging_Guidelines.pdf";
import ServicesHeader from "../ServicesHeader";
import { LocalActiveTab } from "./LocalActiveTab";

function QuoteCalender(props) {
  return (
    <>
      <div className="mx-4">
        <ServicesHeader value="DOMESTIC COURIER" />
        <LocalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
      </div>
      {/* <Row className = 'justify-content-center  mx-5 quote-calender-component'>
        <Col md='4'>
          <i className='fas fa-check' />
        </Col>
        <Col md='4'>
          <i className='fas fa-check' />
        </Col>
        <Col md='4'>
          <i className='fas fa-check' />
        </Col>
      </Row> */}

      {/* <Row className='justify-content-center  mx-5 my-2 quote-calender-component'>
				<Col md='2'>
					<ProcessBox title='Collection Date' img={Calendar} />
				</Col>
				<Col md='1' className='mt-1 mt-md-5 mb-5 mb-md-0'></Col>
				<Col md='2'>
					<i className='fas fa-circle text-secondary fa-2x' />

					<Card className='mt-3'>
						<CardBody className='p-2'>
							<img
								src={DeliveryDestination}
								className='card-img-map img-fluid'
							/>
						</CardBody>

						<CardText className='mb-1'>Shipment Address</CardText>
					</Card>
				</Col>
				<Col md='1' className='mt-1 mt-md-5 mb-5 mb-md-0'>
					<i
						className={`${
							isMobile
								? 'fas fa-arrow-down'
								: 'fas fa-arrow-right'
						} mt-5 text-primary fa-3x`}
					/>
				</Col>
				<Col md='2'>
					<i className='fas fa-circle text-secondary fa-2x' />

					<Card className='mt-3'>
						<CardBody className='p-2'>
							<img src={Payment} className='card-img img-fluid' />
						</CardBody>
						<CardText className='mb-1'>
							Payment Confirmation
						</CardText>
					</Card>
				</Col>
			</Row> */}

      {/* <Row className='m-5 '>
        <Col md='4' align='right' className='text-left ml-0 ml-md-5 mt-sm-2 mt-md-0'>
          <FormGroup>
            <Input type='text' placeholder="text" className='input__width' />
            <Input type='text' placeholder="text" className='input__width mt-2' />
          </FormGroup>
        </Col>
        <Col md='4' align='right' className='text-left mt-sm-2 mt-md-0'>
          <Input type="textarea" placeholder="text box placeholder" rows="4" />
        </Col>
      </Row> */}
      <ProcessList activeIdx={0} />
      <hr className="process_list_hr" />
      <Row className="m-3 quote-calender-component ">
        <Col md="4" align="left" className="text-left ">
          <FormGroup>
            <Label color="primary">Select Collection Date</Label>
            <Input
              name="date"
              type="date"
              min={moment().toISOString().substring(0, 10)}
              value={props.value.startDate}
              className="w-75"
              onChange={(e) => props.onChange({ startDate: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="8" align="right" className="text-left mb-3">
          <Card className="px-2 quote__calendar">
            <CardBody className="line__height">
              <Label className="mb-2">
                <span className="text-danger quote-calender-information">
                  *Important Information
                </span>
              </Label>
              <ul>
                <li className="text-secondary">
                  Ensure the selected date is correct
                </li>
                <li className="text-secondary">
                  Your package should be ready at least 30 min before the driver
                  arrives
                </li>
                <li className="text-secondary">
                  Drivers will only wait 10 min at the collection address
                </li>
                <li className="text-secondary">
                  Courier companies will not assist with additional
                  handling/carrying requests
                </li>
                <li className="text-secondary">
                  Refer to our packing guide to ensure your parcel/cargo’s
                  safety
                </li>
                {/* <li className='text-secondary '>
									Ensure Correct date is selected.
								</li>
								<li className='text-secondary'>
									Ensure your package is ready for before
									collection 30 minutes before the driver
									arrives
								</li>
								<li className='text-secondary'>
									Download our{' '}
									<a
										href={Globally_Prohibited_Items}
										download
									>
										<u>
											{' '}
											<strong className='text-primary'>
												{' '}
												prohibited items{' '}
											</strong>{' '}
										</u>{' '}
									</a>
									for more information
								</li>
								<li className='text-secondary'>
									Download our{' '}
									<a href={Packaging_Guidelines} download>
										<u>
											{' '}
											<strong className='text-primary'>
												{' '}
												packing guide{' '}
											</strong>{' '}
										</u>{' '}
									</a>
									for more information
								</li> */}
              </ul>
            </CardBody>
          </Card>
          {/* <Card>
            <CardBody className="line__height">
              <div className="font-weight-bold">Economy</div>
              <div>Booking Details: 14:00 pm</div>
            </CardBody>
          </Card>
          <Card className="mt-2 ">
            <CardBody className="line__height">
              <div className="font-weight-bold">Overnight Express</div>
              <div>Booking Details: 14:00 pm</div>
            </CardBody>
          </Card>
          <Card className="mt-2 ">
            <CardBody className="line__height">
              <div className="font-weight-bold">Sameday</div>
              <div>Booking Details: 14:00 pm</div>
            </CardBody>
          </Card> */}
        </Col>

        {/* <Col md='4' className='text-left mt-sm-2 mt-md-0'>
          <FormGroup>
            <Label color='secondary'>Sending Details</Label>
          </FormGroup>
        </Col>
        <Col md='4' className='text-left mt-sm-2 mt-md-0'>
          <FormGroup>
            <Label color='secondary'>Receiving Details</Label>
          </FormGroup>
        </Col> */}
      </Row>
    </>
  );
}

export default QuoteCalender;
