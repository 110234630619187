import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Spinner,
  Col,
  Card,
  CardText,
  CardBody,
  Table,
  CardTitle,
  Button,
  Input,
  Label,
} from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import axios from "axios";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import firebase from "../../config/firebase";
import DeliveryDestination from "../../assets/img/DeliveryDestination.png";
import Calendar from "../../assets/img/Calendar.png";
import Payment from "../../assets/img/payment.png";
import Logo from "../../assets/img/logo.png";
import TabImg from "../../assets/img/table.PNG";
import ProcessList1 from "../ProcessList1";
function ItemInvoice(props) {
  const [loader, setLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(5).fill(false));

  const checkTotal = () => {
    let totalSum = 0;

    // let allValues = props.sixth.declarationItems.map(elm => {
    // 	return (totalSum = totalSum + elm.totalValue);

    // });

    return props.sixth?.totalShipmentValue;
  };

  const downloadLicense = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      onclone: (document) => {
        document.getElementById("save-button").style.visibility = "hidden";
      },
      // backgroundColor: null
    }).then(async (canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jspdf();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    });
  };
  const uploadToStorage = () => {
    setLoader(true);
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      onclone: (document) => {
        document.getElementById("save-button").style.visibility = "hidden";
      },
      // backgroundColor: null
    }).then(async (canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdfJS = new jspdf();
      const imgProps = pdfJS.getImageProperties(img);
      const pdfWidth = pdfJS.internal.pageSize.getWidth();
      const pdfHeight = pdfJS.internal.pageSize.getHeight();
      pdfJS.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
      // pdfJS.addImage(img, "JPEG", 0, 0);
      const blob = new Blob([pdfJS.output("blob")], {
        type: "application/pdf",
      });
      const file = new File([blob], `${uuidv4()}.pdf`, {
        type: "application/pdf",
        lastModified: Date.now(),
      });

      let filename = file.name;
      let ext2 = filename.slice(filename.lastIndexOf("."));
      let file_name = uuidv4() + ext2.toLowerCase();
      let storageRef = await firebase
        .storage()
        .ref("recipient/" + file_name)
        .put(file);
      let url = await storageRef.ref.getDownloadURL();
      setLoader(false);

      let obj = { url };
      let seventh = localStorage.setItem(
        "intl_eighth_page",
        JSON.stringify(obj)
      );
    });
  };

  useEffect(() => {
    if (props.activeTab == 8) {
      // uploadToStorage();
    }
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, [props.activeTab]);
  const updatePredicate = () => {
    setIsMobile(window.innerWidth < 767);
  };
  return (
    <>
      <ProcessList1 activeIdx={4} />
      <Container id="divToPrint" className="invoice-component mt-4 ">
        <div className="mx-4">
          <Row className="invoice-header text-white">
            <Col className="text-center">
              <span>BILLING DETAILS</span>
              {/* <span>CONSIGNMENT DETAILS</span> */}
            </Col>
            <Col className="text-center">
              <span>INVOICE DETAILS</span>
              {/* <span>REASON FOR EXPORT</span> */}
            </Col>
          </Row>
          <Row className="invoice-data bg-white">
            <Col className="text-left ">
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Name:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingName}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Contact No:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingContactNo}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Email Address:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingEmail}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Address 1:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingAddress1}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Suburb:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingSuburb}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Postal Code / Zip Code:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingPostalCode}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Country:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingCountry}</span>
                </Col>
              </Row>
              <Row className="px-2 mb-2">
                <Col lg="5" xs="8" align="end">
                  <span>VAT Number (If applicable):</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.seventh?.billingVAT}</span>
                </Col>
              </Row>
            </Col>
            <Col className="text-left vertical__line">
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Invoice Date:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{moment().format("l")}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Invoice Number:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span></span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Total Weight:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.second?.totalWeight}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Chargeable Weight:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span></span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="invoice-header text-white">
            <Col className="text-center">
              <span>SHIPMENT DETAILS</span>
            </Col>
          </Row>
          <Row className="invoice-data bg-white text-dark font-weight-bold pt-2">
            <Col className="text-center">
              <h6 className="font-weight-bold">SHIPPER</h6>
            </Col>
            <Col className="text-center">
              <h6 className="font-weight-bold">CONSIGNEE</h6>
            </Col>
          </Row>
          <Row className=" invoice-data bg-white">
            <Col className="text-left">
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Name:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingName}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Contact Number:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingContact}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Email Address:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingEmail}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Address 1:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingAddress1}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span className="text-nowrap">
                    Complex / Business Park / Estate:
                  </span>
                </Col>
                <Col lg="5" xs="4">
                  <span>
                    {props.fifth?.sendingComplex
                      ? props.fifth.sendingComplex
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Suburb:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingSuburb}</span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Postal Code / Zip Code:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingPostal}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>City:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingCity}</span>
                </Col>
              </Row>
              <Row className=" px-2 mb-2">
                <Col lg="5" xs="8" align="end">
                  <span>Country:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.fromCountry}</span>
                </Col>
              </Row>
            </Col>
            <Col className="text-left vertical__line">
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Name:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.ReceivingName}</span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Contact Number:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.ReceivingContact}</span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Email Address:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.ReceivingEmail}</span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Address 1:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.ReceivingAddress1}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span className="text-nowrap">
                    Complex / Business Park / Estate:
                  </span>
                </Col>
                <Col lg="5" xs="4">
                  <span>
                    {props.fifth?.ReceivingComplex
                      ? props.fifth.ReceivingComplex
                      : ""}{" "}
                  </span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Suburb:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.ReceivingSuburb}</span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Postal Code / Zip Code:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.ReceivingPostal}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>City:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.ReceivingCity}</span>
                </Col>
              </Row>
              <Row className=" px-2 mb-2">
                <Col lg="5" xs="8" align="end">
                  <span>Country:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.toCountry}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="mx-4">
          <Row className="invoice-header text-white">
            <Col className="text-center">
              <span>CHARGES</span>
            </Col>
          </Row>
          <Row>
            <Table className="invoice-booking-details-table">
              <thead>
                <th className="invoice-table-border border-left text-center">
                  Item Description
                </th>
                <th className="invoice-table-border invoice-table-border-desc text-center">
                  VAT Code
                </th>
                <th className="invoice-table-border invoice-table-border-desc text-center">
                  VAT Amount
                </th>
                <th className="invoice-table-border border-right text-center">
                  {" "}
                  Amount
                </th>
              </thead>
              <tbody>
                {/* {props.sixth.totalItems.map((item, i) => (
                  <tr>
                    <td className="invoice-table-border-td border-left text-center"> */}
                {/* International Economy Service */}
                {/* {props.second.itemDetails.map((item, idx) => (
                <tr key={item.idx}>
                  <td className="invoice-table-border-td border-left">
                    <span className="d-flex">
                      {" "}
                      <Input type="text" className="w-75" />
                    </span>
                  </td>
                  <td className="invoice-table-border-td ">
                    <span className="d-flex">
                      {" "}
                      <Input
                        type="number"
                        min="1"
                        className="w-75 border-1"
                        onChange={(e) => handleQtyChange(idx, e.target.value)}
                      />
                    </span>
                  </td>
                  <td className="invoice-table-border-td">
                    <span className="d-flex">
                       <Input type="text" className="w-75" />
                    </span>
                  </td>
                  <td className="invoice-table-border-td">
                    <span className="d-flex">
                       <Input type="text" className="w-75" />
                    </span>
                  </td>
                  <td className="invoice-table-border-td">
                    <span className="d-flex">
                      {" "}
                      <Input
                        type="number"
                        min="1"
                        className="w-75"
                        onChange={(e) => handleUnitChange(idx, e.target.value)}
                      />
                    </span>
                  </td>
                  <td className="invoice-table-border-td border-right">
                     R{handleTotalUnit(idx)}
                  </td>
                </tr>
              ))} */}
                {/* {props.sixth.totalItems.map((item, i) => (
                  <tr>
                    <td className="invoice-table-border-td border-left">
                    
                      {item.description}
                    </td>
                    <td className="invoice-table-border-td text-center"> Z</td>
                    <td className="invoice-table-border-td text-center"> R0</td>
                    <td className="invoice-table-border-td border-right text-center">
                      R{item.totalValue}
                    </td>
                  </tr>
                ))} */}

                <tr>
                  <td className="invoice-table-border-td border-left text-center">
                    Economy Service
                  </td>
                  <td className="invoice-table-border-td text-center"> Z</td>
                  <td className="invoice-table-border-td text-center"> R0</td>
                  <td className="invoice-table-border-td border-right text-center">
                    R{Number(props.third?.rate)}
                  </td>
                </tr>
                <tr>
                  <td className="invoice-table-border-td border-left text-center">
                    Documentation
                  </td>
                  <td className="invoice-table-border-td text-center"> Z</td>
                  <td className="invoice-table-border-td text-center"> R0</td>
                  <td className="invoice-table-border-td border-right text-center">
                    R300
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </div>
        <Row className="justify-content-end m-2">
          <Col lg="3" md="4" align="right" className="text-left mb-3">
            {/* <FormGroup check> */}
            {/* <Input
                  type='checkbox'
                  onChange={() => setIsChecked(!isChecked)}
                /> */}
            <Card className="p-2 item-detail-weight">
              <span>Total VAT: R0</span>
            </Card>
            <Card className="p-2 item-detail-weight">
              <span>Sub Total: R{checkTotal()}</span>
            </Card>
            <Card className="p-2 item-detail-weight">
              <span>Total Amount: R{props.third?.rate}</span>
            </Card>
            {/* <br />
                <span className='text-danger'>
                  * Items not insured for loss or damage
                </span> */}
            {/* </FormGroup> */}
          </Col>
        </Row>
        <div id="save-button" className="m-2 text-left">
          <form action={props.payment?.redirectUri} method="POST">
            <input
              hidden
              name="PAY_REQUEST_ID"
              value={props.payment?.redirectParams?.PAY_REQUEST_ID}
            />
            <input
              hidden
              name="CHECKSUM"
              value={props.payment?.redirectParams?.CHECKSUM}
            />
            <Button type="submit" className="pay-btn px-5">
              {loader ? <Spinner /> : "Pay Now"}
            </Button>
          </form>

          {/* <Button color="primary">Confirm Details</Button> */}
          {/* <Button
            onClick={() => {
              downloadLicense();
            }}
            color="primary"
            className="mx-2 mt-2"
          >
            Download Invoice <i className="fa fa-file-download ml-2"></i>
          </Button> */}
        </div>
      </Container>
    </>
  );
}

export default ItemInvoice;
