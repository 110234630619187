import React, { useEffect,useState } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import axios from "axios";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../config/firebase";
import moment from "moment";
import { getCounters } from "../../store/actions/localActions";
import { useDispatch, useSelector } from "react-redux";
const QuotePaymentUI = (props) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry,"selectedCountry");
  const { counters } = useSelector((state) => state.local);
  const dispatch = useDispatch();

  const docID = uuidv4();
  const downloadRecipient = async (localData) => {
    await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/sendEmailLocal`, {
      // url: props.seventh.url,
      selectedCountry:selectedCountry,
      url: docID,
      email: props.sixth.billingEmail,
      data: localData,
    });
  };
  // console.log('docId', docID);
  // const downloadRecipient = async () => {
  //   const input = document.getElementById("recipientPrint");
  //   html2canvas(input, {
  //     onclone: (document) => {
  //       // document.getElementById("save-button").style.visibility = "hidden";
  //     },
  //     // backgroundColor: null
  //   })
  //     .then(async (canvas) => {
  //       const img = canvas.toDataURL("image/png");
  //       const pdf = new jspdf();
  //       pdf.addImage(img, "JPEG", 0, 0);
  //       // const blob = new Blob([pdf.output], { type: "application/pdf" });
  //       // const file = new File([blob], `${uuidv4()}.pdf`, {
  //       //   type: "application/pdf",
  //       //   lastModified: Date.now(),
  //       // });
  //       // console.log("file", file);
  //       // let filename = file.name;
  //       // let ext2 = filename.slice(filename.lastIndexOf("."));
  //       // let file_name = uuidv4() + ext2.toLowerCase();
  //       // let storageRef = await firebase
  //       //   .storage()
  //       //   .ref("recipient/" + file_name)
  //       //   .put(file);
  //       // let url = await storageRef.ref.getDownloadURL();
  //       // console.log("url", url);
  //       await axios.post("http://localhost:5000/sendEmail", {
  //         url: props.seventh.url,
  //         email: props.sixth.email,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const addToFirestore = async () => {
    let { serviceType, ...selectedCourier } = props.third.selectedCourier;
    let localRef = JSON.parse(localStorage.getItem("local_reference"));

    let shipMentItems = [];
    props.second.itemDetails.forEach((item) => {
      shipMentItems.push({
        DimensionalUnit: "cm",
        Length: item.length,
        Width: item.width,
        Height: item.height,
        MetricUnit: "kg",
        PhysicalWeight: item.weight,
        VolumetricWeight: (item.length * item.width * item.height) / 5000,
      });
    });

    let invoiceRef = 1000;
    if (counters.length > 0 && counters[0].local_counter) {
      invoiceRef = counters[0].local_counter + 1;
    }

    console.log("Counters...", counters);
    let invoice_number = "inv-" + invoiceRef;

    let shipment_number = props.reference;

    let localData = {
      ShipmentNumber: shipment_number,
      Country: "ZA",
      Invoice: {
        BillingInformation: {
          AcceptTsCs: true,
          BillingAddress1: props.sixth?.billingAddress1 || "",
          BillingAddress2: props.sixth?.billingTown || "",
          BillingCompanyName: props.sixth?.billingCompanyName || "",
          BillingContactNumber: props.sixth?.billingContactNo || "",
          BillingCountry: props.sixth?.billingCountry || "",
          BillingEmailAddress: props.sixth?.billingEmail || "",
          BillingFirstName: props.sixth?.billingFirstName || "",
          BillingPostalCode: props.sixth?.billingPostalCode || "",
          BillingSuburb: props.sixth?.billingSuburb || "",
          BillingSurname: props.sixth?.billingLastName || "",
          BillingVatNumber: props.sixth?.vatNumber || "",
        },
        InvoiceInformation: {
          Currency: "ZAR",
          InvoiceAmount: selectedCourier?.TotalRate || 0,
          InvoiceDate: moment().format("YYYY-MM-DD"),
          InvoiceNumber: invoice_number,
          InvoiceType: "local",
          Line1Amount: selectedCourier?.TotalRate || 0,
          Line1Description: "Local Courier",
          Line1VatAmount: 0,
          Line1VatCode: "",
          Line2Amount: "",
          Line2Description: "",
          Line2VatAmount: "",
          Line2VatCode: "",
          ShipmentReference: props.reference,
          WaybillNumber: "",
        },
      },
      ShipmentManifest: {
        AddressInformation: {
          ConsigneeAddress1: props.fifth?.RecievingAddress1 || "",
          ConsigneeAddress2: props.fifth?.RecievingComplexNo || "",
          ConsigneeCity: props.first?.toLocalArea || "",
          ConsigneeContactNumber: props.fifth?.RecievingContact || "",
          ConsigneeCountry: "South Africa",
          ConsigneeEmailAddress: props.fifth?.RecievingEmail || "",

          ConsigneeName: props.fifth?.RecievingName || "",
          ConsigneePostalCodeZipCode: props.first?.toCode || "",
          ConsigneeSuburb: props.first?.toSuburb || "",
          ConsigneeSurname: props.fifth?.RecievingSurName || "",
          ConsigneeCollectionDate: "",

          ShipperAddress1: props.fifth?.sendingAddress1 || "",
          ShipperAddress2: props.fifth?.sendingComplexNo || "",
          ShipperCity: props.first?.fromLocalArea || "",
          ShipperCollectionDate: props.fourth.startDate || "",
          ShipperContactNumber: props.fifth?.sendingContact || "",
          ShipperCountry: "South Africa",
          ShipperEmailAddress: props.fifth?.sendingEmail || "",
          ShipperIdentityNumber: "",
          ShipperName: props.fifth?.sendingName || "",
          ShipperPostalCodeZipCode: props.first?.fromCode || "",
          ShipperSuburb: props.first?.fromSuburb || "",
          ShipperSurname: props.fifth?.sendingSurName || "",
        },
        CargoInformation: {
          Items: shipMentItems,
          TotalVolumetricWeight: props.second?.volumetricWeight || 0,
          TotalPhysicalWeight: props.second?.totalWeight || 0,
        },
        CarrierInformation: {
          Currency: "ZAR",
          Carrier: serviceType || "",
          Price: selectedCourier?.TotalRate || "",
          ServiceSelected: props.third.type || "",
        },
        ShipmentHeader: {
          InsuranceAmount: props.insuranceDetails?.insuranceValue || "",
          InsuranceRequested: props.insuranceDetails.insurance || false,
          InvoiceReference: invoice_number,
          ShipmentCreationDate: moment().format("YYYY-MM-DD"),
          ShipmentType: "domestic",
        },
      },
      PaymentData: props.payment?.redirectParams || "",
      DocId: docID,
    };

    console.log("Local data", localData);

    if (localRef == null) {
      console.log("Document..", docID);
      localStorage.setItem("local_reference", JSON.stringify(props.reference));
      await firebase
        .firestore()
        .collection(`${process.env.REACT_APP_LOCAL_ORDER}`)
        .doc(docID)
        .set(localData);
      downloadRecipient(localData);
    } else if (localRef != null && localRef != props.reference) {
      localStorage.setItem("local_reference", JSON.stringify(props.reference));
      await firebase
        .firestore()
        .collection(`${process.env.REACT_APP_LOCAL_ORDER}`)
        .doc(docID)
        .set(localData);
      downloadRecipient(localData);
    }
  };

  useEffect(() => {
    async function loadData() {
      if (counters && counters.length == 0) {
        await dispatch(getCounters(process.env.REACT_APP_COUNTERS));
      }
      if (counters.length > 0) {
        addToFirestore();
        // downloadRecipient();
      }
    }
    loadData();
  }, [counters]);
  return (
    <>
      <Row id="recipientPrint" className="justify-content-center  m-4">
        <Col md="8" className="mx-auto">
          <span className="text-primary h2 text-center">
            Thank you for your booking.
          </span>
          <hr />
          <br />
          <div className="text-left">
            <span>
              Your reference number : <strong>{props.reference}</strong>
            </span>
            <br />
            <br />
            {/* <span className='font-weight-bold'>
							Please check your inbox
						</span>
						<br /> */}
            <span>Your booking confirmation will be sent shortly.</span>
            <br />
            <br />
            <span className="font-weight-bold">
              For any enquiries regarding your shipment, contact us at{" "}
              <a
                href="mailto:support@shipmystuff.co.za"
                className="text-primary"
              >
                support@shipmystuff.co.za
              </a>
            </span>
            {/* <span>
							For any enquiries regarding your shipment:{' '}
							<strong>support@shipmystuff.co.za</strong>
						</span> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default QuotePaymentUI;
