import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import moment from "moment";
import countriesStates from "../../config/countriesStates.json";
import { useSelector } from "react-redux";
import Euro from "../../assets/img/EuroCurrency.png";

import DeliveryDestination from "../../assets/img/DeliveryDestination.png";
import Calendar from "../../assets/img/Calendar.png";
import Payment from "../../assets/img/payment.png";
import Logo from "../../assets/img/logo.png";
import TabImg from "../../assets/img/table.PNG";
import ProcessList1 from "../ProcessList1";
import ReactFlagsSelect from "react-flags-select";
import countriesList from "../../config/countriesList.json";
import ServicesHeader from "../ServicesHeader";
import InternationalActiveTab from "./InternationalActiveTab";

function ItemDeclaration(props) {

  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry, "selectedCountry");
  const [isMobile, setIsMobile] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(5).fill(false));
  const [reason, setReason] = useState("");
  const [viewGuidlines, setViewGuidlines] = useState(false);
  const [inputList, setInputList] = useState([
    {
      country: "",
      quantity: 0,
      description: "",
      type: "",
      unitValue: 0,
      totalValue: 0,
    },
  ]);
  const [totalItems, setTotalItems] = useState([
    {
      country: "",
      quantity: 0,
      description: "",
      type: "",
      unitValue: 0,
      totalValue: 0,
    },
  ]);

  const handleInputChange = (idx, e) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[idx][name] = value;
    list[idx]["totalValue"] = list[idx]["quantity"] * list[idx]["unitValue"];
    setInputList(list);
    props.onChange({ totalItems: inputList });
  };
  const getCountryCode = (name) => {
    let obj = countriesList.find(
      (countr) => countr.name.toLowerCase() == name.toLowerCase()
    );

    if (obj) {
      return obj.code;
    } else {
      return "";
    }
  };
  const checkTotalValue = (index) => {
    let totalUnit = (
      Number(props.declarationItems[index].quantity) *
      Number(props.declarationItems[index].unitValue)
    ).toFixed(2);

    return totalUnit;
  };

  const totalShipment = () => {
    let totalShipmentValue = 0;

    let allValues = props.declarationItems.map((elm) => {
      return (totalShipmentValue =
        totalShipmentValue + Number(elm.unitValue) * Number(elm.quantity));
    });

    // const
    return totalShipmentValue.toFixed(2);
  };
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : (item = false)
    );
    setCheckedState(updatedCheckedState);
  };

  const getCountryState = (countryCode, stateCode) => {
    if (selectedCountry === "PT") {
      return;
    } else {
      let obj = countriesStates.find((state) => state.name == countryCode);
      if (obj?.states && obj?.states?.length > 0) {
        let stateName = obj.states.find((state) => state.CODE == stateCode);
        if (stateName) {
          return stateName.STATE;
        } else {
          return;
        }
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);
  const updatePredicate = () => {
    setIsMobile(window.innerWidth < 767);
  };
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  if (windowsize > 994) {
    return (
      <>
        <div className="mx-4">
          <ServicesHeader value="INTERNATIONAL COURIER" />
          <InternationalActiveTab
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
          />
          <hr className="service-header__hr-100" />
        </div>

        <ProcessList1 activeIdx={2} />
        <hr className="process_list_intl_hr" />

        <Container className="invoice-component item-declaration mt-3">
          <Row className="justify-content-center mb-3">
            <Col
              xs="12"
              sm="6"
              className="d-flex flex-column flex-sm-row justify-content-center align-items-start"
            >
              <FormGroup check className="mb-2 mb-sm-0">
                <Input 
                align="left"
                  name="radio1"
                  type="radio"
                  defaultChecked={props.declarationData.type == "personal"}
                  onChange={(e) => {
                    props.declarationChange("personal", "");
                  }}
                />{" "}
                <Label check className="ml-2">
                  Non-commercial (personal)
                </Label>
              </FormGroup>
              <FormGroup check className="ml-md-3">
                <Input
                  name="radio1"
                  type="radio"
                  defaultChecked={props.declarationData.type == "commercial"}
                  onChange={(e) => props.declarationChange("commercial", "")}
                />{" "}
                <Label check className="ml-2" >
                  Commercial invoice
                </Label>
              </FormGroup>
            </Col>
          </Row>
          

          <Row
            className={`${
              props.declarationData.type == "commercial" ? "mb-5" : ""
            }`}
          >
            <Col className="text-left mx-2" md="6">
              <Label className="text-danger font-weight-bold imp_info">
                IMPORTANT INFORMATION*
              </Label>
              <ul className="text-white intl_declaration_ul">
                <li>
                  All international shipments requires an commercial invoice
                </li>
                <li>
                  Our guidelines will assist you in completing your commercial
                  invoice, correctly
                </li>
                <li>
                  View our guidelines for completing your invoice accurately{" "}
                  <Input
                    type="checkbox"
                    className="ml-2"
                    checked={viewGuidlines}
                    onChange={(e) => setViewGuidlines(!viewGuidlines)}
                  />
                </li>
                {viewGuidlines && (
                  <Card className="px-3 py-1 quote__calendar inlt__decl__card mt-3">
                    <CardBody className="line__height">
                      <p className="text-secondary mb-0">
                        1. Each items’ description needs to be clearly described
                      </p>
                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-times text-danger "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">T-shirt</p>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-check text-primary "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">
                            100% cotton mens t-shirt{" "}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-times text-danger "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">Picture frames</p>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-check text-primary "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">
                            Wooden picture frames
                          </p>
                        </Col>
                      </Row>

                      <p className="text-secondary mb-0 mt-1">
                        2. Items must not be grouped together
                      </p>

                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-times text-danger "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">
                            Wooden picture frame and 100% cotton mens t-shirt
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-check text-primary "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">
                            Wooden picture frame
                          </p>
                        </Col>
                      </Row>

                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-check text-primary "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">
                            100% cotton mens t-shirt
                          </p>
                        </Col>
                      </Row>
                      <p className="text-secondary mb-0 mt-1">
                        3. Use realistic values for each item
                      </p>
                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-times text-danger "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">
                            100% cotton mens t-shirt - value: R3
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col xs="2" className="d-flex justify-content-end">
                          <i
                            className="fa fa-check text-primary "
                            aria-hidden="true"
                          ></i>
                        </Col>
                        <Col xs="10" className="d-flex justify-content-start">
                          <p className="text-secondary mb-0">
                            100% cotton mens t-shirt - value: R60
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                )}
              </ul>
            </Col>
            <Col className=" declaration_border_left">
              {props.declarationData.type == "commercial" && (
                <>
                  <Row className="justify-content-center">
                    <Col
                      md="12"
                      className="d-flex text-center justify-content-center align-items-center  flex-column"
                    >
                      <FormGroup className="d-flex justify-content-start flex-column mt-4">
                        <p
                          className="text-white d-flex "
                          style={{
                            fontWeight: "500",
                            marginBottom: "8px",
                          }}
                        >
                          Upload your commercial invoice
                        </p>
                        <Input
                          type="file"
                          onChange={(e) =>
                            props.declarationChange("file", e.target.files[0])
                          }
                          style={{ width: "100%" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>

          {props.declarationData.type == "personal" && (
            <>
              <hr
                className=""
                style={{
                  width: "98%",
                  backgroundColor: "white",
                  opacity: 1,
                  color: "white",
                }}
              />
              {/* bootstrap code */}
              <div className="mx-4">
                <Row className="invoice-header declaration__border text-white">
                  <Col xs="12" className="text-center">
                    <span>CONSIGNMENT DETAILS</span>
                  </Col>
                  <Col xs="12" className="text-center">
                    <span>REASON FOR EXPORT</span>
                  </Col>
                </Row>

                <Row className="invoice-data declaration__border bg-white">
                  <Col xs="12" md="6" className="text-left">
                    <Row className="mt-2 px-2">
                      <Col lg="6" xs="7" align="start">
                        <span className="text-nowrap invoice_data_bold">Total Weight: </span>
                      </Col>
                      <Col lg="6" xs="5" align="end">
                        <span className="text-nowrap">
                          {props.second?.totalWeight} Kg
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2 mb-2">
                      <Col lg="6" xs="7" align="start">
                        <span className="invoice_data_bold text-nowrap">
                          ID / Exporters Code:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5" align="end">
                        <span className="text-nowrap">
                          {props.fifth?.idNumber}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2 mb-2">
                      <Col lg="6" xs="8" align="start">
                        <span className="text-nowrap invoice_data_bold">Volumetric Weight: </span>
                      </Col>
                      <Col lg="6" xs="4" align="end">
                        <span className="text-nowrap">
                          {props.second?.volumetricWeight} Kg
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2 mb-2 p-0">
                      <Col lg="6" xs="8" align="start">
                        <span className="invoice_data_bold text-nowrap">
                          Shipping Charge{" "}
                          {selectedCountry === "PT" ? "(EU)" : "(ZAR)"}:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="4" align="end">
                        <span className="text-nowrap">{props.third?.rate}</span>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" md="6" className="text-left vertical__line">
                    <Row className="mt-2" noGutters>
                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Personal Use</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export1"
                              name="export"
                              value="Personal Use"
                              checked={props.reason == "Personal Use"}
                              onChange={() => {
                                props.setReason("Personal Use");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Commercial</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export2"
                              name="export"
                              value="Commercial"
                              checked={props.reason == "Commercial"}
                              onChange={() => {
                                props.setReason("Commercial");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Repair/Return</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export3"
                              name="export"
                              value="Repair/Return"
                              checked={props.reason == "Repair/Return"}
                              onChange={() => {
                                props.setReason("Repair/Return");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Gift</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export4"
                              name="export"
                              value="Gift"
                              checked={props.reason == "Gift"}
                              onChange={() => {
                                props.setReason("Gift");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Samples</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export5"
                              name="export"
                              value="Samples"
                              checked={props.reason == "Samples"}
                              onChange={() => {
                                props.setReason("Samples");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="invoice-header declaration__border text-white">
                  <Col xs="12" className="text-center">
                    <span>SHIPMENT DETAILS</span>
                  </Col>
                </Row>

                <Row className="invoice-data declaration__border bg-white text-dark font-weight-bold pt-2">
                  <Col xs="12" sm="6" className="text-center">
                    <h6>SHIPPER</h6>
                  </Col>
                  <Col xs="12" sm="6" className="text-center">
                    <h6>CONSIGNEE</h6>
                  </Col>
                </Row>

                <Row className="declaration__border invoice-data bg-white">
                  <Col xs="12" md="6" className="text-left">
                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Name:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Surname:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingSurName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Contact Number:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingContact}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Email Address:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className=" d-block text-start">
                          {props.fifth?.sendingEmail}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Address 1:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className=" d-block text-start">
                          {props.fifth?.sendingAddress1}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Address 2:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="d-block text-start">
                          {props.fifth?.sendingComplex
                            ? props.fifth?.sendingComplex
                            : ""}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Suburb:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingSuburb}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Postal Code / Zip Code:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromCode}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          City:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromCity}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          State / Province:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromState &&
                            getCountryState(
                              props.first.fromCountry,
                              props.first.fromState
                            )}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Country:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromCountry}
                        </span>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" md="6" className="text-left vertical__line">
                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Name:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Surname:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingSurName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Contact Number:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingContact}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Email Address:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className=" d-block text-start">
                          {props.fifth?.ReceivingEmail}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Address 1:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="d-block text-start">
                          {props.fifth?.ReceivingAddress1}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Address 2:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="d-block text-start">
                          {props.fifth?.ReceivingComplex
                            ? props.fifth.ReceivingComplex
                            : ""}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Suburb:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingSuburb}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="8" align="end">
                        <span className="text-nowrap d-block text-end">
                          Postal Code / Zip Code:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="4">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toCode}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          City:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toCity}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          State / Province:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toState &&
                            getCountryState(
                              props.first.toCountry,
                              props.first.toState
                            )}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-end">
                          Country:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toCountry}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="mx-4">
                <Row className="invoice-header declaration__border text-white ">
                  <Col className="text-center">
                    <span>ITEM DETAILS</span>
                  </Col>
                </Row>
                <Row className="invoice-data declaration__border__all  bg-white">
                  <Col className="text-center">
                    <span>
                      Description & Value Of Contents / Goods to be shipped.
                    </span>
                  </Col>
                </Row>
                <Row className="Item_declaration">
                  <Table
                    className="invoice-booking-details-table text-center "
                    responsive
                  >
                    <thead align="center">
                      {/* <th className="invoice-table-border border-left">Item No</th> */}
                      <th
                        className="invoice-table-border text-center text-nowrap"
                        width="280"
                      >
                        Country of Manufacture
                      </th>
                      <th
                        className="invoice-table-border text-center"
                        width="130"
                      >
                        Qty / Units
                      </th>
                      <th className="invoice-table-border  " width="480px">
                        Item Description
                      </th>
                      <th
                        className="invoice-table-border text-center"
                        width="130"
                      >
                        Material Type
                      </th>
                      <th
                        className="invoice-table-border text-center"
                        width="130"
                      >
                        Unit Value{" "}
                      </th>
                      <th className="invoice-table-border " width="130">
                        Line Value
                      </th>
                      <th className="invoice-table-border "></th>
                    </thead>
                    <tbody>
                      {props.declarationItems.map((item, idx) => (
                        <tr key={idx} className="decl__row">
                          <td className="invoice-table-border-td border-left">
                            <ReactFlagsSelect
                              onSelect={(code) => {
                                let obj = countriesList.find(
                                  (count) => count.code == code
                                );
                                if (obj)
                                  props.declarationItemChange(
                                    idx,
                                    "country",
                                    obj.name
                                  );
                                else
                                  props.declarationItemChange(
                                    idx,
                                    "country",
                                    code
                                  );
                              }}
                              selected={getCountryCode(item.country)}
                              showSelectedLabel={true}
                              showSecondarySelectedLabel={true}
                              showOptionLabel={true}
                              showSecondaryOptionLabel={true}
                              value={getCountryCode(item.country)}
                              placeholder={"Select Country"}
                              className="item_decl__input__width_flag form-group form-control increase__height mb-0"
                              searchable={true}
                            />

                            {/* <Input
													type='text'
													name='country'
													className='country-td increase__height'
													value={item.country}
													onChange={e =>
														props.declarationItemChange(
															idx,
															'country',
															e.target.value
														)
													}
													required
												/> */}
                          </td>
                          <td
                            className="invoice-table-border-td text-center"
                            align="center"
                          >
                            <span>
                              {" "}
                              <Input
                                type="number"
                                min="1"
                                name="quantity"
                                required
                                value={item.quantity}
                                className="   increase__height"
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td">
                            <span className="d-flex">
                              {" "}
                              <Input
                                type="text"
                                name="description"
                                required
                                value={item.description}
                                className=" increase__height"
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td">
                            <span className="d-flex">
                              {" "}
                              <Input
                                type="text"
                                name="type"
                                className=" increase__height"
                                value={item.type}
                                required
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "type",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td">
                            <span className="d-flex">
                              {" "}
                              <Input
                                type="number"
                                min="1"
                                name="unitValue"
                                className=" increase__height"
                                value={item.unitValue}
                                step="0.01"
                                required
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "unitValue",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td align__middle text-center">
                            <p className="mb-0">
                              {selectedCountry === "PT" ? "€" : "R"}
                              {checkTotalValue(idx)}
                            </p>
                          </td>
                          <td className="invoice-table-border-td border-right">
                            <div className="increase__height">
                              <div>
                                <Button
                                  onClick={() =>
                                    props.handleAddClickDeclaration()
                                  }
                                  className="btn-plus  mt-md-1 border declaration-btn"
                                  size="sm"
                                >
                                  <i class="fas fa-plus"></i>
                                </Button>
                              </div>
                              <div>
                                <Button
                                  disabled={props.declarationItems.length == 1}
                                  onClick={() =>
                                    props.handleRemoveClickDeclaration(idx)
                                  }
                                  className="btn-minus  declaration-btn"
                                  size="sm"
                                >
                                  <i class="fas fa-minus"></i>
                                </Button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
              <Row className="justify-content-end m-2 mb-5">
                <Col lg="3" md="4" align="right" className="text-left mb-3">
                  <Card className="p-2 item-detail-weight">
                    <span>
                      Total Shipment Value:{" "}
                      {selectedCountry === "PT" ? "€" : "R"}
                      {totalShipment()}
                    </span>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  } else {
    return (
      <>
        <div className="mx-4">
          <ServicesHeader value="INTERNATIONAL COURIER" />
          <InternationalActiveTab
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
          />
          <hr className="service-header__hr-100" />
        </div>

        <ProcessList1 activeIdx={2} />
        <hr className="process_list_intl_hr" />

        <Container className="invoice-component item-declaration mt-3">
          <Row className="justify-content-center mb-3">
            <Col
              xs="12"
              sm="6"
              className="d-flex flex-column flex-sm-row justify-content-center align-items-center"
            >
              <FormGroup check className="mb-2 mb-sm-0">
                <Input
                  name="radio1"
                  type="radio"
                  defaultChecked={props.declarationData.type == "personal"}
                  onChange={(e) => {
                    props.declarationChange("personal", "");
                  }}
                />{" "}
                <Label check className="ml-2">
                  Non-commercial (personal)
                </Label>
              </FormGroup>
              <FormGroup check className="ml-sm-3">
                <Input
                  name="radio1"
                  type="radio"
                  defaultChecked={props.declarationData.type == "commercial"}
                  onChange={(e) => props.declarationChange("commercial", "")}
                />{" "}
                <Label check className="ml-2" >
                  Commercial invoice &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row
            className={`${
              props.declarationData.type == "commercial" ? "mb-5" : ""
            }`}
          >
            <Col className="text-left mx-2" md="6">
              <Label className="text-danger font-weight-bold imp_info">
                IMPORTANT INFORMATION*
              </Label>
              <ul className="text-white intl_declaration_ul">
                <li>
                  All international shipments requires an commercial invoice
                </li>
                <li>
                  Our guidelines will assist you in completing your commercial
                  invoice, correctly
                </li>
                <li>
                  View our guidelines for completing your invoice accurately{" "}
                  <Input
                    type="checkbox"
                    className="ml-2"
                    checked={viewGuidlines}
                    onChange={(e) => setViewGuidlines(!viewGuidlines)}
                  />
                </li>
                {viewGuidlines && (
                  // <Card className="px-3 py-1 quote__calendar inlt__decl__card mt-3">
                  //   <CardBody className="line__height">
                  //     <p className="text-secondary mb-0">
                  //       1. Each items’ description needs to be clearly described
                  //     </p>
                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-times text-danger "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">T-shirt</p>
                  //       </Col>
                  //     </Row>
                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-check text-primary "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">
                  //           100% cotton mens t-shirt{" "}
                  //         </p>
                  //       </Col>
                  //     </Row>
                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-times text-danger "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">Picture frames</p>
                  //       </Col>
                  //     </Row>
                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-check text-primary "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">
                  //           Wooden picture frames
                  //         </p>
                  //       </Col>
                  //     </Row>

                  //     <p className="text-secondary mb-0 mt-1">
                  //       2. Items must not be grouped together
                  //     </p>

                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-times text-danger "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">
                  //           Wooden picture frame and 100% cotton mens t-shirt
                  //         </p>
                  //       </Col>
                  //     </Row>
                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-check text-primary "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">
                  //           Wooden picture frame
                  //         </p>
                  //       </Col>
                  //     </Row>

                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-check text-primary "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">
                  //           100% cotton mens t-shirt
                  //         </p>
                  //       </Col>
                  //     </Row>
                  //     <p className="text-secondary mb-0 mt-1">
                  //       3. Use realistic values for each item
                  //     </p>
                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-times text-danger "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">
                  //           100% cotton mens t-shirt - value: R3
                  //         </p>
                  //       </Col>
                  //     </Row>
                  //     <Row className="mt-1">
                  //       <Col xs="2" className="d-flex justify-content-end">
                  //         <i
                  //           className="fa fa-check text-primary "
                  //           aria-hidden="true"
                  //         ></i>
                  //       </Col>
                  //       <Col xs="10" className="d-flex justify-content-start">
                  //         <p className="text-secondary mb-0">
                  //           100% cotton mens t-shirt - value: R60
                  //         </p>
                  //       </Col>
                  //     </Row>
                  //   </CardBody>
                  // </Card>
                  <Card className="px-3 py-1 quote__calendar inlt__decl__card mt-3">
  <CardBody className="guidelinestext">
    <p className="text-secondary mb-0">
      1. Each items’ description needs to be clearly described
    </p>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-times text-danger me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">T-shirt</p>
    </div>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-check text-primary me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">100% cotton mens t-shirt</p>
    </div>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-times text-danger me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">Picture frames</p>
    </div>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-check text-primary me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">Wooden picture frames</p>
    </div>

    <p className="text-secondary mb-0 mt-1">
      2. Items must not be grouped together
    </p>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-times text-danger me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">
        Wooden picture frame and 100% cotton mens t-shirt
      </p>
    </div>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-check text-primary me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">Wooden picture frame</p>
    </div>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-check text-primary me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">100% cotton mens t-shirt</p>
    </div>

    <p className="text-secondary mb-0 mt-1">
      3. Use realistic values for each item
    </p>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-times text-danger me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">
        100% cotton mens t-shirt - value: R3
      </p>
    </div>
    <div className="mt-1 d-flex align-items-center">
      <i className="fa fa-check text-primary me-2" aria-hidden="true"></i>
      <p className="text-secondary mb-0">
        100% cotton mens t-shirt - value: R60
      </p>
    </div>
  </CardBody>
</Card>

                )}
              </ul>
            </Col>
            <Col className=" declaration_border_left">
              {props.declarationData.type == "commercial" && (
                <>
                  <Row className="justify-content-center">
                    <Col
                      md="12"
                      className="d-flex text-center justify-content-center align-items-center  flex-column"
                    >
                      <FormGroup className="d-flex justify-content-start flex-column mt-4">
                        <p
                          className="text-white d-flex "
                          style={{
                            fontWeight: "500",
                            marginBottom: "8px",
                          }}
                        >
                          Upload your commercial invoice
                        </p>
                        <Input
                          type="file"
                          onChange={(e) =>
                            props.declarationChange("file", e.target.files[0])
                          }
                          style={{ width: "100%" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>

          {props.declarationData.type == "personal" && (
            <>
              <hr
                className=""
                style={{
                  width: "98%",
                  backgroundColor: "white",
                  opacity: 1,
                  color: "white",
                }}
              />
              <div className="mx-4">
                <Row className="invoice-header declaration__border text-white">
                  <Col xs="12" className="text-center">
                    <span>CONSIGNMENT DETAILS</span>
                  </Col>
                  <Col xs="12" className="text-center">
                    <span>REASON FOR EXPORT</span>
                  </Col>
                </Row>

                <Row className="invoice-data declaration__border bg-white">
                  <Col xs="12" md="6" className="text-left">
                    <Row className="mt-2 px-2">
                      <Col lg="6" xs="7" align="start">
                        <span className="text-nowrap invoice_data_bold">Total Weight: </span>
                      </Col>
                      <Col lg="6" xs="5" align="end">
                        <span className="text-nowrap">
                          {props.second?.totalWeight} Kg
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2 mb-2">
                      <Col lg="6" xs="7" align="start">
                        <span className="invoice_data_bold text-nowrap">
                          ID / Exporters Code:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5" align="end">
                        <span className="text-nowrap">
                          {props.fifth?.idNumber}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2 mb-2">
                      <Col lg="6" xs="8" align="start">
                        <span className="text-nowrap invoice_data_bold">Volumetric Weight: </span>
                      </Col>
                      <Col lg="6" xs="4" align="end">
                        <span className="text-nowrap">
                          {props.second?.volumetricWeight} Kg
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2 mb-2 p-0">
                      <Col lg="6" xs="8" align="start">
                        <span className="invoice_data_bold text-nowrap">
                          Shipping Charge{" "}
                          {selectedCountry === "PT" ? "(EU)" : "(ZAR)"}:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="4" align="end">
                        <span className="text-nowrap">{props.third?.rate}</span>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="12" md="6" className="text-left vertical__line">
                    <Row className="mt-2" noGutters>
                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Personal Use</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export1"
                              name="export"
                              value="Personal Use"
                              checked={props.reason == "Personal Use"}
                              onChange={() => {
                                props.setReason("Personal Use");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Commercial</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export2"
                              name="export"
                              value="Commercial"
                              checked={props.reason == "Commercial"}
                              onChange={() => {
                                props.setReason("Commercial");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Repair/Return</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export3"
                              name="export"
                              value="Repair/Return"
                              checked={props.reason == "Repair/Return"}
                              onChange={() => {
                                props.setReason("Repair/Return");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Gift</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export4"
                              name="export"
                              value="Gift"
                              checked={props.reason == "Gift"}
                              onChange={() => {
                                props.setReason("Gift");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>

                      <Col xs="12" sm="6" md="4">
                        <Row>
                          <Col xs="7" align="start">
                            <span>Samples</span>
                          </Col>
                          <Col>
                            <Input
                              className="ml-2 mt-2"
                              type="checkbox"
                              id="export5"
                              name="export"
                              value="Samples"
                              checked={props.reason == "Samples"}
                              onChange={() => {
                                props.setReason("Samples");
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="invoice-header declaration__border text-white">
                  <Col xs="12"  className="text-center">
                    <span>SHIPMENT DETAILS</span>
                  </Col>
                </Row>

                <Row className="invoice-data declaration__border bg-white text-dark font-weight-bold pt-2">
                  <Col xs="12" className="text-center">
                    <h6>SHIPPER</h6>
                  </Col>
                </Row>

                <Row className="declaration__border invoice-data bg-white">
                  <Col xs="12" className="text-left">
                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Name:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Surname:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingSurName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Contact Number:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingContact}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6"  xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Email Address:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="d-block text-start">
                          {props.fifth?.sendingEmail}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Address 1:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className=" d-block text-start">
                          {props.fifth?.sendingAddress1}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Address 2:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className=" d-block text-start">
                          {props.fifth?.sendingComplex
                            ? props.fifth?.sendingComplex
                            : ""}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Suburb:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.sendingSuburb}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Postal Code / Zip Code:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromCode}
                        </span>
                      </Col>
                    </Row>
                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          City:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromCity}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          State / Province:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromState &&
                            getCountryState(
                              props.first.fromCountry,
                              props.first.fromState
                            )}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Country:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.fromCountry}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="invoice-data declaration__border bg-white text-dark font-weight-bold pt-2">
                  <Col xs="12" className="text-center">
                    <h6>CONSIGNEE</h6>
                  </Col>
                </Row>
                <Row className="declaration__border invoice-data bg-white">
                  <Col xs="12"  className="text-left vertical__line">
                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Name:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="mt-1 px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Surname:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingSurName}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Contact Number:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingContact}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6"  xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Email Address:{" "}
                        </span>
                      </Col>
                      <Col lg="6"  xs="5">
                        <span className="d-block text-start">
                          {props.fifth?.ReceivingEmail}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Address 1:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className=" d-block text-start">
                          {props.fifth?.ReceivingAddress1}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Address 2:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="d-block text-start">
                          {props.fifth?.ReceivingComplex
                            ? props.fifth.ReceivingComplex
                            : ""}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Suburb:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.fifth?.ReceivingSuburb}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Postal Code / Zip Code:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toCode}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          City:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toCity}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          State / Province:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toState &&
                            getCountryState(
                              props.first.toCountry,
                              props.first.toState
                            )}
                        </span>
                      </Col>
                    </Row>

                    <Row className="px-2">
                      <Col lg="6" xs="7" align="end">
                        <span className="text-nowrap d-block text-start">
                          Country:{" "}
                        </span>
                      </Col>
                      <Col lg="6" xs="5">
                        <span className="text-nowrap d-block text-start">
                          {props.first?.toCountry}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>

              {/* <div className="m-2">
          <Row>
            <Col lg="6" align="left" className="font-weight-bold">
              <span>
                <u>Important Information</u>
              </span>
              <ul>
                <li>
                  Please decalre each item separately and accurately as possible
                </li>
                <li>Do not group items, see example {`>>`}</li>
                <li>Refrain from declaring a item as a {"gift"}</li>
                <li>Use realistic values when declaring each item</li>
              </ul>
            </Col>
            <Col lg="6">
              <img
                src={TabImg}
                alt="tabImg"
                className="w-100 mt-3"
                height="80px"
              />
            </Col>
          </Row>
        </div> */}
              <div className="mx-4">
                <Row className="invoice-header declaration__border text-white ">
                  <Col className="text-center">
                    <span>ITEM DETAILS</span>
                  </Col>
                </Row>
                <Row className="invoice-data declaration__border__all  bg-white">
                  <Col className="text-center">
                    <span>
                      Description & Value Of Contents / Goods to be shipped.
                    </span>
                  </Col>
                </Row>
{windowsize>662?
                <Row className="Item_declaration">
                  <Table
                    className="invoice-booking-details-table text-center "
                    responsive
                  >
                    <thead align="center">
                      <th
                        className="invoice-table-border text-center text-nowrap"
                        width="280"
                      >
                        Country of Manufacture
                      </th>
                      <th
                        className="invoice-table-border text-center"
                        width="130"
                      >
                        Qty / Units
                      </th>
                      <th className="invoice-table-border  " width="480px">
                        Item Description
                      </th>
                      <th
                        className="invoice-table-border text-center"
                        width="130"
                      >
                        Material Type
                      </th>
                      <th
                        className="invoice-table-border text-center"
                        width="130"
                      >
                        Unit Value{" "}
                      </th>
                      <th className="invoice-table-border " width="130">
                        Line Value
                      </th>
                      <th className="invoice-table-border "></th>
                    </thead>
                    <tbody>
                      {props.declarationItems.map((item, idx) => (
                        <tr key={idx} className="decl__row">
                          <td className="invoice-table-border-td border-left">
                            <ReactFlagsSelect
                              onSelect={(code) => {
                                let obj = countriesList.find(
                                  (count) => count.code == code
                                );
                                if (obj)
                                  props.declarationItemChange(
                                    idx,
                                    "country",
                                    obj.name
                                  );
                                else
                                  props.declarationItemChange(
                                    idx,
                                    "country",
                                    code
                                  );
                              }}
                              selected={getCountryCode(item.country)}
                              showSelectedLabel={true}
                              showSecondarySelectedLabel={true}
                              showOptionLabel={true}
                              showSecondaryOptionLabel={true}
                              value={getCountryCode(item.country)}
                              placeholder={"Select Country"}
                              className="item_decl__input__width_flag form-group form-control increase__height mb-0"
                              searchable={true}
                            />
                          </td>
                          <td
                            className="invoice-table-border-td text-center"
                            align="center"
                          >
                            <span>
                              {" "}
                              <Input
                                type="number"
                                min="1"
                                name="quantity"
                                required
                                value={item.quantity}
                                className="   increase__height"
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td">
                            <span className="d-flex">
                              {" "}
                              <Input
                                type="text"
                                name="description"
                                required
                                value={item.description}
                                className=" increase__height"
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "description",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td">
                            <span className="d-flex">
                              {" "}
                              <Input
                                type="text"
                                name="type"
                                className=" increase__height"
                                value={item.type}
                                required
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "type",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td">
                            <span className="d-flex">
                              {" "}
                              <Input
                                type="number"
                                min="1"
                                name="unitValue"
                                className=" increase__height"
                                value={item.unitValue}
                                step="0.01"
                                required
                                onChange={(e) =>
                                  props.declarationItemChange(
                                    idx,
                                    "unitValue",
                                    e.target.value
                                  )
                                }
                              />
                            </span>
                          </td>
                          <td className="invoice-table-border-td align__middle text-center">
                            <p className="mb-0">
                              {selectedCountry === "PT" ? "€" : "R"}
                              {checkTotalValue(idx)}
                            </p>
                          </td>
                          <td className="invoice-table-border-td border-right">
                            <div className="increase__height">
                              <div>
                                <Button
                                  onClick={() =>
                                    props.handleAddClickDeclaration()
                                  }
                                  className="btn-plus  mt-md-1 border declaration-btn"
                                  size="sm"
                                >
                                  <i class="fas fa-plus"></i>
                                </Button>
                              </div>
                              <div>
                                <Button
                                  disabled={props.declarationItems.length == 1}
                                  onClick={() =>
                                    props.handleRemoveClickDeclaration(idx)
                                  }
                                  className="btn-minus  declaration-btn"
                                  size="sm"
                                >
                                  <i class="fas fa-minus"></i>
                                </Button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>:
                <Row className="Item_declaration">

  {props.declarationItems.map((item, idx) => (
    <React.Fragment key={idx}>
       <Col xs={12} className="text-white item-declaration-detail-text py-2">
    Country of Manufacture
  </Col>
      <Col xs={12} >
        <ReactFlagsSelect
          onSelect={(code) => {
            let obj = countriesList.find((count) => count.code == code);
            if (obj)
              props.declarationItemChange(idx, "country", obj.name);
            else
              props.declarationItemChange(idx, "country", code);
          }}
          selected={getCountryCode(item.country)}
          showSelectedLabel={true}
          showSecondarySelectedLabel={true}
          showOptionLabel={true}
          showSecondaryOptionLabel={true}
          value={getCountryCode(item.country)}
          placeholder={"Select Country"}
          className="item_decl__input__width_flag form-group form-control increase__height mb-0"
          searchable={true}
        />
      </Col>
      <Col xs={12} className="text-white item-declaration-detail-text py-2">
    Qty / Units
  </Col>
      <Col xs={12}>
        <Input
          type="number"
          min="1"
          name="quantity"
          required
          value={item.quantity}
          className="increase__height"
          onChange={(e) =>
            props.declarationItemChange(idx, "quantity", e.target.value)
          }
        />
      </Col>
      <Col xs={12} className="text-white item-declaration-detail-text py-2">
    Item Description
  </Col>
      <Col xs={12} >
        <Input
          type="text"
          name="description"
          required
          value={item.description}
          className="increase__height"
          onChange={(e) =>
            props.declarationItemChange(idx, "description", e.target.value)
          }
        />
      </Col>
      <Col xs={12} className="text-white item-declaration-detail-text py-2">
    Material Type
  </Col>
      <Col xs={12} >
        <Input
          type="text"
          name="type"
          className="increase__height"
          value={item.type}
          required
          onChange={(e) =>
            props.declarationItemChange(idx, "type", e.target.value)
          }
        />
      </Col>
      <Col xs={12} className="text-white item-declaration-detail-text py-2" >
    Unit Value
  </Col>
      <Col xs={12} >
        <Input
          type="number"
          min="1"
          name="unitValue"
          className="increase__height"
          value={item.unitValue}
          step="0.01"
          required
          onChange={(e) =>
            props.declarationItemChange(idx, "unitValue", e.target.value)
          }
        />
      </Col>
      <Col xs={12} className="text-white item-declaration-detail-text py-2">
    Line Value
  </Col>
      <Col xs={12} >
        <p className="mb-0 line-value">
          {selectedCountry === "PT" ? "€" : "R"}
          {checkTotalValue(idx)}
        </p>
      </Col>
      <Col xs={12}  className="py-2">
        <div className="increase__height d-flex justify-content-center align-items-center">
          <Button
            onClick={() => props.handleAddClickDeclaration()}
            className="btn-plus mt-md-1 border declaration-btn"
            size="sm"
          >
            <i className="fas fa-plus"></i>
          </Button>
          <Button
            disabled={props.declarationItems.length == 1}
            onClick={() => props.handleRemoveClickDeclaration(idx)}
            className="btn-minus declaration-btn"
            size="sm"
          >
            <i className="fas fa-minus"></i>
          </Button>
        </div>
      </Col>
    </React.Fragment>
  ))}
</Row>}

              </div>
              <Row className="justify-content-end m-2 mb-5">
                <Col lg="3" md="4" align={windowsize>425?"right":"center"} className="text-left mb-3 shipmentcard">
                  <Card className="p-2 item-detail-weight">
                    <span>
                      Total Shipment Value:{" "}
                      {selectedCountry === "PT" ? "€" : "R"}
                      {totalShipment()}
                    </span>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </>
    );
  }
}

export default ItemDeclaration;
