import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Import CSS here
import './assets/scss/index.scss';
import { Provider } from 'react-redux';
import store from './store/store';

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</Provider>,
	document.getElementById('root')
);
