import firebase from 'firebase';

const firebaseConfig = {
	apiKey: 'AIzaSyD5m0fcZfDrsDxrvaIDfYlNeMZy2N0J7kw',
	authDomain: 'ship-my-stuff.firebaseapp.com',
	projectId: 'ship-my-stuff',
	storageBucket: 'ship-my-stuff.appspot.com',
	messagingSenderId: '204811156986',
	appId: '1:204811156986:web:372cef8042afba7f35f843',
	measurementId: 'G-GR8EJ1M38H',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
