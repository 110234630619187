import React from "react";
import qs from "query-string";

import { useState } from "react";
import { Container, Spinner } from "reactstrap";
import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import InternationalPayment from "../components/International/InternationalPayement";
import { useDispatch, useSelector } from "react-redux";
import { getCounters } from "../store/actions/localActions";

import firebase from "../config/firebase";
import PortugalPayment from "../components/Portugal/PortugalPayments";
const ThankYouPortugal = () => {
  const [reference, setReference] = useState("");
  const [loader, setLoader] = useState(true);
  let { counters } = useSelector((state) => state.local);

  const dispatch = useDispatch();

  const [intlCommercialPage, setintlCommercialPage] = useState({ url: "" });

  const history = useHistory();
  // International Data

  const [intlFirstPage, setintlFirstPage] = useState({
    fromCountry: "",
    toCountry: "",
    import: "false",
  });

  const [intlSecondPage, setintlSecondPage] = useState({
    totalWeight: "",
    volumetricWeight: "",
    itemDetails: [{ weight: "0", height: "0", length: "0", width: "0" }],
  });
  const [intlThirdPage, setintlThirdPage] = useState({ rate: 0 });
  const [intlFourthPage, setintlFourthPage] = useState({
    date: "",
    text: "",
  });
  const [intlFifthPage, setintlFifthPage] = useState({
    sendingName: "",
    idNubmer: "",
    sendingSurName: "",
    sendingContact: "",
    sendingComplex: "",
    sendingCity: "",
    sendingAddress2: "",
    sendingAddress1: "",
    sendingSuburb: "",
    sendingPostal: "",
    ReceivingSurName: "",
    ReceivingName: "",
    ReceivingContact: "",
    ReceivingAddress1: "",
    ReceivingAddress2: "",
    ReceivingComplex: "",
    ReceivingCity: "",
    ReceivingSuburb: "",
    ReceivingPostal: "",
  });
  const [intlSixthPage, setintlSixthPage] = useState({
    totalItems: [
      {
        country: "",
        quantity: 0,
        description: "",
        type: "",
        unitValue: 0,
        totalValue: 0,
      },
    ],
  });
  const [intlSeventhPage, setintlSeventhPage] = useState({
    billingEmail: "",
    billingContactNo: "",
    billingName: "",
    billingAddress1: "",
    billingAddress2: "",
    billingComplexNo: "",
    billingSuburb: "",
    billingCountry: "",
    billingPostalCode: "",
    billingVAT: "",
  });

  const [paymentPageIn, setPaymentPageIn] = useState({
    paymentRef: { REFERENCE: "" },
    redirectParams: { CHECKSUM: "", PAY_REQUEST_ID: "" },
    redirectUri: "",
  });
  const [intlEighthPage, setintlEighthPage] = useState({ url: "" });

  let firstIntlPage = JSON.parse(
    localStorage.getItem("intl_country_selection")
  );
  const [sixthPage, setSixthPage] = useState([]);
  const insuranceIntlData = JSON.parse(
    localStorage.getItem("intl_insurance_data")
  );

  let secondIntlPage = JSON.parse(localStorage.getItem("intl_second_page"));
  let thirdIntlPage = JSON.parse(localStorage.getItem("intl_third_page"));
  let fourthIntlPage = JSON.parse(localStorage.getItem("intl_fourth_page"));
  let fifthIntlPage = JSON.parse(localStorage.getItem("intl_fifth_page"));
  let sixthIntlPage = JSON.parse(localStorage.getItem("intl_sixth_page"));

  let seventhIntlPage = JSON.parse(localStorage.getItem("intl_seventh_page"));

  let eighthIntlPage = JSON.parse(localStorage.getItem("intl_eighth_page"));
  let payment = JSON.parse(localStorage.getItem("intl_payment"));
  let commercialPage = JSON.parse(localStorage.getItem("intl_commercial_page"));

  //Usage example:
  let intlFile = localStorage.getItem("intl_declaration");

  const dataURLtoFile = (dataurl, filename) => {
    if (dataurl != "" && dataurl != null) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return "";
    }
  };

  const getDeclarationFile = () => {
    if (intlFile) {
      return {
        uploadInvoice: true,
        file: dataURLtoFile(intlFile, "commercial_invoice"),
      };
    } else {
      return { uploadInvoice: false };
    }
  };

  const handleSixthPage = (obj) => {
    setSixthPage((prevState) => [...prevState, obj]);
  };

  const clearStorage = () => {
    localStorage.setItem("intl_first_page", JSON.stringify(intlFirstPage));

    localStorage.setItem("intl_second_page", JSON.stringify(intlSecondPage));

    localStorage.setItem("intl_third_page", JSON.stringify(intlThirdPage));

    localStorage.setItem("intl_fourth_page", JSON.stringify(intlFourthPage));

    localStorage.setItem("intl_fifth_page", JSON.stringify(intlFifthPage));

    localStorage.setItem("intl_sixth_page", JSON.stringify(intlSixthPage));

    localStorage.setItem("intl_seventh_page", JSON.stringify(intlSeventhPage));

    localStorage.setItem("intl_eighth_page", JSON.stringify(intlEighthPage));

    localStorage.setItem("intl_payment", JSON.stringify(paymentPageIn));

    localStorage.setItem(
      "intl_commercial_page",
      JSON.stringify(intlCommercialPage)
    );
  };

  const storeInternationalLogs = (from, response) => {
    let url = window.location.href;
    let data = "";
    if (response) {
      data = response;
    }
    firebase
      .firestore()
      .collection("internationalOrderLogs")
      .add({
        url,
        from,
        data,
      })
      .then((res) => {})
      .catch((err) => {
        storeInternationalLogs("In store international logs", err?.message);
      });
  };

  useEffect(async () => {
    const ref = qs.parse(window.location.search);

    if (Object.keys(ref).length) {
      console.log(
        "Search params found and length is...",
        Object.keys(ref).length,
        ref
      );

      setLoader(true);
      setReference(ref.REFERENCE);
      await axios
        .post(`${process.env.REACT_APP_DEVELOPMENT}/payment/status`, {
          paymentRef: payment.paymentRef,
        })
        .then(async (res) => {
          console.log("Response of payment ", res);
          if (res.data.success) {
            if (res.data.data.TRANSACTION_STATUS == "1") {
              //   setBool(true);
              setLoader(false);
              if (window.gtag) {
                window.gtag('event', 'purchase');
              }
              //   setActiveTab("9");
              //   history.push("/");
              console.log("Every thing ok");
              storeInternationalLogs("Payment successful", res?.data);
            } else {
              console.log("Status not equal to 1");
              console.log("Payment unsuccessful");
              storeInternationalLogs(
                "Transaction status is not equal to 1",
                res?.data
              );

              await clearStorage();
              //   setActiveTab("1");
              history.push("/");
              // setTimeout(() => {
              //   setLoader(false);
              // }, 2000);
              console.log("Going to redirect trans else");
            }
          } else {
            await clearStorage();
            // setLoader(false);
            history.push("/");
            console.log("Payment unsuccessful");
            storeInternationalLogs("Success false", res?.data);

            // setActiveTab("1");
          }
        })
        .catch((err) => {
          clearStorage();

          storeInternationalLogs("Catch", err?.response?.data);

          history.push("/");
          // setTimeout(() => {
          //   setLoader(false);
          // }, 2000);
          console.log("Going to redirect of catch");
        });
    } else {
      console.log("No search params found...");
      clearStorage();
      storeInternationalLogs("No search params", "");

      history.push("/");

      // setTimeout(() => {
      //   setLoader(false);
      // }, 2000);
      console.log("Going to redirect of else");
    }
  }, [window.location.search]);

  // useEffect(() => {
  //   if (counters && counters.length == 0) {
  //     dispatch(getCounters(process.env.REACT_APP_COUNTERS));
  //   }
  // }, []);

  console.log("PRops", window.location.search);
  return (
    <Container>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center py-5">
          <Spinner size="lg" />
        </div>
      ) : (
        <PortugalPayment
          first={firstIntlPage}
          second={secondIntlPage}
          third={thirdIntlPage}
          fourth={fourthIntlPage}
          fifth={fifthIntlPage}
          sixth={sixthIntlPage}
          seventh={seventhIntlPage}
          eighth={eighthIntlPage}
          commercial={commercialPage}
          payment={payment}
          reference={reference}
          insuranceData={insuranceIntlData}
          onChange={handleSixthPage}
          delcarationInvoice={getDeclarationFile()}
        />
      )}
    </Container>
  );
};

export default ThankYouPortugal;
