import React from "react";
import { Container, Spinner } from "reactstrap";
import QuotePaymentUI from "../components/QuickQoute/QuotePaymentUI";
import { useState } from "react";
import { useEffect } from "react";
import { getCounters } from "../store/actions/localActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import qs from "query-string";

const LocalThankyou = () => {
  const [reference, setReference] = useState("");
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  let { counters } = useSelector((state) => state.local);

  const history = useHistory();

  const localfirstPage = JSON.parse(
    localStorage.getItem("local_country_selection")
  );

  const localsecondPage = JSON.parse(localStorage.getItem("local_second_page"));
  const localthirdPage = JSON.parse(localStorage.getItem("local_third_page"));

  const localfourthPage = JSON.parse(localStorage.getItem("local_fourth_page"));

  const localInsuranceData = JSON.parse(
    localStorage.getItem("local_insurance_data")
  );

  const localfifthPage = JSON.parse(localStorage.getItem("local_fifth_page"));
  const localsixthPage = JSON.parse(localStorage.getItem("local_sixth_page"));
  const localSeventhPage = JSON.parse(
    localStorage.getItem("local_seventh_page")
  );

  const paymentPage = JSON.parse(localStorage.getItem("payment"));
  // Local Initial Data

  const [localFirstPage, setLocalFirstPage] = useState({
    fromRegion: "",
    toRegion: "",
    fromSuburbPost: "",
    toSuburbPost: "",
  });

  const [localinsuranceData, setLocalinsuranceData] = useState({
    insurance: false,
    insuranceDesc: "",
    insuranceValue: "",
  });

  const [localSecondPage, setLocalSecondPage] = useState({
    description: "",
    totalWeight: "",
    volumetricWeight: "",
    itemDetails: [{ weight: "", height: "", length: "", width: "" }],
  });
  const [localThirdPage, setLocalThirdPage] = useState({
    type: "economy",
    selectedCourier: {
      BaseRate: 0,
      SurchargeRate: 0,
      TotalRate: 0,
      Name: "",
    },
  });

  const [localFourthPage, setLocalFourthPage] = useState({ startDate: "" });
  const [localFifthPage, setLocalFifthPage] = useState({
    sendingName: "",
    sendingSurName: "",
    sendingContact: "",
    sendingComplexNo: "",
    sendingCity: "",
    sendingAddress2: "",
    sendingAddress1: "",
    RecievingSurName: "",
    RecievingName: "",
    RecievingContact: "",
    RecievingAddress1: "",
    RecievingAddress2: "",
    RecievingComplexNo: "",
    RecievingCity: "",
  });
  const [localSixthPage, setLocalSixthPage] = useState({ email: "" });
  const [localSventhPage, setLocalSeventhPage] = useState({ url: "" });
  const [localPayment, setLocalPayment] = useState({
    paymentRef: { REFERENCE: "" },
    redirectParams: { CHECKSUM: "", PAY_REQUEST_ID: "" },
    redirectUri: "",
  });

  const clearStorage = () => {
    localStorage.setItem("local_first_page", JSON.stringify(localFirstPage));

    localStorage.setItem("local_second_page", JSON.stringify(localSecondPage));

    localStorage.setItem("local_third_page", JSON.stringify(localThirdPage));

    localStorage.setItem("local_fourth_page", JSON.stringify(localFourthPage));

    localStorage.setItem("local_fifth_page", JSON.stringify(localFifthPage));

    localStorage.setItem("local_sixth_page", JSON.stringify(localSixthPage));

    localStorage.setItem("payment", JSON.stringify(localPayment));

    localStorage.setItem("local_seventh_page", JSON.stringify(localSventhPage));

    localStorage.setItem(
      "local_insurance_data",
      JSON.stringify(localinsuranceData)
    );
  };

  // useEffect(() => {
  //   if (counters && counters.length == 0) {
  //     dispatch(getCounters(process.env.REACT_APP_COUNTERS));
  //   }
  // }, []);

  useEffect(() => {
    const ref = qs.parse(window.location.search);

    if (Object.keys(ref).length) {
      setReference(ref.REFERENCE);

      console.log("Search params found", ref?.REFERENCE);

      axios
        .post(`${process.env.REACT_APP_DEVELOPMENT}/payment/status`, {
          paymentRef: paymentPage?.paymentRef,
        })
        .then(async (res) => {
          console.log("Response of payment ", res);

          if (res.data.success) {
            if (res.data.data.TRANSACTION_STATUS == "1") {
              setLoader(false);
              //   setActiveTab("8");
              console.log("Every thing ok", res);
            } else {
              await clearStorage();
              console.log("Status not equal to 1");

              //   setActiveTab("1");
              history.push("/");
              // setTimeout(() => {
              //   setLoader(false);
              // }, 2000);

              console.log("response check", res);
            }
          } else {
            console.log("Payment unsuccessful");
            await clearStorage();
            history.push("/");

            // setTimeout(() => {
            //   setLoader(false);
            // }, 2000);

            // setActiveTab("1");
          }
        })
        .catch((err) => {
          clearStorage();
          history.push("/");
          console.log("in catch..");
          // setTimeout(() => {
          //   setLoader(false);
          // }, 2000);
        });
    } else {
      history.push("/");
      // setTimeout(() => {
      //   setLoader(false);
      // }, 2000);

      console.log("No search params found...");
    }
  }, [window.location.search]);

  return (
    <Container>
      {loader ? (
        <div className="d-flex justify-content-center align-items-center py-5">
          <Spinner size="lg" />
        </div>
      ) : (
        <QuotePaymentUI
          first={localfirstPage}
          second={localsecondPage}
          third={localthirdPage}
          fourth={localfourthPage}
          fifth={localfifthPage}
          sixth={localsixthPage}
          seventh={localSeventhPage}
          insuranceDetails={localInsuranceData}
          payment={paymentPage}
          reference={reference}
          //   bool={bool}
          //   setBool={setBool}
        />
      )}
    </Container>
  );
};

export default LocalThankyou;
