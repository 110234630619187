import moment from 'moment';
import React, { useEffect, useState } from 'react';
import firebase from '../../config/firebase';
import { Container, Spinner, Row, Col, Table, Button } from 'reactstrap';
import Logo from '../../assets/img/logo.png';
import Euro from '../../assets/img/EuroCurrency.png';

function Invoice_pt(props) {
	const [invoiceDetails, setInvoiceDetails] = useState();
	const handlePrint = divPrint => {
		var printContents = document.getElementById(divPrint).innerHTML;
		var originalContents = document.body.innerHTML;

		document.body.innerHTML = printContents;

		window.print();

		document.body.innerHTML = originalContents;
		window.location.reload();
	};
	useEffect(() => {
		firebase
			.firestore()
			.collection(`${process.env.REACT_APP_INTERNATIONAL_ORDER_PT}`)
			.doc(props.match.params.id)
			.onSnapshot(query => {
				setInvoiceDetails(query.data());
			});
	}, []);

	const getFormattedInvoiceNumber = str => {
		return str.replace(/\s+/g, '');
	};
	const formatNumber = num => {
		return num.toLocaleString('en', {
			useGrouping: false,
			minimumFractionDigits: 2,
		});
	};

	console.log('Invoice Details', invoiceDetails);
	return (
		<>
			{invoiceDetails ? (
				<>
					<Row className='justify-content-center m-2'>
						<Col md='3' xs='6'>
							<Button
								onClick={() => handlePrint('printMe')}
								block
								color='primary'
							>
								PRINT / DOWNLOAD
							</Button>
						</Col>
					</Row>
					<div className='content-scroll'>
					<Container
						id='printMe'
						fluid
						className='invoice-component-copy intl_invoice'
					>
						<hr />
						<Row className='justify-content-center  mx-2'>
							<Col md='6' className='mt-3 mb-3 invoice_img_col'>
								<img className='' src={Logo} />
							</Col>
							<Col
								md='4'
								className='mt-3 mb-3 ml-3 text-right intl_invoice_details'
							> 

								<span>Ship My Stuff</span>
								<br />
								<span>179857614</span>
								{/* <br /> <span>Roodepoort</span> */}
								<br />
								<span>Arcozela</span> <br />
								<span>Portugal</span> <br />
								<span>4110-362</span> <br />
								{/* <span>1724</span> <br /> */}
								{/* <span>067 888 7138</span> */}
								<span>accounts@shipmystuff.pt</span>
							</Col>
						</Row>
						<div className='mx-4 mt-2 '>
						
							<Row className='py-2 invoice-header border-0 text-white tr__print'>
								<Col className='text-center'>
									<span className='intl_invoice_header text-white'>
										BILLING DETAILS
									</span>
								</Col>
								<Col className='text-center'>
									<span className='intl_invoice_header  text-white'>
										INVOICE DETAILS
									</span>
								</Col>
							</Row>
							{/* <div className='content-scroll'> */}
							<Row className='invoice-data border-0 bg-white intl_invoice_body'>
								<Col className='text-left '>
									<Row className='px-2 mt-2'>
										<Col md='6' xs='8' align='end'>
											<span>Name:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingFirstName
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Surname:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingSurname
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Contact Number:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingContactNumber
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Email Address:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingEmailAddress
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Comapnay Name:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingCompanyName
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Vat Number:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingVatNumber
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Address 1:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingAddress1
												}
											</span>
										</Col>
									</Row>
									{/* <Row>
                                <Col md="5" xs="8" align="end">
                                    <span className="text-nowrap">
                                        Complex / Business Park / Estate:
                                    </span>
                                </Col>
                                <Col md="5" xs="4">
                                    <span>

                                    </span>
                                </Col>
                            </Row> */}
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Suburb:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingSuburb
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2 '>
										<Col md='6' xs='8' align='end'>
											<span>Postal Code / Zip Code:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingPostalCode
												}
											</span>
										</Col>
									</Row>
									{/* <Row className=" px-2 ">
                                            <Col md="5" xs="8" align="end">
                                                <span>City:</span>
                                            </Col>
                                            <Col md="5" xs="4">
                                                <span>

                                                </span>
                                            </Col>
                                        </Row> */}
									<Row className=' px-2 mb-2'>
										<Col md='6' xs='8' align='end'>
											<span>Country:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingCountry
												}
											</span>
										</Col>
									</Row>
								</Col>
								<Col className='text-left '>
									<Row className='px-2 mt-2'>
										<Col md='6' xs='8' align='end'>
											<span>Invoice Number:</span>
										</Col>
										<Col md='6' xs='4'>
											<span className='text-nowrap'>
												{
													invoiceDetails?.Invoice
														?.InvoiceInformation
														?.InvoiceNumber
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2 '>
										<Col md='6' xs='8' align='end'>
											<span>Invoice Date:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.InvoiceInformation
														?.InvoiceDate
												}
											</span>
										</Col>
									</Row>

									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Total Weight:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails
														?.ShipmentManifest
														?.CargoInformation
														?.TotalPhysicalWeight
												}
												Kg
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Chargeable Weight:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{Number(
													invoiceDetails
														?.ShipmentManifest
														?.CargoInformation
														?.TotalPhysicalWeight
												) >
												Number(
													invoiceDetails
														?.ShipmentManifest
														?.CargoInformation
														?.TotalVolumetricWeight
												)
													? invoiceDetails
															?.ShipmentManifest
															?.CargoInformation
															?.TotalPhysicalWeight
													: invoiceDetails
															?.ShipmentManifest
															?.CargoInformation
															?.TotalVolumetricWeightt}
												Kg
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Insurance Requested:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{invoiceDetails
													?.ShipmentManifest
													?.ShipmentHeader
													?.InsuranceRequested
													? 'Yes'
													: 'No'}
											</span>
										</Col>
									</Row>
								</Col>
							</Row>
							{/* </div> */}
						</div>

						<Row className='mx-2 mt-2 invoice-details'>
							<Col>
								<Row className='py-2 invoice-header border-0 tr__print'>
									<Col className='text-center text-white '>
										<span className='intl_invoice_header text-white'>
											{' '}
											SHIPMENT DETAILS
										</span>
									</Col>
								</Row>
								<Row className='py-2 mt-2 intl_invoice_sub_header border-0 text-secondary td__print'>
									<Col className='d-flex justify-content-center align-items-center'>
										<span className='intl_invoice_header'>
											SHIPPER
										</span>
									</Col>
									<Col className='justify-content-center border-0 d-flex align-items-center'>
										<span className='intl_invoice_header'>
											CONSIGNEE
										</span>
									</Col>
								</Row>
								{/* <div className='content-scroll'> */}
								<Row className=' invoice-data border-0 bg-white intl_invoice_body'>
									<Col className='text-left'>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Name:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperName
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Contact Number:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperContactNumber
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Email Address:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperEmailAddress
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Address 1:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperAddress1
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span className='text-nowrap'>
													Address 2:
												</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperAddress2
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Suburb:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperSuburb
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Postal / Zip Code:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperPostalCodeZipCode
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>City:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperCity
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2 mb-2'>
											<Col md='6' xs='8' align='end'>
												<span>Country:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperCountry
													}
												</span>
											</Col>
										</Row>
									</Col>
									<Col className='text-left '>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Name:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeName
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Contact Number:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeContactNumber
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Email Address:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeEmailAddress
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Address 1:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															.ConsigneeAddress1
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span className='text-nowrap'>
													Address 2:
												</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															.ConsigneeAddress2
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Suburb:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															.ConsigneeSuburb
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Postal / Zip Code:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															.ConsigneePostalCodeZipCode
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>City:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															.ConsigneeCity
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2 mb-2'>
											<Col md='6' xs='8' align='end'>
												<span>Country:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															.ConsigneeCountry
													}
												</span>
											</Col>
										</Row>
									</Col>
								</Row>
								{/* </div> */}
							</Col>
						</Row>
						<Row className='mx-2 py-2 invoice-header border-0 tr__print'>
							<Col className='text-white text-center'>
								<span className='intl_invoice_header text-white'>
									BILLING ITEMS
								</span>
							</Col>
						</Row>
						<div className='mx-2 mt-2'>
							<Table
								responsive
								className='intl_invoice_table border-0 mb-0'
							>
								<thead className='td__print'>
									<th className='py-2 td__print invoice-table-border text-center'>
										{' '}
										ITEM DESCRIPTION
									</th>
									<th className='py-2 invoice-table-border text-center'>
										{' '}
										WEIGHT{' '}
									</th>
									<th className='py-2 invoice-table-border text-center'>
										{' '}
										VAT CODE{' '}
									</th>
									<th className='py-2 invoice-table-border text-center'>
										{' '}
										VAT AMOUNT
									</th>
									<th className='py-2 invoice-table-border text-center'>
										{' '}
										AMOUNT
									</th>
								</thead>

								<tbody>
									<tr>
										<td className='text-center '>
											Economy Service
										</td>
										<td className='text-center'>
											{
												invoiceDetails?.ShipmentManifest
													?.CargoInformation
													.TotalPhysicalWeight
											}
											Kg
										</td>
										<td className='text-center '>Z</td>
										<td className='text-center '>
										<img className="mb-1 mr-1" src={Euro} height={12} width={12}/>
											{invoiceDetails?.ShipmentManifest
												?.CargoInformation
												.InsuranceRequested
												? formatNumber(300)
												: 0}
										</td>
										<td className='text-center'>
										<img className="mb-1 mr-1" src={Euro} height={12} width={12}/>
											{invoiceDetails?.ShipmentManifest
												?.CargoInformation
												.InsuranceRequested
												? formatNumber(
														Number(
															invoiceDetails
																?.Invoice
																?.InvoiceInformation
																.TotalInvoiceAmount
														) - 300
												  )
												: formatNumber(
														Number(
															invoiceDetails
																?.Invoice
																?.InvoiceInformation
																.TotalInvoiceAmount
														)
												  )}
										</td>
									</tr>

									<tr>
										<td className='text-center '>
											Documentation Fee
										</td>
										<td className='text-center'></td>
										<td className='text-center '></td>
										<td className='text-center '></td>
										<td className='text-center'>
										<img className="mb-1 mr-1" src={Euro} height={12} width={12}/>
											{invoiceDetails?.ShipmentManifest
												?.CargoInformation
												.InsuranceRequested
												? formatNumber(300)
												: 0}
										</td>
									</tr>
								</tbody>
							</Table>
						</div>
						<div className='mx-2 mt-2'></div>

						<Row className='mx-2 pt-4 pb-3'>
							<Col md='5' className='text-left'>
								{/* <span className='text-nowrap local_bottom_text'>
									Tracking Number: (FEDEX API Tracking Number)
								</span>
								<br /> */}
								<span className='text-nowrap local_bottom_text'>
									Any account queries mail us at{' '}
									<a href='mailto: accounts@shipmystuff.pt'>
                                    accounts@shipmystuff.pt
									</a>
								</span>
							</Col>
							<Col md='7'>
								<Row className='justify-content-end '>
									<Col
										md='3'
										className='bg-white invoice-total local_bottom_text'
									>
										VAT:
									</Col>
									<Col
										md='3'
										className='text-center td__print invoice-total-extra  invoice-total local_bottom_text'
									>
										{invoiceDetails?.ShipmentManifest
											?.CargoInformation
											.InsuranceRequested
											? formatNumber(300)
											: formatNumber(0)}
									</Col>
								</Row>
								<Row className='justify-content-end '>
									<Col
										md='3'
										className='bg-white invoice-total local_bottom_text'
									>
										SUBTOTAL (EU):{' '}
									</Col>
									<Col
										md='3'
										className='text-center td__print invoice-total-extra  invoice-total local_bottom_text'
									>
										{invoiceDetails?.ShipmentManifest
											?.CargoInformation
											.InsuranceRequested
											? formatNumber(
													Number(
														invoiceDetails?.Invoice
															?.InvoiceInformation
															.TotalInvoiceAmount
													) - 300
											  )
											: formatNumber(
													Number(
														invoiceDetails?.Invoice
															?.InvoiceInformation
															.TotalInvoiceAmount
													)
											  )}
									</Col>
								</Row>
								<Row className='justify-content-end  '>
									<Col
										md='3'
										className='bg-white invoice-total local_bottom_text'
									>
										TOTAL (EU):{' '}
									</Col>
									<Col
										md='3'
										className='text-center td__print invoice-total-extra  invoice-total local_bottom_text'
									>
										{formatNumber(
											Number(
												invoiceDetails?.Invoice
													?.InvoiceInformation
													.TotalInvoiceAmount
											)
										)}
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
					</div>
				</>
			) : (
				<div className='loader-div'>
					<Spinner size='lg' />
				</div>
			)}
		</>
	);
}

export default Invoice_pt;
