import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from "reactstrap";
import StopWatch from "../assets/img/StopWatch.png";
import ScrollAnimation from "react-animate-on-scroll";
import Find from "../assets/img/Find.png";
import Compare from "../assets/img/Compare.png";
import Book from "../assets/img/Book.png";
import Fade from "react-reveal/Fade";
function BookingPlatform({ textAlign }) {
  return (
    <Row className="row--15 ">
      <Col lg="4" className="mb-2 mb-lg-0">
        <Fade bottom>
          <div className={`online_platform  ${textAlign}`}>
            {/* <div className='icon'>1</div> */}
            <div className="online_img mx-auto">
              <img src={Find} alt="" className="img-fluid" />
            </div>
            <div className="content mt-2">
              <h4 className=" w-600">FIND</h4>

              <p className=" mb-0">Address Information</p>
              <p className="sub_text">{"POINT A << >> POINT B"}</p>
            </div>
          </div>
        </Fade>
      </Col>
      <Col lg="4" className="mb-2 mb-lg-0">
        <Fade bottom>
          <div className={`online_platform  ${textAlign}`}>
            {/* <div className='icon'>2</div> */}
            <div className="online_img mx-auto">
              <img src={Compare} alt="" className="img-fluid" />
            </div>

            <div className="content mt-2">
              <h4 className=" w-600">COMPARE</h4>
            </div>
            <p className="mb-0">Multiple courier quotes</p>
            <p className="sub_text">INSTANTLY!</p>
          </div>
        </Fade>
      </Col>
      <Col lg="4" className=" mb-lg-0">
        <Fade bottom>
          <div className={`online_platform  ${textAlign}`}>
            {/* <div className='icon'>3</div> */}
            <div className="online_img mx-auto">
              <img src={Book} alt="" className="img-fluid" />
            </div>

            <div className="content mt-2">
              <h4 className=" w-600">BOOK</h4>
              <p className="mb-0">Book your shipment</p>
              <p className="sub_text">ONLINE</p>
            </div>
          </div>
        </Fade>
      </Col>
      {/* <Col lg='3' className='mb-2'>
				<ScrollAnimation
					animateIn='fadeInUp'
					animateOut='fadeInOut'
					animateOnce={true}
				>
					<div className={`online_platform  ${textAlign}`}>
						<div className='icon'>4</div>
						<div className='content'>
							<h4 className='title w-600'>BOOK</h4>

							<p className='  mb--0'>
								here are many variations variations have.
							</p>
						</div>
					</div>
				</ScrollAnimation>
			</Col> */}
      {/* <Col xs='6' className={` ${textAlign}`}>
				<Link to='/quickquote'>
					<Button className='btn-default btn-large'>
						DOMESTIC QUOTE
					</Button>
				</Link>
			</Col>
			<Col xs='6' className={`${textAlign}`}>
				<Link to='/international'>
					<Button className='btn-default btn-large'>
						SKY CARGO QUOTE
					</Button>
				</Link>
			</Col> */}
    </Row>
  );
}

export default BookingPlatform;
