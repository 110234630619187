import React from "react";
import { useEffect} from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import ServicesHeader from "../ServicesHeader";
import { LocalActiveTab } from "../QuickQoute/LocalActiveTab";
import { useState } from "react";
import CustomToggler from "./CustomToggler";
import PORTA from "../../assets/img/seafreight/Port-A.png";
import PORTB from "../../assets/img/seafreight/Port-B.png";
import CustomSelector from "./CustomSelector";
import Location from "../../assets/img/seafreight/Location.png";
import TotalShipment from "./TotalShipment";
import ContainerFcl from "../../assets/img/seafreight/Container.png";
import MovingCube from "../../assets/img/seafreight/Asset 4.png";
import Pallet from "../../assets/img/seafreight/Pallet2.png";
import FclContainer from "./FclContainer";
import MovingBox from "./MovingBox";
import PalletLcl from "./PalletLcl";
import TotalCbm from "./TotalCbm";
import axios from "axios";
import { useHistory } from "react-router-dom";

const SeaFreight = () => {
  const [internationalRelocation, setInternationalRelocation] = useState(true);

  const history = useHistory();

  const [internationalRelocationDetails, setInternationalRelocationDetails] =
    useState({
      collectionAddress: "",
      destinationAddress: "",
      fromStreet: "",
      fromCountry: "",
      fromPostCode: "",
      toStreet: "",
      toCountry: "",
      toPostCode: "",

      fromFreeStandingOrComplex: "Free Standing House",
      toFreeStandingOrComplex: "Free Standing House",
      fromOptions: [],
      toOptions: [],
    });

  const onInternationalRelocationChange = (name, val) => {
    setInternationalRelocationDetails((prev) => ({ ...prev, [name]: val }));
  };

  const onInternationalRelocationCheckBoxChange = (name, val) => {
    let temp = [...internationalRelocationDetails[name]];

    if (temp.includes(val)) {
      let filterItems = temp.filter((item) => item != val);

      setInternationalRelocationDetails((prev) => ({
        ...prev,
        [name]: filterItems,
      }));
    } else {
      setInternationalRelocationDetails((prev) => ({
        ...prev,
        [name]: [...temp, val],
      }));
    }
  };

  const [commercial, setCommercial] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [customClearance, setCustomClearance] = useState(false);

  const [otherDetails, setOtherDetails] = useState({
    contactName: "",
    contactNumber: "",
    email: "",
    collectDate: "",
    insuranceValue: "",
  });

  const [details, setDetails] = useState({
    fromPort: { icon: PORTA, label: "PORT (FOB)" },
    toPort: { icon: PORTB, label: "PORT" },
    packageType: { icon: ContainerFcl, label: "CONTAINERS (FCL)" },
    collectionAddress: "",
    destinationAddress: "",
    originPort: "",
    destinationPort: "",
    street: "",
    country: "",
    postCode: "",
  });
  const handleDetailsChange = (option, name) => {
    setDetails((prev) => ({ ...prev, [name]: option }));
    console.log("ON Port change", name);
    if (name == "fromPort") {
      setDetails((prev) => ({
        ...prev,
        toPort: { icon: PORTB, label: "PORT" },
      }));
    }
  };

  // Fcl Container

  const [containerFclDetails, setContainerFclDetails] = useState([
    {
      type: "20 FT STANDARD",
      units: 1,
    },
  ]);

  const handleContainerChange = (name, val, idx) => {
    let temp = [...containerFclDetails];

    temp[idx][name] = val;

    setContainerFclDetails(temp);
  };

  const handleRemoveClick = (index) => {
    const list = [...containerFclDetails];
    list.splice(index, 1);
    setContainerFclDetails(list);
  };
  // handle click event of the Add button

  const handleAddClick = () => {
    setContainerFclDetails([
      ...containerFclDetails,
      {
        type: "20 FT STANDARD",
        units: 1,
      },
    ]);
  };

  // Moving Box

  const [movingBoxes, setMovingBoxes] = useState([
    {
      type: "SMALL",
      cbm: 2,
      // weight: "",
      length: 104,
      width: 92,
      height: 179,
      units: 1,
    },
  ]);

  const handleBoxChange = (name, val, idx) => {
    let temp = [...movingBoxes];

    temp[idx][name] = val;

    if (name == "type") {
      switch (val) {
        case "MEDIUM":
          temp[idx] = {
            ...temp[idx],
            cbm: 3.7,

            length: 218,
            width: 114,
            height: 129,
          };
          break;
        case "LARGE":
          temp[idx] = {
            ...temp[idx],
            cbm: 5.4,
            length: 218,
            width: 114,
            height: 190,
          };
          break;
        default:
          temp[idx] = {
            ...temp[idx],
            cbm: 2,

            length: 104,
            width: 92,
            height: 179,
          };
          break;
      }
    }

    setMovingBoxes(temp);
  };

  const handleRemoveBoxClick = (index) => {
    const list = [...movingBoxes];
    list.splice(index, 1);
    setMovingBoxes(list);
  };
  // handle click event of the Add button

  const handleAddBoxClick = () => {
    setMovingBoxes([
      ...movingBoxes,
      {
        type: "SMALL",
        cbm: 2,
        // weight: "",
        length: 104,
        width: 92,
        height: 179,

        units: 1,
      },
    ]);
  };

  // Pallet LCL

  const [palletBoxes, setPalletBoxes] = useState([
    {
      cbm: "",
      // weight: "",
      length: "",
      width: "",
      height: "",
      units: 1,
    },
  ]);

  const handlePalletChange = (name, val, idx) => {
    let temp = [...palletBoxes];

    temp[idx][name] = val;

    setPalletBoxes(temp);
  };

  const handleRemovePalletClick = (index) => {
    const list = [...palletBoxes];
    list.splice(index, 1);
    setPalletBoxes(list);
  };
  // handle click event of the Add button

  const handleAddPalletClick = () => {
    setPalletBoxes([
      ...palletBoxes,

      {
        cbm: "",
        // weight: "",
        length: "",
        width: "",
        height: "",
        units: 1,
      },
    ]);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearStates = () => {
    setInsurance(false);
    setCustomClearance(false);
    setOtherDetails({
      contactName: "",
      contactNumber: "",
      email: "",
      collectDate: "",
      insuranceValue: "",
    });
    setDetails({
      fromPort: { icon: PORTA, label: "PORT (FOB)" },
      toPort: { icon: PORTB, label: "PORT" },
      packageType: { icon: ContainerFcl, label: "CONTAINERS (FCL)" },
      collectionAddress: "",
      destinationAddress: "",
      originPort: "",
      destinationPort: "",
      street: "",
      country: "",
      postCode: "",
    });
    setContainerFclDetails([
      {
        type: "20 FT STANDARD",
        units: 1,
      },
    ]);
    setMovingBoxes([
      {
        type: "SMALL",
        cbm: 2,
        // weight: "",
        length: 104,
        width: 92,
        height: 179,
        units: 1,
      },

      setInternationalRelocationDetails({
        collectionAddress: "",
        destinationAddress: "",
        fromStreet: "",
        fromCountry: "",
        fromPostCode: "",
        toStreet: "",
        toCountry: "",
        toPostCode: "",

        fromFreeStandingOrComplex: "Free Standing House",
        toFreeStandingOrComplex: "Free Standing House",
        fromOptions: [],
        toOptions: [],
      }),
    ]);

    setPalletBoxes([
      {
        cbm: "",
        // weight: "",
        length: "",
        width: "",
        height: "",
        units: 1,
      },
    ]);
  };

  const getComponentByPackageType = () => {
    let packageType = details?.packageType?.label;

    if (packageType == "MOVING CUBES (LCL)") {
      return (
        <>
          {movingBoxes?.map((box, idx) => {
            return (
              <MovingBox
                details={box}
                handleBoxChange={handleBoxChange}
                index={idx}
                handleAddClick={handleAddBoxClick}
                handleRemoveClick={handleRemoveBoxClick}
                items={movingBoxes}
              />
            );
          })}
        </>
      );
    } else if (packageType == "BOX / PALLETE (LCL)") {
      return (
        <>
          {palletBoxes?.map((box, idx) => {
            return (
              <PalletLcl
                details={box}
                handleBoxChange={handlePalletChange}
                index={idx}
                handleAddClick={handleAddPalletClick}
                handleRemoveClick={handleRemovePalletClick}
                items={palletBoxes}
              />
            );
          })}
        </>
      );
    } else if (packageType == "TOTAL CBM (LCL)") {
      return (
        <>
          <TotalCbm />
        </>
      );
    } else {
      return (
        <>
          {containerFclDetails?.map((details, idx) => {
            return (
              <FclContainer
                details={details}
                handleContainerChange={handleContainerChange}
                index={idx}
                handleAddClick={handleAddClick}
                handleRemoveClick={handleRemoveClick}
                items={containerFclDetails}
              />
            );
          })}
        </>
      );
    }
  };

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let selectedPackage = details?.packageType?.label;
    let payload = {
      ...otherDetails,
      commodityType: internationalRelocation
        ? "International Relocation"
        : "Commercial",
      packageType: details?.packageType,
      insurance: insurance ? "Yes" : "No",
      customClearance: customClearance ? "Yes" : "No",
    };

    if (internationalRelocation) {
      payload = { ...payload, internationalRelocationDetails };
    } else {
      let { packageType, ...commercialDetails } = details;
      payload = {
        ...payload,
        commercialDetails,
      };
    }

    switch (selectedPackage) {
      case "MOVING CUBES (LCL)":
        payload = { ...payload, items: movingBoxes };
        break;
      case "BOX / PALLETE (LCL)":
        payload = { ...payload, items: palletBoxes };
        break;
      default:
        payload = { ...payload, items: containerFclDetails };
        break;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_DEVELOPMENT}/seafreight-contact`,
        payload
      );
      // const { data } = await axios.post(
      //   `https://ship-mystuff-staging-backend-7d8c96594e84.herokuapp.com/seafreight-contact`,
      //   payload
      // );
      // const { data } = await axios.post(
      //   `http://localhost:1234/seafreight-contact`,
      //   payload
      // );
      console.log("Data of query", data);
      // alert("Data submitted successfully!");
      clearStates();
      setIsSubmitting(false);
      history.push("/seafreight/confirmation");
      // setShowSuccessMessage(true);
      // setTimeout(() => {
      //   setShowSuccessMessage(false);
      // }, 7000);
    } catch (error) {
      console.log("Error", error);
      alert("Something went wrong");
      setIsSubmitting(false);
    }
  };

  return (
    <Container fluid="lg" className="d-flex justify-content-center">
      <Form onSubmit={handleSubmit}>
        <div className="sea-freight">
          <Row className="justify-content-center px-20">
            <Col xs="11">
              <div>
                <ServicesHeader value="Sea Freight" />

                <hr className="service-header__hr-100" />
              </div>
              <p className="text-center sea-freight__label">COMMODITY TYPE</p>
              <Row className="justify-content-center align-items-center">
                <Col
                  xs={12}
                  sm="auto"
                  className="text-lg-center text-left mb-2 mb-sm-0"
                >
                  <p className="mb-0 mr-sm-3 sea-freight__label">
                    INTERNATIONAL RELOCATION
                  </p>
                </Col>
                <Col
                  xs={12}
                  sm="auto"
                  className="text-lg-center text-left mb-2 mb-sm-0"
                >
                  <CustomToggler
                    isToggled={internationalRelocation}
                    onToggle={() => {
                      if (internationalRelocation === false) {
                        setCommercial(false);
                        setInternationalRelocation(true);
                      } else {
                        setCommercial(true);
                        setInternationalRelocation(false);
                      }
                      setDetails((prev) => ({
                        ...prev,
                        packageType: {
                          icon: ContainerFcl,
                          label: "CONTAINERS (FCL)",
                        },
                      }));
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  sm="auto"
                  className="text-lg-center text-left mb-2 mb-sm-0"
                >
                  <p className="mb-0 mx-sm-3 sea-freight__label">COMMERCIAL</p>
                </Col>
                <Col xs={12} sm="auto" className="text-lg-center text-left">
                  <CustomToggler
                    isToggled={commercial}
                    onToggle={() => {
                      if (commercial === false) {
                        setCommercial(true);
                        setInternationalRelocation(false);
                      } else {
                        setCommercial(false);
                        setInternationalRelocation(true);
                      }
                      setDetails((prev) => ({
                        ...prev,
                        packageType: {
                          icon: ContainerFcl,
                          label: "CONTAINERS (FCL)",
                        },
                      }));
                    }}
                  />
                </Col>
              </Row>

              {internationalRelocation && (
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <p className="sea-freight__label">FROM</p>
                    <Row noGutters>
                      <Col
                        xs={12}
                        md={6}
                        className="pr-md-3 sea-freight__international-relocation"
                      >
                        <Input
                          type="text"
                          value={internationalRelocationDetails?.fromStreet}
                          required
                          name="fromStreet"
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Street Address"
                        />
                        <Input
                          type="text"
                          value={internationalRelocationDetails?.fromCountry}
                          required
                          name="fromCountry"
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Country"
                          className="mt-2"
                        />
                        <Input
                          type="text"
                          value={internationalRelocationDetails?.fromPostCode}
                          required
                          name="fromPostCode"
                          className="w-lg-75 mt-2 mt-sm-1"
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Zip / Postal Code"
                        />
                      </Col>
                      <Col xs={12} md={6} className="pl-md-3 mt-sm-1">
                        <Input
                          className="freestandingstyle"
                          type="select"
                          name="fromFreeStandingOrComplex"
                          value={
                            internationalRelocationDetails?.fromFreeStandingOrComplex
                          }
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                        >
                          <option value="Free Standing House">
                            Free Standing House
                          </option>
                          <option value="Complex">Complex</option>
                          <option value="Apartment">Apartment</option>
                        </Input>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            name="fromOptions"
                            checked={internationalRelocationDetails?.fromOptions?.includes(
                              "1st Floor Collection"
                            )}
                            onChange={(e) =>
                              onInternationalRelocationCheckBoxChange(
                                "fromOptions",
                                "1st Floor Collection"
                              )
                            }
                          />{" "}
                          <Label
                            check
                            className="sea-freight__label text-transform-none"
                          >
                            1st Floor Collection
                          </Label>
                        </FormGroup>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            name="fromOptions"
                            checked={internationalRelocationDetails?.fromOptions?.includes(
                              "Narrow Driveway"
                            )}
                            onChange={(e) =>
                              onInternationalRelocationCheckBoxChange(
                                "fromOptions",
                                "Narrow Driveway"
                              )
                            }
                          />{" "}
                          <Label
                            check
                            className="sea-freight__label text-transform-none"
                          >
                            Narrow Driveway
                          </Label>
                        </FormGroup>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            checked={internationalRelocationDetails?.fromOptions?.includes(
                              "Weight Restrictions"
                            )}
                            onChange={(e) =>
                              onInternationalRelocationCheckBoxChange(
                                "fromOptions",
                                "Weight Restrictions"
                              )
                            }
                          />{" "}
                          <Label
                            check
                            className="sea-freight__label text-transform-none"
                          >
                            Weight Restrictions
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6} className="pl-sm-3">
                    <p className="sea-freight__label seafrightlabel ">TO</p>
                    <Row noGutters>
                      <Col
                        xs={12}
                        md={6}
                        className="pr-md-3 sea-freight__international-relocation"
                      >
                        <Input
                          type="text"
                          value={internationalRelocationDetails?.toStreet}
                          required
                          name="toStreet"
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Street Address"
                        />
                        <Input
                          type="text"
                          value={internationalRelocationDetails?.toCountry}
                          required
                          name="toCountry"
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Country"
                          className="mt-2"
                        />
                        <Input
                          type="text"
                          value={internationalRelocationDetails?.toPostCode}
                          required
                          name="toPostCode"
                          className="w-lg-75 mt-2 "
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                          placeholder="Zip / Postal Code"
                        />
                      </Col>
                      <Col xs={12} md={6} className="pl-md-3 ">
                        <Input
                          className="freestandingstyle"
                          type="select"
                          required
                          name="toFreeStandingOrComplex"
                          value={
                            internationalRelocationDetails?.toFreeStandingOrComplex
                          }
                          onChange={(e) =>
                            onInternationalRelocationChange(
                              e.target.name,
                              e.target.value
                            )
                          }
                        >
                          <option value="Free Standing House">
                            Free Standing House
                          </option>
                          <option value="Complex">Complex</option>
                          <option value="Apartment">Apartment</option>
                        </Input>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            checked={internationalRelocationDetails?.toOptions?.includes(
                              "1st Floor Delivery"
                            )}
                            onChange={(e) =>
                              onInternationalRelocationCheckBoxChange(
                                "toOptions",
                                "1st Floor Delivery"
                              )
                            }
                          />{" "}
                          <Label
                            check
                            className="sea-freight__label text-transform-none"
                          >
                            1st Floor Delivery
                          </Label>
                        </FormGroup>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            checked={internationalRelocationDetails?.toOptions?.includes(
                              "Narrow Driveway"
                            )}
                            onChange={(e) =>
                              onInternationalRelocationCheckBoxChange(
                                "toOptions",
                                "Narrow Driveway"
                              )
                            }
                          />{" "}
                          <Label
                            check
                            className="sea-freight__label text-transform-none"
                          >
                            Narrow Driveway
                          </Label>
                        </FormGroup>
                        <FormGroup check className="mt-2">
                          <Input
                            type="checkbox"
                            checked={internationalRelocationDetails?.toOptions?.includes(
                              "Weight Restrictions"
                            )}
                            onChange={(e) =>
                              onInternationalRelocationCheckBoxChange(
                                "toOptions",
                                "Weight Restrictions"
                              )
                            }
                          />{" "}
                          <Label
                            check
                            className="sea-freight__label text-transform-none"
                          >
                            Weight Restrictions
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {commercial && (
                <Row className="mt-3">
                  <Col xs={12} md={6}>
                    <p className="sea-freight__label">FROM</p>
                    <Row noGutters>
                      <Col xs={12} md={6} className="pr-md-2">
                        <CustomSelector
                          options={[
                            { icon: PORTA, label: "PORT (FOB)" },
                            {
                              icon: Location,
                              label: "FACTORY / PREMISE (EX-WORKS)",
                            },
                          ]}
                          value={details?.fromPort}
                          onSelect={handleDetailsChange}
                          name="fromPort"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        {details?.fromPort?.label === "PORT (FOB)" ? (
                          <Input
                           className="freestandingstyle"
                            type="text"
                            name="originPort"
                            onChange={(e) =>
                              handleDetailsChange(e.target.value, e.target.name)
                            }
                            required
                            value={details?.originPort}
                            placeholder="Origin Port"
                          />
                        ) : details?.fromPort?.label ? (
                          <>
                            <Input
                            className="freestandingstyle"
                              type="text"
                              name="street"
                              onChange={(e) =>
                                handleDetailsChange(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                              required
                              value={details?.street}
                              placeholder="Street Address"
                            />
                            <Input
                              type="text"
                              name="country"
                              onChange={(e) =>
                                handleDetailsChange(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                              className="mt-2"
                              required
                              value={details?.country}
                              placeholder="Country"
                            />
                            <Input
                              type="text"
                              name="postCode"
                              onChange={(e) =>
                                handleDetailsChange(
                                  e.target.value,
                                  e.target.name
                                )
                              }
                              required
                              className="mt-2"
                              value={details?.postCode}
                              placeholder="Zip / Postal Code"
                            />
                          </>
                        ) : (
                          <Input
                            type="text"
                            placeholder="Collection Address"
                            name="collectionAddress"
                            onChange={(e) =>
                              handleDetailsChange(e.target.value, e.target.name)
                            }
                            required
                            value={details?.collectionAddress}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <p className="sea-freight__label seafrightlabel">TO</p>
                    <Row noGutters>
                      <Col xs={12} md={6} className="pr-md-2">
                        <CustomSelector
                          options={[{ icon: PORTA, label: "PORT" }]}
                          value={details?.toPort}
                          onSelect={handleDetailsChange}
                          name="toPort"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        {details?.toPort?.label === "PORT" ? (
                          <Input
                           className="freestandingstyle"
                            type="text"
                            placeholder="Destination Port"
                            name="destinationPort"
                            onChange={(e) =>
                              handleDetailsChange(e.target.value, e.target.name)
                            }
                            required
                            value={details?.destinationPort}
                          />
                        ) : (
                          <Input
                            type="text"
                            placeholder="Delivery Address"
                            name="destinationAddress"
                            onChange={(e) =>
                              handleDetailsChange(e.target.value, e.target.name)
                            }
                            required
                            value={details?.destinationAddress}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <hr className="service-header__hr-100" />

              {/* <Row>
                <Col xs="3">
                  <p className="sea-freight__label text-primary">
                    PACKAGE TYPE
                  </p>
                  {internationalRelocation && (
                    <CustomSelector
                      options={[
                        { icon: ContainerFcl, label: "CONTAINERS (FCL)" },
                        { icon: MovingCube, label: "MOVING CUBES (LCL)" },
                        { icon: Pallet, label: "TOTAL CBM (LCL)" },
                        // { icon: Pallet, label: "BOX / PALLETE (LCL)" },
                      ]}
                      value={details?.packageType}
                      onSelect={handleDetailsChange}
                      name="packageType"
                    />
                  )}
                  {commercial && (
                    <CustomSelector
                      options={[
                        { icon: ContainerFcl, label: "CONTAINERS (FCL)" },
                        { icon: Pallet, label: "BOX / PALLETE (LCL)" },
                      ]}
                      value={details?.packageType}
                      onSelect={handleDetailsChange}
                      name="packageType"
                    />
                  )}
                </Col>
                <Col xs="9" className="pl-0">
                  {getComponentByPackageType()}
                </Col>
              </Row> */}
              <Row>
                <Col  className="mb-3 mb-md-0">
                  <p className="sea-freight__label text-primary">
                    PACKAGE TYPE
                  </p>
                  {internationalRelocation && (
                    <CustomSelector
                      options={[
                        { icon: ContainerFcl, label: "CONTAINERS (FCL)" },
                        { icon: MovingCube, label: "MOVING CUBES (LCL)" },
                        { icon: Pallet, label: "TOTAL CBM (LCL)" },
                        // { icon: Pallet, label: "BOX / PALLETE (LCL)" },
                      ]}
                      value={details?.packageType}
                      onSelect={handleDetailsChange}
                      name="packageType"
                    />
                  )}
                  {commercial && (
                    <CustomSelector
                      options={[
                        { icon: ContainerFcl, label: "CONTAINERS (FCL)" },
                        { icon: Pallet, label: "BOX / PALLETE (LCL)" },
                      ]}
                      value={details?.packageType}
                      onSelect={handleDetailsChange}
                      name="packageType"
                    />
                  )}
                </Col>
                <Col xs='12' className="pl-md-3 mt-md-3">
                  {getComponentByPackageType()}
                </Col>
              </Row>
              {details?.packageType?.label !== "TOTAL CBM (LCL)" ? (
                <>
                  <hr className="service-header__hr-100 mt-5 mb-2" />

                  <Row noGutters>
                    <Col xs={12}>
                      <p className="fw-600 sea-freight__label text-primary">
                        OTHER DETAILS
                      </p>
                    </Col>
                    <Col xs={12}>
                      <div className="d-flex flex-md-row flex-column justify-content-center justify-content-md-start  align-items-start align-items-md-center">
                        <div className="seacollectdate d-flex  flex-md-row flex-column align-items-md-center align-items-start  ">
                          <p className="mb-0 mr-md-3 sea-freight__label ">
                            Collect Date
                          </p>
                          <Input
                            type="date"
                            value={otherDetails?.collectDate}
                            name="collectDate"
                            required
                            className="mb-3 mb-md-0 mr-md-3 sea-freight__input seacollectdate"
                            onChange={(e) =>
                              setOtherDetails((prev) => ({
                                ...prev,
                                [e.target.name]: e.target.value,
                              }))
                            }
                          />
                        </div>

                        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start ml-md-2 ml-sm-0 ml-lg-0 ml-md-0">
                          <p className="mb-0 mr-md-3 sea-freight__label">
                            Insurance
                          </p>
                          <CustomToggler
                            isToggled={insurance}
                            onToggle={() => setInsurance(!insurance)}
                            className="mb-3 mb-md-0"
                          />
                        </div>

                        <div className="d-flex flex-column flex-md-row align-items-md-center align-items-start">
                          <p className="mb-0 py-2 ml-md-2 mr-md-3 sea-freight__label">
                            Require Custom Clearance
                          </p>
                          <CustomToggler
                            isToggled={customClearance}
                            onToggle={() =>
                              setCustomClearance(!customClearance)
                            }
                            className="mb-3 mb-md-0"
                          />
                        </div>
                      </div>

                      {insurance && (
                        <>
                          <FormGroup className="mt-3 mb-1">
                            <InputGroup>
                              <Input
                                placeholder="Insurance value R*"
                                className="route-item-insurance sea-freight__input"
                                name="insuranceValue"
                                value={otherDetails?.insuranceValue}
                                type="number"
                                onChange={async (e) => {
                                  setOtherDetails((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                  }));
                                }}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                          <span className="text-sm text-white font-weight-bold">
                            Indicate your shipment value for insurance purposes.
                          </span>
                        </>
                      )}
                    </Col>

                    <Col xs={12}>
                      <hr className="service-header__hr-100" />
                    </Col>
                  </Row>
                  <Row noGutters className="contact_details">
                    <Col xs={12}>
                      <p className="fw-600 sea-freight__label text-primary">
                        CONTACT DETAILS
                      </p>
                    </Col>
                    <Col xs={12} md={4}>
                      <Input
                        type="text"
                        placeholder="Contact Name"
                        name="contactName"
                        value={otherDetails?.contactName}
                        required
                        onChange={(e) =>
                          setOtherDetails((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={4}
                      className="mt-3 ml-lg-5 ml-md-5 mt-md-0"
                    >
                      <Input
                        type="text"
                        placeholder="Contact Number (Optional)"
                        name="contactNumber"
                        value={otherDetails?.contactNumber}
                        onChange={(e) =>
                          setOtherDetails((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }))
                        }
                      />
                    </Col>
                  </Row>
                  <Row noGutters className="mt-3 contact_details">
                    <Col xs={12} md={4}>
                      <Input
                        type="email"
                        placeholder="Email Address (Quoting purpose)"
                        required
                        name="email"
                        value={otherDetails?.email}
                        onChange={(e) =>
                          setOtherDetails((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value,
                          }))
                        }
                      />
                    </Col>
                    <Col
                      xs={12}
                      md={8}
                      className="mt-3 mt-md-0 d-flex align-items-start"
                    >
                      {showSuccessMessage && (
                        <div className="total-cbm__card p-3 mr-1">
                          <p className="mb-0 text-center fs-14">
                            “Thank you, one of our consultants will be in touch
                            soon”
                            <br />
                            “In the meantime check out
                            <a
                              className="text-primary mx-1"
                              href="/packing-guidelines"
                              target="_blank"
                            >
                              packaging guide
                            </a>
                            for international shipments”
                          </p>
                        </div>
                      )}
                      <Button
                        className="bg-primary text-white fw-600 px-4 ml-auto"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <Spinner size="sm" /> : "SUBMIT"}
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="mb-5"></div>
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Container>
  );
};

export default SeaFreight;
