import React from 'react';

import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  Table,
} from 'reactstrap';

import Logo from "../../assets/img/logo.png";

function Notes() {
  return (
    <>
      <Container fluid className="invoice-component">
        <Row className="justify-content-center">
          <Col md="6" className="mt-3">
            <img className="img-fluid invoice-img" src={Logo} />
          </Col>
          <Col md="6" className="mt-3">
            <Card className="invoice-card text-justify px-2">
              <span className="py-3">
                Ship My Stuff (Pty) Ltd
                <br /> Roodepoort, Johannesburg
                <br /> 1724 <br />
                Tel: +27 67 888 7138 <br /> Email: <br />{' '}
                bookings@shipmystuff.co.za
              </span>
            </Card>
          </Col>
        </Row>

        <Row className="m-2 invoice-details">
          <Col>
            <Row className="invoice-header">
              <Col className="text-left">
                <span>QUOTE DETAILS</span>
              </Col>
            </Row>
            <Row className="invoice-data">
              <Col className="text-left ">
                <Row className="mt-2 px-2">
                  <span> Invoice Number:</span>
                </Row>
                <Row className="mt-3 px-2">
                  <span> Invoice Date:</span>
                </Row>
                <Row className="mt-3 px-2">
                  <span> Name:</span>
                </Row>
                <Row className="px-2">
                  <span> Contact Number: </span>
                </Row>
                <Row className="mt-3 px-2">
                  <span> Email </span>
                </Row>
                <Row className="mt-3 px-2">
                  <span> Address 1 </span>
                </Row>
                <Row className="px-2">
                  <span> Address 2 </span>
                </Row>
                <Row className="mt-3 px-2 ">
                  <span>
                    Region, City, Postal Code <br /> Country
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="invoice-header">
              <Col className="text-left">
                <span>SHIPMENT DETAILS</span>
              </Col>
            </Row>
            <Row className=" invoice-data">
              <Col className="text-left">
                <Row className="mt-2 px-2">
                  <span> Courier Tracking Number:</span>
                </Row>
                <br />
                <Row className="mt-3 px-2">
                  <span> Name:</span>
                </Row>
                <Row className="px-2">
                  <span> Contact Number: </span>
                </Row>
                <Row className="mt-3 px-2">
                  <span> Email </span>
                </Row>
                <Row className="mt-3 px-2">
                  <span> Address 1 </span>
                </Row>
                <Row className="px-2">
                  <span> Address 2 </span>
                </Row>
                <Row className="mt-3 px-2 mb-1">
                  <span>
                    Region, City, Postal Code <br /> Country
                  </span>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="m-2 invoice-details">
          <Col>
            <Row className="invoice-header">
              <Col className="text-left">BOOKING DETAILS</Col>
            </Row>
            <Row className="invoice-booking-details">
              <Col
                md="5"
                className="invoice-booking-details-col text-left mr-md-2"
              >
                <span>Service:{'{Economy/Overnight/Express/Etc}'}</span>
              </Col>
              <Col md="3" className="invoice-booking-details-col text-left">
                <span>Collection Data:{'{Date}'}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="m-2">
          <Table responsive className="invoice-booking-details-table">
            <thead>
              <th className="invoice-table-border border-left">Item No</th>
              <th className="invoice-table-border " scope="col">
                | Item Description
              </th>
              <th className="invoice-table-border">| Weight </th>
              <th className="invoice-table-border">| Rate</th>
              <th className="invoice-table-border">| Surcharge</th>
              <th className="invoice-table-border border-right">
                | Total amount
              </th>
            </thead>
            <tbody>
              <tr>
                <td className="invoice-table-border-td border-left">1</td>
                <td className="invoice-table-border-td">| Bed</td>
                <td className="invoice-table-border-td">| 50kg</td>
                <td className="invoice-table-border-td">| R120 </td>
                <td className="invoice-table-border-td">| R24 </td>
                <td className="invoice-table-border-td border-right">| R144</td>
              </tr>
              <tr>
                <td className="invoice-table-border-td border-left">1</td>
                <td className="invoice-table-border-td">| Bed</td>
                <td className="invoice-table-border-td">| 50kg</td>
                <td className="invoice-table-border-td">| R120 </td>
                <td className="invoice-table-border-td">| R24 </td>
                <td className="invoice-table-border-td border-right">| R144</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <Row className="mt-3 mx-2 mb-5">
          <Col md="6" className="text-left">
            <Card className="invoice-card-last text-justify p-3 pb-4">
              <Row className="card-row-height">
                <Col>
                  <span>Courier Name:{'{Courier namer}'}</span>
                </Col>
              </Row>
              <Row className="card-row-height">
                <Col>
                  <span>Reference Number {'{Courier Ref number}'}</span>
                </Col>
              </Row>
              <Row className="card-row-height">
                <Col>
                  <span>Booking Enquiry {'{Courier namer}'}</span>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md="6">
            <Row className="justify-content-end ">
              <Col md="6" className="bg-white invoice-total">
                VAT Amount: R24
              </Col>
            </Row>
            <Row className="justify-content-end mt-1">
              <Col md="6" className="bg-white invoice-total">
                Total Weight: R24
              </Col>
            </Row>
            <Row className="justify-content-end  mt-1">
              <Col md="6" className="bg-white invoice-total">
                Total Amount: R24
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row className="mt-2">
          <Col>
            <Table responsive className="invoice-table">
              <thead>
                <tr>
                  <th>QUOTE DETAILS</th>
                  <th>SHIPMENT DETAILS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Invoice Number:
                    <br /> Invoice Date:
                    <br /> Name: <br /> Contact Number: <br /> Email <br />{' '}
                    Address 1 <br /> Address 2 <br /> Region, City, Postal Code
                    Country
                  </td>
                  <td>
                    Courier Tracking Number:
                    <br /> Name:
                    <br /> Address 1<br /> Address 2<br /> Contact Number:
                    <br /> Email <br /> Region, City, Postal Code Country
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row> */}

        {/* <Row className="mt-1 mb-2">
          <Col>
            <Table responsive className="invoice-table">
              <thead>
                <tr>
                  <th>BOOKING DETAILS</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className="invoice-service">
                  <td className="mr-2 mt-2 mb-2">
                    Service: '{'Economy/Overnight Express/Etc'}'
                  </td>
                  <td className=" mt-2 mb-2">Collection Date: {Date}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row> */}
      </Container>
    </>
  );
}

export default Notes;
