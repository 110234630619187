import React from "react";

import localCourier from "../assets/img/Button_Truck.png";
import internationalCourier from "../assets/img/Button_Airplane.png";
import seaCourier from "../assets/img/Button_Ship.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { onServiceChange } from "../store/actions/serviceActions";
import { Button, Col, Row } from "reactstrap";
import shiptruck from '../assets/img/truckship.png';
import { useState,useEffect } from "react";

const ServicesHeader = ({ value }) => {
  const { selectedService } = useSelector((state) => state.service);
  console.log(selectedService,"selectedService>>>>>>>>>>>>>>>>>");
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry,"selectedCountry");
  const dispatch = useDispatch();
  return (
    // <div className='banner_carousal'>
    <div className="service-header">
      <div className="d-flex flex-lg-row flex-md-row flex-column align-items-center mt-2 mb-2 ">
        {selectedCountry=="PT"? <></>:
        <button
          typ="button"
          className={`w-sm-100 mr-lg-2 mr-md-2 mb-sm-1 ${
            selectedService == "local" ? "sd-button-active" : "sd-button"
          }`}
          onClick={() => dispatch(onServiceChange("local"))}
        >
          <span className="d-flex justify-content-center sd-button-front">
            <img className="" src={localCourier} />
          </span>
        </button>
}
<>
        <button
          className={` mr-lg-2 mr-md-2 ${
            selectedService == "international"
              ? "sd-button-active"
              : "sd-button"
          }`}
          typ="button"
          onClick={() => dispatch(onServiceChange("international"))}
        >
         
           {selectedCountry==='PT'? <span className="d-flex justify-content-center sd-button-front-truckship "><img  src={shiptruck}   /></span>  : <span className="d-flex justify-content-center sd-button-front"><img className="" src={internationalCourier} /></span>}
         
        </button>
        <button
          typ="button"
          className={` mr-lg-2 mr-md-2 ${
            selectedService == "sea" ? "sd-button-active" : "sd-button"
          }`}
          onClick={() => dispatch(onServiceChange("sea"))}
        >
          <span className="d-flex justify-content-center sd-button-front">
            <img className="" src={seaCourier} />
          </span>
        </button>
        </>
        <div className="text-center py-3 fw-bold sd-button-selected w-100 w-lg-100 mb-1  text-white">
        {value==='Express / Courier Services'? "Express / Courier Services":value==='Sea Freight'? "Sea Freight":value==='DOMESTIC COURIER'? "Domestic Courier":value}{" "}
        </div>
      </div>
    </div>
  );
};

export default ServicesHeader;
