import client from '../../Repository/Repository';
import DawningRepository from '../../Repository/DawningRepository';
// import FedexRepository from '../../Repository/FedexRespository';
import firebase from '../../config/firebase';
import axios from 'axios';
const qs = require('querystring');

export const getDawningRates =
	(obj, weight, doc_id, baseURL, onSuccess = () => {}) =>
	async dispatch => {
		let { data } = await axios.get(`${baseURL}/getDBTToken`);

		console.log('Dawnwing Obj', {
			rates: obj,
			weight,
			doc_id,
		});

		try {
			DawningRepository.defaults.headers.Authorization = `Bearer ${data.token}`;
			let response = await DawningRepository.post('/getDBTRates', {
				rates: obj,
				weight,
				doc_id,
			});

			dispatch({
				type: 'GET_LOCAL_DAWNWING_RATES',
				payload: response.data.data.uiPrices,
			});
			onSuccess(response.data.data);
		} catch (error) {
			dispatch({ type: 'CLEAR_DAWNING_RATES' });
			onSuccess([]);
		}
	};

export const getOptRates = obj => async dispatch => {
	dispatch({ type: 'SET_OPT_RATES_LOADING', payload: true });
	client
		.post('/rates/opt-in', obj)
		.then(res => {
			dispatch({ type: 'GET_LOCAL_OPT_RATES', payload: res.data });
			dispatch({ type: 'SET_OPT_RATES_LOADING', payload: false });
		})
		.catch(err => {
			dispatch({ type: 'CLEAR_LOCAL_RATES' });
			dispatch({ type: 'SET_OPT_RATES_LOADING', payload: false });
		});
};

export const getCourierRates =
	(obj, surcharges, weight, doc_id, onSuccess = () => {}) =>
	async dispatch => {
		let quoteTableCollection = firebase
			.firestore()
			.collection('quote_table');
		client
			.post('/rates', obj)
			.then(async res => {
				let courierData = [];
				res.data.rates.forEach(data => {
					let rate_exl_vat =
						data.rate - surcharges - data.base_rate.vat;
					let tax = 0;
					let surchargeTax = (surcharges * 14) / 100;
					if (weight.total_weight >= weight.vol_weight) {
						if (
							weight.total_weight > 0.01 &&
							weight.total_weight <= 10
						) {
							tax = (rate_exl_vat * 16) / 100;
						} else if (
							weight.total_weight > 10 &&
							weight.total_weight <= 20
						) {
							tax = (rate_exl_vat * 18) / 100;
						} else {
							tax = (rate_exl_vat * 24) / 100;
						}
					} else {
						if (
							weight.vol_weight > 0.01 &&
							weight.vol_weight <= 10
						) {
							tax = (rate_exl_vat * 16) / 100;
						} else if (
							weight.vol_weight > 10 &&
							weight.vol_weight <= 20
						) {
							tax = (rate_exl_vat * 18) / 100;
						} else {
							tax = (rate_exl_vat * 24) / 100;
						}
					}

					courierData.push({
						rate_excluding_vat:
							rate_exl_vat + tax + data.base_rate.vat,
						rate: data.rate + tax + surchargeTax,
						surcharges: surcharges + surchargeTax,
						vat: data.base_rate.vat,
						code: data.service_level.code,
						serviceType: 'COURIER_GUY',
					});
				});

				console.log('Courier Actual rates', res.data.rates);
				dispatch({
					type: 'GET_LOCAL_RATES',
					payload: courierData,
				});
				onSuccess({ uiPrices: courierData, apiPrices: res.data.rates });

				// if (res?.data?.rates.length > 0) {
				// 	let document = await quoteTableCollection.doc(doc_id).get();
				// 	if (document && document.exists) {
				// 		quoteTableCollection.doc(doc_id).update({
				// 			courier_guy: res.data.rates,
				// 		});
				// 	} else {
				// 		quoteTableCollection.doc(doc_id).set({
				// 			courier_guy: res.data.rates,
				// 			type: 'local',
				// 		});
				// 	}
				// }
			})
			.catch(err => {
				dispatch({ type: 'CLEAR_COURIER_GUY_RATES' });
				onSuccess([]);
			});
	};

export const getCounters = counters => async dispatch => {
	console.log('Env', counters);
	firebase
		.firestore()
		.collection(`${counters}`)
		.onSnapshot(async query => {
			let countersData = [];
			for (let doc of query.docs) {
				countersData.push({ id: doc.id, ...doc.data() });
			}
			dispatch({ type: 'GET_COUNTERS', payload: countersData });
		});
};
