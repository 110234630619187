import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Table,
} from "reactstrap";
import SectionTitle from "./SectionTitle";

function WeightCalculator() {
  const [itemDetails, setItemDetails] = useState([
    {
      width: "",
      length: "",
      height: "",
      weight: "",
    },
  ]);
  const [dimension, setDimensions] = useState("cm");
  const [weight, setWeight] = useState("kg");
  const handleRemoveClick = (index) => {
    const list = [...itemDetails];
    list.splice(index, 1);
    setItemDetails(list);
  };

  const clearData = () => {
    setItemDetails([
      {
        width: "",
        length: "",
        height: "",
        weight: "",
      },
    ]);
    setDimensions("cm");
    setWeight("kg");
  };
  // handle click event of the Add button

  const handleAddClick = () => {
    setItemDetails([
      ...itemDetails,
      {
        length: "",
        width: "",
        height: "",
        weight: "",
      },
    ]);
  };

  const handleDetailsChange = (idx, key, val) => {
    let newArr = [...itemDetails];
    newArr[idx][key] = val;
    setItemDetails(newArr);
  };

  const checkCurrentM3 = () => {
    let total = 0;
    let itm = itemDetails[itemDetails.length - 1];
    switch (dimension) {
      case "cm":
        total += (itm.length * itm.width * itm.height) / 1000000;
        return total;
      case "in":
        total +=
          (itm.length * 2.54 * (itm.width * 2.54) * (itm.height * 2.54)) /
          1000000;

        return total;
      case "ft":
        total +=
          (itm.length * 30.48 * (itm.width * 30.48) * (itm.height * 30.48)) /
          1000000;

        return total;
      case "mm":
        total +=
          (itm.length * 0.1 * (itm.width * 0.1) * (itm.height * 0.1)) / 1000000;

        return total;
      case "yd":
        total +=
          (itm.length * 91.94 * (itm.width * 91.94) * (itm.height * 91.94)) /
          1000000;
        return total;
      case "m":
        // total += itm.length * itm.width * itm.height;
        total +=
          (itm.length * 100 * (itm.width * 100) * (itm.height * 100)) / 1000000;
        return total;
      default:
        return total;
    }
  };

  const checkCurrentFt3 = () => {
    let val = checkCurrentM3() * 35.3146667;
    if (val && val > 0) {
      return val.toFixed(3);
    } else {
      return val;
    }
  };

  const checkCurrentVolumetricWeight = () => {
    let totalVol = 0;
    let total = 0;
    let itm = itemDetails[itemDetails.length - 1];
    switch (dimension) {
      case "cm":
        total += (itm.length * itm.width * itm.height) / 5000;
        if (total > 0) {
          return total.toFixed(3);
        } else {
          return total;
        }
      case "in":
        total +=
          (itm.length * 2.54 * (itm.width * 2.54) * (itm.height * 2.54)) / 5000;

        if (total > 0) {
          return total.toFixed(3);
        } else {
          return total;
        }
      case "ft":
        total +=
          (itm.length * 30.48 * (itm.width * 30.48) * (itm.height * 30.48)) /
          5000;

        if (total > 0) {
          return total.toFixed(3);
        } else {
          return total;
        }
      case "mm":
        total +=
          (itm.length * 0.1 * (itm.width * 0.1) * (itm.height * 0.1)) / 5000;

        if (total > 0) {
          return total.toFixed(3);
        } else {
          return total;
        }
      case "yd":
        total +=
          (itm.length * 91.94 * (itm.width * 91.94) * (itm.height * 91.94)) /
          5000;

        if (total > 0) {
          return total.toFixed(3);
        } else {
          return total;
        }
      case "m":
        total +=
          (itm.length * 100 * (itm.width * 100) * (itm.height * 100)) / 5000;
        // total += (itm.length * itm.width * itm.height) / 5000;

        if (total > 0) {
          return total.toFixed(3);
        } else {
          return total;
        }
      default:
        return total;
    }
  };

  const checkCurrentWeightLbs = () => {
    let total = 0;
    let itm = itemDetails[itemDetails.length - 1];
    if (weight == "kg") {
      total += Number(itm.weight) * 2.20462;
    } else {
      total += Number(itm.weight);
    }
    if (total > 0) {
      return total.toFixed(3);
    } else {
      return total;
    }
  };

  const getTotalM3 = () => {
    let total = 0;
    switch (dimension) {
      case "cm":
        itemDetails.forEach((itm) => {
          total += (itm.length * itm.width * itm.height) / 1000000;
        });
        return total;
      case "in":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 2.54 * (itm.width * 2.54) * (itm.height * 2.54)) /
            1000000;
        });
        return total;
      case "ft":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 30.48 * (itm.width * 30.48) * (itm.height * 30.48)) /
            1000000;
        });
        return total;
      case "mm":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 0.1 * (itm.width * 0.1) * (itm.height * 0.1)) /
            1000000;
        });
        return total;
      case "yd":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 91.94 * (itm.width * 91.94) * (itm.height * 91.94)) /
            1000000;
        });
        return total;
      case "m":
        itemDetails.forEach((itm) => {
          // total += itm.length * itm.width * itm.height;
          total +=
            (itm.length * 100 * (itm.width * 100) * (itm.height * 100)) /
            1000000;
        });
        return total;
      default:
        return total;
    }
  };

  const getTotalFt3 = () => {
    let val = getTotalM3() * 35.3146667;
    if (val && val > 0) {
      return val.toFixed(3);
    } else {
      return 0;
    }
  };

  const getTotalVolumetricWeight = () => {
    let total = 0;

    switch (dimension) {
      case "cm":
        itemDetails.forEach((itm) => {
          total += (itm.length * itm.width * itm.height) / 5000;
        });

        return total;
      case "in":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 2.54 * (itm.width * 2.54) * (itm.height * 2.54)) /
            5000;
        });
        return total;
      case "ft":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 30.48 * (itm.width * 30.48) * (itm.height * 30.48)) /
            5000;
        });

        return total;
      case "mm":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 0.1 * (itm.width * 0.1) * (itm.height * 0.1)) / 5000;
        });
        return total;
      case "yd":
        itemDetails.forEach((itm) => {
          total +=
            (itm.length * 91.94 * (itm.width * 91.94) * (itm.height * 91.94)) /
            5000;
        });
        return total;
      case "m":
        itemDetails.forEach((itm) => {
          // total += (itm.length * itm.width * itm.height) / 5000;
          total +=
            (itm.length * 100 * (itm.width * 100) * (itm.height * 100)) / 5000;
        });

        return total;
      default:
        return total;
    }
  };

  const getTotalWeightLbs = () => {
    let total = 0;

    if (weight == "kg") {
      itemDetails.forEach((itm) => {
        total += Number(itm.weight) * 2.20462;
      });
    } else {
      itemDetails.forEach((itm) => {
        total += Number(itm.weight);
      });
    }
    if (total > 0) {
      return total.toFixed(3);
    } else {
      return total;
    }
  };
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  return (
    <div className="section_gap booking_section">
      <Container>
        <Row className="">
          <Col lg="12"  className="mb--40">
            <SectionTitle
              textAlign="text-center"
              title="CUBIC METER CALCULATOR
                            "
              description="Let us help you calculate your parcel/cargo’s cubic meters.
                            "
            />
          </Col>
        </Row>
        <Row>
          <Col className="px-10">
            <Card className="calculator">
              <Row className="d-flex flex-col justify-content-center">
                <Col
                  sm="12"
                  lg='2'
                  md="3"
                  className="d-flex flex-nowrap justify-content-start align-items-center flex-column"
                >
                  <p className="text-center  text-primary mb-0 font-weight-bold mb-lg-0 mb-2 measurement-unit">UNIT OF MEARUREMENT</p>
                </Col>
                <Col
                  xs="12"
                  md="2"
                  className="d-flex justify-content-start align-items-center flex-column"
                >
                  <FormGroup className="w-100">
                    <Label>DIMENSION</Label>
                    <Input
                      value={dimension}
                      type="select"
                      onChange={(e) => setDimensions(e.target.value)}
                    >
                      <option value="cm">cm</option>
                      <option value="in">in</option>
                      <option value="ft">ft</option>
                      <option value="mm">mm</option>
                      <option value="yd">yd</option>
                      <option value="m">m</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col
                  xs="12"
                  md="2"
                  className="d-flex justify-content-start align-items-center flex-column"
                >
                  <FormGroup className="w-100">
                    <Label>WEIGHT</Label>
                    <Input
                      value={weight}
                      type="select"
                      onChange={(e) => setWeight(e.target.value)}
                      width="100%"
                    >
                      <option value="kg">Kg</option>
                      <option value="lbs">Lbs</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs="11" className="d-flex flex-column justify-content-end align-items-center">
                {windowsize>768?
                  <Table
                    responsive
                    className="borderless quote-itemdetails-table cubic-meter-table"
                  >
                    <thead className="text-center text-uppercase">
                      <tr>
                        <th >
                          <Label for="country">Weight </Label>
                        </th>
                        <th>
                          <Label for="country">Length</Label>
                        </th>
                        <th>
                          <Label for="country">Width </Label>
                        </th>
                        <th>
                          <Label for="country">Height</Label>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody style={{border:'none'}}>
                      {itemDetails.map((inp, idx) => (
                        <tr key={idx}>
                          <td>
                            <FormGroup className="mb-0">
                              <InputGroup>
                                <Input
                                  type="number"
                                  name="weight"
                                  className="increase__height intl_item-detail "
                                  required
                                  value={inp.weight}
                                  onChange={(e) =>
                                    handleDetailsChange(
                                      idx,
                                      "weight",
                                      e.target.value
                                    )
                                  }
                                />
                                <InputGroupText>{weight}</InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className="mb-0">
                              <InputGroup>
                                <Input
                                  type="number"
                                  name="length"
                                  className="increase__height intl_item-detail "
                                  required
                                  value={inp.length}
                                  onChange={(e) =>
                                    handleDetailsChange(
                                      idx,
                                      "length",
                                      e.target.value
                                    )
                                  }
                                />
                                <InputGroupText>{dimension}</InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className="mb-0">
                              <InputGroup>
                                <Input
                                  type="number"
                                  name="width"
                                  required
                                  value={inp.width}
                                  onChange={(e) =>
                                    handleDetailsChange(
                                      idx,
                                      "width",
                                      e.target.value
                                    )
                                  }
                                  className="increase__height intl_item-detail "
                                />
                                <InputGroupText>{dimension}</InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup className="mb-0">
                              <InputGroup>
                                <Input
                                  type="number"
                                  name="height"
                                  required
                                  value={inp.height}
                                  onChange={(e) =>
                                    handleDetailsChange(
                                      idx,
                                      "height",
                                      e.target.value
                                    )
                                  }
                                  className="increase__height intl_item-detail "
                                />
                                <InputGroupText>{dimension}</InputGroupText>
                              </InputGroup>
                            </FormGroup>
                          </td>
                          <td>
                            <div className="increase__button_height">
                              <div>
                                <Button
                                  className="btn-plus  mt-md-1"
                                  size="sm"
                                  onClick={() => handleAddClick()}
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              </div>
                              <div>
                                <Button
                                  className="btn-minus "
                                  size="sm"
                                  onClick={() => handleRemoveClick(idx)}
                                  disabled={itemDetails.length < 2}
                                >
                                  <i className="fas fa-minus"></i>
                                </Button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  :
                  <>
                  {itemDetails.map((inp, idx) => (
        <Row key={idx} className="align-items-center mb-3 w-100 calculatortable">
          <Col xs="12" md="3">
            <FormGroup className="mb-0 ">
              <Label for={`weight-${idx}`}>Weight</Label>
              <InputGroup>
                <Input
                  id={`weight-${idx}`}
                  type="number"
                  name="weight"
                  className="increase__height intl_item-detail"
                  required
                  value={inp.weight}
                  onChange={(e) => handleDetailsChange(idx, 'weight', e.target.value)}
                />
                <InputGroupText>{weight}</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs="12" md="3">
            <FormGroup className="mb-0">
              <Label for={`length-${idx}`}>Length</Label>
              <InputGroup>
                <Input
                  id={`length-${idx}`}
                  type="number"
                  name="length"
                  className="increase__height intl_item-detail"
                  required
                  value={inp.length}
                  onChange={(e) => handleDetailsChange(idx, 'length', e.target.value)}
                />
                <InputGroupText>{dimension}</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs="12" md="3">
            <FormGroup className="mb-0">
              <Label for={`width-${idx}`}>Width</Label>
              <InputGroup>
                <Input
                  id={`width-${idx}`}
                  type="number"
                  name="width"
                  required
                  value={inp.width}
                  onChange={(e) => handleDetailsChange(idx, 'width', e.target.value)}
                  className="increase__height intl_item-detail"
                />
                <InputGroupText>{dimension}</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs="12" md="3">
            <FormGroup className="mb-0">
              <Label for={`height-${idx}`}>Height</Label>
              <InputGroup>
                <Input
                  id={`height-${idx}`}
                  type="number"
                  name="height"
                  required
                  value={inp.height}
                  onChange={(e) => handleDetailsChange(idx, 'height', e.target.value)}
                  className="increase__height intl_item-detail"
                />
                <InputGroupText>{dimension}</InputGroupText>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs="12" className="d-flex justify-content-md-start justify-content-center  mt-2 mt-md-0">
          <div className="increase__button_height">
                              <div>
                                <Button
                                  className="btn-plus  mt-md-1"
                                  size="sm"
                                  onClick={() => handleAddClick()}
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              </div>
                              <div>
                                <Button
                                  className="btn-minus "
                                  size="sm"
                                  onClick={() => handleRemoveClick(idx)}
                                  disabled={itemDetails.length < 2}
                                >
                                  <i className="fas fa-minus"></i>
                                </Button>
                              </div>
                            </div>
            
          </Col>
        </Row>
      ))}
      </>
    }
    
                </Col>
                <Col xs="11">
                  <hr />
                </Col>
              </Row>

              <Row className="justify-content-center calculator__volume">
                <Col xs="12" className="calculator__col">
                  <Row>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Volume m3</p>
                        <Input
                          readOnly
                          value={
                            checkCurrentM3() > 0
                              ? checkCurrentM3().toFixed(3)
                              : checkCurrentM3()
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Volume ft3</p>
                        <Input readOnly value={checkCurrentFt3()} />
                      </FormGroup>
                    </Col>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Volumetric Wt kg</p>
                        <Input
                          readOnly
                          value={checkCurrentVolumetricWeight()}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Weight lbs</p>
                        <Input readOnly value={checkCurrentWeightLbs()} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-center calculator__volume">
                <Col xs="12" className="calculator__col">
                  <Row>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Total m3</p>
                        <Input
                          readOnly
                          value={
                            getTotalM3() > 0
                              ? getTotalM3().toFixed(3)
                              : getTotalM3()
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Total ft3</p>
                        <Input readOnly value={getTotalFt3()} />
                      </FormGroup>
                    </Col>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Total Vol. Wt kg</p>
                        <Input
                          readOnly
                          value={
                            weight == "kg"
                              ? getTotalVolumetricWeight() > 0
                                ? getTotalVolumetricWeight().toFixed(3)
                                : getTotalVolumetricWeight()
                              : getTotalVolumetricWeight() > 0
                              ? (getTotalVolumetricWeight() / 2.20462).toFixed(
                                  3
                                )
                              : getTotalVolumetricWeight() / 2.20462
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="w-152" md="3">
                      <FormGroup>
                        <p>Total Vol. Wt lbs</p>
                        <Input
                          readOnly
                          value={
                            weight == "lbs"
                              ? getTotalVolumetricWeight() > 0
                                ? getTotalVolumetricWeight().toFixed(3)
                                : getTotalVolumetricWeight()
                              : getTotalVolumetricWeight() > 0
                              ? (getTotalVolumetricWeight() * 2.20462).toFixed(
                                  3
                                )
                              : getTotalVolumetricWeight() * 2.20462
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className=" justify-content-center calculator_btn">
                <Col
                  xs="12"
                  className="d-flex justify-content-center  calculator__col"
                >
                  <Button
                    className="bg-primary text-white font-weight-bold px-4"
                    onClick={() => clearData()}
                    size="lg"
                  >
                    RESET
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WeightCalculator;
