import React from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import CustomField from "./CustomField";

const PalletLcl = ({
  details,
  handleBoxChange,
  index,
  handleAddClick,
  handleRemoveClick,
  items,
}) => {
  const handlePlusClick = () => {
    handleBoxChange("units", details?.units + 1, index);
  };
  const handleMinusClick = () => {
    if (details?.units > 1) {
      handleBoxChange("units", details?.units - 1, index);
    }
  };
  return (
    <div className="d-flex align-items-center w-100 movingboxtable ">
      <div className="mr-0 mr-md-2 movingboxinput">
        {index == 0 && <p className="sea-freight__label">CBM</p>}
        <FormGroup className={`mb-0 ${index != 0 ? "mt-3" : "mt-0 "}`}>
          <Input
            type="number"
            value={details?.cbm}
            className="sea-freight__w-76"
            required
            onChange={(e) => handleBoxChange("cbm", e.target.value, index)}
          />
        </FormGroup>
      </div>
      {/* <div className="mr-2">
        {index == 0 && <p className="sea-freight__label">Weight</p>}
        <FormGroup className={`mb-0 ${index != 0 ? "mt-3" : "mt-0 "}`}>
          <InputGroup className="sea-freight__input-group">
            <Input
              type="number"
              min={1}
              name="weight"
              value={details?.weight}
              required
              className="sea-freight__w-72"
              // placeholder="Min 0.1kg"
              onChange={(e) => handleBoxChange("weight", e.target.value, index)}
            />
            <InputGroupText>KG</InputGroupText>
          </InputGroup>
        </FormGroup>
      </div> */}
      <div className="mr-0 mr-md-2 movingboxinput">
        {index == 0 && <p className="sea-freight__label">WIDTH</p>}
        <FormGroup className={`mb-0 ${index != 0 ? "mt-3" : "mt-0 "}`}>
          <InputGroup className="sea-freight__input-group">
            <Input
              type="number"
              min={1}
              name="width"
              value={details?.width}
              required
              className="sea-freight__w-72"
              // placeholder="Min 1cm"
              onChange={(e) => handleBoxChange("width", e.target.value, index)}
            />
            <InputGroupText>cm</InputGroupText>
          </InputGroup>
        </FormGroup>
      </div>
      <div className="mr-0 mr-md-2 movingboxinput">
        {index == 0 && <p className="sea-freight__label">LENGTH</p>}
        <FormGroup className={`mb-0 ${index != 0 ? "mt-3" : "mt-0 "}`}>
          <InputGroup className="sea-freight__input-group">
            <Input
              type="number"
              min={1}
              name="length"
              value={details?.length}
              required
              className="sea-freight__w-72"
              // placeholder="Min 1cm"
              onChange={(e) => handleBoxChange("length", e.target.value, index)}
            />
            <InputGroupText>cm</InputGroupText>
          </InputGroup>
        </FormGroup>
      </div>

      <div className="mr-0 mr-md-2 movingboxinput">
        {index == 0 && <p className="sea-freight__label">HEIGHT</p>}
        <FormGroup className={`mb-0 ${index != 0 ? "mt-3" : "mt-0 "}`}>
          <InputGroup className="sea-freight__input-group">
            <Input
              type="number"
              min={1}
              name="height"
              value={details?.height}
              required
              className="sea-freight__w-72"
              // placeholder="Min 1cm"
              onChange={(e) => handleBoxChange("height", e.target.value, index)}
            />
            <InputGroupText>cm</InputGroupText>
          </InputGroup>
        </FormGroup>
      </div>

      <div className="mr-0 mr-md-2 movingboxinput">
        {index == 0 && <p className="sea-freight__label">UNITS</p>}

        <div className={`${index != 0 ? "mt-3" : "mt-0"}`}>
          <CustomField
            val={details?.units}
            handlePlusClick={handlePlusClick}
            handleMinusClick={handleMinusClick}
          />
        </div>
      </div>
      <div className="d-flex align-items-end">
        <div className="sea-freight__btn-group d-flex flex-column">
          <Button onClick={handleAddClick} className="btn-plus  " size="sm">
            <i class="fas fa-plus"></i>
          </Button>

          <Button
            disabled={items?.length == 1}
            onClick={() => handleRemoveClick(index)}
            className="btn-minus "
            size="sm"
          >
            <i class="fas fa-minus"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PalletLcl;
