import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  CardSubtitle,
  Label,
  Input,
  FormGroup,
  CardFooter,
  Container,
} from "reactstrap";
import Payment from "../../assets/img/payment.png";
import Calendar from "../../assets/img/Calendar.png";
import DeliveryDestination from "../../assets/img/DeliveryDestination.png";
import ProcessList from "../ProcessList";
import ServicesHeader from "../ServicesHeader";
import { LocalActiveTab } from "./LocalActiveTab";

function AddressDetails(props) {
  const [isMobile, setIsMobile] = useState(false);
  // const firstPage = JSON.parse(localStorage.getItem('local_first_page'));
  const firstPage = JSON.parse(localStorage.getItem("local_country_selection"));
  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);
  const updatePredicate = () => {
    setIsMobile(window.innerWidth < 767);
  };
  return (
    <div className="address-details ">
      <div className="mx-4">
        <ServicesHeader value="DOMESTIC COURIER" />
        <LocalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
      </div>

      <ProcessList activeIdx={1} />

      <hr className="process_list_hr" />

      <Row className="justify-content-center mx-3 my-2  quote-calender-component ">
        <Col md="6" className="text-left mb-3 ">
          <Label>SHIPPER DETAILS:</Label>
          <Row>
            <Col md="5">
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="Name"
                    value={props.value.sendingName}
                    onChange={(e) =>
                      props.onChange({
                        sendingName: e.target.value,
                      })
                    }
                    className="mt-1"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="Surname"
                    value={props.value.sendingSurName}
                    onChange={(e) =>
                      props.onChange({
                        sendingSurName: e.target.value,
                      })
                    }
                    className="mt-2"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="Contact Number"
                    className="mt-2"
                    value={props.value.sendingContact}
                    onChange={(e) =>
                      props.onChange({
                        sendingContact: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="email"
                    placeholder="Email Address"
                    className="mt-2"
                    value={props.value.sendingEmail}
                    onChange={(e) =>
                      props.onChange({
                        sendingEmail: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
            </Col>

            <Col md="7" className="mb-4">
              <Input
                type="text"
                placeholder="Address 1"
                className="mt-1"
                value={props.value.sendingAddress1}
                onChange={(e) =>
                  props.onChange({
                    sendingAddress1: e.target.value,
                  })
                }
                required
              />
              {/* <Input
                type="text"
                placeholder="Address 2"
                className="mt-1"
                value={props.value.sendingAddress2}
                onChange={(e) =>
                  props.onChange({ sendingAddress2: e.target.value })
                }
              /> */}
              <Input
                type="text"
                placeholder="Address 2 (Optional)"
                className="mt-2"
                value={props.value.sendingComplexNo}
                onChange={(e) =>
                  props.onChange({
                    sendingComplexNo: e.target.value,
                  })
                }
              />
              <Input
                type="text"
                placeholder="Suburb"
                className="mt-2"
                value={firstPage?.fromSuburb ? firstPage.fromSuburb : ""}
                // onChange={e =>
                // 	props.onChange({
                // 		sendingSuburb: e.target.value,
                // 	})
                // }
                readOnly
                required
              />
              <Input
                type="text"
                placeholder="City"
                className="mt-2"
                value={
                  firstPage != null && firstPage.fromLocalArea != null
                    ? firstPage.fromLocalArea
                    : ""
                }
                // onChange={e =>
                // 	props.onChange({
                // 		sendingCity: e.target.value,
                // 	})
                // }
                // required
                readOnly
              />
              <Input
                type="text"
                placeholder="Postal Code"
                className="mt-2"
                value={
                  firstPage != null && firstPage?.fromCode != null
                    ? firstPage.fromCode
                    : ""
                }
                // onChange={(e) => props.onChange({ sendingPostalCode: e.target.value })}
                readOnly
              />

              {/* <Row>
                <Col md="7">
                  
                </Col>
              </Row>
              <Row>
                <Col md="7">
                 
                </Col>
              </Row>
              <Row>
                <Col md="7">
                 
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Col>
        <Col md="6" className="border__class text-left mb-3">
          <Label className="text-primary">CONSIGNEE DETAILS:</Label>
          <Row>
            <Col md="5">
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="Name"
                    className="mt-1"
                    value={props.value.RecievingName}
                    onChange={(e) =>
                      props.onChange({
                        RecievingName: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="Surname"
                    className="mt-2"
                    value={props.value.RecievingSurName}
                    onChange={(e) =>
                      props.onChange({
                        RecievingSurName: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="Contact Number"
                    className="mt-2"
                    value={props.value.RecievingContact}
                    onChange={(e) =>
                      props.onChange({
                        RecievingContact: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    type="email"
                    placeholder="Email Address"
                    className="mt-2"
                    value={props.value.RecievingEmail}
                    onChange={(e) =>
                      props.onChange({
                        RecievingEmail: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
              </Row>
            </Col>

            <Col md="7">
              <Input
                type="text"
                placeholder="Address 1"
                className="mt-1"
                value={props.value.RecievingAddress1}
                onChange={(e) =>
                  props.onChange({
                    RecievingAddress1: e.target.value,
                  })
                }
                required
              />
              {/* <Input
                type="text"
                placeholder="Address 2"
                className="mt-1"
                value={props.value.RecievingAddress2}
                onChange={(e) =>
                  props.onChange({ RecievingAddress2: e.target.value })
                }
              /> */}
              <Input
                type="text"
                placeholder="Address 2 (Optional)"
                className="mt-2"
                value={props.value.RecievingComplexNo}
                onChange={(e) =>
                  props.onChange({
                    RecievingComplexNo: e.target.value,
                  })
                }
              />
              <Input
                type="text"
                placeholder="Suburb"
                className="mt-2"
                value={firstPage?.toSuburb ? firstPage?.toSuburb : ""}
                // onChange={e =>
                // 	props.onChange({
                // 		RecievingSuburb: e.target.value,
                // 	})
                // }
                readOnly
                required
              />
              <Input
                type="text"
                placeholder="City"
                className="mt-2"
                value={
                  firstPage != null && firstPage.toLocalArea != null
                    ? firstPage.toLocalArea
                    : ""
                }
                // onChange={e =>
                // 	props.onChange({
                // 		RecievingCity: e.target.value,
                // 	})
                // }
                // required
                readOnly
              />
              <Input
                type="text"
                placeholder="Postal Code"
                className="mt-2"
                value={
                  firstPage != null && firstPage.toCode != null
                    ? firstPage.toCode
                    : ""
                }
                // onChange={(e) => props.onChange({ RecievingPostalCode: e.target.value })}
                readOnly
              />

              {/* <Row>
        <Col md='7'>
        
        </Col>
      </Row>
      <Row>
        <Col md='7'>
        
        </Col>
      </Row>
      <Row>
        <Col md='7'>
        
        </Col>
      </Row> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default AddressDetails;
