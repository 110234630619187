import React from "react";
import { Col, Row, Button, Container } from "react-bootstrap";

import TickImg from "../assets/img/tick-green.png";
import TickGrayImg from "../assets/img/tick-gray.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const InternationalRelocation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="pagesHeaderWrapper">
        <h1 className="international-relocations-heading">
          INTERNATIONAL <sapn style={{ color: "#00b7ae" }}> RELOCATION</sapn>
        </h1>

        <Container className="d-flex justify-content-around flex-wrap">
          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3
              style={{
                fontWeight: "medium",
                fontSize: "18px",
                margin: "0px",
                marginLeft: "30px",
                textTransform: "none",
              }}
              className="my-2"
            >
              Moving Cubes
            </h3>
          </div>

          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3
              style={{
                fontWeight: "medium",
                fontSize: "18px",
                margin: "0px",
                marginLeft: "30px",
                textTransform: "none",
              }}
              className="my-2"
            >
              Full Containers (FCL)
            </h3>
          </div>

          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3
              style={{
                fontWeight: "medium",
                fontSize: "18px",
                margin: "0px",
                marginLeft: "30px",
                textTransform: "none",
              }}
              className="my-2"
            >
              Pallets / Boxes (LCL)
            </h3>
          </div>
          <div className="d-flex align-items-center" style={{ width: "250px" }}>
            <img src={TickImg} alt="tick" />
            <h3
              style={{
                fontWeight: "medium",
                fontSize: "18px",
                margin: "0px",
                marginLeft: "30px",
                textTransform: "none",
              }}
              className="my-2"
            >
              Container Packing
            </h3>
          </div>
        </Container>
      </div>
      <div>
        <h2 className="font-weight-normal my-3 internationalHeading mb-lg-2 mb-md-2">
          Moving abroad can be stressful,{" "}
          <sapn style={{ color: "#00b7ae" }}>let us deal with the stress</sapn>
        </h2>
        <div style={{ background: "#EAEAEA", padding: "40px 0" }}>
          <Container className="relocations-container">
            <Row>
              <Col
                style={{ color: "#404b51" }}
                className="d-flex my-3 flex-column justify-content-between"
                lg={6} xl={6}
              >
                <div>
                  <h4
                    style={{
                      marginBottom: "0px",
                    }}
                    className="font-weight-bold text-center"
                  >
                    Moving Cubes
                  </h4>

                  <div className="d-flex align-items-center my-1">
                    <img src={TickGrayImg} alt="tick-gray" />
                    <p className="tickCubes">
                      Various moving cube configurations
                    </p>
                  </div>

                  <div className="d-flex align-items-center my-1">
                    <img src={TickGrayImg} alt="tick-gray" />
                    <p className="tickCubes">Incl. cube packing service</p>
                  </div>

                  <div className="d-flex align-items-center my-1">
                    <img src={TickGrayImg} alt="tick-gray" />
                    <p className="tickCubes">International shipping standards</p>
                  </div>

                  <div className="d-flex flex-colum flex-lg-row align-items-center my-1">
                    <img src={TickGrayImg} alt="tick-gray" />
                    <p className="tickCubes">
                      Safe and secure method for transport
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <h4
                    style={{
                      marginBottom: "0px",
                    }}
                    className="font-weight-bold text-center"
                  >
                    Pallets/Boxes (LCL)
                  </h4>

                  <div className="d-flex align-items-center my-1">
                    <img src={TickGrayImg} alt="tick-gray" />
                    <p className="tickText">Charge per Cubic meter (CBM)</p>
                  </div>
                  <div className="d-flex align-items-center my-1">
                    <img src={TickGrayImg} alt="tick-gray" />
                    <p className="tickText">Palletization</p>
                  </div>
                  <div className="d-flex align-items-center my-1">
                    <img src={TickGrayImg} alt="tick-gray" />
                    <p className="tickText">Up to 1000 kg per CBM</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} xl={6} >
                <div className="mt-3 tableWrapper internationalrelocations">
                  <table
                    style={{ marginBottom: 0 }}
                    class="table intl-relocation-table"
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ color: "#404b51", background: "white" }}
                          className="text-center"
                          colspan="5"
                        >
                          Moving Cube Configurations
                        </th>
                      </tr>
                      <tr
                        style={{
                          background: "#404B51",
                          color: "#fff",
                        }}
                        className="text-center"
                      >
                        <th
                          style={{
                            height: "73px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          scope="col"
                        >
                          TYPE
                        </th>
                        <th
                        className="align-items-center"
                          style={{
                            height: "73px",
                            paddingBottom: "25px",
                          }}
                          scope="col"
                        >
                          CBM
                        </th>
                        <th scope="col" style={{
                            height: "73px",
                            paddingBottom: "25px",
                          }} >LENGTH (cm)</th>
                        <th scope="col" style={{
                            height: "73px",
                            paddingBottom: "25px",
                          }} >HEIGHT (cm)</th>
                        <th scope="col" style={{
                            height: "73px",
                            paddingBottom: "25px",
                          }}>WIDTH (cm)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="tdLarge intl-relocation-td-white">
                          SMALL
                        </td>
                        <td className="tdCgreen">2.07</td>
                        <td className="intl-relocation-td-white">100</td>
                        <td className="intl-relocation-td-white">187</td>
                        <td className="intl-relocation-td-white">111</td>
                      </tr>
                      {/* <tr>
												<td className='tdLarge intl-relocation-td-white'>
													BUDGET MEDIUM
												</td>
												<td className='tdCgreen'>
													3.1
												</td>
												<td className='intl-relocation-td-white'>
													146
												</td>
												<td className='intl-relocation-td-white'>
													187
												</td>
												<td className='intl-relocation-td-white'>
													111
												</td>
											</tr> */}
                      <tr>
                        <td className="tdLarge intl-relocation-td-white">
                          MEDIUM
                        </td>
                        <td className="tdCgreen">3.77</td>
                        <td className="intl-relocation-td-white">226</td>
                        <td className="intl-relocation-td-white">137</td>
                        <td className="intl-relocation-td-white">122</td>
                      </tr>
                      {/* <tr>
												<td className='tdLarge intl-relocation-td-white'>
													BUDGET LARGE
												</td>
												<td className='tdCgreen'>
													5.45
												</td>
												<td className='intl-relocation-td-white'>
													226
												</td>
												<td className='intl-relocation-td-white'>
													122
												</td>
												<td className='intl-relocation-td-white'>
													198
												</td>
											</tr> */}
                      <tr>
                        <td className="tdLarge intl-relocation-td-white">
                          LARGE
                        </td>
                        <td className="tdCgreen">6.17</td>
                        <td className="intl-relocation-td-white">226</td>
                        <td className="intl-relocation-td-white">187</td>
                        <td className="intl-relocation-td-white">146</td>
                      </tr>
                      <tr>
                        <td className="tdLarge intl-relocation-td-white">
                          CUSTOM
                        </td>
                        <td className="tdCgreen"></td>

                        <td
                          className="text-center intl-relocation-td-white"
                          colspan="3"
                        >
                          Custom requirement
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <h2 className="font-weight-bold my-3 internationalHeading ">
        Our shipping process, in
        <sapn style={{ color: "#00b7ae" }}> 5 EASY </sapn>steps
      </h2>

      <div className="stepsWrapper my-3">
        <Container>
          <div className=" d-flex flex-wrap align-items-start justify-content-around ">
            <div className="stepTextContainer d-flex flex-column justify-content-center align-items-center">
              <p>1.</p>
              <p className="onelinetext">Select service: FCL / LCL / CUBE</p>
            </div>

            <div className="stepTextContainer d-flex flex-column justify-content-center align-items-center">
              <p>2.</p>
              <p>Quote & Book</p>
            </div>

            <div className="stepTextContainer d-flex flex-column justify-content-center align-items-center">
              <p>3.</p>
              <p>Pack & Collect</p>
            </div>

            <div className="stepTextContainer d-flex flex-column justify-content-center align-items-center">
              <p>4.</p>
              <p>Ship to Destination Country</p>
            </div>

            <div className="stepTextContainer d-flex flex-column justify-content-center align-items-center">
              <p>5.</p>
              <p className="onelinetext">Destination Clearance & Final Delivery</p>
            </div>
          </div>
        </Container>
      </div>

      <div
        style={{ background: "#404b51" }}
        className="buttonWrapper py-3 d-flex  justify-content-center align-items-center"
      >
        <Link to="/contactus">
          <Button
            style={{ fontSize: "25px", padding: "4px 40px" }}
            className="font-weight-bold"
          >
            ENQUIRE TODAY!
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default InternationalRelocation;
