import React from 'react';
import axios from 'axios';

// const baseURL = `https://apis-sandbox.fedex.com`;
// const baseURL = `https://apis.fedex.com`;
const baseURL = `${process.env.REACT_APP_DEVELOPMENT}`;

export default axios.create({
	baseURL,
});
