import React from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Container,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import { useState } from 'react';
import Logo3 from '../assets/img/Logo3.png';

function Header() {
	let [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Container
				fluid
				className='p-0 main_header position-fixed'
				style={{ zIndex: 1 }}
			>
				<Container className=''>
					<Navbar light expand='md' className=''>
						<NavbarBrand href='/'>
							<img src={Logo3} className='' />
						</NavbarBrand>
						<NavbarToggler onClick={toggle} />
						<Collapse isOpen={isOpen} navbar>
							<Nav className='ml-auto' navbar>
								<NavItem>
									<NavLink href='/main'>Home</NavLink>
								</NavItem>

								{/* <NavItem>
                  <NavLink href="/main">Quick Quote</NavLink>
                </NavItem> */}

								<NavItem>
									<NavLink href='/aboutus'>About Us</NavLink>
								</NavItem>
								<NavItem>
									<NavLink href='/contactus'>
										Contact Us
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink href='/trackparcel'>
										Track Parcel
									</NavLink>
								</NavItem>
								{/* <NavItem>
									<NavLink href='/privacy-policy'>
										Privacy Policy
									</NavLink>
								</NavItem> */}
							</Nav>
						</Collapse>
					</Navbar>
				</Container>
			</Container>
		</>
	);
}

export default Header;
