import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Table,
  Badge,
  Card,
} from "reactstrap";
import Scale from "../../assets/img/Scale.png";
import MeasuringTape from "../../assets/img/MeasuringTape.png";
import Globally_Prohibited_Items from "../../assets/docs/Globally_Prohibited_Items.pdf";
import LossOrDamage from "../../assets/docs/Insurance_Items_Not_Covered.pdf";
import ServicesHeader from "../ServicesHeader";
import InternationalActiveTab from "./InternationalActiveTab";
import DimensionsCard from "../DimensionsCard";

import Envelope1 from "../../assets/img/phase5/Envalope1.png";
import SmallParcel from "../../assets/img/phase5/SmallParcel.png";
import GeneralParcel from "../../assets/img/phase5/GeneralParcel.png";
import Tube from "../../assets/img/phase5/Tube.png";
import Stroller from "../../assets/img/phase5/Stroller.png";
import MediumBox from "../../assets/img/phase5/SmallParcel2.png";
import Laptop from "../../assets/img/phase5/Laptopbag.png";
import PlasticCrate from "../../assets/img/phase5/Crate.png";
import LargeSuitcase from "../../assets/img/phase5/LargeSuitcase.png";
import SmallSuitcase from "../../assets/img/phase5/SmallSuitcae.png";
import Otc from "../../assets/img/phase5/Medicine.png";

import TvBox from "../../assets/img/phase5/TV.png";
import Pallet from "../../assets/img/phase5/Pallet2.png";

import Tyres from "../../assets/img/phase5/Tyres1.png";
import Measure from "../../assets/img/phase5/Copy of Measure.png";

function ItemDetails(props) {
  const [inputList, setInputList] = useState([
    { weight: "", height: "", length: "", width: "" },
  ]);
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    props.onChange({ itemDetails: list });
    setInputList(list);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { weight: "", height: "", length: "", width: "" },
    ]);
  };

  let [isChecked, setIsChecked] = useState(false);
  const [weight, setWeight] = useState(0);
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [insurance, setInsurance] = useState(false);
  const [insuranceValue, setInsuranceValue] = useState("");

  const dimensions = [
    {
      title: "A4 Envelope",
      icon: Envelope1,
      length: 33,
      width: 4,
      height: 23,
    },
    {
      title: "Small Parcel",
      icon: SmallParcel,
      length: 20,
      width: 20,
      height: 30,
    },
    {
      title: "General Parcel",
      icon: GeneralParcel,
      length: 50,
      width: 50,
      height: 50,
    },
    {
      title: "Medium Box",
      icon: MediumBox,
      length: 40,
      width: 30,
      height: 30,
    },
    {
      title: "Large Box",
      icon: MediumBox,
      length: 70,
      width: 65,
      height: 50,
    },
    {
      title: "Laptop",
      icon: Laptop,
      length: 37,
      width: 10,
      height: 25,
    },
    {
      title: "Plastic Crate 651",
      icon: PlasticCrate,
      length: 67,
      width: 40,
      height: 41,
    },
    {
      title: "Large suitcase",
      icon: LargeSuitcase,
      length: 70,
      width: 30,
      height: 50,
    },
    {
      title: "Hand Luggage",
      icon: SmallSuitcase,
      length: 53,
      width: 23,
      height: 35,
    },
    {
      title: "Medicine",
      icon: Otc,
      text: "Contact Us",
    },
    {
      title: "Stroller",
      icon: Stroller,
      length: 90,
      width: 60,
      height: 90,
    },

    {
      title: "TV Box",
      icon: TvBox,
      length: 115,
      width: 15,
      height: 77,
    },
    {
      title: "Tube",
      icon: Tube,
      length: 96,
      width: 15,
      height: 15,
    },
    {
      title: "Pallet",
      icon: Pallet,
      length: 120,
      width: 80,
      height: 100,
    },
    {
      title: "Custom",
      icon: Measure,
      text: "Dimensions",
    },

    // {
    //   title: "2 x Tyres",
    //   icon: Tyres,
    //   length: 70,
    //   width: 40,
    //   height: 70,
    // },
  ];
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  return (
    <Row className="itemdetails-component m-2">
      <Col xs="12">
        <Row className="document__row no-gutters">
          <Col xs="12">
            <ServicesHeader value="INTERNATIONAL COURIER" />
            <InternationalActiveTab
              activeTab={props.activeTab}
              setActiveTab={props.setActiveTab}
            />
            <hr className="service-header__hr-100" />
          </Col>
        </Row>
      </Col>
      <Col>
        {/* <Row className="document__row no-gutters">
          <Col
            md="3"
            className="d-flex justify-content-start align-items-center"
          >
            <Label className="m-0 p-0">COMMODITY TYPE:</Label>
          </Col>
          <Col
            md="4"
            className="d-flex justify-content-start align-items-center"
          >
            <Button
              className={`${
                props.packageType == "document" ? "active_btn" : ""
              }`}
              onClick={() => props.packageTypeChange("document")}
            >
              Document
            </Button>
          </Col>
          <Col
            md="4"
            className="d-flex justify-content-start align-items-center"
          >
            <Button
              className={`${
                props.packageType == "non-document" ? "active_btn" : ""
              }`}
              onClick={() => props.packageTypeChange("non-document")}
            >
              Non-Document
            </Button>
          </Col>
       
        </Row> */}
        {/* {
          <Row className="document__row no-gutters">
            <Col
              md="3"
              className="d-flex justify-content-start align-items-center"
            ></Col>
            <Col
              md="9"
              className="d-flex justify-content-start align-items-center"
            >
              {props.packageType == "document" ? (
                <p className="m-0 text-white  p-0">
                  Flyer bags / envelopes will be supplied by our partners
                </p>
              ) : (
                <></>
                // <p className='m-0 text-white text-left p-0'>
                // 	For shipments over 68 Kg in total weight,
                // 	send your shipments’ details to{' '}
                // 	<a
                // 		href='mailto:support@shipmystuff.co.za'
                // 		className='text-primary'
                // 	>
                // 		support@shipmystuff.co.za
                // 	</a>
                // </p>
              )}
            </Col>
          </Row>
        } */}
        <Row className="justify-content-start noGutters">
          {/* <Col md="3">
              <Badge className="text-white">Place Holder</Badge>
              <hr></hr>
            </Col> */}
          {/* <Col
            md="3"
            sm="12"
            className="item_details_weight_col d-flex flex-column justify-content-end  hidescale"
          >
            <div className="scale__img">
              <img src={Scale} className="img-fluid " width="85px" />
            </div>
            <hr className="w-50 weight_margin " />
          </Col> */}
          <Col md="12" xs="12" className="item_details_length_col ">
            <Row noGutters>
              <Col xs="12" className="d-flex flex-wrap justify-content-center ">
                {dimensions?.map((dimension, idx) => {
                  return (
                    <div
                      // xs="3"
                      key={idx}
                      onClick={() => {
                        props.handleOnDimensionCardClick(dimension);
                      }}
                      className="dimension-card-parent"
                    >
                      <DimensionsCard details={dimension} />
                    </div>
                  );
                })}
              </Col>
              {/* {dimensions?.map((dimension, idx) => {
                return (
                  <Col
                    // xs="3"
                    key={idx}
                    onClick={() => {
                      props.handleOnDimensionCardClick(dimension);
                    }}
                  >
                    <DimensionsCard details={dimension} />
                  </Col>
                );
              })} */}
              <hr />
            </Row>
            {/* <div className="tape__img">
              <img src={MeasuringTape} className="img-fluid" width="70px" />
            </div>
            <hr className="w-100" /> */}
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="12">
            {windowsize > 425 ? (
              <Table responsive className="borderless quote-itemdetails-table">
                <thead className="text-center text-uppercase">
                  <tr>
                    <th>
                      <Label for="country">Weight </Label>
                    </th>
                    <th>
                      <Label for="country">Length</Label>
                    </th>
                    <th>
                      <Label for="country">Width </Label>
                    </th>
                    <th>
                      <Label for="country">Height</Label>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style={{ border: "0px" }}>
                  {props.value &&
                    props.value.map((inp, idx) => (
                      <tr>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                type="number"
                                name="weight"
                                className="increase__height intl_item-detail "
                                required
                                min={0.3}
                                max={68}
                                step={0.1}
                                value={inp.weight}
                                onChange={(e) =>
                                  props.onChange(idx, "weight", e.target.value)
                                }
                                placeholder={"Min 0.3kg"}
                              />
                              <InputGroupText>kg</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                type="number"
                                name="length"
                                className="increase__height intl_item-detail "
                                min={1}
                                max={274}
                                required
                                value={inp.length}
                                onChange={(e) =>
                                  props.onChange(idx, "length", e.target.value)
                                }
                                placeholder="Min 1cm"
                              />
                              <InputGroupText>cm</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                type="number"
                                min={1}
                                name="width"
                                required
                                className="increase__height intl_item-detail "
                                value={inp.width}
                                onChange={(e) =>
                                  props.onChange(idx, "width", e.target.value)
                                }
                                placeholder="Min 1cm"
                              />
                              <InputGroupText>cm</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup className="mb-0">
                            <InputGroup>
                              <Input
                                type="number"
                                min={1}
                                name="height"
                                value={inp.height}
                                required
                                className="increase__height intl_item-detail "
                                onChange={(e) =>
                                  props.onChange(idx, "height", e.target.value)
                                }
                                placeholder="Min 1cm"
                              />
                              <InputGroupText>cm</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                        </td>
                        <td>
                          <div className="increase__button_height">
                            <div>
                              <Button
                                onClick={props.handleAddClick}
                                className="btn-plus  mt-md-1"
                                size="sm"
                              >
                                <i class="fas fa-plus"></i>
                              </Button>
                            </div>
                            <div>
                              <Button
                                disabled={props.value.length == 1}
                                onClick={() => props.handleRemoveClick(idx)}
                                className="btn-minus "
                                size="sm"
                              >
                                <i class="fas fa-minus"></i>
                              </Button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <div className="borderless quote-itemdetails-table">
                {props.value &&
                  props.value.map((inp, idx) => (
                    <Row key={idx} className="align-items-center mb-1">
                      <Col md={3} sm={6}>
                        <Col>
                          <Label for="country">Weight</Label>
                        </Col>
                        <FormGroup className="mb-0">
                          <InputGroup>
                            <Input
                              type="number"
                              name="weight"
                              className="increase__height intl_item-detail"
                              required
                              min={0.3}
                              max={68}
                              step={0.1}
                              value={inp.weight}
                              onChange={(e) =>
                                props.onChange(idx, "weight", e.target.value)
                              }
                              placeholder="Min 0.3kg"
                            />
                            <InputGroupText>kg</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3} sm={6}>
                        <Col>
                          <Label for="country">Length</Label>
                        </Col>
                        <FormGroup className="mb-0">
                          <InputGroup>
                            <Input
                              type="number"
                              name="length"
                              className="increase__height intl_item-detail"
                              min={1}
                              max={274}
                              required
                              value={inp.length}
                              onChange={(e) =>
                                props.onChange(idx, "length", e.target.value)
                              }
                              placeholder="Min 1cm"
                            />
                            <InputGroupText>cm</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3} sm={6}>
                        <Col>
                          <Label for="country">Width</Label>
                        </Col>
                        <FormGroup className="mb-0">
                          <InputGroup>
                            <Input
                              type="number"
                              min={1}
                              name="width"
                              required
                              className="increase__height intl_item-detail"
                              value={inp.width}
                              onChange={(e) =>
                                props.onChange(idx, "width", e.target.value)
                              }
                              placeholder="Min 1cm"
                            />
                            <InputGroupText>cm</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={3} sm={6}>
                        <Col>
                          <Label for="country">Height</Label>
                        </Col>
                        <FormGroup className="mb-0">
                          <InputGroup>
                            <Input
                              type="number"
                              min={1}
                              name="height"
                              value={inp.height}
                              required
                              className="increase__height intl_item-detail"
                              onChange={(e) =>
                                props.onChange(idx, "height", e.target.value)
                              }
                              placeholder="Min 1cm"
                            />
                            <InputGroupText>cm</InputGroupText>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={2} sm={6}>
                        <div className="increase__button_height flex-row">
                          <Button
                            onClick={props.handleAddClick}
                            className="btn-plus mt-md-1"
                            size="sm"
                          >
                            <i className="fas fa-plus"></i>
                          </Button>
                          <Button
                            disabled={props.value.length === 1}
                            onClick={() => props.handleRemoveClick(idx)}
                            className="btn-minus"
                            size="sm"
                          >
                            <i className="fas fa-minus"></i>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </div>
            )}
          </Col>
        </Row>
        {windowsize <= 768 ? (
          <Row className="m-2">
            <Col
              md="4"
              sm="12"
              xs="4"
              align={windowsize <= 425 ? "center" : "right"}
              className="weight_col_intl text-left mb-3 px-0 weight_col_intl"
            >
              {/* <FormGroup check> */}
              {/* <Input
                  type='checkbox'
                  onChange={() => setIsChecked(!isChecked)}
                /> */}
              <Card className="p-2 item-detail-weight ">
                <span>Total Weight: {props.checkTotalWeight()} kg</span>
                <span>
                  Total Vol. Weight: {props.checkVolumetricWeight()} Kg
                </span>
                <span>Total Items: {props.value.length}</span>
              </Card>
              {/* <br />
                <span className='text-danger'>
                  * Items not insured for loss or damage
                </span> */}
              {/* </FormGroup> */}
            </Col>
            <Col sm="12" className="text-left px-0 mb-1">
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={props.insuranceData.insurance}
                  onChange={async (e) => {
                    props.insuranceChange("insurance", e.target.checked);
                  }}
                />
                <span className="text-white text-uppercase font-weight-bold itemdetails-text">
                  Insure your parcel/cargo against
                </span>
                <br />
                <span className="text-primary text-uppercase font-weight-bold itemdetails-text">
                  theft, loss or damage
                </span>
                {/* <span className='text-primary text-uppercase font-weight-bold'>
								Insure your parcel against theft, loss or damage
							</span>
							<br />
							<span className='text-danger ml-3 pointer font-weight-bold'>
								<a href={Globally_Prohibited_Items} download>
									* Prohibited goods
								</a>
							</span>
							<br />
							<span className='text-danger ml-3  font-weight-bold'>
								<a href={LossOrDamage} download>
									* Items not insured for loss or damage
								</a>
							</span> */}
              </FormGroup>
            </Col>
          </Row>
        ) : (
          <Row className="justify-content-md-end m-2">
            <Col sm="12" className="text-left px-0">
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={props.insuranceData.insurance}
                  onChange={async (e) => {
                    props.insuranceChange("insurance", e.target.checked);
                  }}
                />
                <span className="text-white text-uppercase font-weight-bold">
                  Insure your parcel/cargo against
                </span>
                <br />
                <span className="text-primary text-uppercase font-weight-bold">
                  theft, loss or damage
                </span>
                {/* <span className='text-primary text-uppercase font-weight-bold'>
								Insure your parcel against theft, loss or damage
							</span>
							<br />
							<span className='text-danger ml-3 pointer font-weight-bold'>
								<a href={Globally_Prohibited_Items} download>
									* Prohibited goods
								</a>
							</span>
							<br />
							<span className='text-danger ml-3  font-weight-bold'>
								<a href={LossOrDamage} download>
									* Items not insured for loss or damage
								</a>
							</span> */}
              </FormGroup>
            </Col>
            <Col
              md="4"
              sm="12"
              xs="4"
              align="right"
              className="weight_col_intl text-left mb-3 px-0 weight_col_intl"
            >
              {/* <FormGroup check> */}
              {/* <Input
                  type='checkbox'
                  onChange={() => setIsChecked(!isChecked)}
                /> */}
              <Card className="p-2 item-detail-weight ">
                <span>Total Weight: {props.checkTotalWeight()} kg</span>
                <span>
                  Total Vol. Weight: {props.checkVolumetricWeight()} Kg
                </span>
                <span>Total Items: {props.value.length}</span>
              </Card>
              {/* <br />
                <span className='text-danger'>
                  * Items not insured for loss or damage
                </span> */}
              {/* </FormGroup> */}
            </Col>
          </Row>
        )}
        <div
          className={`${
            props.insuranceData.insurance ? "d-block mb-5" : "d-none"
          }`}
        >
          <Row className="m-2 ">
            <Col md="3" className="text-left ">
              <FormGroup>
                <InputGroup>
                  <Input
                    placeholder="Insurance value R*"
                    className="route-item-insurance"
                    name="insurace value"
                    value={props.insuranceData.insuranceValue}
                    type="number"
                    onChange={async (e) => {
                      props.insuranceChange("insuranceValue", e.target.value);
                    }}
                    required={props.insuranceData.insurance}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-start m-2 ">
            <Col className="text-left" md="8">
              <span className="text-sm text-white font-weight-bold">
                Indicate your shipment value for insurance purposes.
                {/* <br />
								Any value over R30,000 contact us at{' '}
								<a
									href='mailto:support@shipmystuff.co.za'
									className='text-primary'
								>
									support@shipmystuff.co.za
								</a> */}
              </span>
            </Col>
          </Row>
        </div>

        {/* <div className={`${isChecked ? 'd-block' : 'd-none'}`}>
            <Row className='justify-content-center m-2 '>
              <Col md='5' className='text-left '>
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder='Item description'
                      className='route-item-description'
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md='7' className='text-left '>
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder='Insurance value R*'
                      className='route-item-insurance'
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div> */}
      </Col>
    </Row>
  );
}

export default ItemDetails;
