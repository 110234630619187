import React, { useEffect } from "react";
import { Fade } from "react-reveal";
import { Col, Container, Row } from "reactstrap";

function PrivacyPolicy() {
  //Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid className="terms_privacy privacy__policy  px-0">
        {/* <br />
				<br />
				<h2 className='text-center  text-primary term_privacy_heading'>
					Privacy Policy
				</h2>
				<hr /> */}
        <Row style={{ margin: 0 }}>
          <Col lg="12" className="mb--40 p-0">
            <div className={`section-title-bg text-center py-2 `}>
              <Fade bottom>
                <h1 className="title w-600 ">PRIVACY POLICY</h1>
              </Fade>
            </div>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col className={` text-center`}>
              <p className="description  mb-0 py-3">
                This is the privacy statement of Ship My Stuff (Pty) Ltd, “us”
                or “we”, refer to Ship My Stuff (Pty) Ltd.
                <br />
                Company number 2021/933469/07 incorporated in South Africa{" "}
                <br />
                Registered address is at Roodepoort, Johannesburg, South-Africa.
              </p>
              <hr className="m-0" />
            </Col>
          </Row>
          <div className="mt-4 mb-5 prohibted_guide ">
            {/* <p className='mb-0'>
						This is the privacy statement of Ship My Stuff (Pty)
						Ltd, “us” or “we”, refer to Ship My Stuff (Pty) Ltd.
					</p>
					<p className='mb-0'>
						Company number 2021/933469/07 incorporated in South
						Africa
					</p>
					<p className='mb-0'>
						Registered address is at Roodepoort, Johannesburg,
						South-Africa.
					</p> */}
            <div className="mt-4">
              <strong className="text-primary   main_heading __underline">
                Introduction
              </strong>
            </div>

            <br />
            <p>
              This privacy notice aims to inform you about how we collect and
              process any information that we collect from you, or that you
              provide to us. It covers information that could identify you
              (“personal information”) and information that could not. In the
              context of the law and this notice, “process” means collect,
              store, transfer, use or otherwise act on information. It tells you
              about your privacy rights and how the law protects you.
            </p>
            <p>
              We are committed to protecting your privacy and the
              confidentiality of your personal information. Our policy is not
              just an exercise in complying with the law, but a continuation of
              our respect for you and your personal information.
            </p>
            <p>
              We undertake to preserve the confidentiality of all information
              you provide to us and hope that you reciprocate.
            </p>
            <p>
              Our policy complies with the Protection of Personal Information
              Act 2013.
            </p>
            <p>
              The law requires us to tell you about your rights and our
              obligations to you in regard to the processing and control of your
              personal information.
            </p>
            <p>
              Except as set out below, we do not share, sell, or disclose to a
              third party, any information collected through our website.
            </p>
            <br />

            <strong className="text-primary main_heading">
              1.
              <strong className="ml-4 text-primary main_heading __underline">
                Information we process
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                We may collect, use, store and transfer different kinds of
                personal information about you. We have collated these into
                groups as follows:
              </p>
              <p>
                Your identity includes information such as first name, last
                name, title, date of birth, and other identifiable information
                that you may have provided at some time.
              </p>
              <p>
                Your contact information includes information such as billing
                address, delivery address, email address, telephone numbers and
                any other information you have given to us for the purpose of
                communication or meeting.
              </p>
              <p>
                Transaction information includes details about payments or
                communications to and from you and information about the
                products and services you have purchased from us.
              </p>
              <p>
                Technical information includes your internet protocol (IP)
                address, browser type and version, time zone setting and
                location, browser plug-in types and versions, operating system
                and platform and other technology on the devices you use to
                access this website.
              </p>
              <p>
                Marketing information includes your preferences in receiving
                marketing from us; communication preferences; responses, and
                actions in relation to your use of our services.
              </p>
              <p>
                We may aggregate anonymous information such as statistical or
                demographic data for any purpose. Anonymous information is
                information that does not identify you as an individual.
                Aggregated information may be derived from your personal
                information but is not considered personal information in law
                because it does not reveal your identity.
              </p>
              <p>
                For example, we may aggregate profile information to assess
                interest in a product or service.
              </p>
              <p>
                However, if we combine or connect aggregated data with your
                personal information so that it can identify you in any way, we
                treat the combined data as personal information, and it will be
                used in accordance with this privacy notice.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              2.
              <strong className="ml-3 text-primary main_heading __underline">
                Special personal information
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Special personal information is data about your race or
                ethnicity, religious or philosophical beliefs, sex life,
                political opinions, trade union membership, information about
                your health and biometric data.
              </p>
              <p>
                We do not collect any special personal information about you.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              3.
              <strong className="ml-4 text-primary main_heading __underline">
                If you do not provide personal information we need
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Where we need to collect personal information by law, or under
                the terms of a contract, we have with you, and you fail to
                provide that information when requested, we may not be able to
                perform that contract. In that case, we may have to stop
                providing a service to you. If so, we will notify you of this at
                the time.
              </p>
            </div>
            <h4 className="text-primary">
              <u>The bases on which we process information about you</u>
            </h4>
            <br />
            <strong className="text-primary main_heading">
              4.
              <strong className="ml-4 text-primary main_heading __underline">
                Information we process because we have a contractual obligation
                with you
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                When you create an account on our website, buy a product or
                service from us, or otherwise agree to our terms and conditions,
                a contract is formed between you and us.
              </p>
              <p>
                In order to carry out our obligations under that contract, we
                must process the information you give us. Some of this
                information may be personal information.
              </p>
              <p>We may use it in order to:</p>
              <ul className="terms_privacy_ul">
                <li>verify your identity for security purposes</li>
                <li>sell products to you</li>
                <li>provide you with our services</li>
                <li>
                  provide you with suggestions and advice on products, services
                  and how to obtain the most from using our website
                </li>
              </ul>
              <p>
                We process this information on the basis there is a contract
                between us, or that you have requested we use the information
                before we enter into a legal contract.
              </p>
              <p>
                We shall continue to process this information until the contract
                between us ends or is terminated by either party under the terms
                of the contract.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              5.
              <strong className="ml-4 text-primary main_heading __underline">
                Information we process with your consent
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Through certain actions when otherwise there is no contractual
                relationship between us, such as when you browse our website or
                ask us to provide you more information about our business,
                including our products and services, you provide your consent to
                us to process information that may be personal information.
              </p>
              <p>
                Wherever possible, we aim to obtain your explicit consent to
                process this information, for example, by asking you to agree to
                our use of cookies.
              </p>
              <p>
                If you have given us explicit permission to do so, we may from
                time to time, pass your name and contact information to selected
                associates whom we consider may provide services or products you
                would find useful.
              </p>
              <p>
                We continue to process your information on this basis until you
                withdraw your consent or it can be reasonably assumed that your
                consent no longer exists.
              </p>
              <p>
                You may withdraw your consent at any time by instructing us at
                info@shipmystuff.co.za. However, if you do so, you may not be
                able to use our website or our services further.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              6.
              <strong className="ml-4 text-primary main_heading __underline">
                Information we process for the purposes of legitimate interests
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                We may process the information on the basis there is a
                legitimate interest, either to you or to us, of doing so.
              </p>
              <p>
                Where we process your information on this basis, we do after
                having given careful consideration for the following non
                exhaustive list of purposes:
              </p>
              <ul className="terms_privacy_ul">
                <li>
                  whether the same objective could be achieved through other
                  means
                </li>
                <li>
                  whether processing (or not processing) might cause you harm
                </li>
                <li>
                  whether you would expect us to process your information, and
                  whether you would, in the round, consider it reasonable to do
                  so
                </li>
                <li>Fraud prevention and detection</li>
                <li>Market research and statistical analysis</li>
                <li>Audit & record keeping</li>
                <li>Assessment and processing of claims and complaints</li>
                <li>Development and improvement of products and services</li>
              </ul>
              <p>
                For example, we may process your information on this basis for
                the purposes of:
              </p>
              <ul className="terms_privacy_ul">
                <li>
                  record-keeping for the proper and necessary administration of
                  our business
                </li>
                <li>
                  responding to unsolicited communication from you to which we
                  believe you would expect a response
                </li>
                <li>protecting and asserting the legal rights of any party</li>
                <li>
                  insuring against or obtaining professional advice that is
                  required to manage business risk
                </li>
                <li>
                  protecting your interests where we believe we have a duty to
                  do so
                </li>
              </ul>
            </div>
            <br />
            <strong className="text-primary main_heading">
              7.
              <strong className="ml-4 text-primary main_heading __underline">
                Information we process because we have a legal obligation
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Sometimes, we must process your information in order to comply
                with a statutory obligation.
              </p>
              <p>
                For example, we may be required to give information to legal
                authorities if they so request or if they have the proper
                authorization such as a search warrant or court order.
              </p>
              <p>This may include your personal information.</p>
            </div>
            <h4 className="text-primary">
              <u>Specific uses of information you provide to us</u>
            </h4>
            <br />
            <strong className="text-primary main_heading">
              8.
              <strong className="ml-4 text-primary main_heading __underline">
                Information provided on the understanding that it will be shared
                with a third party
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Our website allows you to post information with a view to that
                information being read, copied, downloaded, or used by other
                people.
              </p>
              <p>Examples include:</p>
              <ul className="terms_privacy_ul">
                <li>posting a message on our forum</li>
                <li>tagging an image</li>
                <li>
                  clicking on an icon next to another visitor’s message to
                  convey your agreement, disagreement or thanks
                </li>
              </ul>
              <p>
                In posting personal information, it is up to you to satisfy
                yourself about the privacy level of every person who might use
                it.
              </p>
              <p>
                We do store it, and we reserve a right to use it in the future
                in any way we decide.
              </p>
              <p>
                Once your information enters the public domain, we have no
                control over what any individual third party may do with it. We
                accept no responsibility for their actions at any time.
              </p>
              <p>
                Provided your request is reasonable and there is no legal basis
                for us to retain it, then at our discretion, we may agree to
                your request to delete personal information that you have
                posted. You can make a request by contacting us at
                info@shipmystuff.co.za.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              9.
              <strong className="ml-4 text-primary main_heading __underline">
                Complaints regarding content on our website
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                We attempt to moderate user generated content, but we are not
                always able to do so as soon as that content is published.
              </p>
              <p>
                If you complain about any of the content on our website, we
                shall investigate your complaint.
              </p>
              <p>
                If we feel it is justified or if we believe the law requires us
                to do so, we shall remove the content while we investigate.
              </p>
              <p>
                Free speech is a fundamental right, so we have to make a
                judgment as to whose right will be obstructed: yours, or that of
                the person who posted the content that offends you.
              </p>
              <p>
                If we think your complaint is vexatious or without any basis, we
                shall not correspond with you about it.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              10.
              <strong className="ml-4 text-primary main_heading __underline">
                Information relating to your method of payment
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Payment information is never taken by us or transferred to us
                either through our website or otherwise. Our employees and
                contractors never have access to it.
              </p>
              <p>
                At the point of payment, you are transferred to a secure page on
                the website of [WorldPay / OPayo / PayPal] or some other
                reputable payment service provider. That page may be branded to
                look like a page on our website, but it is not controlled by us.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              11.
              <strong className="ml-4 text-primary main_heading __underline">
                Job application and employment
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                If you send us information in connection with a job application,
                we may keep it for up to 1 year in case we decide to contact you
                at a later date.
              </p>
              <p>
                If we employ you, we collect information about you and your work
                from time to time throughout the period of your employment. This
                information will be used only for purposes directly relevant to
                your employment. After your employment has ended, we will keep
                your file for 1 year before destroying or deleting it.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              12.
              <strong className="ml-4 text-primary main_heading __underline">
                Communicating with us
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                When you contact us, whether by telephone, through our website
                or by e-mail, we collect the information you have given to us in
                order to reply with the information you need.
              </p>
              <p>
                We record your request and our reply in order to increase the
                efficiency of our business.
              </p>
              <p>
                We keep personally identifiable information associated with your
                messages, such as your name and email address so as to be able
                to track our communications with you to provide a high-quality
                service.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              13.
              <strong className="ml-4 text-primary main_heading __underline">
                Complaining
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                When we receive a complaint, we record all the information you
                have given to us.
              </p>
              <p>We use that information to resolve your complaint.</p>
              <p>
                If your complaint reasonably requires us to contact some other
                person, we may decide to give to that other person some of the
                information contained in your complaint. We do this as
                infrequently as possible, but it is a matter for our sole
                discretion as to whether we do give information, and if we do,
                what that information is.
              </p>
              <p>
                We may also compile statistics showing information obtained from
                this source to assess the level of service we provide, but not
                in a way that could identify you or any other person.
              </p>
            </div>
            <h4 className="text-primary">
              <u>
                Use of information we collect through automated systems when you
                visit our website
              </u>
            </h4>
            <br />
            <strong className="text-primary main_heading">
              14.
              <strong className="ml-4 text-primary main_heading __underline">
                Cookies
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Cookies are small text files that are placed on your computer's
                hard drive by your web browser when you visit any website. They
                allow information gathered on one web page to be stored until it
                is needed for use on another, allowing a website to provide you
                with a personalized experience and the website owner with
                statistics about how you use the website so that it can be
                improved.
              </p>
              <p>
                Some cookies may last for a defined period, such as one day or
                until you close your browser. Others last indefinitely.
              </p>
              <p>
                Your web browser should allow you to delete any you choose. It
                also should allow you to prevent or limit their use.
              </p>
              <p>
                Our website uses cookies. They are placed by software that
                operates on our servers, and by software operated by third
                parties whose services we use.
              </p>
              <p>
                When you first visit our website, we ask you whether you wish us
                to use cookies. If you choose not to accept them, we shall not
                use them for your visit except to record that you have not
                consented to their use for any other purpose.
              </p>
              <p>
                If you choose not to use cookies or you prevent their use
                through your browser settings, you will not be able to use all
                the functionality of our website.
              </p>
              <p>We use cookies in the following ways:</p>
              <ul className="terms_privacy_ul">
                <li>to track how you use our website</li>
                <li>
                  to record whether you have seen specific messages we display
                  on our website
                </li>
                <li>to keep you signed in to our website</li>
                <li>
                  to record your answers to surveys and questionnaires on our
                  website while you complete them
                </li>
                <li>
                  to record the conversation thread during a live chat with our
                  support team
                </li>
              </ul>
            </div>
            <br />
            <strong className="text-primary main_heading">
              15.
              <strong className="ml-4 text-primary main_heading __underline">
                Personal identifiable information from your browsing activity
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Requests by your web browser to our servers for web pages and
                other content on our website are recorded.
              </p>
              <p>
                We record information such as your geographical location, your
                Internet service provider and your IP address. We also record
                information about the software you are using to browse our
                website, such as the type of computer or device and the screen
                resolution.
              </p>
              <p>
                We use this information in aggregate to assess the popularity of
                the webpages on our website and how we perform in providing
                content to you.
              </p>
              <p>
                If combined with other information we know about you from
                previous visits, the information possibly could be used to
                identify you personally, even if you are not signed in to our
                website.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              16.
              <strong className="ml-4 text-primary main_heading __underline">
                Our use of re-marketing
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Re-marketing involves placing a cookie on your computer when you
                browse our website in order to be able to serve you an advert
                for our products or services when you visit some other website.
              </p>
              <p>
                We may use a third party to provide us with re-marketing
                services from time to time. If so, then if you have consented to
                our use of cookies, you may see advertisements for our products
                and services on other websites.
              </p>
            </div>
            <h4 className="text-primary">
              <u>Disclosure and sharing of your information</u>
            </h4>
            <br />
            <strong className="text-primary main_heading">
              17.
              <strong className="ml-4 text-primary main_heading __underline">
                Information we obtain from third parties
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Although we do not disclose your personal information to any
                third party (except as set out in this notice), we sometimes
                receive information that is indirectly made up from your
                personal information from third parties whose services we use.
              </p>
              <p>No such information is personally identifiable to you.</p>
            </div>
            <br />
            <strong className="text-primary main_heading ">
              18.
              <strong className="ml-4 text-primary main_heading __underline">
                Third-party advertising on our website
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Third parties may advertise on our website. In doing so, those
                parties, their agents or other companies working for them may
                use technology that automatically collects information about you
                when their advertisement is displayed on our website.
              </p>
              <p>
                They may also use other technology such as cookies or JavaScript
                to personalize the content of, and to measure the performance of
                their adverts.
              </p>
              <p>
                We do not have control over these technologies or the
                information that these parties obtain. Accordingly, this privacy
                notice does not cover the information practices of these third
                parties.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              19.
              <strong className="ml-4 text-primary main_heading __underline">
                Credit reference
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                To assist in combating fraud, we share information with credit
                reference agencies, so far as it relates to clients or customers
                who instruct their credit card issuer to cancel payment to us
                without having first provided an acceptable reason to us and
                given us the opportunity to refund their money.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              20.
              <strong className="ml-4 text-primary main_heading __underline">
                Information may be processed outside South Africa
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>Our websites are hosted in United States of America.</p>
              <p>
                We may also use outsourced services in countries outside South
                Africa from time to time in other aspects of our business.
              </p>
              <p>
                Accordingly, information obtained within South Africa or any
                other country could be processed outside South Africa.
              </p>
              <p>
                For example, some of the software our website uses may have been
                developed in the United States of America or Australia.
              </p>
            </div>
            <h4 className="text-primary">
              <u>Control over your own information</u>
            </h4>
            <br />
            <strong className="text-primary main_heading">
              21.
              <strong className="ml-4 text-primary main_heading __underline">
                Your duty to inform us of changes
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                It is important that the personal information we hold about you
                is accurate and current. Please keep us informed if your
                personal information changes.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              22.
              <strong className="ml-4 text-primary main_heading __underline">
                Access to your personal information
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                At any time, you may review or update personally identifiable
                information that we hold about you, by signing in to your
                account on our website.
              </p>
              <p>
                To obtain a copy of any information that is not provided on our
                website you should contact us to make that request.
              </p>
              <p>
                After receiving the request, we will tell you when we expect to
                provide you with the information, and whether we require any fee
                for providing it to you.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              23.
              <strong className="ml-4 text-primary main_heading __underline">
                Removal of your information
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                If you wish us to remove personally identifiable information
                from our website, you should contact us to make your request.
              </p>
              <p>This may limit the service we can provide to you.</p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              24.
              <strong className="ml-4 text-primary main_heading __underline">
                Verification of your information
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                When we receive any request to access, edit or delete personal
                identifiable information we shall first take reasonable steps to
                verify your identity before granting you access or otherwise
                taking any action. This is important to safeguard your
                information.
              </p>
            </div>
            <h4 className="text-primary">
              <u>Other matters</u>
            </h4>
            <br />
            <strong className="text-primary main_heading">
              25.
              <strong className="ml-4 text-primary main_heading __underline">
                Use of website by children
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                We do not sell products or provide services for purchase by
                children, nor do we market to children.
              </p>
              <p>
                If you are under 18, you may use our website only with consent
                from a parent or guardian
              </p>
              <p>
                We collect information about all users of and visitors to these
                areas regardless of age, and we anticipate that some of those
                users and visitors will be children.
              </p>
              <p>
                Such child users and visitors will inevitably visit other parts
                of the website and will be subject to whatever on-site marketing
                they find, wherever they visit.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              26.
              <strong className="ml-4 text-primary main_heading __underline">
                Encryption of data sent between us
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                We use Secure Sockets Layer (SSL) certificates to verify our
                identity to your browser and to encrypt any data you give us.
              </p>
              <p>
                Whenever information is transferred between us, you can check
                that it is done so using SSL by looking for a closed padlock
                symbol or another trust mark in your browser’s URL bar or
                toolbar.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              27.
              <strong className="ml-4 text-primary main_heading __underline">
                How you can complain
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                If you are not happy with our privacy policy or if you have any
                complaint then you should tell us.
              </p>
              <p>
                You can find further information about our complaint handling
                procedure at complaints@shipmystuff.co.za.
              </p>
              <p>
                If a dispute is not settled, then we hope you will agree to
                attempt to resolve it by engaging in good faith with us in the
                process of mediation or arbitration.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              28.
              <strong className="ml-4 text-primary main_heading __underline">
                Retention period for personal data
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Except as otherwise mentioned in this privacy notice, we keep
                your personal information only for as long as required by us:
              </p>
              <ul className="terms_privacy_ul">
                <li>to provide you with the services you have requested</li>
                <li>
                  to comply with other law, including for the period demanded by
                  our tax authorities
                </li>
                <li>to support a claim or defence in court</li>
              </ul>
            </div>
            <br />
            <strong className="text-primary main_heading">
              29.
              <strong className="ml-4 text-primary main_heading __underline">
                Compliance with the law
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                Our privacy policy has been compiled so as to comply with the
                law of every country or legal jurisdiction in which we aim to do
                business. If you think it fails to satisfy the law of your
                jurisdiction, we should like to hear from you.
              </p>
              <p>
                However, ultimately it is your choice as to whether you wish to
                use our website.
              </p>
            </div>
            <br />
            <strong className="text-primary main_heading">
              30.
              <strong className="ml-4 text-primary main_heading __underline">
                Review of this privacy policy
              </strong>
            </strong>

            <br />
            <br />
            <div className="ml-4 ml-md-5">
              <p>
                We may update this privacy notice from time to time as
                necessary. The terms that apply to you are those posted here on
                our website on the day you use our website. We advise you to
                print a copy for your records.
              </p>
              <p>
                If you have any question regarding our privacy policy, please
                contact us.
              </p>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
