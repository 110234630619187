import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Label,
  Input,
  FormGroup,
} from "reactstrap";

import Payment from "../../assets/img/payment.png";
import Calendar from "../../assets/img/Calendar.png";
import DeliveryDestination from "../../assets/img/DeliveryDestination.png";
import ProcessList1 from "../ProcessList1";
import moment from "moment";
import Packaging_Guidelines from "../../assets/docs/Packaging_Guidelines.pdf";
import Globally_Prohibited_Items from "../../assets/docs/Globally_Prohibited_Items.pdf";
import InternationalActiveTab from "./InternationalActiveTab";
import ServicesHeader from "../ServicesHeader";

function Calender(props) {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);
  const updatePredicate = () => {
    setIsMobile(window.innerWidth < 767);
  };
  return (
    <>
      <div className="mx-4">
        <ServicesHeader value="INTERNATIONAL COURIER" />
        <InternationalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
      </div>

      <ProcessList1 activeIdx={0} />
      <hr className="process_list_intl_hr" />
      <Row className="m-lg-3  quote-calender-component ">
        <Col md="4" xs="12" align="left" className="text-left ">
          <FormGroup>
            <Label color="primary">Select Collection Date</Label>
            <Input
              name="calenderDate"
              type="date"
              min={moment().format("YYYY-MM-DD")}
              className="w-75"
              onChange={(e) => props.onChange({ date: e.target.valueAsDate })}
            />
          </FormGroup>
        </Col>
        <Col md="8" align="right" className="text-left mb-3">
          <Card className="px-2 quote__calendar">
            <CardBody className="line__height">
              <Label className="mb-2">
                <span className="text-danger add-delivery-opt-Calender">
                  *Important Information
                </span>
              </Label>
              <ul>
                <li className="text-secondary">
                  Ensure the selected date is correct
                </li>
                <li className="text-secondary">
                  Your package should be ready at least 30 min before the driver
                  arrives
                </li>
                <li className="text-secondary">
                  Drivers will only wait 10 min at the collection address
                </li>
                <li className="text-secondary">
                  Courier companies will not assist with additional
                  handling/carrying requests
                </li>
                <li className="text-secondary">
                  Refer to our packing guide to ensure your parcel/cargo’s
                  safety
                </li>
                {/* <li className='text-secondary '>
									Ensure Correct date is selected.
								</li>
								<li className='text-secondary'>
									Ensure your package is ready for before
									collection 30 minutes before the driver
									arrives
								</li>
								<li className='text-secondary'>
									Download our{' '}
									<a
										href={Globally_Prohibited_Items}
										download
									>
										<u>
											{' '}
											<strong className='text-primary'>
												{' '}
												prohibited items{' '}
											</strong>{' '}
										</u>{' '}
									</a>
									for more information
								</li>
								<li className='text-secondary'>
									Download our{' '}
									<a href={Packaging_Guidelines} download>
										<u>
											{' '}
											<strong className='text-primary'>
												{' '}
												packing guide{' '}
											</strong>{' '}
										</u>{' '}
									</a>
									for more information
								</li> */}
              </ul>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Row className='m-5'>
				<Col
					md='3'
					align='right'
					className='text-left ml-5 mt-sm-2 mt-md-0 quote-calender-component'
				>
					<FormGroup>
						<Label color='secondar'>Select Collection Date</Label>
						<Input
							name='calenderDate'
							type='date'
							min={moment().format('YYYY-MM-DD')}
							className='w-80'
							onChange={e =>
								props.onChange({ date: e.target.valueAsDate })
							}
						/>
					</FormGroup>
				</Col>
				<Col md='4' align='right' className='text-left mt-sm-2 mt-md-0'>
					<Input
						type='textarea'
						placeholder='text box placeholder'
						rows='4'
						className='card'
						onChange={e => props.onChange({ text: e.target.value })}
					/>
				</Col>
			</Row> */}
    </>
  );
}

export default Calender;
