import React, { useEffect, useState } from 'react';
import firebase from '../../config/firebase';
import { Container, Row, Col, Table, Button, Spinner } from 'reactstrap';
import Logo from '../../assets/img/logo.png';
import moment from 'moment';

function LocalInvoice(props) {
	const [invoiceDetails, setInvoiceDetails] = useState('');
	const handlePrint = divPrint => {
		var printContents = document.getElementById(divPrint).innerHTML;
		var originalContents = document.body.innerHTML;

		document.body.innerHTML = printContents;

		window.print();

		document.body.innerHTML = originalContents;
		window.location.reload();
	};

	const formatNumber = num => {
		return num.toLocaleString('en', {
			useGrouping: false,
			minimumFractionDigits: 2,
		});
	};
	const getFormattedInvoiceNumber = str => {
		return str.replace(/\s+/g, '');
	};

	useEffect(() => {
		firebase
			.firestore()
			.collection(`${process.env.REACT_APP_LOCAL_ORDER}`)
			.doc(props.match.params.id)
			.onSnapshot(query => {
				setInvoiceDetails(query.data());
			});
	}, []);

	return (
		<>
			{invoiceDetails ? (
				<>
					<Row className='justify-content-center m-2'>
						<Col md='3' xs='6'>
							<Button
								onClick={() => handlePrint('printMe')}
								block
								color='primary'
							>
								PRINT / DOWNLOAD
							</Button>
						</Col>
					</Row>

					<Container
						id='printMe'
						fluid
						className='invoice-component local_invoice'
					>
						<hr />
						<Row className='justify-content-center mx-2 '>
							<Col md='6' className='mt-3 mb-3 invoice_img_col'>
								<img
									src={Logo}
									// height='150px'
									className='img-fluid'
								/>
							</Col>
							<Col
								md='4'
								className='mt-3 mb-3 ml-3 text-right local_invoice_details'
							>
								<span>Ship My Stuff (Pty) Ltd</span>
								<br />
								<span>2021/933469/07</span>
								{/* <br /> <span>Roodepoort</span> */}
								<br />
								<span>Johannesburg</span> <br />
								<span>South Africa</span> <br />
								<span>1724</span> <br />
								{/* <span>067 888 7138</span> */}
								<span>accounts@shipmystuff.co.za</span>
							</Col>
						</Row>
						<div className='mx-4 mt-2'>
							<Row className='py-2 invoice-header border-0 text-white tr__print'>
								<Col className='text-center'>
									<span className='local_invoice_header'>
										BILLING DETAILS
									</span>
								</Col>
								<Col className='text-center'>
									<span className='local_invoice_header'>
										INVOICE DETAILS
									</span>
								</Col>
							</Row>
							<Row className=' bg-white local_invoice_body'>
								<Col className='text-left'>
									<Row className='px-2 mt-2'>
										<Col md='6' xs='8' align='end'>
											<span>Name:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingFirstName
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Surname:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingSurname
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Contact Number:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingContactNumber
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Email Address:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingEmailAddress
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Company Name:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingCompanyName
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Vat Number:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingVatNumber
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Address 1:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingAddress1
												}
											</span>
										</Col>
									</Row>
									{/* <Row>
                                <Col md="5" xs="8" align="end">
                                    <span className="text-nowrap">
                                        Complex / Business Park / Estate:
                                    </span>
                                </Col>
                                <Col md="5" xs="4">
                                    <span>

                                    </span>
                                </Col>
                            </Row> */}
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Suburb:</span>
										</Col>
										<Col md='6' xs='4'>
											<span className='text-nowrap'>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingSuburb
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2 '>
										<Col md='6' xs='8' align='end'>
											<span>Postal Code / Zip Code:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingPostalCode
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2 '>
										<Col md='6' xs='8' align='end'>
											<span>City:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingSuburb
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2 mb-2'>
										<Col md='6' xs='8' align='end'>
											<span>Country:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.BillingInformation
														?.BillingCountry
												}
											</span>
										</Col>
									</Row>
								</Col>
								<Col className='text-left '>
									<Row className='px-2 mt-2'>
										<Col md='6' xs='8' align='end'>
											<span>Invoice Number:</span>
										</Col>
										<Col md='6' xs='4'>
											<span className='text-nowrap'>
												{
													invoiceDetails?.Invoice
														?.InvoiceInformation
														?.InvoiceNumber
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Invoice Date:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{
													invoiceDetails?.Invoice
														?.InvoiceInformation
														?.InvoiceDate
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Insurance Requested:</span>
										</Col>
										<Col md='6' xs='4'>
											<span>
												{invoiceDetails
													?.ShipmentManifest
													?.ShipmentHeader
													?.InsuranceRequested
													? 'Yes'
													: 'No'}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Service:</span>
										</Col>
										<Col md='6' xs='4'>
											<span className='text-uppercase'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.CarrierInformation
														?.ServiceSelected
												}
											</span>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>

						<Row className='mx-2 invoice-details mt-3'>
							<Col>
								<Row className='py-2 invoice-header border-0 tr__print'>
									<Col className='text-center text-white'>
										<span className='local_invoice_header'>
											{' '}
											SHIPMENT DETAILS
										</span>
									</Col>
								</Row>
								<Row className='py-1 mt-2 text-secondary local_invoice_sub_header border-0 td__print'>
									<Col className='text-center'>
										<span className='local_invoice_header'>
											SHIPPER
										</span>
									</Col>
									<Col className='text-center'>
										<span className='local_invoice_header'>
											CONSIGNEE
										</span>
									</Col>
								</Row>
								<Row className=' invoice-data border-0 bg-white local_invoice_body'>
									<Col className='text-left'>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Name:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperName
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Contact Number:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperContactNumber
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Email Address:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperEmailAddress
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Address 1:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperAddress1
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span className='text-nowrap'>
													Address 2:
												</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperAddress2
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Suburb:</span>
											</Col>
											<Col md='6' xs='4'>
												<span className='text-nowrap'>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperSuburb
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Region:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperCountry
													}
												</span>
											</Col>
										</Row>

										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Postal / Zip Code:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperPostalCodeZipCode
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>City:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperCity
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2 mb-2'>
											<Col md='6' xs='8' align='end'>
												<span>Country:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>SOUTH AFRICA</span>
											</Col>
										</Row>
									</Col>
									<Col className='text-left '>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Name:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeName
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Contact Number:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeContactNumber
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Email Address:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeEmailAddress
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Address 1:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeAddress1
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span className='text-nowrap'>
													Address 2:
												</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeAddress2
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Suburb:</span>
											</Col>
											<Col md='6' xs='4'>
												<span className='text-nowrap'>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeSuburb
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Region:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeCountry
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2'>
											<Col md='6' xs='8' align='end'>
												<span>Postal / Zip Code:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneePostalCodeZipCode
													}
												</span>
											</Col>
										</Row>
										<Row className='px-2'>
											<Col md='6' xs='8' align='end'>
												<span>City:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>
													{
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeCity
													}
												</span>
											</Col>
										</Row>
										<Row className=' px-2 mb-2'>
											<Col md='6' xs='8' align='end'>
												<span>Country:</span>
											</Col>
											<Col md='6' xs='4'>
												<span>SOUTH AFRICA</span>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className='mx-2 py-2 invoice-header border-0 tr__print'>
							<Col className='text-white text-center '>
								<span className='local_invoice_header'>
									BILLING ITEMS
								</span>
							</Col>
							{/* <Col className="text-white">SERVICE TYPE: <strong className="text-dark">({invoiceDetails.order_details.type})</strong></Col> */}
						</Row>
						<div className='mx-2 mt-2'>
							<Table
								responsive
								className='local_invoice_table border-0 mb-0'
							>
								<thead className='td__print py-1 mt-2 text-secondary local_invoice_sub_header border-0  '>
									<th className=' text-center'>
										{' '}
										ITEM DESCRIPTION
									</th>
									<th className='  text-center'> WEIGHT </th>

									<th className=' text-center'>
										{' '}
										DIMENSIONS{' '}
									</th>
									<th className=' text-center'>
										{' '}
										VAT AMOUNT
									</th>
									<th className='  text-center'> AMOUNT</th>
								</thead>
								<tbody>
									{invoiceDetails?.ShipmentManifest?.CargoInformation?.Items.map(
										(itd, idx) => (
											<tr>
												<td className='text-center text-capitalize '>
													Domestic Courier:{' '}
													{
														invoiceDetails
															?.ShipmentManifest
															?.CarrierInformation
															?.ServiceSelected
													}
												</td>
												<td className='text-center'>
													{itd.PhysicalWeight}
													Kg
												</td>
												<td className='text-center '>
													{itd.Length}x{itd.Width}x
													{itd.Height}
												</td>
												<td className='text-center '>
													R0.00
												</td>
												<td className='text-center  '>
													R{' '}
													{formatNumber(
														Number(
															invoiceDetails
																?.Invoice
																?.InvoiceInformation
																?.InvoiceAmount
														) /
															invoiceDetails
																?.ShipmentManifest
																?.CargoInformation
																?.Items.length
													)}
													{/* {invoiceDetails &&
															invoiceDetails.order_details &&
															invoiceDetails
																.order_details
																.courier &&
															formatNumber(
																invoiceDetails
																	.order_details
																	.courier
																	.TotalRate /
																	invoiceDetails
																		.Loc_Shipments
																		.itemDetails
																		.items
																		.length
															)} */}
												</td>
											</tr>
										)
									)}
								</tbody>
							</Table>
						</div>

						<Row className='mx-2 pt-4 pb-3'>
							<Col md='5' className='text-left'>
								{/* <span className='text-nowrap local_bottom_text'>
									Waybill / Tracking Number: (Courier API
									Tracking/booking number)
								</span>
								<br /> */}
								<span className='text-nowrap local_bottom_text'>
									Any account queries mail us at{' '}
									<a href='mailto:accounts@shipmystuff.co.za'>
										accounts@shipmystuff.co.za
									</a>
								</span>
							</Col>
							<Col md='7'>
								<Row className='justify-content-end '>
									<Col
										md='3'
										className='bg-white invoice-total local_bottom_text'
									>
										VAT:
									</Col>
									<Col
										md='3'
										className='local-invoice-total-extra text-center td__print  invoice-total local_bottom_text'
									>
										0.00
									</Col>
								</Row>
								<Row className='justify-content-end '>
									<Col
										md='3'
										className='bg-white invoice-total local_bottom_text'
									>
										SUBTOTAL (ZAR):{' '}
									</Col>
									<Col
										md='3'
										className='text-center local-invoice-total-extra td__print  invoice-total local_bottom_text'
									>
										{
											invoiceDetails?.Invoice
												?.InvoiceInformation
												?.InvoiceAmount
										}
									</Col>
								</Row>
								<Row className='justify-content-end  '>
									<Col
										md='3'
										className='bg-white invoice-total local_bottom_text'
									>
										TOTAL (ZAR):{' '}
									</Col>
									<Col
										md='3'
										className='text-center local-invoice-total-extra td__print  invoice-total local_bottom_text'
									>
										{
											invoiceDetails?.Invoice
												?.InvoiceInformation
												?.InvoiceAmount
										}
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</>
			) : (
				<div className='loader-div'>
					<Spinner size='lg' />
				</div>
			)}
		</>
	);
}

export default LocalInvoice;
