import React from "react";
import { FormGroup, Input } from "reactstrap";
import inventory from "../../assets/docs/inventory.pdf";

const TotalCbm = () => {
  return (
    <div className="d-flex flex-sm-row flex-column w-100 ">
      <div className="mr-3 w-100">
        <p className="sea-freight__label">CBM</p>
        <FormGroup className="mb-0">
          <Input
            type="number"
            // value={details?.cbm}
            className="w-100"
            // onChange={(e) => handleBoxChange("cbm", e.target.value, index)}
          />
        </FormGroup>
      </div>

      <div className="mr-3 w-100 mt-3 mt-md-0 mt-lg-0 mt-sm-0">
        <p className="sea-freight__label text-primary ">
          Need Help calculating your CBM?
        </p>
        <div className="total-cbm__card w-100 p-3">
          <p className="mb-0 text-start">
            Download our
            <a className="text-primary mx-1" href={inventory} download>
              inventory list
            </a>
            to help calculate your shipment's
            total cubic meter.
            {/* <a className="text-primary">Download.</a> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TotalCbm;
