import React, { useState } from "react";
import { Row,Col } from "react-bootstrap";
import { Container } from "reactstrap";

import Class1 from '../assets/img/Class1.png';
import Class2 from '../assets/img/Class2.png';
import Class3 from '../assets/img/Class3.png';
import Class4 from '../assets/img/Class4.png';
import Class5 from '../assets/img/Class5.png';
import Class6 from '../assets/img/Class6.png';
import Class7 from '../assets/img/Class7.png';
import Class8 from '../assets/img/Class8.png';
import Class9 from '../assets/img/Class9.png';


function Hazardous() {
  const [activeClass, setActiveClass] = useState({
    1: true,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
  });

  const handleClick = (num) => {
    setActiveClass({
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      [num]: true,
    });
  };
  return (
    <>
      <Container  >
        <Row>
          <Col className="d-flex justify-content-center" lg="3" xs="6">
              <div
                className={`${
                  activeClass[1] && "active-bg"
                }  hazardous-item rounded-lg p-4  my-3 `}
                onClick={() => {
                  handleClick(1);
                }}
              >
                <h3
                  className={`heading mb-2`}
                >
                  CLASS 1
                </h3>
                <img
                  className="hazardous-image"
                  src={Class1}
                  alt="class1"
                />
              </div>
          </Col>
          <Col lg="3" xs="6" className="d-flex justify-content-center">
          <div
            className={`${
              activeClass[2] && "active-bg"
            }  hazardous-item rounded-lg p-4 my-3`}
            onClick={() => {
              handleClick(2);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 2
            </h3>
            <img
              className="hazardous-image"
              src={Class2}
              alt="class2"
            />
          </div>
          </Col>
          <Col lg="3" xs="6" className="d-flex justify-content-center">
          <div
            className={`${
              activeClass[3] && "active-bg"
            }  hazardous-item rounded-lg p-4 my-3`}
            onClick={() => {
              handleClick(3);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 3
            </h3>
            <img
              className="hazardous-image"
              src={Class3}
              alt="class3"
            />
          </div>
          </Col>
          <Col lg="3" xs="6" className="d-flex justify-content-center">
          <div
            className={`${
              activeClass[4] && "active-bg"
            }  hazardous-item rounded-lg p-4 my-3`}
            onClick={() => {
              handleClick(4);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 4
            </h3>
            <img
              className="hazardous-image"
              src={Class4}
              alt="class4"
            />
          </div>
          </Col>

        </Row>
        <Row>
            <Col lg="3" xs="6" className="d-flex justify-content-center">
            <div
            className={`${
              activeClass[5] && "active-bg"
            }  hazardous-item rounded-lg p-4 my-3`}
            onClick={() => {
              handleClick(5);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 5
            </h3>
            <img
              className="hazardous-image"
              src={Class5}
              alt="class5"
            />
          </div>
            </Col>
            <Col lg="3" xs="6" className="d-flex justify-content-center">
            <div
            className={`${
              activeClass[6] && "active-bg"
            }   hazardous-item rounded-lg p-4 my-3`}
            onClick={() => {
              handleClick(6);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 6
            </h3>
            <img
              className="hazardous-image"
              src={Class6}
              alt="class6"
            />
          </div>           
            </Col>
            <Col lg="3" xs="6" className="d-flex justify-content-center">
            <div
            className={`${
              activeClass[7] && "active-bg"
            }  hazardous-item rounded-lg p-4 my-3`}
            onClick={() => {
              handleClick(7);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 7
            </h3>
            <img
              className="hazardous-image"
              src={Class7}
              alt="class7"
            />
          </div>    
            </Col>
            <Col lg="3" xs="6" className="d-flex justify-content-center">
            <div
            className={`${
              activeClass[8] && "active-bg"
            }  hazardous-item rounded-lg p-4 my-3`}
            onClick={() => {
              handleClick(8);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 8
            </h3>
            <img
              className="hazardous-image"
              src={Class8}
              alt="class8"
            />
          </div>    
            </Col>
        </Row>  
          <Row>
              <Col lg="3" xs="6" className="d-flex justify-content-center">
          <div
              className={`${
              activeClass[9] && "active-bg"
              } hazardous-item rounded-lg p-4 my-3`}
              onClick={() => {
                handleClick(9);
            }}
          >
            <h3
              className={`heading mb-2`}
            >
              CLASS 9
            </h3>
            <img
              className="hazardous-image"
              src={Class9}
              alt="class9"
            />
              </div>
            </Col>
          </Row>
        
      </Container>

      {/* <div className="flex flex-wrap justify-center">
        <div
          className={`${
            activeClass[1] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA] hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(1);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 1
          </h3>
          <img
            className=""
            src="./images/Class1.png"
            alt="class1"
          />
        </div>

        <div
          className={`${
            activeClass[2] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(2);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 2
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class2.png"
            alt="class2"
          />
        </div>

        <div
          className={`${
            activeClass[3] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(3);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 3
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class3.png"
            alt="class3"
          />
        </div>

        <div
          className={`${
            activeClass[4] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(4);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 4
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class4.png"
            alt="class4"
          />
        </div>

        <div
          className={`${
            activeClass[5] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(5);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 5
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class5.png"
            alt="class5"
          />
        </div>

        <div
          className={`${
            activeClass[6] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(6);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 6
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class6.png"
            alt="class6"
          />
        </div>

        <div
          className={`${
            activeClass[7] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(7);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 7
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class7.png"
            alt="class7"
          />
        </div>

        <div
          className={`${
            activeClass[8] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(8);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 8
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class8.png"
            alt="class8"
          />
        </div>

        <div
          className={`${
            activeClass[9] && "bg-[#EAEAEA]"
          }  rounded-lg p-4 flex flex-col items-center cursor-pointer my-3 w-36 md:w-40 hover:bg-[#EAEAEA]`}
          onClick={() => {
            handleClick(9);
          }}
        >
          <h3
            className={` text-[#404B51] text-base	 md:text-xl font-semibold text-center mb-2`}
          >
            CLASS 9
          </h3>
          <img
            className="h-16 w-16 md:w-24 md:h-24"
            src="./images/Class9.png"
            alt="class9"
          />
        </div>
      </div> */}
    </>
  );
}

export default Hazardous;
