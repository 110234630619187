import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Label,
  Form,
  Input,
  FormGroup,
  Button,
  Container,
  Spinner,
} from "reactstrap";
import jsonData from "../../config/southAfricaTowns.json";
import countryData from "../../config/southAfricaRegions.json";
// import Autocomplete from "react-autocomplete";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import ThemeButton from "../ThemeButton";
import { getOptRates, getDawningToken } from "../../store/actions/localActions";
import { useDispatch, useSelector } from "react-redux";
import southAfricaPostalCodes from "../../config/southAfricaPostalCodes.json";
import optionalRates from "../../config/optionalRates.json";
import ServicesHeader from "../ServicesHeader";
import { LocalActiveTab } from "./LocalActiveTab";

const CustomAutoComplete = styled(Autocomplete)`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100% !important;
`;

const StyledTextField = styled(TextField)`
  background: white;
  border-radius: 0.25rem;
  & label {
    // font-size: 0.85rem;
    font-size: 16px !important;
    margin-left: 13px;
    font-weight: 400;
    color: #495057;
    text-transform: none !important;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
      border-bottom: none;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }

  & .MuiInputBase-input {
    padding-left: 12px !important;
    color: #495057 !important;
  }
    @media (max-width:390px){
    & label{
    font-size: 11px !important;
    }
    }
`;
function QouteCountrySelection(props) {
  const dispatch = useDispatch();
  const [fromRegion, setFromRegion] = useState("");
  const [toRegion, setToRegion] = useState("");
  const [fromAutoComplete, setFromAutoComplete] = useState([]);
  const [toAutoComplete, setToAutoComplete] = useState([]);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [fromPost, setFromPost] = useState([]);
  const [selectedFromPost, setSelectedFromPost] = useState("");
  const [selectedToPost, setSelectedToPost] = useState("");
  const [toPost, setToPost] = useState([]);
  const firstPage = JSON.parse(localStorage.getItem("local_first_page"));
  const [additional, setAdditional] = useState(false);
  const [checkedState, setCheckedState] = useState(new Array(2).fill(false));

  const [fromPostalCode, setFromPostalCode] = useState([]);
  const [toPostalCode, setToPostalCode] = useState([]);

  const [addCheckOne, setAddCheckOne] = useState(0);
  const [addCheckTwo, setAddCheckTwo] = useState(0);

  const { optRates, optTimeBaseRates, optRatesLoading } = useSelector(
    (state) => state.local
  );

  const handleCheckOneChange = (val) => {
    if (val == addCheckOne) {
      setAddCheckOne(0);
    } else {
      setAddCheckOne(val);
    }
  };

  const handleCheckTwoChange = (val) => {
    if (val == addCheckTwo) {
      setAddCheckOne(0);
    } else {
      setAddCheckTwo(val);
    }
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : (item = false)
    );
    setCheckedState(updatedCheckedState);
  };
  const country =
    countryData &&
    countryData.map((c) => {
      return c.Regions;
    });

  const matchedFunction = (from) => {
    const firstCountry = jsonData.filter((e) => e.RegionLower == from);
    const suburbPost = firstCountry.map((fs) => {
      return { fromSuburbPost: fs.CityTownLower };
    });
    const postalCodes = southAfricaPostalCodes.filter(
      (e) => e.RegionLower == from
    );
    setFromPostalCode(postalCodes);
    setFromPost(suburbPost);
  };

  const matchedFunction1 = (from) => {
    const firstCountry = jsonData.filter((e) => e.RegionLower == from);
    const suburbPost = firstCountry.map((fs) => {
      return { toSuburbPost: fs.CityTownLower };
    });
    const postalCodes = southAfricaPostalCodes.filter(
      (e) => e.RegionLower == from
    );
    setToPostalCode(postalCodes);
    setToPost(suburbPost);
  };

  const getPostalCodesData = (data) => {
    const postalData = southAfricaPostalCodes.find(
      (e) => e.FriendlyName == data
    );
    if (postalData) {
      return postalData;
    } else {
      return "";
    }
  };

  const matchSelectedFromSuburb = () => {
    const suburb = jsonData.filter((e) => e.Region == fromRegion);
    const allSuburbs = suburb.map((e) => {
      return { label: e.Suburb };
    });
    setFromAutoComplete(allSuburbs);
  };

  const matchedSelectedFromPostalCode = () => {
    const postalCode = jsonData.filter((e) => e.Region === fromRegion);
    const allPostalCodes = postalCode.map((e) => {
      return { label: e.PostalCode.toString() };
    });
    setFromAutoComplete(allPostalCodes);
  };
  const matchSelectedToSuburb = () => {
    const suburb = jsonData.filter((e) => e.Region === toRegion);
    const allSuburbs = suburb.map((e) => {
      return { label: e.Suburb };
    });
    setToAutoComplete(allSuburbs);
  };
  const matchedSelectedToPostalCode = () => {
    const postalCode = jsonData.filter((e) => e.Region === toRegion);
    const allPostalCodes = postalCode.map((e) => {
      return { label: e.PostalCode.toString() };
    });
    setToAutoComplete(allPostalCodes);
  };

  const [deliveryType, setDeliveryType] = useState("residential");

  const getSortedData = (arr) => {
    return arr.sort((a, b) => (a < b ? -1 : 1));
  };

  // console.log(firstCountry.length);
  // console.log(country);

  useEffect(async () => {
    if (props.checkValues() == true) {
      let data = {
        collection_address: {
          type: deliveryType,
          street_address: props.value.fromStreet,
          local_area: props.value.fromCode.split(",")[0].trim(),
          city: props.value.fromLocalArea,
          zone: props.value.fromRegion,
          country: "ZA",
          code: props.value.fromCode.split(",")[1].trim(),
        },
        delivery_address: {
          type: deliveryType,
          city: props.value.toLocalArea,
          street_address: props.value.toStreet,
          local_area: props.value.toCode.split(",")[0].trim(),
          zone: props.value.toRegion,
          country: "ZA",
          code: props.value.toCode.split(",")[1].trim(),
        },
      };

      props.setCollectionAddress(data.collection_address);
      props.setDeliveryAddress(data.delivery_address);
    }
  }, [props.value]);
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  if(windowsize<768)
    {
  return (
    <>
      <Form className="country-selection-component py-2" autocomplete="off">
        <Row className="justify-content-center">
          <Col xs="11">
            <ServicesHeader value="DOMESTIC COURIER" />
            <LocalActiveTab
              activeTab={props.activeTab}
              setActiveTab={props.setActiveTab}
            />
            <hr className="service-header__hr-100" />
          </Col>
        </Row>

        <Row className="select-country justify-content-center ">
          <Col>
            <Row className="justify-content-center">
              <Col sm="12" xs='12' className="text-left">
                <Label for="examplePassword">COLLECTION FROM:</Label>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col sm="12" xs="12" className="text-left ">
                <FormGroup>
                  <Input
                    id="shipfrom"
                    name="fromRegion"
                    placeholder="southafrica"
                    type="select"
                    className="country__selection__input__width quotelabels"
                    required
                    value={props.value.fromRegion}
                    onChange={(e) => {
                      matchedFunction(e.target.value);
                      props.onChange(e.target.name, e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Region
                    </option>
                    {country &&
                      getSortedData(country).map((c) => (
                        <option value={c}>{c}</option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12" xs="12" className="text-left">
                <FormGroup>
                  <CustomAutoComplete
                    id="nba teams"
                    options={
                      fromPost.sort((a, b) =>
                        a.fromSuburbPost.toLowerCase() <
                        b.fromSuburbPost.toLowerCase()
                          ? -1
                          : 1
                      )
                      // props.value.fromLocalArea == ''
                      // 	? fromPost
                      // 			.slice(0, 5)
                      // 			.sort((a, b) =>
                      // 				a.fromSuburbPost.toLowerCase() <
                      // 				b.fromSuburbPost.toLowerCase()
                      // 					? -1
                      // 					: 1
                      // 			)
                      // 	: fromPost.sort((a, b) =>
                      // 			a.fromSuburbPost.toLowerCase() <
                      // 			b.fromSuburbPost.toLowerCase()
                      // 				? -1
                      // 				: 1
                      // 	  )
                    }
                    renderInput={(params) => (
                      <StyledTextField
                        className="country__selection__input__width_child quotelabels"
                        {...params}
                        label="Select City / Town"
                      />
                    )}
                    getOptionLabel={(option, idx) => {
                      return option.fromSuburbPost;
                    }}
                    style={{ width: 270 }}
                    value={{
                      fromSuburbPost: props.value.fromLocalArea,
                    }}
                    disableClearable
                    onChange={(_event, newTeam) => {
                      props.onChange("fromLocalArea", newTeam.fromSuburbPost);
                    }}

                    // onInputChange={() => {
                    // 	if (props.value.fromRegion != '') {
                    // 		const firstCountry =
                    // 			jsonData.filter(
                    // 				e =>
                    // 					e.RegionLower ==
                    // 					props.value
                    // 						.fromRegion
                    // 			);
                    // 		const suburbPost =
                    // 			firstCountry.map(fs => {
                    // 				return {
                    // 					fromSuburbPost:
                    // 						fs.CityTownLower,
                    // 				};
                    // 			});
                    // 		setFromPost(suburbPost);
                    // 	}
                    // }}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" xs="12" className="text-left">
                <FormGroup>
                  <div className="d-flex">
                    <CustomAutoComplete
                      disableClearable
                      id="toSelect"
                      options={
                        fromPostalCode
                        // props.value.fromCode == ''
                        // 	? fromPostalCode.slice(0, 5)
                        // 	: fromPostalCode
                      }
                      renderInput={(toLabels) => (
                        <StyledTextField
                          className="country__selection__input__width_child quotelabels"
                          {...toLabels}
                          label="Search Suburb / Postal Code"
                        />
                      )}
                      getOptionLabel={(option) => option.FriendlyName}
                      style={{ width: 270 }}
                      value={getPostalCodesData(props.value.fromCode)}
                      onChange={(_event, data) => {
                        props.onChange("fromCode", data.FriendlyName);
                        props.onChange("fromSuburb", data.SuburbLower);
                      }}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col sm="12" xs="12" className="text-left ">
                <FormGroup>
                  <Input
                    name="fromStreet"
                    autocomplete="off"
                    placeholder="E.g. 15 Louis Botha Ave"
                    type="text"
                    className="country__selection__input__width quotelabels"
                    required
                    value={props.value.fromStreet}
                    onChange={(e) => {
                      props.onChange("fromStreet", e.target.value);
                    }}
                    autoComplete="new-password"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col sm="12" xs='12' className="text-left">
                <Label for="examplePassword">DELIVERY TO:</Label>
              </Col> 
            </Row>

            <Row className="justify-content-center">
            <Col sm="12" xs="12" className="text-left">
                <FormGroup>
                  <Input
                    id="shipto"
                    name="toRegion"
                    placeholder="southafrica"
                    type="select"
                    className="country__selection__input__width quotelabels"
                    required
                    value={props.value.toRegion}
                    onChange={(e) => {
                      matchedFunction1(e.target.value);
                      props.onChange(e.target.name, e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Region
                    </option>
                    {country &&
                      getSortedData(country).map((c) => <option>{c}</option>)}
                  </Input>
                </FormGroup>
              </Col>

              <Col sm="12" xs="12" className="text-left">
                <FormGroup>
                  <CustomAutoComplete
                    disableClearable
                    id="toSelect"
                    options={
                      toPost.sort((a, b) =>
                        a.toSuburbPost.toLowerCase() <
                        b.toSuburbPost.toLowerCase()
                          ? -1
                          : 1
                      )
                      // props.value.toLocalArea == ''
                      // 	? toPost
                      // 			.slice(0, 5)
                      // 			.sort((a, b) =>
                      // 				a.toSuburbPost.toLowerCase() <
                      // 				b.toSuburbPost.toLowerCase()
                      // 					? -1
                      // 					: 1
                      // 			)
                      // 	: toPost.sort((a, b) =>
                      // 			a.toSuburbPost.toLowerCase() <
                      // 			b.toSuburbPost.toLowerCase()
                      // 				? -1
                      // 				: 1
                      // 	  )
                    }
                    renderInput={(toLabels) => (
                      <StyledTextField
                        className="country__selection__input__width_child quotelabels"
                        {...toLabels}
                        label="Select City / Town"
                      />
                    )}
                    getOptionLabel={(option) => option.toSuburbPost}
                    style={{ width: 270 }}
                    value={{
                      toSuburbPost: props.value.toLocalArea,
                    }}
                    onChange={(_event, newTeam) => {
                      props.onChange("toLocalArea", newTeam.toSuburbPost);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" xs="12" className="text-left ">
                <FormGroup>
                  <CustomAutoComplete
                    disableClearable
                    id="toSelect"
                    options={
                      toPostalCode
                      // props.value.toCode == ''
                      // 	? toPostalCode.slice(0, 5)
                      // 	: toPostalCode
                    }
                    renderInput={(toLabels) => (
                      <StyledTextField
                        className="country__selection__input__width_child quotelabels"
                        {...toLabels}
                        label="Search Suburb / Postal Code"
                      />
                    )}
                    getOptionLabel={(option) => option.FriendlyName}
                    style={{ width: 270 }}
                    value={getPostalCodesData(props.value.toCode)}
                    onChange={(_event, data) => {
                      props.onChange("toCode", data.FriendlyName);
                      props.onChange("toSuburb", data.SuburbLower);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col sm="12" xs="12" className="text-left">
                <FormGroup>
                  <Input
                    name="toStreet"
                    placeholder="E.g. 10 Paul Street"
                    type="text"
                    className="country__selection__input__width quotelabels"
                    required
                    value={props.value.toStreet}
                    onChange={(e) => {
                      props.onChange("toStreet", e.target.value);
                    }}
                    autoComplete="new-password"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="4" className="text-left text-primary mt-2">
                <h5>
                  <b>Delivery Type</b>
                </h5>
              </Col>
              <Col md="3"></Col>
              <Col md="4"></Col>
            </Row>

            <Row className="justify-content-center">
              <Col
                sm="12"
                xs='12'
                className="text-left text-primary d-flex align-items-center"
              >
                <FormGroup check inline>
                  <Label check className="mr-2">
                    Business
                  </Label>
                  <Input
                    type="checkbox"
                    name="checkbox1"
                    id="checkbox1"
                    value="business"
                    checked={deliveryType == "business"}
                    onChange={(e) => {
                      handleOnChange(0);
                      setDeliveryType(e.target.value);
                      props.deliveryTypeChange(e.target.value);
                    }}
                  />{" "}
                </FormGroup>
              </Col>
             
            </Row>
            <Row className="justify-content-center">
              <Col
                sm='12'
                xs="12"
                className="text-left text-primary d-flex align-items-center"
              >
                <FormGroup check inline >
                  <Label check className="mr-2">
                    Residential
                  </Label>{" "}
                  <Input
                    type="checkbox"
                    name="checkbox2"
                    id="checkbox2"
                    value="residential"
                    checked={deliveryType == "residential"}
                    onChange={(e) => {
                      handleOnChange(1);
                      setDeliveryType(e.target.value);
                      props.deliveryTypeChange(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
             
            </Row>
            <Row className="mt-2  ">
              <Col
                className="text-left"
                sm="12"
                sx="12"
                style={{ marginBottom:"2rem" }}
              >
                <FormGroup check inline className="additional_checkbox">
                  <span className="text-danger add-delivery-Quote-opt text-md-nowrap">
                    ADDITIONAL DELIVERY OPTIONS*
                  </span>
                  <Input
                    type="checkbox"
                    className="ml-2 "
                    onChange={async (e) => {
                      props.clearRates();
                      if (props.checkValues()) {
                        if (e.target.checked) {
                          await dispatch(
                            getOptRates({
                              collection_address: props.collectionAddress,
                              delivery_address: props.deliveryAddress,
                            })
                          );
                        }
                      }
                      // handleCheckOneChange(0);
                      // handleCheckTwoChange(0);
                      setAdditional(!additional);
                    }}
                  />
                </FormGroup>
                <div className={`${additional ? "d-block " : "d-none"}`}>
                  {props.checkValues() ? (
                    <>
                      {optRatesLoading ? (
                        <Row>
                          <Col
                            md="11"
                            className="d-flex justify-content-center align-items-center py-5"
                          >
                            <Spinner></Spinner>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {optRates?.length > 0 ? (
                            <Row className="mt-2">
                              <Col
                                md="4"
                                className="d-flex justify-content-start align-items-center"
                              >
                                <Label className="mb-0">
                                  SPECIAL COLLECTION/DELIVERY REQUEST?
                                </Label>
                              </Col>
                              <Col md="3">
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    props.optRateChange(e.target.value)
                                  }
                                  value={props.opt_in_rates[0]}
                                >
                                  <option
                                    value=""
                                    selected={props.opt_in_rates.length == 0}
                                    className="disabled__opt"
                                  ></option>
                                  {optRates.length > 0 &&
                                    optRates.map((rate) => {
                                      return (
                                        <option value={rate.id}>
                                          {rate.name}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                            </Row>
                          ) : (
                            <span className="text-primary">
                              Delivery Requests not available for the entered
                              regions!
                            </span>
                          )}
                          {/* <Row className='mt-2'>
													<Col
														md='3'
														className='d-flex justify-content-start align-items-center'
													>
														<Label className='mb-0'>
															ANY DELIVERY REQUEST?
														</Label>
													</Col>
													<Col md='3'>
														<Input
															type='select'
															onChange={e =>
																props.optRateChange(
																	e.target.value
																)
															}
															value={
																props
																	.opt_in_rates[0]
															}
														>
															<option
																value=''
																selected={
																	props
																		.opt_in_rates
																		.length == 0
																}
																className='disabled__opt'
															></option>
															{optionalRates &&
																optionalRates
																	.opt_in_rates
																	.length > 0 &&
																optionalRates.opt_in_rates.map(
																	rate => {
																		return (
																			<option
																				value={
																					rate.id
																				}
																			>
																				{
																					rate.name
																				}
																			</option>
																		);
																	}
																)}
														</Input>
													</Col>
												</Row> */}
                          {optTimeBaseRates?.length > 0 && (
                            <>
                              <Row>
                                <Col md="11 pr-0">
                                  <hr className="add_hr" />
                                </Col>
                              </Row>

                              <Row className="mb-5">
                                <Col md="6">
                                  <h5 className="text-primary">
                                    <b>FROM:</b>
                                  </h5>
                                  {optTimeBaseRates
                                    .filter((rate) => rate.type == "collection")
                                    .map((rate, idx) => {
                                      return (
                                        <Row key={idx} className="additional__margin__top">
                                          <Col xs="8">
                                            <Label
                                              className="text-nowrap"
                                              check
                                              color="white"
                                            >
                                              {rate.name}
                                            </Label>
                                          </Col>
                                          <Col
                                            xs="4"
                                            className="d-flex  justify-content-start"
                                          >
                                            <Input
                                              name="radio2"
                                              type="checkbox"
                                              checked={
                                                props.opt_in_time_based_rates.find(
                                                  (rt) => rt == rate
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                props.optTimeRateChange(
                                                  rate,
                                                  "collection"
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </Col>

                                <Col
                                  md="6"
                                  className="additional__border__left justify-content-end"
                                >
                                  <h5 className="text-primary">
                                    <b>TO:</b>
                                  </h5>

                                  {optTimeBaseRates
                                    .filter((rate) => rate.type == "delivery")
                                    .map((rate, idx) => {
                                      return (
                                        <Row className="additional__margin__top">
                                          <Col xs="8">
                                            <Label
                                              className="text-nowrap"
                                              check
                                              color="white"
                                            >
                                              {rate.name}
                                            </Label>
                                          </Col>
                                          <Col
                                            xs="4"
                                            className="d-flex  justify-content-start"
                                          >
                                            <Input
                                              name="radio2"
                                              type="checkbox"
                                              checked={
                                                props.opt_in_time_based_rates.find(
                                                  (rt) => rt == rate
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                props.optTimeRateChange(
                                                  rate,
                                                  "delivery"
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <span className="text-primary">
                      Fill out all above fields to check additional delivery
                      options!
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}else{
  return (
    <>
      <Form className="country-selection-component py-2" autocomplete="off">
        <Row className="justify-content-center">
          <Col xs="11">
            <ServicesHeader value="DOMESTIC COURIER" />
            <LocalActiveTab
              activeTab={props.activeTab}
              setActiveTab={props.setActiveTab}
            />
            <hr className="service-header__hr-100" />
          </Col>
        </Row>

        <Row className="select-country justify-content-center ">
          <Col>
            <Row className="justify-content-center">
              <Col md="4" xs='4' className="text-left">
                <Label for="examplePassword">COLLECTION FROM:</Label>
              </Col>
              <Col md="3" xs='4'></Col>
              <Col md="4" xs='4' className="text-left">
                <Label for="examplePassword">DELIVERY TO:</Label>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="4" xs="4" className="text-left ">
                <FormGroup>
                  <Input
                    id="shipfrom"
                    name="fromRegion"
                    placeholder="southafrica"
                    type="select"
                    className="country__selection__input__width"
                    required
                    value={props.value.fromRegion}
                    onChange={(e) => {
                      matchedFunction(e.target.value);
                      props.onChange(e.target.name, e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Region
                    </option>
                    {country &&
                      getSortedData(country).map((c) => (
                        <option value={c}>{c}</option>
                      ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col
                md="3"
                xs='4'
                className="d-flex align-items-center justify-content-center"
              >
                <FormGroup>
                  <Label className="text-primary mb-0 p-0">
                    {"<< REGION >>"}
                  </Label>
                </FormGroup>
              </Col>

              <Col md="4" xs="4" className="text-left">
                <FormGroup>
                  <Input
                    id="shipto"
                    name="toRegion"
                    placeholder="southafrica"
                    type="select"
                    className="country__selection__input__width"
                    required
                    value={props.value.toRegion}
                    onChange={(e) => {
                      matchedFunction1(e.target.value);
                      props.onChange(e.target.name, e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Select Region
                    </option>
                    {country &&
                      getSortedData(country).map((c) => <option>{c}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="4" xs="4" className="text-left">
                <FormGroup>
                  <CustomAutoComplete
                    id="nba teams"
                    options={
                      fromPost.sort((a, b) =>
                        a.fromSuburbPost.toLowerCase() <
                        b.fromSuburbPost.toLowerCase()
                          ? -1
                          : 1
                      )
                      // props.value.fromLocalArea == ''
                      // 	? fromPost
                      // 			.slice(0, 5)
                      // 			.sort((a, b) =>
                      // 				a.fromSuburbPost.toLowerCase() <
                      // 				b.fromSuburbPost.toLowerCase()
                      // 					? -1
                      // 					: 1
                      // 			)
                      // 	: fromPost.sort((a, b) =>
                      // 			a.fromSuburbPost.toLowerCase() <
                      // 			b.fromSuburbPost.toLowerCase()
                      // 				? -1
                      // 				: 1
                      // 	  )
                    }
                    renderInput={(params) => (
                      <StyledTextField
                        className="country__selection__input__width_child"
                        {...params}
                        label="Select City / Town"
                      />
                    )}
                    getOptionLabel={(option, idx) => {
                      return option.fromSuburbPost;
                    }}
                    style={{ width: 270 }}
                    value={{
                      fromSuburbPost: props.value.fromLocalArea,
                    }}
                    disableClearable
                    onChange={(_event, newTeam) => {
                      props.onChange("fromLocalArea", newTeam.fromSuburbPost);
                    }}

                    // onInputChange={() => {
                    // 	if (props.value.fromRegion != '') {
                    // 		const firstCountry =
                    // 			jsonData.filter(
                    // 				e =>
                    // 					e.RegionLower ==
                    // 					props.value
                    // 						.fromRegion
                    // 			);
                    // 		const suburbPost =
                    // 			firstCountry.map(fs => {
                    // 				return {
                    // 					fromSuburbPost:
                    // 						fs.CityTownLower,
                    // 				};
                    // 			});
                    // 		setFromPost(suburbPost);
                    // 	}
                    // }}
                  />
                </FormGroup>
              </Col>
              <Col
                md="3"
                xs='4'
                className="d-flex align-items-center justify-content-center"
              >
                <FormGroup>
                  <Label className="text-primary mb-0 p-0">
                    {"<< CITY / TOWN >>"}
                  </Label>
                </FormGroup>
              </Col>

              <Col md="4" xs="4" className="text-left">
                <FormGroup>
                  <CustomAutoComplete
                    disableClearable
                    id="toSelect"
                    options={
                      toPost.sort((a, b) =>
                        a.toSuburbPost.toLowerCase() <
                        b.toSuburbPost.toLowerCase()
                          ? -1
                          : 1
                      )
                      // props.value.toLocalArea == ''
                      // 	? toPost
                      // 			.slice(0, 5)
                      // 			.sort((a, b) =>
                      // 				a.toSuburbPost.toLowerCase() <
                      // 				b.toSuburbPost.toLowerCase()
                      // 					? -1
                      // 					: 1
                      // 			)
                      // 	: toPost.sort((a, b) =>
                      // 			a.toSuburbPost.toLowerCase() <
                      // 			b.toSuburbPost.toLowerCase()
                      // 				? -1
                      // 				: 1
                      // 	  )
                    }
                    renderInput={(toLabels) => (
                      <StyledTextField
                        className="country__selection__input__width_child"
                        {...toLabels}
                        label="Select City / Town"
                      />
                    )}
                    getOptionLabel={(option) => option.toSuburbPost}
                    style={{ width: 270 }}
                    value={{
                      toSuburbPost: props.value.toLocalArea,
                    }}
                    onChange={(_event, newTeam) => {
                      props.onChange("toLocalArea", newTeam.toSuburbPost);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="4" xs="4" className="text-left">
                <FormGroup>
                  <div className="d-flex">
                    <CustomAutoComplete
                      disableClearable
                      id="toSelect"
                      options={
                        fromPostalCode
                        // props.value.fromCode == ''
                        // 	? fromPostalCode.slice(0, 5)
                        // 	: fromPostalCode
                      }
                      renderInput={(toLabels) => (
                        <StyledTextField
                          className="country__selection__input__width_child"
                          {...toLabels}
                          label="Search Suburb / Postal Code"
                        />
                      )}
                      getOptionLabel={(option) => option.FriendlyName}
                      style={{ width: 270 }}
                      value={getPostalCodesData(props.value.fromCode)}
                      onChange={(_event, data) => {
                        props.onChange("fromCode", data.FriendlyName);
                        props.onChange("fromSuburb", data.SuburbLower);
                      }}
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col
                md="3"
                xs='4'
                className="d-flex align-items-center justify-content-center"
              >
                <FormGroup>
                  <Label className="text-primary mb-0 p-0">
                    {"<< SUBURB >>"}
                  </Label>
                </FormGroup>
              </Col>

              <Col md="4" xs="4" className="text-left ">
                <FormGroup>
                  <CustomAutoComplete
                    disableClearable
                    id="toSelect"
                    options={
                      toPostalCode
                      // props.value.toCode == ''
                      // 	? toPostalCode.slice(0, 5)
                      // 	: toPostalCode
                    }
                    renderInput={(toLabels) => (
                      <StyledTextField
                        className="country__selection__input__width_child"
                        {...toLabels}
                        label="Search Suburb / Postal Code"
                      />
                    )}
                    getOptionLabel={(option) => option.FriendlyName}
                    style={{ width: 270 }}
                    value={getPostalCodesData(props.value.toCode)}
                    onChange={(_event, data) => {
                      props.onChange("toCode", data.FriendlyName);
                      props.onChange("toSuburb", data.SuburbLower);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="4" xs="4" className="text-left ">
                <FormGroup>
                  <Input
                    name="fromStreet"
                    autocomplete="off"
                    placeholder="E.g. 15 Louis Botha Ave"
                    type="text"
                    className="country__selection__input__width quotelabels"
                    required
                    value={props.value.fromStreet}
                    onChange={(e) => {
                      props.onChange("fromStreet", e.target.value);
                    }}
                    autoComplete="new-password"
                  ></Input>
                </FormGroup>
              </Col>
              <Col
                md="3"
                xs='4'
                className="d-flex align-items-center justify-content-center"
              >
                <FormGroup>
                  <Label className="text-primary mb-0 p-0">
                    {"<< STREET >>"}
                  </Label>
                </FormGroup>
              </Col>

              <Col md="4" xs="4" className="text-left">
                <FormGroup>
                  <Input
                    name="toStreet"
                    placeholder="E.g. 10 Paul Street"
                    type="text"
                    className="country__selection__input__width"
                    required
                    value={props.value.toStreet}
                    onChange={(e) => {
                      props.onChange("toStreet", e.target.value);
                    }}
                    autoComplete="new-password"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md="4" className="text-left text-primary mt-2">
                <h5>
                  <b>Delivery Type</b>
                </h5>
              </Col>
              <Col md="3"></Col>
              <Col md="4"></Col>
            </Row>

            <Row className="justify-content-center">
              <Col
                md="4"
                sm='12'
                className="text-left text-primary d-flex align-items-center"
              >
                <FormGroup check inline>
                  <Label check className="mr-2">
                    Business
                  </Label>
                  <Input
                    type="checkbox"
                    name="checkbox1"
                    id="checkbox1"
                    value="business"
                    checked={deliveryType == "business"}
                    onChange={(e) => {
                      handleOnChange(0);
                      setDeliveryType(e.target.value);
                      props.deliveryTypeChange(e.target.value);
                    }}
                  />{" "}
                </FormGroup>
                <FormGroup check inline className="ml-3 ">
                  <Label check className="mr-2">
                    Residential
                  </Label>{" "}
                  <Input
                    type="checkbox"
                    name="checkbox2"
                    id="checkbox2"
                    value="residential"
                    checked={deliveryType == "residential"}
                    onChange={(e) => {
                      handleOnChange(1);
                      setDeliveryType(e.target.value);
                      props.deliveryTypeChange(e.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3"></Col>
              <Col md="4"></Col>
            </Row>
            <Row className="mt-2  ">
              <Col
                className="text-left"
                md="12"
                style={{ marginLeft: "2.7rem" }}
              >
                <FormGroup check inline className="">
                  <span className="text-danger add-delivery-opt text-nowrap">
                    ADDITIONAL DELIVERY OPTIONS*
                  </span>
                  <Input
                    type="checkbox"
                    className="ml-2"
                    onChange={async (e) => {
                      props.clearRates();
                      if (props.checkValues()) {
                        if (e.target.checked) {
                          await dispatch(
                            getOptRates({
                              collection_address: props.collectionAddress,
                              delivery_address: props.deliveryAddress,
                            })
                          );
                        }
                      }
                      // handleCheckOneChange(0);
                      // handleCheckTwoChange(0);
                      setAdditional(!additional);
                    }}
                  />
                </FormGroup>
                <div className={`${additional ? "d-block " : "d-none"}`}>
                  {props.checkValues() ? (
                    <>
                      {optRatesLoading ? (
                        <Row>
                          <Col
                            md="11"
                            className="d-flex justify-content-center align-items-center py-5"
                          >
                            <Spinner></Spinner>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {optRates?.length > 0 ? (
                            <Row className="mt-2">
                              <Col
                                md="4"
                                className="d-flex justify-content-start align-items-center"
                              >
                                <Label className="mb-0">
                                  SPECIAL COLLECTION/DELIVERY REQUEST?
                                </Label>
                              </Col>
                              <Col md="3">
                                <Input
                                  type="select"
                                  onChange={(e) =>
                                    props.optRateChange(e.target.value)
                                  }
                                  value={props.opt_in_rates[0]}
                                >
                                  <option
                                    value=""
                                    selected={props.opt_in_rates.length == 0}
                                    className="disabled__opt"
                                  ></option>
                                  {optRates.length > 0 &&
                                    optRates.map((rate) => {
                                      return (
                                        <option value={rate.id}>
                                          {rate.name}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                            </Row>
                          ) : (
                            <span className="text-primary">
                              Delivery Requests not available for the entered
                              regions!
                            </span>
                          )}
                          {/* <Row className='mt-2'>
													<Col
														md='3'
														className='d-flex justify-content-start align-items-center'
													>
														<Label className='mb-0'>
															ANY DELIVERY REQUEST?
														</Label>
													</Col>
													<Col md='3'>
														<Input
															type='select'
															onChange={e =>
																props.optRateChange(
																	e.target.value
																)
															}
															value={
																props
																	.opt_in_rates[0]
															}
														>
															<option
																value=''
																selected={
																	props
																		.opt_in_rates
																		.length == 0
																}
																className='disabled__opt'
															></option>
															{optionalRates &&
																optionalRates
																	.opt_in_rates
																	.length > 0 &&
																optionalRates.opt_in_rates.map(
																	rate => {
																		return (
																			<option
																				value={
																					rate.id
																				}
																			>
																				{
																					rate.name
																				}
																			</option>
																		);
																	}
																)}
														</Input>
													</Col>
												</Row> */}
                          {optTimeBaseRates?.length > 0 && (
                            <>
                              <Row>
                                <Col md="11 pr-0">
                                  <hr className="add_hr" />
                                </Col>
                              </Row>

                              <Row className="mb-5">
                                <Col md="6">
                                  <h5 className="text-primary">
                                    <b>FROM:</b>
                                  </h5>
                                  {optTimeBaseRates
                                    .filter((rate) => rate.type == "collection")
                                    .map((rate, idx) => {
                                      return (
                                        <Row key={idx} className="additional__margin__top">
                                          <Col xs="8">
                                            <Label
                                              className="text-nowrap"
                                              check
                                              color="white"
                                            >
                                              {rate.name}
                                            </Label>
                                          </Col>
                                          <Col
                                            xs="4"
                                            className="d-flex  justify-content-start"
                                          >
                                            <Input
                                              name="radio2"
                                              type="checkbox"
                                              checked={
                                                props.opt_in_time_based_rates.find(
                                                  (rt) => rt == rate
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                props.optTimeRateChange(
                                                  rate,
                                                  "collection"
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </Col>

                                <Col
                                  md="6"
                                  className="additional__border__left justify-content-end"
                                >
                                  <h5 className="text-primary">
                                    <b>TO:</b>
                                  </h5>

                                  {optTimeBaseRates
                                    .filter((rate) => rate.type == "delivery")
                                    .map((rate, idx) => {
                                      return (
                                        <Row className="additional__margin__top">
                                          <Col xs="8">
                                            <Label
                                              className="text-nowrap"
                                              check
                                              color="white"
                                            >
                                              {rate.name}
                                            </Label>
                                          </Col>
                                          <Col
                                            xs="4"
                                            className="d-flex  justify-content-start"
                                          >
                                            <Input
                                              name="radio2"
                                              type="checkbox"
                                              checked={
                                                props.opt_in_time_based_rates.find(
                                                  (rt) => rt == rate
                                                )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) => {
                                                props.optTimeRateChange(
                                                  rate,
                                                  "delivery"
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      );
                                    })}
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <span className="text-primary">
                      Fill out all above fields to check additional delivery
                      options!
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}
}

export default QouteCountrySelection;
