import React, { useEffect, useState } from 'react';
import EuropeanCurrecy from '../../assets/img/EuroCurrency.png';
import firebase from '../../config/firebase';
import {
	Container,
	Row,
	Col,
	Card,
	Table,
	Button,
	Input,
	Spinner,
} from 'reactstrap';
import moment from 'moment';
import countriesStates from '../../config/countriesStates.json';
import countriesList from '../../config/countriesList.json';
import { useSelector } from 'react-redux';

function DeclarationForm_pt(props) {
	const [invoiceDetails, setInvoiceDetails] = useState();
	let shipmentValue = 0;
	const handlePrint = divPrint => {
		var printContents = document.getElementById(divPrint).innerHTML;
		var originalContents = document.body.innerHTML;

		document.body.innerHTML = printContents;

		window.print();

		document.body.innerHTML = originalContents;
		window.location.reload();
	};

	const getCountryState = (countryCode, stateCode) => {
		let obj = countriesStates.find(state => state.name == countryCode);
		if (obj.states && obj.states.length > 0) {
			let stateName = obj.states.find(state => state.CODE == stateCode);
			if (stateName) {
				return stateName.STATE;
			} else {
				return;
			}
		} else {
			return;
		}
	};

	useEffect(() => {
		firebase
			.firestore()
			.collection(`${process.env.REACT_APP_INTERNATIONAL_ORDER_PT}`)
			.doc(props.match.params.id)
			.onSnapshot(query => {
				setInvoiceDetails(query.data());
			});
	}, []);

	const getFormattedInvoiceNumber = str => {
		return str.replace(/\s+/g, '');
	};

	console.log('Invoice Details...', invoiceDetails);

	return (
		<>
			{invoiceDetails ? (
				<>
					<Row className='justify-content-center m-2'>
						<Col md='3' xs='6'>
							<Button
								onClick={() => handlePrint('printMe')}
								block
								color='primary'
							>
								PRINT / DOWNLOAD
							</Button>
						</Col>
					</Row>
<div className='content-scroll'>
					<Container
						id='printMe'
						fluid
						className='invoice-component-copy mt-4'
					>
						<div className='mx-4'>
							<Row className='invoice-header-transparent py-2 justify-content-center declaration__border'>
								<span className='text-secondary commercial_invoice'>
									COMMERCIAL INVOICE
								</span>
							</Row>
							<Row className='invoice-header declaration__border tr__print py-2'>
								<Col className='text-center text-white'>
									<span>INVOICE DETAILS</span>
								</Col>
							</Row>
							<Row className='invoice-data declaration__border'>
								<Col className=''>
									<Row className='py-2 px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Invoice Number: </span>
										</Col>
										<Col md='6' xs='4'>
											<span className='text-nowrap'>
												{
													invoiceDetails?.Invoice
														?.InvoiceInformation
														?.InvoiceNumber
												}
											</span>
										</Col>
									</Row>
								</Col>
								<Col className=''>
									<Row className='py-2 px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Invoice Date: </span>
										</Col>
										<Col md='6' xs='4'>
											<span className='text-nowrap'>
												{
													invoiceDetails?.Invoice
														?.InvoiceInformation
														?.InvoiceDate
												}
											</span>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className='py-2 invoice-header declaration__border tr__print text-white'>
								<Col className='text-center'>
									{/* <span>INVOICE DETAILS</span> */}
									<span>CONSIGNMENT DETAILS</span>
								</Col>
								<Col className='text-center'>
									{/* <span>INVOICE DETAILS</span> */}
									<span>REASON FOR EXPORT</span>
								</Col>
							</Row>
							{/* <div className='content-scroll'> */}
							<Row className='invoice-data declaration__border bg-white'>
								<Col className='text-left'>
									<Row>
										<Col md='6'>
											<Row className='mt-2 px-2'>
												<Col
													lg='6'
													xs='8'
													align='start'
												>
													<span className='text-nowrap'>
														Total Weight:{' '}
													</span>
												</Col>
												<Col lg='6' xs='4' align='end'>
													<span className='text-nowrap'>
														{
															invoiceDetails
																?.ShipmentManifest
																?.CargoInformation
																?.TotalPhysicalWeight
														}
														Kg
													</span>
												</Col>
											</Row>
										</Col>
										<Col md='6'>
											<Row className='mt-1 px-2 mb-2'>
												<Col
													md='6'
													xs='8'
													align='start'
												>
													<span className='invoice_data_bold text-nowrap'>
														ID / Exporters Code:{' '}
													</span>
												</Col>
												<Col md='6' xs='4' align='end'>
													<span className='text-nowrap'>
														{
															invoiceDetails
																?.ShipmentManifest
																?.AddressInformation
																?.ShipperIdentityNumber
														}
													</span>
												</Col>
											</Row>
										</Col>
									</Row>

									<Row>
										<Col md='6'>
											<Row className='mt-1 px-2 mb-2'>
												<Col
													md='6'
													xs='8'
													align='start'
												>
													<span className='text-nowrap'>
														Volumetric Weight:{' '}
													</span>
												</Col>
												<Col md='6' xs='4' align='end'>
													<span className='text-nowrap'>
														{
															invoiceDetails
																?.ShipmentManifest
																?.CargoInformation
																?.TotalVolumetricWeight
														}
														Kg
													</span>
												</Col>
											</Row>
										</Col>
										<Col md='6'>
											<Row className='mt-1 px-2 mb-2 p-0'>
												<Col
													md='6'
													xs='8'
													align='start'
												>
													<span className='invoice_data_bold text-nowrap'>
														Shipping Charge (EU) :{' '}
													</span>
												</Col>
												<Col md='6' xs='4' align='end'>
													<span className='text-nowrap'>
														{
															invoiceDetails
																?.Invoice
																?.InvoiceInformation
																?.TotalInvoiceAmount
														}{' '}
													</span>
												</Col>
											</Row>
										</Col>
									</Row>

									{/* <Row className='mt-2 px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Total Weight: </span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														.Intl_Shipments
														.itemDetails.totalWeight
												}
												Kg
											</span>
										</Col>
									</Row>
									<Row className='mt-1 px-2 mb-2'>
										<Col md='6' xs='8' align='end'>
											<span>Volumetric Weight: </span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span>
												{' '}
												{
													invoiceDetails
														.Intl_Shipments
														.itemDetails
														.volumetricWeight
												}
												Kg
											</span>
										</Col>
									</Row>
									<Row className='mt-1 px-2 mb-2'>
										<Col lg='6' xs='8' align='end'>
											<span className='invoice_data_bold'>
												ID Number / Exporters Code:{' '}
											</span>
										</Col>
										<Col lg='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														.Intl_Shipments.IdNumber
												}
											</span>
										</Col>
									</Row>
									<Row className='mt-1 px-2 mb-2'>
										<Col lg='6' xs='8' align='end'>
											<span className='invoice_data_bold'>
												Shipping Charge:{' '}
											</span>
										</Col>
										<Col lg='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														.Intl_Shipments
														.itemDetails.totalAmount
												}{' '}
												exl VAT
											</span>
										</Col>
									</Row> */}
								</Col>
								<Col className='text-left vertical__line'>
									<Row className='mt-2' noGutters>
										<Col md='4'>
											<Row>
												<Col xs='7' align='start'>
													<span>Personal Use</span>
												</Col>
												<Col>
													<Input
														className='ml-2 mt-2'
														type='checkbox'
														id='export1'
														name='export'
														disabled={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport !=
															'Personal Use'
														}
														checked={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport ==
															'Personal Use'
														}
														value='Personal Use'
													/>
												</Col>
											</Row>
										</Col>
										<Col md='4'>
											<Row>
												<Col xs='7' align='start'>
													<span>Commercial</span>
												</Col>
												<Col>
													<Input
														className='ml-2 mt-2'
														type='checkbox'
														id='export2'
														name='export'
														disabled={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport !=
															'Commercial'
														}
														checked={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport ==
															'Commercial'
														}
														value='Commercial'
													/>
												</Col>
											</Row>
										</Col>
										<Col md='4'>
											<Row>
												<Col xs='7' align='start'>
													<span>Repair/Return</span>
												</Col>
												<Col>
													<Input
														className='ml-2 mt-2'
														type='checkbox'
														id='export3'
														name='export'
														disabled={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport !=
															'Repair'
														}
														checked={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport ==
															'Repair'
														}
														value='Repair'
													/>
												</Col>
											</Row>
										</Col>
										<Col md='4'>
											<Row>
												<Col xs='7' align='start'>
													<span>Gift</span>
												</Col>
												<Col>
													<Input
														className='ml-2 mt-2'
														type='checkbox'
														id='export4'
														name='export'
														disabled={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport !=
															'Gift'
														}
														checked={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport ==
															'Gift'
														}
														value='Gift'
													/>
												</Col>
											</Row>
										</Col>
										<Col md='4'>
											<Row>
												<Col xs='7' align='start'>
													<span>Samples</span>
												</Col>
												<Col>
													<Input
														className='ml-2 mt-2'
														type='checkbox'
														id='export5'
														name='export'
														disabled={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport !=
															'Samples'
														}
														checked={
															invoiceDetails
																?.CommercialInvoice
																?.HeaderInformation
																?.ReasonForExport ==
															'Samples'
														}
														value='Samples'
													/>
												</Col>
											</Row>
										</Col>
									</Row>
								</Col>
							</Row>
							{/* </div> */}
							<Row className='py-2 invoice-header declaration__border tr__print text-white'>
								<Col className='text-center'>
									<span>SHIPMENT DETAILS</span>
								</Col>
							</Row>
							<Row className='intl_invoice_data declaration__border bg-white text-dark font-weight-bold py-2'>
								<Col className='text-center'>
									<span className='d-flex justify-content-center align-items-center '>
										SHIPPER
									</span>
								</Col>
								<Col className='text-center d-flex justify-content-center align-items-center '>
									<span>CONSIGNEE</span>
								</Col>
							</Row>
							<Row className='invoice-data declaration__border bg-white'>
								<Col className='text-left'>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Name:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperName
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Surname:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperSurname
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Contact Number:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperContactNumber
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Email Address:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperEmailAddress
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Address 1:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperAddress1
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span className='text-nowrap'>
												Address 2:
											</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperAddress2
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Suburb:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperSuburb
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Postal / Zip Code:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperPostalCodeZipCode
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>City:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperCity
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>State / Province:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{invoiceDetails
													?.ShipmentManifest
													?.AddressInformation
													?.ShipperState &&
													getCountryState(
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperCountry,
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ShipperState
													)}
											</span>
										</Col>
									</Row>
									<Row className=' px-2 mb-2'>
										<Col md='6' xs='8' align='end'>
											<span>Country:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ShipperCountry
												}
											</span>
										</Col>
									</Row>
								</Col>
								<Col className='text-left vertical__line'>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Name:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeName
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Surname:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeSurname
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Contact Number:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeContactNumber
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Email Address:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeEmailAddress
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Address 1:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeAddress1
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span className='text-nowrap'>
												Address 2:
											</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeAddress2
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Suburb:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeSuburb
												}
											</span>
										</Col>
									</Row>
									<Row className=' px-2'>
										<Col md='6' xs='8' align='end'>
											<span>Postal / Zip Code:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneePostalCodeZipCode
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>City:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeCity
												}
											</span>
										</Col>
									</Row>
									<Row className='px-2'>
										<Col md='6' xs='8' align='end'>
											<span>State / Province:</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span className='text-nowrap'>
												{invoiceDetails
													?.ShipmentManifest
													?.AddressInformation
													?.ConsigneeState &&
													getCountryState(
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeCountry,
														invoiceDetails
															?.ShipmentManifest
															?.AddressInformation
															?.ConsigneeState
													)}
											</span>
										</Col>
									</Row>
									<Row className=' px-2 mb-2'>
										<Col md='6' xs='8' align='end'>
											<span className='text-nowrap'>
												Country:
											</span>
										</Col>
										<Col md='6' xs='4' align='start'>
											<span>
												{
													invoiceDetails
														?.ShipmentManifest
														?.AddressInformation
														?.ConsigneeCountry
												}
											</span>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
						<div className='mx-4'>
							<Row className='py-2 invoice-header declaration__border tr__print text-white '>
								<Col className='text-center'>
									<span>ITEM DETAILS</span>
								</Col>
							</Row>
							<Row className='invoice-data declaration__border__all py-2  bg-white'>
								<Col className='text-center'>
									<strong className='text-secondary'>
										Description & Value Of Contents / Goods
										to be shipped.
									</strong>
								</Col>
							</Row>
							<Row className='invoice-booking'>
								<Table
									className='invoice-booking-details-table mb-0'
									responsive
								>
									<thead align='center'>
										{/* <th className="invoice-table-border border-left">Item No</th> */}
										<th className=' invoice-table-border-desc '>
											Country of Manufacture
										</th>
										<th className='invoice-table-border invoice-table-border-desc'>
											Qty / Units
										</th>
										<th className='invoice-table-border invoice-table-border-desc '>
											Item Description
										</th>
										<th className='invoice-table-border invoice-table-border-desc '>
											Material Type
										</th>
										<th className='invoice-table-border invoice-table-border-desc'>
											Unit Value{' '}
										</th>
										<th className=' line-val invoice-table-border-desc '>
											Total Line Value (EU)
										</th>
									</thead>
									<tbody>
										{invoiceDetails?.CommercialInvoice?.DeclaredItems?.Items.map(
											(item, index) => {
												shipmentValue =
													shipmentValue +
													item.totalValue;
												return (
													<tr
														key={index}
														className='decl__row'
													>
														<td className='text-center invoice-table-border-td border-left'>
															{
																item.CountryOfManufacture
															}
														</td>
														<td className='invoice-table-border-td text-center'>
															{item.Quantity}
														</td>
														<td className='invoice-table-border-td text-center'>
															{
																item.ItemDescription
															}
														</td>
														<td className='invoice-table-border-td text-center'>
															{
																item.TypeOfMaterial
															}
														</td>
														<td className='invoice-table-border-td text-center'>
															{item.UnitValue}
														</td>
														<td className='invoice-table-border-td border-right text-center'>
															{item.LineValue}
														</td>
													</tr>
												);
											}
										)}
									</tbody>
								</Table>
							</Row>
							<Row className='justify-content-end declaration__border'>
								<Col
									md='7'
									className=' d-flex justify-content-start align-items-center'
								>
									<span className='int_decl_inv_txt'>
										The value is declared in Portuguese Euro (EU)
									</span>
								</Col>
								<Col
									md='3'
									className='d-flex justify-content-end align-items-center'
								>
									<span className='text-nowrap int_decl_inv_txt '>
										Total Shipment Value (EU):
									</span>
								</Col>
								<Col
									lg='2'
									md='2'
									align='right'
									className='text-center p-0 border-left py-3'
								>
									<span className='int_decl_inv_txt '>
                                    <img className="mb-1 mr-1" src={EuropeanCurrecy} height={12} width={12}/>
										{
											invoiceDetails?.CommercialInvoice
												?.DeclaredItems
												?.TotalShipmentValue
										}
									</span>
								</Col>
							</Row>
							<Row className='invoice-header declaration__border tr__print py-2 text-white'>
								<Col className='text-center'>
									<span>DECLARATION</span>
								</Col>
							</Row>
							<Row className='invoice-header-transparent declaration__border__all mb-3  py-2'>
								<Col>
									<Row className='mt-2'>
										<Col md='8'>
											<span className='text-secondary'>
												I declare the information
												contained in this invoice is
												correct and truthful.
												{/* I declare that the information
												contained in the invoice is
												correct
												<br /> to the best of my
												knowledge. */}
											</span>
										</Col>
										<Col
											md='4'
											className='text-center mt-3'
										>
											<span className='text-secondary'>
												Signature
											</span>
										</Col>
									</Row>
									<Row className='mb-2 mt-4'>
										<Col
											md='8'
											className='d-flex justify-content-start align-items-end'
										>
											<span className='text-secondary'>
												Name & Surname:{'  '}
											</span>
											<span className='line___height'>
												---------------------------------------
											</span>
										</Col>
										<Col
											md='4'
											className='d-flex justify-content-center align-items-end'
										>
											<span className='line___height'>
												---------------------------------------
											</span>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</Container>
					</div>
				</>
			) : (
				<div className='loader-div'>
					<Spinner size='lg' />
				</div>
			)}
		</>
	);
}

export default DeclarationForm_pt;
