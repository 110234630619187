import React, { useEffect, useState } from 'react';
import {
	Container,
	Row,
	Spinner,
	Col,
	Card,
	CardText,
	CardBody,
	Table,
	CardTitle,
	Button,
	Input,
	Label,
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import firebase from '../../config/firebase';
import DeliveryDestination from '../../assets/img/DeliveryDestination.png';
import Calendar from '../../assets/img/Calendar.png';
import Payment from '../../assets/img/payment.png';
import Logo from '../../assets/img/logo.png';
import TabImg from '../../assets/img/table.PNG';
import ProcessList1 from '../ProcessList1';
function CommercialInvoice(props) {
	const [loader, setLoader] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [checkedState, setCheckedState] = useState(new Array(5).fill(false));

	let first = JSON.parse(localStorage.getItem('intl_first_page'));
	let second = JSON.parse(localStorage.getItem('intl_second_page'));
	let third = JSON.parse(localStorage.getItem('intl_third_page'));
	let fourth = JSON.parse(localStorage.getItem('intl_fourth_page'));
	let fifth = JSON.parse(localStorage.getItem('intl_fifth_page'));
	let sixth = JSON.parse(localStorage.getItem('intl_sixth_page'));
	let seventh = JSON.parse(localStorage.getItem('intl_seventh_page'));
	let eighth = JSON.parse(localStorage.getItem('intl_eighth_page'));
	let payment = JSON.parse(localStorage.getItem('intl_payment'));

	const checkTotal = () => {
		let totalSum = 0;
		let allValues = sixth.totalItems.map(elm => {
			return (totalSum = totalSum + elm.totalValue);
		});
		return totalSum;
	};

	const uploadToStorage = () => {
		setLoader(true);
		const commercial = document.getElementById('commercialToPrint');
		html2canvas(commercial, {
			onclone: document => {},
			// backgroundColor: null
		}).then(async canvas => {
			const imgCOM = canvas.toDataURL('image/png');

			const pdfJSX = new jspdf();
			const imgProps = pdfJSX.getImageProperties(imgCOM);
			const pdfWidth = pdfJSX.internal.pageSize.getWidth();
			const pdfHeight = pdfJSX.internal.pageSize.getHeight();
			pdfJSX.addImage(imgCOM, 'JPEG', 0, 0, pdfWidth, pdfHeight);
			// pdfJSX.addImage(img, "JPEG", 0, 0);
			const blob = new Blob([pdfJSX.output('blob')], {
				type: 'application/pdf',
			});
			const file = new File([blob], `${uuidv4()}.pdf`, {
				type: 'application/pdf',
				lastModified: Date.now(),
			});

			let filename = file.name;
			let ext2 = filename.slice(filename.lastIndexOf('.'));
			let file_name = uuidv4() + ext2.toLowerCase();
			let storageRef = await firebase
				.storage()
				.ref('recipient/' + file_name)
				.put(file);
			let url = await storageRef.ref.getDownloadURL();
			setLoader(false);

			let obj = { url };
			let seventh = localStorage.setItem(
				'intl_commercial_page',
				JSON.stringify(obj)
			);
		});
	};

	useEffect(() => {
		// uploadToStorage();
		updatePredicate();
		window.addEventListener('resize', updatePredicate);
		return () => window.removeEventListener('resize', updatePredicate);
	}, []);
	const updatePredicate = () => {
		setIsMobile(window.innerWidth < 767);
	};
	return (
		<>
			{/* <ProcessList1 activeIdx={4} /> */}
			<div>
				<Container
					id='commercialToPrint'
					className='invoice-component mt-4 '
				>
					<div className='mx-4'>
						<Row className='invoice-header text-white'>
							<Col className='text-left'>
								{/* <span>INVOICE DETAILS</span> */}
								<span>CONSIGNMENT DETAILS</span>
							</Col>
							<Col className='text-left'>
								{/* <span>INVOICE DETAILS</span> */}
								<span>REASON FOR EXPORT</span>
							</Col>
						</Row>
						<Row className='invoice-data bg-white'>
							<Col className='text-left '>
								<Row className='mt-2 px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Total Weight: </span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{second.totalWeight} Kg</span>
									</Col>
								</Row>
								<Row className='mt-1 px-2 mb-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Volumetric Weight: </span>
									</Col>
									<Col lg='5' xs='4'>
										<span>
											{second.volumetricWeight} Kg
										</span>
									</Col>
								</Row>
							</Col>
							<Col className='text-left vertical__line'>
								<Row className='mt-2' noGutters>
									<Col md='4'>
										<Row>
											<Col xs='7' align='start'>
												<span>Personal Use</span>
											</Col>
											<Col>
												<Input
													className='ml-2 mt-2'
													type='checkbox'
													id='export1'
													name='export'
													value='Personal Use'
													readOnly
													checked={
														sixth.reason ==
														'Personal Use'
													}
												/>
											</Col>
										</Row>
									</Col>
									<Col md='4'>
										<Row>
											<Col xs='7' align='start'>
												<span>Commercial</span>
											</Col>
											<Col>
												<Input
													className='ml-2 mt-2'
													type='checkbox'
													id='export2'
													name='export'
													value='Commercial'
													readOnly
													checked={
														sixth.reason ==
														'Commercial'
													}
												/>
											</Col>
										</Row>
									</Col>
									<Col md='4'>
										<Row>
											<Col xs='7' align='start'>
												<span>Repair/Return</span>
											</Col>
											<Col>
												<Input
													className='ml-2 mt-2'
													type='checkbox'
													id='export3'
													name='export'
													value='Repair'
													readOnly
													checked={
														sixth.reason ==
														'Repair/Return'
													}
												/>
											</Col>
										</Row>
									</Col>
									<Col md='4'>
										<Row>
											<Col xs='7' align='start'>
												<span>Gift</span>
											</Col>
											<Col>
												<Input
													className='ml-2 mt-2'
													type='checkbox'
													id='export4'
													name='export'
													value='Gift'
													readOnly
													checked={
														sixth.reason == 'Gift'
													}
												/>
											</Col>
										</Row>
									</Col>
									<Col md='4'>
										<Row>
											<Col xs='7' align='start'>
												<span>Samples</span>
											</Col>
											<Col>
												<Input
													className='ml-2 mt-2'
													type='checkbox'
													id='export5'
													name='export'
													value='Samples'
													readOnly
													checked={
														sixth.reason ==
														'Samples'
													}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row className='invoice-header text-white'>
							<Col className='text-center'>
								<span>SHIPMENT DETAILS</span>
							</Col>
						</Row>
						<Row className='invoice-data bg-white text-dark font-weight-bold pt-2'>
							<Col className='text-center'>
								<h6 className='font-weight-bold'>SHIPPER</h6>
							</Col>
							<Col className='text-center'>
								<h6 className='font-weight-bold'>CONSIGNEE</h6>
							</Col>
						</Row>
						<Row className=' invoice-data bg-white'>
							<Col className='text-left'>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Name:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.sendingName}</span>
									</Col>
								</Row>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Contact Number:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.sendingContact}</span>
									</Col>
								</Row>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Email Address:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.sendingEmail}</span>
									</Col>
								</Row>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Address 1:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.sendingAddress1}</span>
									</Col>
								</Row>
								<Row className='mt-1 px-2'>
									<Col lg='5' xs='8' align='end'>
										<span className='text-nowrap'>
											Complex / Business Park / Estate:
										</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>
											{fifth.sendingComplex
												? fifth.sendingComplex
												: ''}
										</span>
									</Col>
								</Row>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Suburb:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.sendingSuburb}</span>
									</Col>
								</Row>
								<Row className=' px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Postal Code / Zip Code:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.sendingPostal}</span>
									</Col>
								</Row>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>City:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.sendingCity}</span>
									</Col>
								</Row>
								<Row className=' px-2 mb-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Country:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{first.fromCountry}</span>
									</Col>
								</Row>
							</Col>
							<Col className='text-left vertical__line'>
								<Row className=' px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Name:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.ReceivingName}</span>
									</Col>
								</Row>
								<Row className=' px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Contact Number:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.ReceivingContact}</span>
									</Col>
								</Row>
								<Row className=' px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Email Address:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.ReceivingEmail}</span>
									</Col>
								</Row>
								<Row className=' px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Address 1:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.ReceivingAddress1}</span>
									</Col>
								</Row>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span className='text-nowrap'>
											Complex / Business Park / Estate:
										</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>
											{fifth.ReceivingComplex
												? fifth.ReceivingComplex
												: ''}
										</span>
									</Col>
								</Row>
								<Row className=' px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Suburb:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.ReceivingSuburb}</span>
									</Col>
								</Row>
								<Row className=' px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Postal Code / Zip Code:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.ReceivingPostal}</span>
									</Col>
								</Row>
								<Row className='px-2'>
									<Col lg='5' xs='8' align='end'>
										<span>City:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{fifth.ReceivingCity}</span>
									</Col>
								</Row>
								<Row className=' px-2 mb-2'>
									<Col lg='5' xs='8' align='end'>
										<span>Country:</span>
									</Col>
									<Col lg='5' xs='4'>
										<span>{first.toCountry}</span>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					{/* <div className="m-2">
          <Row>
            <Col lg="6" align="left" className="font-weight-bold">
              <span>
                <u>Important Information</u>
              </span>
              <ul>
                <li>
                  Please declare each item separately and accurately as possible
                </li>
                <li>Do not group items, see example {`>>`}</li>
                <li>Refrain from declaring a item as a {"gift"}</li>
                <li>Use realistic values when declaring each item</li>
              </ul>
            </Col>
            <Col lg="6">
              <img
                src={TabImg}
                alt="tabImg"
                className="w-100 mt-3"
                height="80px"
              />
            </Col>
          </Row>
        </div> */}

					<div className='mx-4'>
						<Row className='invoice-header text-white'>
							<Col className='text-center'>
								<span>ITEM DETAILS</span>
							</Col>
						</Row>
						{/* <Row className="invoice-data  mx-1">
            <Col className="text-center">
              <span>Description & Value of Content/Goods to be shipped.</span>
            </Col>
          </Row> */}
						<Row>
							<Table className='invoice-booking-details-table'>
								<thead>
									{/* <th className="invoice-table-border border-left">Item No</th> */}
									<th className='invoice-table-border border-left'>
										Country Of Manufacture
									</th>
									{/* <th className="invoice-table-border">| Quantity</th> */}
									<th className='invoice-table-border invoice-table-border-desc '>
										Qty/Units
									</th>
									<th className='invoice-table-border invoice-table-border-desc '>
										Item Description
									</th>
									<th className='invoice-table-border invoice-table-border-desc '>
										Material
									</th>
									<th className='invoice-table-border invoice-table-border-desc '>
										Unit Value
									</th>
									{/* <th className="invoice-table-border"> Unit Value </th> */}
									<th className='invoice-table-border border-right'>
										{' '}
										Total Line Value
									</th>
								</thead>
								<tbody>
									{/* {second.itemDetails.map((item, idx) => (
                <tr key={item.idx}>
                  <td className="invoice-table-border-td border-left">
                    <span className="d-flex">
                      {" "}
                      <Input type="text" className="w-75" />
                    </span>
                  </td>
                  <td className="invoice-table-border-td ">
                    <span className="d-flex">
                      {" "}
                      <Input
                        type="number"
                        min="1"
                        className="w-75 border-1"
                        onChange={(e) => handleQtyChange(idx, e.target.value)}
                      />
                    </span>
                  </td>
                  <td className="invoice-table-border-td">
                    <span className="d-flex">
                       <Input type="text" className="w-75" />
                    </span>
                  </td>
                  <td className="invoice-table-border-td">
                    <span className="d-flex">
                       <Input type="text" className="w-75" />
                    </span>
                  </td>
                  <td className="invoice-table-border-td">
                    <span className="d-flex">
                      {" "}
                      <Input
                        type="number"
                        min="1"
                        className="w-75"
                        onChange={(e) => handleUnitChange(idx, e.target.value)}
                      />
                    </span>
                  </td>
                  <td className="invoice-table-border-td border-right">
                     R{handleTotalUnit(idx)}
                  </td>
                </tr>
              ))} */}
									{sixth.totalItems.map((item, i) => (
										<tr>
											<td className='invoice-table-border-td border-left'>
												{item.country}
											</td>
											<td className='invoice-table-border-td'>
												{' '}
												{item.quantity}
											</td>
											<td className='invoice-table-border-td'>
												{' '}
												{item.description}
											</td>
											<td className='invoice-table-border-td'>
												{' '}
												{item.type}
											</td>
											<td className='invoice-table-border-td'>
												{' '}
												R{item.unitValue}
											</td>
											<td className='invoice-table-border-td border-right'>
												R{item.totalValue}
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Row>
					</div>
					<Row className='justify-content-end m-2'>
						<Col
							lg='3'
							md='4'
							align='right'
							className='text-left mb-3'
						>
							{/* <FormGroup check> */}
							{/* <Input
                  type='checkbox'
                  onChange={() => setIsChecked(!isChecked)}
                /> */}

							<Card className='p-2 item-detail-weight'>
								<span>
									Total Shipment Value: R {checkTotal()}
								</span>
							</Card>
							{/* <br />
                <span className='text-danger'>
                  * Items not insured for loss or damage
                </span> */}
							{/* </FormGroup> */}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default CommercialInvoice;
