import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button, Card, Table } from "reactstrap";
import Fade from "react-reveal";
import Button_Airplane from "../assets/img/Button_Airplane.png";
import Button_Truck from "../assets/img/Button_Truck.png";
import Button_Ship from "../assets/img/Button_Ship.png";

import Measure_guideline from "../assets/img/Measure_guideline.png";

import Box_measure_guideline from "../assets/img/Box_measure_guideline.png";
import Package_guideline from "../assets/img/Package_guideline.png";
import Scale_guideline from "../assets/img/Scale_guideline.png";
import { HashLink } from "react-router-hash-link";

function Guidelines() {
  const [active, setActive] = useState(1);
  //Scroll To Top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12" className="mb--40 p-0">
            <div className={`section-title-bg text-center py-2 mb-4`}>
              <Fade bottom>
                <h1 className="title w-600 mb--20">PACKING GUIDE</h1>
              </Fade>
              <Fade bottom>
                <p className="description  mb-0">
                  Securing and packing your parcel/cargo correctly is important.
                  <br />
                  Our packing guidelines, outline important points to consider
                  before loading your shipment.
                </p>
              </Fade>
            </div>
          </Col>
        </Row>
        {/* <h2 className='text-center  main_heading  text-primary '>
					Packaging Do’s & Don’ts
				</h2> */}
        {/* <hr /> */}
        <Container>
          <div className="mt-4 mb-5 prohibted_guide">
            <Row className="px-0 px-md-4 justify-content-center">
              <Col lg="4">
                <Button
                  className={`${
                    active == 1 ? "bg-primary" : "bg-secondary"
                  } resources_btn  mt-2 mt-lg-0`}
                  onClick={() => setActive(1)}
                >
                  COURIER
                  <div className="ml-auto">
                    <img src={Button_Truck} className=" img-fluid" width="30" />
                    <img
                      src={Button_Airplane}
                      className="ml-2 img-fluid"
                      width="30"
                    />
                  </div>
                </Button>
              </Col>

              <Col lg="4">
                <Button
                  className={`${
                    active == 2 ? "bg-primary" : "bg-secondary"
                  } resources_btn  mt-2 mt-lg-0`}
                  onClick={() => setActive(2)}
                >
                  SEA FREIGHT
                  <img
                    src={Button_Ship}
                    className="ml-auto img-fluid"
                    width="30"
                  />
                </Button>
              </Col>
            </Row>
            <br />

            {active == 1 && (
              <div className="mt-4 resources_section">
                <Row>
                  <Col md="6">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <img
                        src={Package_guideline}
                        className=" guideline__logo"
                      />
                    </div>

                    <h4 className="my-2 text-primary heading d-flex justify-content-center align-items-center ">
                      Packing Guidelines
                    </h4>

                    <p className="text-primary font-weight-bold">
                      During transit, your package will be handled multiple
                      times. Your package will go through sorting facilities.
                      Other packages might be stacked on top of it, and
                      sometimes the final delivery destination can be a bit
                      bumpy.
                      <br /> Ship My Stuff has outlined important criteria below
                      to help you secure your package.
                    </p>
                    <ul>
                      <li>
                        <strong>General overview,</strong>
                        <ul>
                          <li>Do not overload your carton/box</li>
                          <li>Use original packaging where possible</li>
                          <li>
                            Household packaging (scrunched up paper or
                            shredded), is not recommended
                          </li>
                          <li>
                            Cushioning the contents with at least 5-8cm around
                            the item will help with protection in transit and
                            sortation
                          </li>
                        </ul>
                      </li>

                      <li>
                        <strong>Securing your box,</strong> use a ‘wide
                        pressure’ plastic tape, to ensure safe transportation.
                        Do not use the following tapes to secure your
                        box/carton:
                        <ul>
                          <li>Masking tape</li>
                          <li>Scotch tape</li>
                          <li>Duct tape</li>
                          <li>Strings</li>
                        </ul>
                      </li>
                      <li>
                        <strong>Use a box/carton</strong> That is structurally
                        sound and in good condition, preferably ‘corrugated’
                        with flaps
                      </li>
                      <ul>
                        <li>
                          Make sure the box can maintain at least five times the
                          weight of your items inside, without making the box
                          bend or tear
                        </li>
                        <li>
                          Ensure the box has enough room for protective material
                          (5-8cm)
                        </li>
                        <li>Preferable double-walled boxes</li>
                        <li>
                          Do not overload your box/carton, lids should not bulge
                        </li>
                        <li>
                          Use original packing material/boxes where possible
                        </li>
                        <li>
                          Use additional protective material (bubble wrap or
                          newspaper) around the item where possible
                        </li>
                        <li>Remove old courier labels</li>
                      </ul>
                      <li>
                        <strong>Fragile Items,</strong> it is important to mark
                        the box ‘Fragile’ with a coloured marker pen.
                        <br /> This will ensure additional care is taken during
                        the sortation and transportation of your parcel.
                      </li>
                      <li>
                        <strong>Handling heavy items</strong> (more than 18Kgs),
                        marking the box/carton ‘Heavy’ will guide the
                        transporters in handling the package appropriately
                      </li>
                      <ul>
                        <li>
                          Consider splitting box/carton into two boxes/cartons
                          if possible
                        </li>
                        <li>
                          Use original packaging if available which contains the
                          original polystyrene protection
                        </li>
                      </ul>
                      <li>
                        <strong>Re-using old box/carton,</strong> make sure
                        there are no damages (holes / old labels/tears) to the
                        box. Do not use a box that cannot hold at least five
                        times the weight of your parcel!
                      </li>
                    </ul>
                  </Col>
                  <Col md="6">
                    <Row className="mb-3">
                      <Col
                        xs="6"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={Scale_guideline}
                          className=" guideline__logo"
                        />
                      </Col>
                      <Col
                        xs="6"
                        className="d-flex justify-content-center align-items-center guidline__border__left"
                      >
                        <img
                          src={Measure_guideline}
                          className=" guideline__logo"
                        />
                      </Col>
                    </Row>
                    <h4 className="my-2 text-primary heading d-flex justify-content-center align-items-center ">
                      Weigh and Measure
                    </h4>
                    <p className="font-weight-bold">
                      Consignments are charges on the greater of either the
                      total physical weight or the total volumetric weight.
                    </p>
                    <p className="font-weight-bold">
                      Some items are high in volumetric weight but are low in
                      actual weight.
                    </p>
                    <p className="font-weight-bold">
                      E.g. guitar case, pillows, nappies and etc.
                    </p>

                    <div className="d-flex justify-content-center align-items-center flex-column border py-5 mt-4 border-primary">
                      <Row className="mt-4">
                        <Col
                          xs="12"
                          className=" d-flex justify-content-center align-items-center"
                        >
                          <p className="font-weight-bold heading">
                            Volumetric Weight Calculation
                          </p>
                        </Col>
                      </Row>

                      <Row className="mt-3 justify-content-center">
                        <Col
                          xs="12"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <span className="text-primary">
                            Length (cm) x Height (cm) x Width (cm)
                          </span>
                        </Col>
                        <Col xs="12" md="7" className="text-center">
                          <hr className="mt-1 mb-1" />
                        </Col>
                        <Col
                          xs="12"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <span className="text-primary">5000</span>
                        </Col>
                      </Row>
                      <Row className="mt-2 justify-content-center">
                        {/* <Col lg='6'>
												<Card className='rounded-0 p-1'>
													<p className='font-weight-bold text-secondary'>
														Example 1: Package 1
													</p>
													<p>
														Dimensions: 10cm x 40cm
														x 80cm
													</p>
													<p>
														Volumetric Weight:
														Dimensions / 5000 =
														6.4kg
													</p>
													<p>
														Chargeable Weight:
														<strong className='ml-1'>
															Actual Weight
														</strong>
													</p>
												</Card>
											</Col>
											<Col lg='6'>
												<Card className='rounded-0 p-1'>
													<p className='font-weight-bold text-secondary'>
														Example 2: Package 1
													</p>
													<p>
														Dimensions: 10cm x 40cm
														x 190cm
													</p>
													<p>
														Volumetric Weight:
														Dimensions / 5000 =
														15.2kg
													</p>
													<p>
														Chargeable Weight:
														<strong className='ml-1'>
															Actual Weight
														</strong>
													</p>
												</Card>
											</Col> */}
                        <Col
                          xs="12"
                          className="d-flex justify-content-center align-items-center mt-3"
                        >
                          <img
                            src={Box_measure_guideline}
                            className="img-fluid w-50"
                          />
                        </Col>
                        <Col xs="12" md="7" c>
                          <hr className="mt-2-mb-2 " />
                        </Col>
                      </Row>

                      <Row className="">
                        <Col
                          xs="12"
                          className="pb-2 d-flex justify-content-center align-items-center"
                        >
                          <p className="font-weight-bold heading">EXAMPLE</p>
                        </Col>
                      </Row>

                      <Row className="mt-2 justify-content-center">
                        <Col
                          xs="12"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <span className="text-primary">
                            10cm x 40cm x 190cm
                          </span>
                        </Col>
                        <Col xs="12" md="7">
                          <hr className="mt-1 mb-1 " />
                        </Col>
                        <Col
                          xs="12"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <span className="text-primary">5000</span>
                        </Col>
                      </Row>

                      <Row className="mt-5 mb-4 justify-content-center">
                        <Col
                          xs="12"
                          md="12"
                          className="d-flex justify-content-center align-items-center"
                        >
                          <p className="text-nowrap">
                            <span className="text-primary">
                              Volumetric weight =
                            </span>
                            <span className="text-secondary ml-1">15.2 kg</span>
                          </p>
                        </Col>
                        <Col
                          xs="12"
                          md="12"
                          className="d-flex justify-content-center align-items-center "
                        >
                          <p className="text-nowrap">
                            <span className="text-primary">
                              Physical weight =
                            </span>
                            <span className="text-secondary ml-1">11 kg</span>
                          </p>
                        </Col>
                        <Col
                          xs="12"
                          className="d-flex justify-content-center align-items-center mt-3"
                        >
                          <p className="text-nowrap">
                            <span className="text-primary">
                              Chargeable weight =
                            </span>
                            <span className="text-secondary ml-1">15.2 kg</span>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xs="12" className="">
                    <Row className="mt-3 resources_section ">
                      <Col className="" md="12">
                        <h1>Recommended packaging</h1>
                      </Col>
                    </Row>
                    <Table responsive className="guidlines__table mt-2">
                      <thead className="align-self-center">
                        <tr>
                          <th className="text-center">
                            Awkward / Unique Items
                          </th>
                          <th className="border__left text-center">
                            Recommened Packaging
                          </th>
                          <th className="border__left text-center">Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>BBQ</td>
                          <td className="border__left">CARTON/BOX/FLAT PACK</td>
                          <td className="border__left">
                            Original packaging is preferable.
                          </td>
                        </tr>
                        <tr>
                          <td>Beds</td>
                          <td className="border__left">FLAT PACKED</td>
                          <td className="border__left"></td>
                        </tr>
                        <tr>
                          <td className="border__left">Bicycle / Bike</td>
                          <td className="border__left">FLAT PACKED</td>
                          <td className="border__left"></td>
                        </tr>
                        <tr>
                          <td>Blinds</td>
                          <td className="border__left">TUBE/BOX</td>
                          <td className="border__left">
                            Wrapped in bubble wrap inside the tube.
                          </td>
                        </tr>

                        <tr>
                          <td>Books</td>
                          <td className="border__left">BOX</td>
                          <td className="border__left">
                            Tightly packed inside Box with padding.
                          </td>
                        </tr>

                        <tr>
                          <td>Car Panels / Boots / Bumper Bars</td>
                          <td className="border__left">CARTON/BOX/CRATE</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Chair/s</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Clothing</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>
                            Electronic Equipment (Printers, Laptops,
                            photographic, scanners, TVs, etc.)
                          </td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Preferably in original packaging. Otherwise bubble
                            wrap inside the box.
                          </td>
                        </tr>

                        <tr>
                          <td>COT / Pram / Stroller</td>
                          <td className="border__left">FLAT PACK/CARTON/BOX</td>
                          <td className="border__left">
                            Needs to be packed inside a box with bubble wrap as
                            padding.
                          </td>
                        </tr>
                        <tr>
                          <td>Cutlery</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>
                        <tr>
                          <td>DVD player / DVD’s / CD’s</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Engines</td>
                          <td className="border__left">CARTON/BOX/CRATE</td>
                          <td className="border__left">
                            Engines under 35kgs required foam or padding inside.
                            Engines over 35kgs must be packaged with foam and an
                            enclosed wooden crate. All fluids must be drained,
                            leakage will be charged to the shipper’s account.
                          </td>
                        </tr>

                        <tr>
                          <td>Furniture</td>
                          <td className="border__left">FLATPACK</td>
                          <td className="border__left">
                            All furniture must be disassembled and wrapped in
                            bubble wrap inside the flat pack.
                          </td>
                        </tr>

                        <tr>
                          <td>Gearbox</td>
                          <td className="border__left">CARTON/BOX/CRATE</td>
                          <td className="border__left">
                            Gearboxes under 35kgs required foam or padding
                            inside. Gearboxes over 35kgs must be packaged with
                            foam and an enclosed wooden crate. All fluids must
                            be drained, leakage will be charged to the shipper’s
                            account.
                          </td>
                        </tr>

                        <tr>
                          <td>Gifts</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Ensure lots of bubble wrap for fragile items.
                          </td>
                        </tr>

                        <tr>
                          <td>Golf Clubs / Golf Bags</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Golf Clubs / Golf Bags should be wrapped with bubble
                            wrap inside a sturdy box.
                          </td>
                        </tr>

                        <tr>
                          <td>Heaters</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Oil heaters need to be drained before being
                            packaged.
                          </td>
                        </tr>

                        <tr>
                          <td>Homewares / Kitchenware</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Lots of bubble wrap for fragile items.
                          </td>
                        </tr>

                        <tr>
                          <td>Luggage / Suitcase</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Strong and durable material should be used with
                            bubble wrap and cardboard.
                          </td>
                        </tr>

                        <tr>
                          <td>Motor accessories / Panels / Diffs / Towbars</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            All items must be drained of all fluids, leakage
                            will be charged to the shipper’s account.
                          </td>
                        </tr>

                        <tr>
                          <td>Musical Instruments</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Requires a hard case with bubble wrap.
                          </td>
                        </tr>

                        <tr>
                          <td>Ski’s</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Surfboard / Wakeboard</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Fins should be removed and wrapped in bubble wrap
                            with foam padding inside a box.
                          </td>
                        </tr>

                        <tr>
                          <td>Tools / Toolbox</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Tent / Camping Equipment</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Tyres / Wheel / Mag</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Packaged inside a sturdy box with protection.
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            )}

            {active == 2 && (
              <div className="mt-4 resources_section">
                <Row>
                  <Col md="6">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                      <img
                        src={Package_guideline}
                        className=" guideline__logo"
                      />
                    </div>
                    <h4 className="my-2 text-primary heading d-flex justify-content-center align-items-center ">
                      Packing Guidelines
                    </h4>
                    <p className="">
                      The same packing rules apply for sea freight as for
                      international courier when shipping boxes via sea.
                    </p>
                    <p>
                      Shipping boxes and/or general cargo requires palletisation
                      to further secure and handle the cargo appropriately.
                    </p>
                    <p>
                      A move cube is an optional and convenient method for
                      packing cargo without needing to worry about additional
                      packing material.
                    </p>
                  </Col>
                  <Col md="6"className="mt-2" >
                    <Row className="mb-3">
                      <Col
                        xs="6"
                        className="d-flex justify-content-center align-items-center"
                      >
                        <img
                          src={Scale_guideline}
                          className=" guideline__logo"
                        />
                      </Col>
                      <Col
                        xs="6"
                        className="d-flex justify-content-center align-items-center guidline__border__left"
                      >
                        <img
                          src={Measure_guideline}
                          className=" guideline__logo"
                        />
                      </Col>
                    </Row>
                    <h4 className="my-2 text-primary heading d-flex justify-content-center align-items-center ">
                      Weigh and Measure
                    </h4>
                    <p>
                      A cubic meter (CBM) metric is used when shipping
                      boxes/general cargo via containers.
                    </p>
                    {/* <p className='mt-2'>The formula</p>
										<p>
											Length (cm) X Width (cm) X Height
											(cm) / 1000000 = Cubic meter (m3)
										</p> */}
                    <HashLink
                      to="/main#weightcalculator"
                      scroll={(el) => {
                        el.scrollIntoView({
                          behavior: "auto",
                          block: "start",
                        });
                      }}
                    >
                      <p className="mt-2">
                        <u className="text-primary font-weight-bold ">
                          Try our volumetric calculator
                        </u>
                      </p>
                    </HashLink>

                    <p className="mt-2">
                      There are 3 shipping configurations when shipping your
                      cargo by sea:
                    </p>
                    <ul>
                      <li>
                        LCL - Per cube
                        <ul>
                          <li>
                            Dimensions: length (1m) x width (1m) x height (1m)
                          </li>
                          <li>Max weight: 1000 Kg</li>
                        </ul>
                      </li>

                      <li>
                        FCL - 20ft container
                        <ul>
                          <li>Cubic meters: 33 CBM</li>
                          <li>Max weight: 25,000 Kg</li>
                          <li>
                            Dimensions (internal): length (5.9m) x width (2.35m)
                            x height (2.39m)
                          </li>
                        </ul>
                      </li>

                      <li>
                        FCL - 40ft container
                        <ul>
                          <li>Cubic meters: 67 CBM</li>
                          <li>Max weight: 27,500 Kg</li>
                          <li>
                            Dimensions (internal): length (12.03m) x width
                            (2.35m) x height (2.39m)
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </Col>

                  <Col xs="12" className="">
                    <Row className="mt-3 resources_section ">
                      <Col className="" md="12">
                        <h1>Recommended packaging</h1>
                      </Col>
                    </Row>
                    <Table responsive className="guidlines__table mt-2">
                      <thead>
                        <tr>
                          <th className="text-center">
                            Awkward / Unique Items
                          </th>
                          <th className="border__left text-center">
                            Recommened Packaging
                          </th>
                          <th className="border__left text-center">Comments</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Large furniture (Couches, Cabinets, and desk
                            servers)
                          </td>
                          <td className="border__left">WRAPPED </td>
                          <td className="border__left">
                            Couches, cabinets, and desk servers are wrapped with
                            protective layers of cardboard material.
                          </td>
                        </tr>

                        <tr>
                          <td>
                            Sentimental furniture (Couches, Cabinets, and desk
                            servers)
                          </td>
                          <td className="border__left">CRATED</td>
                          <td className="border__left">
                            Preferably bubble wrapped and crated to ensure
                            additional protection during transit.
                          </td>
                        </tr>

                        <tr>
                          <td>Motorbikes / Scooters / Vesper</td>
                          <td className="border__left">CRATED</td>
                          <td className="border__left">
                            All engine and gearbox fluids need to be drained.
                            Additional clearance and permits will be required.
                          </td>
                        </tr>

                        <tr>
                          <td>Car</td>
                          <td className="border__left"></td>
                          <td className="border__left">
                            All fluids need to be drained, additional clearance
                            and permits may be required.
                          </td>
                        </tr>

                        <tr>
                          <td>Gym Equipment</td>
                          <td className="border__left">CARTON/BOX/CRATE</td>
                          <td className="border__left">
                            All gym equipment needs to be disassembled and
                            wrapped in bubble wrap inside the flat-pack or
                            crates.
                          </td>
                        </tr>

                        <tr>
                          <td>Large Appliances</td>
                          <td className="border__left">WRAPPED/BOXED</td>
                          <td className="border__left">
                            Preferable original packaging or cardboard wrapped.
                          </td>
                        </tr>

                        <tr>
                          <td>BBQ</td>
                          <td className="border__left">CARTON/BOX/FLAT PACK</td>
                          <td className="border__left">
                            Original packaging is preferable.
                          </td>
                        </tr>
                        <tr>
                          <td>Beds</td>
                          <td className="border__left">FLAT PACKED</td>
                          <td className="border__left"></td>
                        </tr>
                        <tr>
                          <td className="border__left">Bicycle / Bike</td>
                          <td className="border__left">FLAT PACKED</td>
                          <td className="border__left"></td>
                        </tr>
                        <tr>
                          <td>Blinds</td>
                          <td className="border__left">TUBE/BOX</td>
                          <td className="border__left">
                            Wrapped in bubble wrap inside the tube.
                          </td>
                        </tr>

                        <tr>
                          <td>Books</td>
                          <td className="border__left">BOX</td>
                          <td className="border__left">
                            Tightly packed inside Box with padding.
                          </td>
                        </tr>

                        <tr>
                          <td>Car Panels / Boots / Bumper Bars</td>
                          <td className="border__left">CARTON/BOX/CRATE</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Chair/s</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Clothing</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>
                            Electronic Equipment (Printers, Laptops,
                            photographic, scanners, TVs, etc.)
                          </td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Preferably in original packaging. Otherwise bubble
                            wrap inside the box.
                          </td>
                        </tr>

                        <tr>
                          <td>COT / Pram / Stroller</td>
                          <td className="border__left">FLAT PACK/CARTON/BOX</td>
                          <td className="border__left">
                            Needs to be packed inside a box with bubble wrap as
                            padding.
                          </td>
                        </tr>
                        <tr>
                          <td>Cutlery</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>
                        <tr>
                          <td>DVD player / DVD’s / CD’s</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Engines</td>
                          <td className="border__left">CARTON/BOX/CRATE</td>
                          <td className="border__left">
                            Engines under 35kgs required foam or padding inside.
                            Engines over 35kgs must be packaged with foam and an
                            enclosed wooden crate. All fluids must be drained,
                            leakage will be charged to the shipper’s account.
                          </td>
                        </tr>

                        <tr>
                          <td>Furniture</td>
                          <td className="border__left">FLATPACK</td>
                          <td className="border__left">
                            All furniture must be disassembled and wrapped in
                            bubble wrap inside the flat pack.
                          </td>
                        </tr>

                        <tr>
                          <td>Gearbox</td>
                          <td className="border__left">CARTON/BOX/CRATE</td>
                          <td className="border__left">
                            Gearboxes under 35kgs required foam or padding
                            inside. Gearboxes over 35kgs must be packaged with
                            foam and an enclosed wooden crate. All fluids must
                            be drained, leakage will be charged to the shipper’s
                            account.
                          </td>
                        </tr>

                        <tr>
                          <td>Gifts</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Ensure lots of bubble wrap for fragile items.
                          </td>
                        </tr>

                        <tr>
                          <td>Golf Clubs / Golf Bags</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Golf Clubs / Golf Bags should be wrapped with bubble
                            wrap inside a sturdy box.
                          </td>
                        </tr>

                        <tr>
                          <td>Heaters</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Oil heaters need to be drained before being
                            packaged.
                          </td>
                        </tr>

                        <tr>
                          <td>Homewares / Kitchenware</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Lots of bubble wrap for fragile items.
                          </td>
                        </tr>

                        <tr>
                          <td>Luggage / Suitcase</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Strong and durable material should be used with
                            bubble wrap and cardboard.
                          </td>
                        </tr>

                        <tr>
                          <td>Motor accessories / Panels / Diffs / Towbars</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            All items must be drained of all fluids, leakage
                            will be charged to the shipper’s account.
                          </td>
                        </tr>

                        <tr>
                          <td>Musical Instruments</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Requires a hard case with bubble wrap.
                          </td>
                        </tr>

                        <tr>
                          <td>Ski’s</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Surfboard / Wakeboard</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Fins should be removed and wrapped in bubble wrap
                            with foam padding inside a box.
                          </td>
                        </tr>

                        <tr>
                          <td>Tools / Toolbox</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Tent / Camping Equipment</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left"></td>
                        </tr>

                        <tr>
                          <td>Tyres / Wheel / Mag</td>
                          <td className="border__left">CARTON/BOX</td>
                          <td className="border__left">
                            Packaged inside a sturdy box with protection.
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Container>
      </Container>
    </>
  );
}

export default Guidelines;
