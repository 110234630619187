import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
} from "reactstrap";
import countryData from "../../config/intlCountries.json";

import arrow2 from "../../assets/img/arrows2.png";
import axios from "axios";
import allCountries from "../../config/countriesList.json";
import countriesStates from "../../config/countriesStates.json";
import postalAwareCountries from "../../config/postalAwareCountries.json";
// import Autocomplete from "react-autocomplete";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { responsiveFontSizes, withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import ThemeButton from "../ThemeButton";

import ReactFlagsSelect from "react-flags-select";
import ServicesHeader from "../ServicesHeader";
import InternationalActiveTab from "./InternationalActiveTab";
import { useSelector } from "react-redux";

const CustomAutoComplete = styled(Autocomplete)`
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100% !important;
`;

const StyledTextField = styled(TextField)`
  background: white;
  border-radius: 0.25rem;
  & label {
    font-size: 0.85rem;
    margin-left: 13px;
    font-weight: 400;
    color: #495057;
  }
  & label.Mui-focused {
    color: white;
  }

  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
      border-bottom: none;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
  & .MuiInputBase-input {
    padding-left: 12px !important;
    color: #495057 !important;
  }
`;
function CountrySelection(props) {
  console.log(props, "props");
  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry, "selectedCountry");
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);
  const [importFrom, setImportFrom] = useState(false);
  const [exportFrom, setExportFrom] = useState(false);
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const firstPage = JSON.parse(localStorage.getItem("intl_first_page"));
  const [checkedState, setCheckedState] = useState(new Array(2).fill(false));

  const [fromPost, setFromPost] = useState([]);
  const [toPost, setToPost] = useState([]);

  const [fromState, setFromState] = useState([]);
  const [toState, setToState] = useState([]);

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : (item = false)
    );
    setCheckedState(updatedCheckedState);
  };

  const setFromCountryStates = (code) => {
    console.log(code, "code>>>>>>>>>>>>");
    let obj = countriesStates.find((state) => state.name == code);

    if (obj && obj.states && obj.states.length > 0) {
      setFromPost(obj.states);
    } else {
      setFromPost([]);
    }
    props.onChange("fromCountry", code);
  };

  const setToCountryStates = (code) => {
    console.log(code, "code>>>>>>");
    let obj = countriesStates.find((state) => state.name == code);
    if (obj && obj.states && obj.states.length > 0) {
      setToPost(obj.states);
    } else {
      setToPost([]);
    }
    props.onChange("toCountry", code);
  };

  const Country =
    countryData &&
    countryData.map((c) => {
      return c.Countries;
    });

  const getSortedData = (arr) => {
    return arr.sort((a, b) => (a.name < b.name ? -1 : 1));
  };

  const getFromPostValue = (val) => {
    let obj = fromPost.find((data) => data.CODE == val);
    if (obj) {
      return obj;
    } else {
      return "";
    }
  };
  const [selected, setSelected] = useState("");
  const getToPostValue = (val) => {
    let obj = toPost.find((data) => data.CODE == val);
    if (obj) {
      return obj;
    } else {
      return "";
    }
  };

  const postalAwareCheck = (code) => {
    let obj = postalAwareCountries.find((Country) => Country.CODE == code);
    if (obj) {
      return true;
    } else {
      return false;
    }
  };
  if (windowsize < 768) {
    return (
      <Row className="country-selection-component select-country justify-content-center no-gutters">
        <Col xs="12">
          <Row className=" justify-content-center">
            <Col xs="11" md="11">
              <ServicesHeader value="Express / Courier Services" />
              <InternationalActiveTab
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
              />
              <hr className="service-header__hr-100" />
            </Col>
          </Row>
        </Col>
        {selectedCountry == "PT" && (
          <Col xs="11">
            <Row className="justify-content-center">
              <Col sm="12" xs="12" className="text-left">
                <Label for="examplePassword">Contact Info:</Label>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                md="12"
                xs="12"
                className="text-left d-flex align-items-center"
              >
                <FormGroup className="w-100">
                  <Input
                    id="email"
                    placeholder="Enter Email Address"
                    className="country__selection__input__width"
                    type="email"
                    name="email"
                    onChange={(e) =>
                      props.contactDetailsChange("email", e.target.value)
                    }
                    required
                    value={props.contactDetails.email}
                    autoComplete="new-password"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                md="12"
                xs="12"
                className="text-left d-flex align-items-center"
              >
                <Input
                  id="phone"
                  placeholder="Enter Contact Number (optional)"
                  className="country__selection__input__width"
                  type="text"
                  name="phone"
                  onChange={(e) =>
                    props.contactDetailsChange("phone", e.target.value)
                  }
                  value={props.contactDetails.phone}
                  autoComplete="new-password"
                ></Input>
              </Col>
            </Row>
            <hr className="service-header__hr-100" />
          </Col>
        )}

        <Col xs="11">
          <Row className="justify-content-center">
            <Col sm="12" xs="12" className="text-left">
              <Label for="examplePassword">COLLECTION FROM:</Label>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              md="12"
              xs="12"
              className="text-left d-flex align-items-center"
            >
              {/* <FormGroup> */}
              <ReactFlagsSelect
                key={`from-${props.value.fromCountry}`}
                onSelect={(code) => {
                  if (selectedCountry === "PT") {
                    if (code !== "PT") {
                      setFromCountryStates(code);
                      // if (props.value.toCountry) {
                      // 	setToCountryStates('ZA');
                      // }
                      if (props.value.toCountry !== "PT") {
                        setToCountryStates("PT");
                      }
                    } else {
                      setFromCountryStates(code);
                    }
                  } else {
                    if (code !== "ZA") {
                      setFromCountryStates(code);
                      // if (props.value.toCountry) {
                      // 	setToCountryStates('ZA');
                      // }
                      if (props.value.toCountry !== "ZA") {
                        setToCountryStates("ZA");
                      }
                    } else {
                      setFromCountryStates(code);
                    }
                  }
                }}
                selected={props.value.fromCountry}
                showSelectedLabel={true}
                showSecondarySelectedLabel={true}
                // selectedSize={selectedSize}
                showOptionLabel={true}
                showSecondaryOptionLabel={true}
                // optionsSize={optionsSize}
                placeholder={"Select Country"}
                className="country__selection__input__width form-group form-control"
                searchable={true}
              />
              {/* <Input
    									id='shipfrom'
    									name='shipfrom'
    									placeholder='ship from'
    									className='country__selection__input__width'
    									type='select'
    									required
    									value={props.value.fromCountry}
    									onChange={e => {
    										let obj = countriesStates.find(
    											state =>
    												state.name == e.target.value
    										);

    										if (
    											obj &&
    											obj.states &&
    											obj.states.length > 0
    										) {
    											setFromPost(obj.states);
    										} else {
    											setFromPost([]);
    										}
    										props.onChange(
    											'fromCountry',
    											e.target.value
    										);
    									}}
    								>
    									<option value='' selected disabled>
    										Select Country
    									</option>
    									{allCountries &&
    										getSortedData(allCountries).map(cn => (
    											<option value={cn.code}>
    												{cn.name}
    											</option>
    										))}
    								</Input> */}
              {/* </FormGroup> */}
            </Col>
            <Col md="12" xs="12" className="text-left ">
              <FormGroup>
                {/* {fromPost?.length < 1 ? (
                    <Input
                      id="shipfrom"
                      name="shipfrom"
                      placeholder={`${
                        props.value.fromCountry == ""
                          ? "Enter State / Province"
                          : "-"
                      }`}
                      className="country__selection__input__width"
                      type="text"
                      onChange={(e) => {
                        props.onChange("fromState", e.target.value);
                      }}
                      required
                      value={props.value.fromState}
                      disabled={props.value.fromCountry != ""}
                      autoComplete="new-password"

                      // disabled={postalAwareCheck(
                      // 	props.value.fromCountry
                      // )}
                    ></Input>
                  ) : (
                    <CustomAutoComplete
                      id="nba teams"
                      options={fromPost}
                      renderInput={(params) => (
                        <StyledTextField
                          className="country__selection__input__width_child"
                          {...params}
                          label="Search State / Province"
                        />
                      )}
                      getOptionLabel={(option) => option.STATE}
                      style={{ width: 270 }}
                      value={getFromPostValue(props.value.fromState)}
                      disableClearable
                      onChange={(_event, stateData) => {
                        props.onChange("fromState", stateData.CODE);
                      }}
                      autoComplete="new-password"
                    />
                  )} */}
                {
                  // selectedCountry === 'PT' ? (
                  //   <Input
                  //     id="shipfrom"
                  //     name="shipfrom"
                  //     placeholder='Enter State / Province'
                  //     className="country__selection__input__width"
                  //     type="text"
                  //     onChange={(e) => {
                  //       props.onChange('fromState', e.target.value);
                  //     }}
                  //     required
                  //     value={props.value.fromState}
                  //     autoComplete="new-password"
                  //   />
                  // ) :
                  fromPost?.length < 1 ? (
                    <Input
                      id="shipfrom"
                      name="shipfrom"
                      placeholder={
                        props.value.fromCountry === ""
                          ? "Enter State / Province"
                          : "-"
                      }
                      className="country__selection__input__width"
                      type="text"
                      onChange={(e) => {
                        props.onChange("fromState", e.target.value);
                      }}
                      required
                      value={props.value.fromState}
                      disabled={props.value.fromCountry !== ""}
                      autoComplete="new-password"
                    />
                  ) : (
                    <CustomAutoComplete
                      id="nba teams"
                      options={fromPost}
                      renderInput={(params) => (
                        <StyledTextField
                          className="country__selection__input__width_child"
                          {...params}
                          label="Search State / Province"
                        />
                      )}
                      getOptionLabel={(option) => option.STATE}
                      style={{ width: 270 }}
                      value={getFromPostValue(props.value.fromState)}
                      disableClearable
                      onChange={(_event, stateData) => {
                        props.onChange("fromState", stateData.CODE);
                      }}
                      autoComplete="new-password"
                    />
                  )
                }
              </FormGroup>
            </Col>
            <Col md="12" xs="12" className="text-left ">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  placeholder="Enter City / Town"
                  className="country__selection__input__width"
                  type="text"
                  onChange={(e) => {
                    props.onChange("fromCity", e.target.value);
                  }}
                  value={props.value.fromCity}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="12" xs="12" className="text-left">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  placeholder="Enter Postal / Zip Code"
                  className="country__selection__input__width"
                  type="text"
                  maxLength="10"
                  onChange={(e) => {
                    props.onChange("fromCode", e.target.value);
                  }}
                  value={props.value.fromCode}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="12" xs="12" className="text-left">
              <Label for="examplePassword">DELIVERY TO:</Label>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="12" xs="12" className="text-left">
              <ReactFlagsSelect
                key={`from-${props.value.toCountry}`}
                onSelect={(code) => {
                  if (selectedCountry === "PT") {
                    if (code !== "PT") {
                      setToCountryStates(code);
                      if (props.value.fromCountry !== "PT") {
                        setFromCountryStates("PT");
                      }
                    } else {
                      setToCountryStates(code);
                    }
                  } else {
                    if (code !== "ZA") {
                      setToCountryStates(code);
                      if (props.value.fromCountry !== "ZA") {
                        setFromCountryStates("ZA");
                      }
                    } else {
                      setToCountryStates(code);
                    }
                  }
                }}
                selected={props.value.toCountry}
                showSelectedLabel={true}
                showSecondarySelectedLabel={true}
                // selectedSize={selectedSize}
                showOptionLabel={true}
                showSecondaryOptionLabel={true}
                // optionsSize={optionsSize}
                placeholder={"Select Country"}
                className="country__selection__input__width form-group form-control"
                searchable={true}
              />
              {/* <FormGroup>
    								<Input
    									id='shipfrom'
    									name='shipfrom'
    									placeholder='ship from'
    									className='country__selection__input__width'
    									type='select'
    									required
    									value={props.value.toCountry}
    									onChange={e => {
    										let obj = countriesStates.find(
    											state =>
    												state.name == e.target.value
    										);
    										if (
    											obj &&
    											obj.states &&
    											obj.states.length > 0
    										) {
    											setToPost(obj.states);
    										} else {
    											setToPost([]);
    										}
    										props.onChange(
    											'toCountry',
    											e.target.value
    										);
    									}}
    								>
    									<option value='' selected disabled>
    										Select Country
    									</option>
    									{allCountries &&
    										getSortedData(allCountries).map(cn => (
    											<option value={cn.code}>
    												{cn.name}
    											</option>
    										))}
    								</Input>
    							</FormGroup> */}
            </Col>
            <Col md="12" xs="12" className="text-left ">
              <FormGroup>
                {/* {selectedCountry==='PT'? (
                     <Input
                     id="shipfrom"
                     name="shipfrom"
                     placeholder="Enter State / Province"
                     className="country__selection__input__width"
                     type="text"
                     // maxLength='2'
                     onChange={(e) => {
                       props.onChange("toState", e.target.value);
                     }}
                     required
                     value={props.value.toState}
                     autoComplete="new-password"
                   ></Input>
                  ): */}
                {toPost.length < 1 ? (
                  <Input
                    id="shipfrom"
                    name="shipfrom"
                    placeholder={`${
                      props.value.toCountry == ""
                        ? "Enter State / Province"
                        : "-"
                    }`}
                    className="country__selection__input__width"
                    type="text"
                    // maxLength='2'
                    onChange={(e) => {
                      props.onChange("toState", e.target.value);
                    }}
                    required
                    disabled={props.value.toCountry != ""}
                    // disabled={postalAwareCheck(
                    // 	props.value.toCountry
                    // )}
                    value={props.value.toState}
                    autoComplete="new-password"
                  ></Input>
                ) : (
                  <CustomAutoComplete
                    id="nba teams"
                    options={toPost}
                    renderInput={(params) => (
                      <StyledTextField
                        className="country__selection__input__width_child"
                        {...params}
                        label="Search State / Province"
                      />
                    )}
                    getOptionLabel={(option) => option.STATE}
                    style={{ width: 270 }}
                    value={getToPostValue(props.value.toState)}
                    disableClearable
                    onChange={(_event, stateData) => {
                      console.log(stateData.CODE, "stateData code>>>>>>>>>>>");
                      props.onChange("toState", stateData.CODE);
                    }}
                  />
                )}
              </FormGroup>
            </Col>
            <Col md="12" xs="12" className="text-left ">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  placeholder="Enter City / Town"
                  className="country__selection__input__width"
                  type="text"
                  onChange={(e) => {
                    props.onChange("toCity", e.target.value);
                  }}
                  value={props.value.toCity}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="12" xs="12" className="text-left">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  maxLength="10"
                  placeholder="Enter Postal / Zip Code"
                  className="country__selection__input__width"
                  type="text"
                  onChange={(e) => {
                    props.onChange("toCode", e.target.value);
                  }}
                  value={props.value.toCode}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
          </Row>
          {/* DeliveryTypeSection */}
          <Row className="justify-content-center">
            <Col md="12" className="text-left text-primary mt-2">
              <h5>
                <b>Delivery Type</b>
              </h5>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              sm="4"
              className="text-left text-primary d-flex align-items-center"
            >
              <FormGroup check inline>
                <Label check className="mr-2">
                  Business
                </Label>
                <Input
                  type="checkbox"
                  name="checkbox1"
                  id="checkbox1"
                  value="business"
                  checked={props.deliveryType == "business"}
                  onChange={(e) => {
                    handleOnChange(0);

                    props.deliveryTypeChange(e.target.value);
                  }}
                />{" "}
              </FormGroup>
            </Col>
            <Col
              sm="4"
              className="text-left text-primary d-flex align-items-center"
            >
              <FormGroup check inline>
                <Label check className="mr-2">
                  Residential
                </Label>{" "}
                <Input
                  type="checkbox"
                  name="checkbox2"
                  id="checkbox2"
                  value="residential"
                  checked={props.deliveryType == "residential"}
                  onChange={(e) => {
                    handleOnChange(1);

                    props.deliveryTypeChange(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col sm="4"></Col>
          </Row>
          {/* {selectedCountry == "PT" && ( */}
          <Row className="justify-content-start">
            <Col xs="12" className="justify-content-start d-flex mt-3">
              <Button
                color="secondary"
                className="portugal_prev "
                onClick={() => {
                  props.setActiveTab(2);
                }}
              >
                <i className="fas fa-arrow-left " />
              </Button>
            </Col>
          </Row>
          {/* )} */}
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="country-selection-component select-country justify-content-center no-gutters">
        <Col xs="12">
          <Row className=" justify-content-center">
            <Col xs="11">
              <ServicesHeader value="Express / Courier Services" />
              <InternationalActiveTab
                activeTab={props.activeTab}
                setActiveTab={props.setActiveTab}
              />
              <hr className="service-header__hr-100" />
            </Col>
          </Row>
        </Col>
        {selectedCountry == "PT" && (
          <Col xs="12">
            <Row className="justify-content-center">
              <Col md="4" xs="4" className="text-left">
                <Input
                  id="email"
                  placeholder="Enter Email Address"
                  className="country__selection__input__width"
                  type="email"
                  name="email"
                  onChange={(e) =>
                    props.contactDetailsChange("email", e.target.value)
                  }
                  required
                  value={props.contactDetails.email}
                  autoComplete="new-password"
                ></Input>
              </Col>
              <Col
                md="3"
                xs="4"
                className="d-flex align-items-center justify-content-center"
              >
                <FormGroup>
                  <Label className="text-primary mb-0 p-0">
                    {"<< CONTACT INFO >>"}
                  </Label>
                </FormGroup>
              </Col>
              <Col md="4" xs="4" className="text-left">
                <Input
                  id="phone"
                  placeholder="Enter Contact Number (optional)"
                  className="country__selection__input__width"
                  type="text"
                  name="phone"
                  onChange={(e) =>
                    props.contactDetailsChange("phone", e.target.value)
                  }
                  value={props.contactDetails.phone}
                  autoComplete="new-password"
                ></Input>
              </Col>

              <Col md="11" xs="12">
                <hr className="service-header__hr-100" />
              </Col>
            </Row>
          </Col>
        )}

        <Col>
          <Row className="justify-content-center">
            <Col md="4" xs="4" className="text-left">
              <Label for="examplePassword">COLLECTION FROM:</Label>
            </Col>
            <Col md="3" xs="4"></Col>
            <Col md="4" xs="4" className="text-left">
              <Label for="examplePassword">DELIVERY TO:</Label>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="4" xs="4" className="text-left d-flex align-items-center">
              {/* <FormGroup> */}
              <ReactFlagsSelect
                key={`from-${props.value.fromCountry}`}
                onSelect={(code) => {
                  if (selectedCountry === "PT") {
                    if (code !== "PT") {
                      setFromCountryStates(code);
                      // if (props.value.toCountry) {
                      // 	setToCountryStates('ZA');
                      // }
                      if (props.value.toCountry !== "PT") {
                        setToCountryStates("PT");
                      }
                    } else {
                      setFromCountryStates(code);
                    }
                  } else {
                    if (code !== "ZA") {
                      setFromCountryStates(code);
                      // if (props.value.toCountry) {
                      // 	setToCountryStates('ZA');
                      // }
                      if (props.value.toCountry !== "ZA") {
                        setToCountryStates("ZA");
                      }
                    } else {
                      setFromCountryStates(code);
                    }
                  }
                }}
                selected={props.value.fromCountry}
                showSelectedLabel={true}
                showSecondarySelectedLabel={true}
                // selectedSize={selectedSize}
                showOptionLabel={true}
                showSecondaryOptionLabel={true}
                // optionsSize={optionsSize}
                placeholder={"Select Country"}
                className="country__selection__input__width form-group form-control"
                searchable={true}
              />
              {/* <Input
    									id='shipfrom'
    									name='shipfrom'
    									placeholder='ship from'
    									className='country__selection__input__width'
    									type='select'
    									required
    									value={props.value.fromCountry}
    									onChange={e => {
    										let obj = countriesStates.find(
    											state =>
    												state.name == e.target.value
    										);

    										if (
    											obj &&
    											obj.states &&
    											obj.states.length > 0
    										) {
    											setFromPost(obj.states);
    										} else {
    											setFromPost([]);
    										}
    										props.onChange(
    											'fromCountry',
    											e.target.value
    										);
    									}}
    								>
    									<option value='' selected disabled>
    										Select Country
    									</option>
    									{allCountries &&
    										getSortedData(allCountries).map(cn => (
    											<option value={cn.code}>
    												{cn.name}
    											</option>
    										))}
    								</Input> */}
              {/* </FormGroup> */}
            </Col>
            <Col
              md="3"
              xs="4"
              className="d-flex align-items-center justify-content-center"
            >
              <FormGroup>
                <Label className="text-primary mb-0 p-0">
                  {"<< COUNTRY >>"}
                </Label>
              </FormGroup>
            </Col>

            <Col md="4" xs="4" className="text-left">
              <ReactFlagsSelect
                key={`from-${props.value.toCountry}`}
                onSelect={(code) => {
                  if (selectedCountry === "PT") {
                    if (code !== "PT") {
                      setToCountryStates(code);
                      if (props.value.fromCountry !== "PT") {
                        setFromCountryStates("PT");
                      }
                    } else {
                      setToCountryStates(code);
                    }
                  } else {
                    if (code !== "ZA") {
                      setToCountryStates(code);
                      if (props.value.fromCountry !== "ZA") {
                        setFromCountryStates("ZA");
                      }
                    } else {
                      setToCountryStates(code);
                    }
                  }
                }}
                selected={props.value.toCountry}
                showSelectedLabel={true}
                showSecondarySelectedLabel={true}
                // selectedSize={selectedSize}
                showOptionLabel={true}
                showSecondaryOptionLabel={true}
                // optionsSize={optionsSize}
                placeholder={"Select Country"}
                className="country__selection__input__width form-group form-control"
                searchable={true}
              />
              {/* <FormGroup>
    								<Input
    									id='shipfrom'
    									name='shipfrom'
    									placeholder='ship from'
    									className='country__selection__input__width'
    									type='select'
    									required
    									value={props.value.toCountry}
    									onChange={e => {
    										let obj = countriesStates.find(
    											state =>
    												state.name == e.target.value
    										);
    										if (
    											obj &&
    											obj.states &&
    											obj.states.length > 0
    										) {
    											setToPost(obj.states);
    										} else {
    											setToPost([]);
    										}
    										props.onChange(
    											'toCountry',
    											e.target.value
    										);
    									}}
    								>
    									<option value='' selected disabled>
    										Select Country
    									</option>
    									{allCountries &&
    										getSortedData(allCountries).map(cn => (
    											<option value={cn.code}>
    												{cn.name}
    											</option>
    										))}
    								</Input>
    							</FormGroup> */}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="4" xs="4" className="text-left ">
              <FormGroup>
                {/* {fromPost?.length < 1 ? (
                    <Input
                      id="shipfrom"
                      name="shipfrom"
                      placeholder={`${
                        props.value.fromCountry == ""
                          ? "Enter State / Province"
                          : "-"
                      }`}
                      className="country__selection__input__width"
                      type="text"
                      onChange={(e) => {
                        props.onChange("fromState", e.target.value);
                      }}
                      required
                      value={props.value.fromState}
                      disabled={props.value.fromCountry != ""}
                      autoComplete="new-password"

                      // disabled={postalAwareCheck(
                      // 	props.value.fromCountry
                      // )}
                    ></Input>
                  ) : (
                    <CustomAutoComplete
                      id="nba teams"
                      options={fromPost}
                      renderInput={(params) => (
                        <StyledTextField
                          className="country__selection__input__width_child"
                          {...params}
                          label="Search State / Province"
                        />
                      )}
                      getOptionLabel={(option) => option.STATE}
                      style={{ width: 270 }}
                      value={getFromPostValue(props.value.fromState)}
                      disableClearable
                      onChange={(_event, stateData) => {
                        props.onChange("fromState", stateData.CODE);
                      }}
                      autoComplete="new-password"
                    />
                  )} */}
                {
                  // selectedCountry === 'PT' ? (
                  //   <Input
                  //     id="shipfrom"
                  //     name="shipfrom"
                  //     placeholder='Enter State / Province'
                  //     className="country__selection__input__width"
                  //     type="text"
                  //     onChange={(e) => {
                  //       props.onChange('fromState', e.target.value);
                  //     }}
                  //     required
                  //     value={props.value.fromState}
                  //     autoComplete="new-password"
                  //   />
                  // ) :
                  fromPost?.length < 1 ? (
                    <Input
                      id="shipfrom"
                      name="shipfrom"
                      placeholder={
                        props.value.fromCountry === ""
                          ? "Enter State / Province"
                          : "-"
                      }
                      className="country__selection__input__width"
                      type="text"
                      onChange={(e) => {
                        props.onChange("fromState", e.target.value);
                      }}
                      required
                      value={props.value.fromState}
                      disabled={props.value.fromCountry !== ""}
                      autoComplete="new-password"
                    />
                  ) : (
                    <CustomAutoComplete
                      id="nba teams"
                      options={fromPost}
                      renderInput={(params) => (
                        <StyledTextField
                          className="country__selection__input__width_child"
                          {...params}
                          label="Search State / Province"
                        />
                      )}
                      getOptionLabel={(option) => option.STATE}
                      style={{ width: 270 }}
                      value={getFromPostValue(props.value.fromState)}
                      disableClearable
                      onChange={(_event, stateData) => {
                        props.onChange("fromState", stateData.CODE);
                      }}
                      autoComplete="new-password"
                    />
                  )
                }
              </FormGroup>
            </Col>
            <Col
              md="3"
              xs="4"
              className="d-flex align-items-center justify-content-center"
            >
              <FormGroup>
                <Label className="text-primary mb-0 p-0">
                  {"<< STATE / PROVINCE >>"}
                </Label>
              </FormGroup>
            </Col>

            <Col md="4" xs="4" className="text-left ">
              <FormGroup>
                {/* {selectedCountry==='PT'? (
                     <Input
                     id="shipfrom"
                     name="shipfrom"
                     placeholder="Enter State / Province"
                     className="country__selection__input__width"
                     type="text"
                     // maxLength='2'
                     onChange={(e) => {
                       props.onChange("toState", e.target.value);
                     }}
                     required
                     value={props.value.toState}
                     autoComplete="new-password"
                   ></Input>
                  ): */}
                {toPost.length < 1 ? (
                  <Input
                    id="shipfrom"
                    name="shipfrom"
                    placeholder={`${
                      props.value.toCountry == ""
                        ? "Enter State / Province"
                        : "-"
                    }`}
                    className="country__selection__input__width"
                    type="text"
                    // maxLength='2'
                    onChange={(e) => {
                      props.onChange("toState", e.target.value);
                    }}
                    required
                    disabled={props.value.toCountry != ""}
                    // disabled={postalAwareCheck(
                    // 	props.value.toCountry
                    // )}
                    value={props.value.toState}
                    autoComplete="new-password"
                  ></Input>
                ) : (
                  <CustomAutoComplete
                    id="nba teams"
                    options={toPost}
                    renderInput={(params) => (
                      <StyledTextField
                        className="country__selection__input__width_child"
                        {...params}
                        label="Search State / Province"
                      />
                    )}
                    getOptionLabel={(option) => option.STATE}
                    style={{ width: 270 }}
                    value={getToPostValue(props.value.toState)}
                    disableClearable
                    onChange={(_event, stateData) => {
                      console.log(stateData.CODE, "stateData code>>>>>>>>>>>");
                      props.onChange("toState", stateData.CODE);
                    }}
                  />
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="4" xs="4" className="text-left ">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  placeholder="Enter City / Town"
                  className="country__selection__input__width"
                  type="text"
                  onChange={(e) => {
                    props.onChange("fromCity", e.target.value);
                  }}
                  value={props.value.fromCity}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
            <Col
              md="3"
              xs="4"
              className="d-flex justify-content-center align-items-center"
            >
              <FormGroup>
                <Label className="text-primary mb-0 p-0">
                  {"<< CITY / TOWN >>"}
                </Label>
              </FormGroup>
            </Col>

            <Col md="4" xs="4" className="text-left ">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  placeholder="Enter City / Town"
                  className="country__selection__input__width"
                  type="text"
                  onChange={(e) => {
                    props.onChange("toCity", e.target.value);
                  }}
                  value={props.value.toCity}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="4" xs="4" className="text-left">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  placeholder="Enter Postal / Zip Code"
                  className="country__selection__input__width"
                  type="text"
                  maxLength="10"
                  onChange={(e) => {
                    props.onChange("fromCode", e.target.value);
                  }}
                  value={props.value.fromCode}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
            <Col
              md="3"
              xs="4"
              className="d-flex justify-content-center align-items-center"
            >
              <FormGroup>
                <Label className="text-primary mb-0 p-0">
                  {"<< POSTAL / ZIP CODE >>"}
                </Label>
              </FormGroup>
            </Col>

            <Col md="4" xs="4" className="text-left">
              <FormGroup>
                <Input
                  id="shipfrom"
                  name="shipfrom"
                  maxLength="10"
                  placeholder="Enter Postal / Zip Code"
                  className="country__selection__input__width"
                  type="text"
                  onChange={(e) => {
                    props.onChange("toCode", e.target.value);
                  }}
                  value={props.value.toCode}
                  required
                  autoComplete="new-password"
                ></Input>
              </FormGroup>
            </Col>
          </Row>

          {/* DeliveryTypeSection */}
          <Row className="justify-content-center">
            <Col md="4" className="text-left text-primary mt-2">
              <h5>
                <b>Delivery Type</b>
              </h5>
            </Col>
            <Col md="3"></Col>
            <Col md="4"></Col>
          </Row>

          <Row className="justify-content-center">
            <Col
              md="4"
              className="text-left text-primary d-flex align-items-center"
            >
              <FormGroup check inline>
                <Label check className="mr-2">
                  Business
                </Label>
                <Input
                  type="checkbox"
                  name="checkbox1"
                  id="checkbox1"
                  value="business"
                  checked={props.deliveryType == "business"}
                  onChange={(e) => {
                    handleOnChange(0);

                    props.deliveryTypeChange(e.target.value);
                  }}
                />{" "}
              </FormGroup>
              <FormGroup check inline className="ml-3 ">
                <Label check className="mr-2">
                  Residential
                </Label>{" "}
                <Input
                  type="checkbox"
                  name="checkbox2"
                  id="checkbox2"
                  value="residential"
                  checked={props.deliveryType == "residential"}
                  onChange={(e) => {
                    handleOnChange(1);

                    props.deliveryTypeChange(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3"></Col>
            <Col md="4"></Col>
            {/* {selectedCountry == "PT" && ( */}
            <Col xs="12" className="justify-content-start d-flex mt-3">
              <Button
                color="secondary"
                className="portugal_prev "
                onClick={() => {
                  props.setActiveTab(2);
                }}
              >
                <i className="fas fa-arrow-left " />
              </Button>
            </Col>
            {/* )} */}
          </Row>
        </Col>
      </Row>
    );
  }
}

export default CountrySelection;
