import ProcessBox from './ProcessBox';
import Payment from '../assets/img/payment.png';
import Calendar from '../assets/img/Calendar.png';
import DeliveryDestination from '../assets/img/DeliveryDestination.png';
import React, { useEffect, useState } from 'react';
import arrow1 from '../assets/img/arrows2.png';

const ProcessList = ({ activeIdx }) => {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		updatePredicate();
		window.addEventListener('resize', updatePredicate);
		return () => window.removeEventListener('resize', updatePredicate);
	}, []);
	const updatePredicate = () => {
		setIsMobile(window.innerWidth < 576);
	};

	return (
		<div
			className='d-block px-10 d-flex w-100 align-items-center justify-content-around mt-4 '
			style={{ gap: 60,overflow:"auto" }}
		>
			<ProcessBox
				isActive={activeIdx == 0}
				title='Collection Date'
				img={Calendar}
				isMobile={isMobile}
			/>

			<img src={arrow1} className='process_list_arrow' />

			{/* <i
				className={`${
					isMobile ? 'fas fa-arrow-down' : 'fas fa-arrow-right'
				} mt-3 mt-md-5 mb-4 text-primary fa-3x`}
			/> */}
			<ProcessBox
				isActive={activeIdx == 1}
				title='Address Information'
				img={DeliveryDestination}
				isMobile={isMobile}
			/>

			<img src={arrow1} className='process_list_arrow' />

			{/* <i
				className={`${
					isMobile ? 'fas fa-arrow-down' : 'fas fa-arrow-right'
				} mt-3 mt-md-5 mb-4 text-primary fa-3x`}
			/> */}
			<ProcessBox
				isActive={activeIdx == 2}
				title='Checkout '
				img={Payment}
				isMobile={isMobile}
			/>
		</div>
	);
};

export default ProcessList;
