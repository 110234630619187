import React, { useEffect,useState } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import axios from "axios";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../config/firebase";
import moment from "moment";
import { getCounters } from "../../store/actions/localActions";
import { useDispatch, useSelector } from "react-redux";
const InternationalPayment = (props) => {
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry, "selectedCountry");
  const { counters } = useSelector((state) => state.local);
  const dispatch = useDispatch();
  const docID = uuidv4();
  const downloadRecipient = async (intlData) => {
    console.log("Going to send email!...", intlData);
    console.log(intlData, "intlData>>>>>>>>>");
    await axios.post(`${process.env.REACT_APP_DEVELOPMENT}/sendEmail`, {
      // commercial: props.commercial.url,
      selectedCountry,
      url: docID,
      // commercial: ,
      email: props.seventh.billingEmail,
      data: intlData,
    });
  };
  const addToFirestore = async () => {
    let url = "";
    let intlRef = JSON.parse(localStorage.getItem("intl_reference"));
    console.log(counters, "counters>>>>>>>>>>>>");
    // let invoiceRef = 1000;
    // if (counters.length > 0 && counters[0].international_counter) {
    //   invoiceRef = counters[0].international_counter + 1;
    // }

    // let invoice_number = "inv-" + invoiceRef;

    let shipment_number = props.reference;
    let invoice_number = shipment_number.replace("sms", "inv");

    let creation_date = moment().format("YYYY-MM-DD");

    let shipMentItems = [];
    let declarationItems = [];
    props.second?.itemDetails.forEach((item) => {
      shipMentItems.push({
        DimensionalUnit: "CM",
        Length: item.length,
        Width: item.width,
        Height: item.height,
        MetricUnit: "KG",
        PhysicalWeight: item.weight,
        VolumetricWeight: (item.length * item.width * item.height) / 5000,
      });
    });
    props.sixth?.declarationItems.forEach((itm) => {
      declarationItems.push({
        CountryOfManufacture: itm.country,
        ItemDescription: itm.description,
        Quantity: itm.quantity,
        TypeOfMaterial: itm.type,
        UnitValue: itm.unitValue,
        LineValue: itm.totalValue,
      });
    });

    if (props.delcarationInvoice?.uploadInvoice) {
      let filename = props.delcarationInvoice.file.name;
      let ext2 = filename.slice(filename.lastIndexOf("."));
      let file_name = uuidv4() + ext2.toLowerCase();
      let storageRef = await firebase
        .storage()
        .ref("invoices/" + file_name)
        .put(props.delcarationInvoice.file);
      url = await storageRef.ref.getDownloadURL();
    }

    let intlData = {
      ShipmentNumber: shipment_number,
      Country: props.first?.fromCountry || "",
      FedexAccountNumber: "690918900",
      DhlAccountNumber: "305995557",
      Thirdparty_Information: {
        AccountNumber: "",
        CreationDate: creation_date,
        WaybillNumber: "",
      },
      CommercialInvoice: {
        DeclaredItems: {
          Items: declarationItems,
          TotalShipmentValue: props.sixth?.totalShipmentValue || 0,
        },
        InvoiceUpload: "",
        HeaderInformation: {
          Currency: "ZAR",
          IdExportersCode: props.fifth?.idNumber || "",
          InvoiceNumber: invoice_number,
          InvoiceDate: creation_date,
          ReasonForExport: props.sixth?.reason || "",
          TotalShipmentCharge: props.sixth?.totalShipmentValue || 0,
        },
      },
      Invoice: {
        BillingInformation: {
          AcceptTsCs: true,
          BillingAddress1: props.seventh?.billingAddress1 || "",
          BillingAddress2: props.seventh?.billingTown || "",
          BillingCompanyName: props.seventh?.billingCountryName || "",
          BillingContactNumber: props.seventh?.billingContactNo || "",
          BillingCountry: props.seventh?.billingCountry || "",
          BillingEmailAddress: props.seventh?.billingEmail || "",
          BillingFirstName: props.seventh?.billingFirstName || "",
          BillingPostalCode: props.seventh?.billingPostalCode || "",
          BillingSuburb: props.seventh?.billingSuburb || "",
          BillingSurname: props.seventh?.billingLastName || "",
          BillingVatNumber: props.seventh?.billingVatNumber || "",
        },
        InvoiceInformation: {
          Currency: "ZAR",
          TotalInvoiceAmount: props.third.rate,

          InvoiceDate: creation_date,
          InvoiceNumber: invoice_number,
          InvoiceType:
            props.first.fromCountry == "ZA" && props.first.toCountry != "ZA"
              ? "export"
              : props.first.fromCountry != "ZA" && props.first.toCountry == "ZA"
              ? "import"
              : "domestic",
          Line1Amount: props.third?.rate || 0,
          Line1Description: "International Courier",
          Line1VatAmount: 0,
          Line1VatCode: "Z",
          Line2Amount: "",
          Line2Description: "",
          Line2VatAmount: 0,
          Line2VatCode: "",
          TotalVatAmount: 0,
          ShipmentReference: props.reference,
          WaybillNumber: "",
        },
      },
      ShipmentManifest: {
        AddressInformation: {
          ConsigneeAddress1: props.fifth?.ReceivingAddress1 || "",
          ConsigneeAddress2:
            props.fifth?.ReceivingComplex || ""
              ? props.fifth.ReceivingComplex
              : "N/A",
          ConsigneeCity: props.first?.toCity || "",
          ConsigneeContactNumber: props.fifth?.ReceivingContact || "",
          ConsigneeCountry: props.first?.toCountry || "",
          ConsigneeEmailAddress: props.fifth?.ReceivingEmail || "",

          ConsigneeName: props.fifth?.ReceivingName || "",
          ConsigneePostalCodeZipCode: props.first?.toCode || "",
          ConsigneeSuburb: props.fifth?.ReceivingSuburb || "",
          ConsigneeSurname: props.fifth?.ReceivingSurName || "",
          ConsigneeCollectionDate: "",
          ConsigneeState: props.first?.toState || "",

          ShipperState: props.first?.fromState || "",
          ShipperAddress1: props.fifth?.sendingAddress1 || "",
          ShipperAddress2:
            props.fifth?.sendingComplex || "" ? props.fifth.sendingComplex : "",
          ShipperCity: props.first?.fromCity || "",
          ShipperCollectionDate: props.fourth?.date || "",
          ShipperContactNumber: props.fifth?.sendingContact || "",
          ShipperCountry: props.first?.fromCountry || "",
          ShipperEmailAddress: props.fifth?.sendingEmail || "",
          ShipperIdentityNumber: props.fifth?.idNumber || "",
          ShipperName: props.fifth?.sendingName || "",
          ShipperPostalCodeZipCode: props.first?.fromCode || "",
          ShipperSuburb: props.fifth?.sendingSuburb || "",
          ShipperSurname: props.fifth?.sendingSurName || "",
        },
        CargoInformation: {
          Items: shipMentItems,
          TotalVolumetricWeight: props.second?.volumetricWeight || 0,
          TotalPhysicalWeight: props.second?.totalWeight || 0,
        },
        CarrierInformation: {
          Currency: "ZAR",
          Carrier: props.third?.serviceType || "",
          Price: props.third?.rate || 0,
          ServiceSelected: props.third?.type || "",
        },
        ShipmentHeader: {
          InsuranceAmount: props.insuranceData?.insuranceValue || 0,
          InsuranceRequested: props.insuranceData?.insurance || false,
          InvoiceReference: invoice_number,
          ShipmentCreationDate: creation_date,
          ShipmentType:
            props.first.fromCountry == "ZA" && props.first.toCountry != "ZA"
              ? "export"
              : props.first.fromCountry != "ZA" && props.first.toCountry == "ZA"
              ? "import"
              : "3rdparty",
        },
      },
      DocId: docID,
      selectedCountry,
    };

    if (url != "") {
      intlData.CommercialInvoice = {
        InvoiceUpload: url,
        HeaderInformation: {
          Currency: "ZAR",
          IdExportersCode: props.fifth?.idNumber || "",
          InvoiceNumber: invoice_number,
          InvoiceDate: creation_date,
        },
      };
    }

    console.log("Intl..data", intlData);

    if (intlRef == null) {
      console.log("Document..", docID);

      console.log("Going to add first time", intlData);

      localStorage.setItem("intl_reference", JSON.stringify(props.reference));

      await firebase
        .firestore()
        .collection(`${process.env.REACT_APP_INTERNATIONAL_ORDER}`)
        .doc(docID)
        .set(intlData);
      downloadRecipient(intlData);
      console.log("Adding first time");
    } else if (intlRef != null && intlRef != props.reference) {
      console.log("Document..", docID);
      console.log("Going to add again", intlData);
      localStorage.setItem("intl_reference", JSON.stringify(props.reference));
      await firebase
        .firestore()
        .collection(`${process.env.REACT_APP_INTERNATIONAL_ORDER}`)
        .doc(docID)
        .set(intlData);
      downloadRecipient(intlData);
    }
  };

  // useEffect(() => {
  //   async function loadData() {
  //     if (counters && counters.length == 0) {
  //       await dispatch(getCounters(process.env.REACT_APP_COUNTERS));
  //     }
  //     if (counters.length > 0) {
  //       addToFirestore();
  //     }
  //   }
  //   loadData();
  // }, [counters]);

  useEffect(() => {
   if(selectedCountry){
      addToFirestore();
   }
  }, [selectedCountry]);

  console.log("International thankyou page just called...");
  return (
    <>
      <Row id="recipientPrint" className="justify-content-center  m-4">
        <Col md="8" className="mx-auto">
          <span className="text-primary h2 text-center">
            Thank you for your booking.
          </span>
          <hr />
          <div className="text-left">
            <br />
            <span>
              Your reference number : <strong>{props.reference}</strong>
            </span>
            <br />
            <br />
            <span className="font-weight-bold">
              {/* Please check your inbox */}
              Your booking confirmation will be sent shortly.
            </span>
            {/* <br /> */}
            {/* <span>
							You will recieve your booking confirmation will be
							sent shortly
						</span> */}
            <br />
            <br />
            <span className="font-weight-bold">
              {/* For any enquiries regarding your shipment:{' '} */}
              For any enquiries regarding your shipment, contact us at{" "}
              <a
                href="mailto:support@shipmystuff.co.za"
                className="text-primary"
              >
                support@shipmystuff.co.za
              </a>
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InternationalPayment;
