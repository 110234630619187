import React, { useState } from 'react';

import { Accordion, Card, Button } from 'react-bootstrap';
import Packaging_Guidelines from '../assets/docs/Packaging_Guidelines.pdf';
import Globally_Prohibited_Items from '../assets/docs/Globally_Prohibited_Items.pdf';
import { Col, Row } from 'reactstrap';
import Button_Airplane from '../assets/img/Button_Airplane.png';
import Button_Truck from '../assets/img/Button_Truck.png';
import Button_Ship from '../assets/img/Button_Ship.png';

function AccordionComp() {
	const [active, setActive] = useState(2);
	return (
		<div className=' mt-4 mb-5'>
			<Row className='px-0 px-md-4'>
				<Col lg='4'>
					<Button
						className={`${
							active == 1 ? 'bg-primary' : 'bg-secondary'
						} resources_btn  mt-2 mt-lg-0`}
						onClick={() => setActive(1)}
					>
						DOMESTIC COURIER
						<img
							src={Button_Truck}
							className='ml-auto img-fluid'
							width='30'
						/>
					</Button>
				</Col>
				<Col lg='4'>
					<Button
						className={`${
							active == 2 ? 'bg-primary' : 'bg-secondary'
						} resources_btn  mt-2 mt-lg-0`}
						onClick={() => setActive(2)}
					>
						INTERNATIONAL COURIER
						<img
							src={Button_Airplane}
							className='ml-auto img-fluid'
							width='30'
						/>
					</Button>
				</Col>
				<Col lg='4'>
					<Button
						className={`${
							active == 3 ? 'bg-primary' : 'bg-secondary'
						} resources_btn  mt-2 mt-lg-0`}
						onClick={() => setActive(3)}
					>
						SEA FREIGHT
						<img
							src={Button_Ship}
							className='ml-auto img-fluid'
							width='30'
						/>
					</Button>
				</Col>
			</Row>
			{active == 1 && (
				<>
					<div className='mt-4 resources_section mb-2'>
						<Row className=''>
							<Col className='' md='12'>
								<h1>Domestic Courier</h1>
							</Col>
						</Row>
					</div>
					<Accordion
						className={`rn-accordion-style`}
						defaultActiveKey='0'
					>
						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='0'
								>
									What is your average transit time?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='0'>
								<Card.Body>
									Each courier’s transit time can vary
									depending on the pickup and delivery
									addresses.
									<br /> Economy service:
									<ul className='m-0'>
										<li>
											Transit time ranges between 2 – 3
											days
										</li>
									</ul>
									Same day delivery:
									<ul className='m-0'>
										<li>
											Note: only applicable in the Gauteng
											region
										</li>
									</ul>
									Overnight delivery:
									<ul className='m-0'>
										<li>
											Transit time ranges between 1 – 2
											days
										</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='1'
								>
									Do your online quote prices include
									surcharges?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='1'>
								<Card.Body>
									The prices we provide our customers via our
									online booking platform is the final price.
									Ship My Stuff will rebill or refund the
									customer if the following applies:
									<ul>
										<li>Incorrect weight supplied</li>
										<li>Incorrect dimensions supplied</li>
										<li>
											Collection or Delivery addresses
											falls under the following:
											<ul>
												<li>
													Deemed a high-risk area by
													the supplier
												</li>
												<li>
													Remote areas such as
													farms/plots / power stations
													/ general outlying areas
												</li>
												<li>
													Special delivery requests
													such as shopping
													centres/consulates or
													embassies
												</li>
											</ul>
										</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='2'
								>
									What am I not allowed to send on domestic
									shipments?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='2'>
								<Card.Body>
									Please refer to our{' '}
									<a href='/globally-prohibited-items'>
										<strong>
											<u className='font-weight-bold'>
												globally prohibited list.
											</u>
										</strong>
									</a>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='3'
								>
									How do I package my parcel securely?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='3'>
								<Card.Body>
									Please refer to our{' '}
									<a href='/packing-guidelines'>
										<u className='font-weight-bold'>
											packing guide.
										</u>
									</a>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='4'
								>
									How Do I Know if my shipment has arrived on
									time?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='4'>
								<Card.Body>
									After booking your shipment using our online
									booking platform you will receive a booking
									confirmation via email. The waybill
									number/reference number on your invoice can
									be used to track your parcel/cargo’s
									delivery status.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='5'
								>
									Do I need to insure my parcels?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='5'>
								<Card.Body>
									It is not mandatory to insure your parcels,
									we always recommend insuring your parcel if
									it is of high value. A claim cannot be
									processed if insurance has not been
									requested.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='6'
								>
									What happens if my parcel goes missing or is
									damaged during transit?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='6'>
								<Card.Body>
									If your parcel goes missing, we will submit
									a claim on your behalf to our carriers to
									recover damages. If your parcel is insured,
									then an insurance claim can be issued.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='7'
								>
									Do I require any shipping documents after my
									booking?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='7'>
								<Card.Body>
									A confirmation email will be sent after your
									shipment has been successfully booked
									online. Instructions will be provided to
									prepare your documentation prior to
									collection.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='8'
								>
									I cannot find the postal code or area for
									collection or delivery
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='8'>
								<Card.Body>
									Select the closest bordering area/suburb or
									postal code. If you can’t find the
									area/postal code you are looking for,
									contact us at{' '}
									<a href='mailto:support@shipmystuff.co.za'>
										<u className='font-weight-bold'>
											support@shipmystuff.co.za
										</u>
									</a>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='9'
								>
									Actual weight vs volumetric weight vs
									chargeable weight
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='9'>
								<Card.Body>
									The actual weight of your package/cargo
									includes your packaging material. Volumetric
									weight is calculated as follows: package’
									dimensions (width x length x height) /
									factor (5000). Chargeable weight is the{' '}
									<strong>highest</strong> value between
									actual weight and volumetric weight.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='10'
								>
									Submitting incorrect shipment details?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='10'>
								<Card.Body>
									If the final weight and dimensions of your
									parcel/cargo differ from the weight and
									dimensions given at the time of the booking,
									Ship My Stuff will issue a rebill to the
									customer to recover the difference in monies
									owed.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='11'
								>
									Can I track my parcel/cargo?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='11'>
								<Card.Body>
									A waybill number/reference number on your
									invoice can be used to retrieve your
									parcel/cargo’ delivery status using{' '}
									<a href='/trackparcel'>
										<u className='font-weight-bold'>
											our online tracking tool
										</u>
									</a>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='12'
								>
									Cancelling my order
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='12'>
								<Card.Body>
									t is important to notify our support team at{' '}
									<a href='mailto:support@shipmystuff.co.za'>
										<u className='font-weight-bold'>
											support@shipmystuff.co.za.
										</u>
									</a>
									<br /> If the parcel has not been collected
									yet, the customer qualifies for a 50%
									refund.
									<br /> Please note if the parcel has been
									collected, no refund is available to the
									customer.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='13'
								>
									What is a waybill?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='13'>
								<Card.Body>
									This document has all the shipment details
									and is used by our carriers as part of their
									internal process.
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				</>
			)}
			{active == 2 && (
				<>
					<div className='mt-4 resources_section mb-2'>
						<Row className=''>
							<Col className='' md='12'>
								<h1>International Courier</h1>
							</Col>
						</Row>
					</div>
					<Accordion
						className={`rn-accordion-style`}
						defaultActiveKey='0'
					>
						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='0'
								>
									What is your average transit time?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='0'>
								<Card.Body>
									Each countries’ transit time can vary
									depending on the destination. The average
									transit time ranges between 3 – 10 days.
									<br /> Transit time excludes:
									<ul>
										<li>Customs delays</li>
										<li>Customs inspections</li>
										<li>Weather conditions</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='1'
								>
									Why do I benefit from using your service, as
									opposed to using the courier companies
									directly?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='1'>
								<Card.Body>
									We receive competitive rates from our
									suppliers (Courier companies) due to the
									volume of shipments we handle every day. Our
									service also provides the ability to compare
									multiple quotes from various courier
									companies instantly!
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='2'
								>
									Do your online quote prices include
									surcharges?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='2'>
								<Card.Body>
									The prices we provide our customers via our
									online booking platform is the final price.
									Ship My Stuff will rebill or refund the
									customer if the following applies:
									<ul>
										<li>Incorrect weight supplied</li>
										<li>Incorrect dimensions supplied</li>
										<li>
											Collection or Delivery addresses
											falls under the following:
											<ul>
												<li>
													Deemed a high-risk area by
													the supplier
												</li>
												<li>
													Remote areas such as farms /
													plots / power stations /
													general outlying areas
												</li>
												<li>
													Special delivery requests
													such as shopping centres /
													consulates or embassies
												</li>
											</ul>
										</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='3'
								>
									Does my quote include duties and taxes?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='3'>
								<Card.Body>
									Duties and taxes always apply on commercial
									shipments in some cases when you send a gift
									then duties and taxes will be exempt. Please
									ask us for further advise.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='4'
								>
									What am I not allowed to send
									internationally?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='4'>
								<Card.Body>
									Please refer to our{' '}
									<a href='/globally-prohibited-items'>
										<u className='font-weight-bold'>
											globally prohibited list
										</u>
									</a>{' '}
									of items
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='5'
								>
									How do I package my parcel securely?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='5'>
								<Card.Body>
									Please refer to our{' '}
									<u className='font-weight-bold'>
										<a href='/packing-guidelines'>
											packing guide.
										</a>
									</u>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='6'
								>
									How Do I know if my shipment has arrived on
									time?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='6'>
								<Card.Body>
									After booking your shipment using our{' '}
									<u className='font-weight-bold'>
										online booking
									</u>{' '}
									platform you will receive a booking
									confirmation via email.
									<br />
									The waybill number / reference number on
									your invoice can be used to{' '}
									<u className='font-weight-bold'>
										<a href='/trackparcel'>
											track your parcel
										</a>
									</u>{' '}
									/ cargo’s delivery status.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='7'
								>
									How does import clearance work?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='7'>
								<Card.Body>
									When your parcel enters the destination
									country, formal customs clearance but be
									done. Every single shipment that enters a
									country has to go through customs clearance
									before the parcel can be delivered. This
									process can usually take 1-3 days depending
									if import taxes need to be paid on the
									shipment.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='8'
								>
									How does import taxes work?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='8'>
								<Card.Body>
									Import taxes and duties is calculated based
									on the <strong>value</strong> of your
									shipment. All commercial shipments are
									subject to import taxes due to the nature of
									the shipment.
									<br /> In many countries, shipments that are
									declared as gifts or personal belongings may
									be exempted of import taxes. Many countries
									have a certain a certain value threshold for
									shipments to be exempt from import taxes and
									duties.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='9'
								>
									Does my shipment qualify to be exempted from
									import taxes and duties?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='9'>
								<Card.Body>
									Based on the country you are sending your
									shipment to, it will be exempt from import
									taxes and duties if it the total value of
									the shipment falls under a certain value.
									See below our most common countries we ship
									to and their value thresholds.{' '}
									<strong>Meaning:</strong> if your shipment
									value is under these amount, you will not be
									subject to import taxes and duties{' '}
									<strong>(only for gifts):</strong>
									<ul>
										<li>New Zealand – 110 NZD (+-R1140)</li>
										<li>Australia – 1000 AUD (+-R11000)</li>
										<li>
											United Kingdom – 39 EUR (+-R600)
										</li>
										<li>
											United States of America – 100USD
											(+-R1500)
										</li>
										<li>South Korea – 100USD (+-R1500)</li>
									</ul>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='10'
								>
									Why do you need my ID number when sending or
									importing a shipment?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='10'>
								<Card.Body>
									The ID number of the recipient is needed for
									formal customs clearance when the shipment
									enters the destination country. When doing
									import clearance, the shipment is cleared
									under your ID number if you do not have an
									importers code.
									<br /> The ID number of the sender is needed
									for export clearance if you do not have an
									exporters code. Before your shipment leaves
									the country, export clearance is done under
									your ID number.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='11'
								>
									Submitting incorrect shipment details?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='11'>
								<Card.Body>
									If the final weight and dimensions of your
									parcel/cargo differ from the weight and
									dimensions given at the time of the booking,
									Ship My Stuff will issue a rebill to the
									customer to recover the difference in monies
									owed.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='12'
								>
									Are the rates you provided the cheapest
									option?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='12'>
								<Card.Body>
									Due to our high shipping volume, we secure
									competitive rates from our suppliers, in
									turn, we offer cost-effective rates to our
									customers.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='13'
								>
									How important is it to give accurate weight
									and dimensions of my parcel?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='13'>
								<Card.Body>
									It is vital that all the information
									provided when booking a parcel is as
									accurate as possible as it may change the
									final price of the shipment.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='14'
								>
									Do I require any shipping documents after my
									booking?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='14'>
								<Card.Body>
									A confirmation email will be sent after your
									shipment has been successfully booked
									online. Instructions will be provided to
									prepare your documentation prior to
									collection.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='15'
								>
									What is a waybill?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='15'>
								<Card.Body>
									This document has all the shipment details
									on and is used by our carriers as part of
									their internal process.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='16'
								>
									Why do I have to print the commercial
									invoice?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='16'>
								<Card.Body>
									The commercial invoice is very important as
									it serves as the item declaration and is
									used by customs to clear your shipment.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='17'
								>
									Cancelling my order
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='17'>
								<Card.Body>
									It is important to notify our support team
									at{' '}
									<u className='font-weight-bold'>
										<a href='mailto:support@shipmystuff.co.za'>
											support@shipmystuff.co.za.
										</a>
									</u>
									<br /> If the parcel has not been collected
									yet, the customer qualifies for a 50%
									refund.
									<br /> Please note if the parcel has been
									collected, no refund is available to the
									customer.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='18'
								>
									Do I need to insure my parcels?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='18'>
								<Card.Body>
									It is not mandatory to insure your parcels,
									we always recommend insuring your parcel if
									it is of high value. A claim cannot be
									processed if insurance has not been
									requested.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='19'
								>
									What happens if my parcel goes missing or is
									damaged during transit?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='19'>
								<Card.Body>
									If your parcel goes missing, we will submit
									a claim on your behalf to our carriers to
									recover damages. If your parcel is insured,
									then an insurance claim can be issued.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='20'
								>
									Can I track my parcel/cargo?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='20'>
								<Card.Body>
									A waybill number/reference number on your
									invoice can be used to retrieve your
									parcel/cargo’ delivery status using{' '}
									<u className='font-weight-bold'>
										<a href='/trackparcel'>
											our online tracking tool
										</a>
									</u>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='21'
								>
									Can we ship hazardous goods?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='21'>
								<Card.Body>
									We do send hazardous goods, however, it’s
									important to understand the destination
									country rules and regulations. Hazardous
									goods often require special packaging and
									handling, and an SDS (Safety Data Sheet) is
									required to secure the hazardous goods
									accordingly.
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				</>
			)}
			{active == 3 && (
				<>
					<div className='mt-4 resources_section mb-2'>
						<Row className=''>
							<Col className='' md='12'>
								<h1>Sea Freight</h1>
							</Col>
						</Row>
					</div>
					<Accordion
						className={`rn-accordion-style`}
						defaultActiveKey='0'
					>
						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='0'
								>
									What is your average transit time?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='0'>
								<Card.Body>
									Each country’s transit time can vary
									depending on the trading route.
									<br /> The average transit time can be
									requested during the quoting process
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='1'
								>
									Do your sea freight quotes include
									surcharges?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='1'>
								<Card.Body>
									Ship My Stuff will outline all the foreseen
									and unforeseen surcharges during the quoting
									process.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='2'
								>
									What am I not allowed to send
									internationally?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='2'>
								<Card.Body>
									Please refer to our{' '}
									<u className='font-weight-bold'>
										<a href='/globally-prohibited-items'>
											globally prohibited list
										</a>{' '}
									</u>
									of items
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='3'
								>
									How do I package my cargo securely?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='3'>
								<Card.Body>
									Please refer to our{' '}
									<u className='font-weight-bold'>
										<a href='/packing-guidelines'>
											packing guide.
										</a>{' '}
									</u>
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='4'
								>
									Do I need to insure my cargo?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='4'>
								<Card.Body>
									It is not mandatory to insure your parcels,
									we always recommend insuring your parcel if
									it is of high value.
									<br /> A claim cannot be processed if
									insurance has not been requested.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='5'
								>
									What happens if my parcel goes missing
									during transit?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='5'>
								<Card.Body>
									If your cargo goes missing, we will submit a
									claim on your behalf to our carriers to
									recover damages. If your cargo is insured,
									then an insurance claim can be issued.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='6'
								>
									Can we ship hazardous goods?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='6'>
								<Card.Body>
									We do send hazardous goods, however, it’s
									important to understand the destination
									country rules and regulations. Hazardous
									goods often require special packaging and
									handling, and an SDS (Safety Data Sheet) is
									required to secure the hazardous goods
									accordingly.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='7'
								>
									What is the difference between an LCL and an
									FCL?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='7'>
								<Card.Body>
									FCL involves shipping your goods in a full
									container. With this option, you pay for the
									entire container so only your goods occupy
									the whole space.
									<br />
									For LCL shipments, only pay for the space
									you consume inside the container.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='8'
								>
									Do we provide packaging and wrapping
									services?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='8'>
								<Card.Body>
									We offer move-cube services, crating,
									boxing, wrapping, and container packing
									services.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='9'
								>
									Do you offer move cubes?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='9'>
								<Card.Body>
									We offer move cubes in 3 different sizes as
									well as custom build move cubes.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='10'
								>
									Do you help pack my goods?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='10'>
								<Card.Body>
									We can offer the packaging supplies, but it
									is up to the client to pack their boxes.
									<br />
									However, we can arrange for laborers to help
									you pack if requested.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='11'
								>
									What is classified as “overweight”?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='11'>
								<Card.Body>
									If your cargo is more than 1.1 ton per CBM
									(1mx1mx1m), then it will be considered
									overweight and a surcharge will be applied.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='12'
								>
									Do your drivers help pack?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='12'>
								<Card.Body>
									Our transporters are only there to collect
									cargo and will not help pack any goods.
									<br />
									We can arrange packing services on request.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='13'
								>
									Can I send my cargo without a move cube?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='13'>
								<Card.Body>
									Yes, indeed. We can pick your packed cargo
									up, then we palletize and wrap it before it
									goes into the container.
									<br />A move cube is only a safety
									precaution and makes it easier to handle
									during the shipments’ handling points.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='14'
								>
									Will my cargo be fumigated?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='14'>
								<Card.Body>
									All cargo containers will be fumigated
									before leaving the country.
								</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant='link'
									eventKey='15'
								>
									Do I need to declare all my items?
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey='15'>
								<Card.Body>
									You must declare all your items on our
									templates supplied as you pack. Undeclared
									items can cause unexpected charges if
									prohibited or restricted items are found or
									not properly declared.
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				</>
			)}

			{/* <Accordion className={`rn-accordion-style`} defaultActiveKey='0'>
				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='0'
						>
							What is your average transit time?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='0'>
						<Card.Body>
							Each countries’ transit time can vary depending on
							the destination. The average transit time ranges
							between 3 – 10 days.
							<br /> Transit time excludes:
							<ul>
								<li>Customs delays</li>
								<li>Customs inspections</li>
								<li>Weather conditions</li>
							</ul>
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='1'
						>
							Why do I benefit from using your service, as opposed
							to using the courier companies directly?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='1'>
						<Card.Body>
							We receive competitive rates our suppliers (Courier
							companies) due to the volume of shipments we handle
							every day. <br />
							Our service also provides the ability to compare
							multiple quotes from various courier companies
							instantly!
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='2'
						>
							Do your online quote prices include surcharges?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='2'>
						<Card.Body>
							The prices we provide our customers via our online
							booking platform is the final price.
							<br /> Ship My Stuff will rebill or refund the
							customer if the following applies:
							<ul>
								<li>Incorrect weight supplied</li>
								<li>Incorrect dimensions supplied</li>
								<li>
									Collection or Delivery addresses falls under
									the following:
									<ul>
										<li>
											Deemed a high-risk area by the
											supplier
										</li>
										<li>
											Remote areas such as farms / plots /
											power stations / general outlying
											areas
										</li>
										<li>
											Special delivery requests such as
											shopping centres / consulates or
											embassies
										</li>
									</ul>
								</li>
							</ul>
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='3'
						>
							Does my quote include duties and taxes?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='3'>
						<Card.Body>
							Duties and taxes always apply on commercial
							shipments in some cases when you send a gift then
							duties and taxes will be exempt. Please ask us for
							further advise.
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='4'
						>
							What am I not allowed to send internationally?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='4'>
						<Card.Body>
							Please refer to our international prohibited list of
							items
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='5'
						>
							How do I package my parcel securely?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='5'>
						<Card.Body>
							Please refer to our{' '}
							<u className='font-weight-bold'>
								<a href={Packaging_Guidelines} download>
									packing guide.
								</a>
							</u>
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='6'
						>
							How Do I know if my shipment has arrived on time?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='6'>
						<Card.Body>
							After booking your shipment using our
							<u className='font-weight-bold'>
								online booking
							</u>{' '}
							platform you will receive a booking confirmation via
							email.
							<br />
							The waybill number / reference number on your
							invoice can be used to{' '}
							<u className='font-weight-bold'>
								<a href='/trackparcel'>track your parcel</a>
							</u>{' '}
							/ cargo’s delivery status.
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='7'
						>
							How does import clearance work?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='7'>
						<Card.Body>
							When your parcel enters the destination country,
							formal customs clearance but be done. Every single
							shipment that enters a country has to go through
							customs clearance before the parcel can be
							delivered. This process can usually take 1-3 days
							depending if import taxes need to be paid on the
							shipment.
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='8'
						>
							How does import taxes work?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='8'>
						<Card.Body>
							Import taxes and duties is calculated based on the{' '}
							<strong>value</strong> of your shipment. All
							commercial shipments are subject to import taxes due
							to the nature of the shipment.
							<br /> In many countries, shipments that are
							declared as gifts or personal belongings may be
							exempted of import taxes. Many countries have a
							certain a certain value threshold for shipments to
							be exempt from import taxes and duties.
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='9'
						>
							Does my shipment qualify to be exempted from import
							taxes and duties?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='9'>
						<Card.Body>
							Based on the country you are sending your shipment
							to, it will be exempt from import taxes and duties
							if it the total value of the shipment falls under a
							certain value. See below our most common countries
							we ship to and their value thresholds.{' '}
							<strong>Meaning:</strong> if your shipment value is
							under these amount, you will not be subject to
							import taxes and duties{' '}
							<strong>(only for gifts):</strong>
							<ul>
								<li>New Zealand – 110 NZD (+-R1140)</li>
								<li>Australia – 1000 AUD (+-R11000)</li>
								<li>United Kingdom – 39 EUR (+-R600)</li>
								<li>
									United States of America – 100USD (+-R1500)
								</li>
								<li>South Korea – 100USD (+-R1500)</li>
							</ul>
						</Card.Body>
					</Accordion.Collapse>
				</Card>

				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant='link'
							eventKey='10'
						>
							Why do you need my ID number when sending or
							importing a shipment?
						</Accordion.Toggle>
					</Card.Header>
					<Accordion.Collapse eventKey='10'>
						<Card.Body>
							The ID number of the recipient is needed for formal
							customs clearance when the shipment enters the
							destination country. When doing import clearance,
							the shipment is cleared under your ID number if you
							do not have an importers code.
							<br /> The ID number of the sender is needed for
							export clearance if you do not have an exporters
							code. Before your shipment leaves the country,
							export clearance is done under your ID number.
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion> */}
		</div>
	);
}

export default AccordionComp;
