import React, { useState, useRef, useEffect } from "react";

const CustomSelector = ({ options, onSelect, value, name }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setIsOpen(false);
    onSelect(option, name);
  };

  return (
    <div className="custom-input-with-dropdown" ref={dropdownRef}>
      {/* <input
        type="text"
        onClick={handleInputClick}
        value={value?.label}
        readOnly
        className="w-100"
      /> */}
      <div
        className="input d-flex align-items-center"
        onClick={handleInputClick}
      >
        <img src={value?.icon} className="mr-2" />{" "}
        <p className="mb-0 input__text"> {value?.label}</p>
        {!isOpen ? (
          <i className="fa fa-angle-down"></i>
        ) : (
          <i className="fa fa-angle-up"></i>
        )}
      </div>

      {isOpen && (
        <ul className="dropdown-options">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionSelect(option)}>
              {option?.icon && <img src={option?.icon} className="mr-2" />}{" "}
              <p className="mb-0">{option.label}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelector;
