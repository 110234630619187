import ProcessBox from './ProcessBox';
import Payment from '../assets/img/payment.png';
import Calendar from '../assets/img/Calendar.png';
import Clipboard from '../assets/img/Clipboard.png';
import DeliveryDestination from '../assets/img/DeliveryDestination.png';
import React, { useEffect, useState } from 'react';
import arrow1 from '../assets/img/arrows2.png';

const ProcessList1 = ({ activeIdx }) => {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		updatePredicate();
		window.addEventListener('resize', updatePredicate);
		return () => window.removeEventListener('resize', updatePredicate);
	}, []);
	const updatePredicate = () => {
		setIsMobile(window.innerWidth < 576);
	};

	return (
		<div style={{ overflow: 'auto' }}>
			<div
				className='d-block px-10 d-flex w-100  justify-content-around mt-4 process-list-1'
				style={{ gap: 7,alignItems:"center" }}
			>
				<ProcessBox
					isActive={activeIdx == 0}
					// title={isMobile?'Collection':'Collection Date'}
					title={'Collection Date'}
					img={Calendar}
				/>
				<img src={arrow1} className='d-none d-lg-block process_list_arrow1' />
				{/* <i
					className={`${
						isMobile ? 'fas fa-arrow-down' : 'fas fa-arrow-right'
					} mt-3 mt-md-5 mb-4 text-primary fa-3x`}
				/> */}
				<ProcessBox
					isActive={activeIdx == 1}
					// title={isMobile?'Address':'Address Information'}
					title={'Address Information'}
					img={DeliveryDestination}
				/>
				<img src={arrow1} className='d-none d-lg-block process_list_arrow1' />
				{/* <i
					className={`${
						isMobile ? 'fas fa-arrow-down' : 'fas fa-arrow-right'
					} mt-3 mt-md-5 mb-4 text-primary fa-3x`}
				/> */}
				<ProcessBox
					isActive={activeIdx == 2}
					// title={isMobile?'Invoice':'Invoice Generation'}
					title={'Invoice Generation'}
					img={Clipboard}
				/>
				<img src={arrow1} className='d-none d-lg-block process_list_arrow1' />
				{/* <i
					className={`${
						isMobile ? 'fas fa-arrow-down' : 'fas fa-arrow-right'
					} mt-3 mt-md-5 mb-4 text-primary fa-3x`}
				/> */}
				<ProcessBox
					isActive={activeIdx == 3}
					title='Checkout'
					img={Payment}
				/>
			</div>
		</div>
	);
};

export default ProcessList1;
