import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  Label,
  Input,
  Container,
} from "reactstrap";
import Payment from "../../assets/img/payment.png";
import Calendar from "../../assets/img/Calendar.png";
import DeliveryDestination from "../../assets/img/DeliveryDestination.png";
// import jsonData from '../../config/csvJson.json';
import countriesStates from "../../config/countriesStates.json";
import ProcessList1 from "../ProcessList1";
import ServicesHeader from "../ServicesHeader";
import InternationalActiveTab from "./InternationalActiveTab";
import { useSelector } from "react-redux";

function AddressDetail(props) {
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry,"selectedCountry");
  const [isMobile, setIsMobile] = useState(false);
  const [fromSuburbPost, setFromSuburbPost] = useState([]);
  const [toSuburbPost, setToSuburbPost] = useState([]);

  // const international = JSON.parse(localStorage.getItem('intl_first_page'));
  // if (international) {
  //     if (international.fromCountry) {
  //         const list = jsonData.filter((e) => e.Region === international.fromCountry)
  //         const newFrom = list.map(e => { return { suburb: e.SearchResult }; })
  //         setFromSuburbPost(newFrom)
  //     }
  //     if (international.toCountry) {
  //         const list = jsonData.filter((e) =>  e.Region === international.toCountry)
  //         const newTo = list.map(e => { return { suburb: e.SearchResult }; });
  //         setToSuburbPost(newTo);
  //     }
  // }

  const getCountryState = (countryCode, stateCode) => {
if(selectedCountry==='PT'){
return;
}else{
    let obj = countriesStates.find((state) => state?.name == countryCode);
    if (obj?.states && obj.states.length > 0) {
      let stateName = obj.states.find((state) => state.CODE == stateCode);
      if (stateName) {
        return stateName.STATE;
      } else {
        return;
      }
    } else {
      return;
    }
  }
  };
  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);
  const updatePredicate = () => {
    setIsMobile(window.innerWidth < 767);
  };
  return (
    <>
      <div className="mx-4">
        <ServicesHeader value="INTERNATIONAL COURIER" />
        <InternationalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
      </div>

      <ProcessList1 activeIdx={1} />
      <hr className="process_list_intl_hr" />
      <Container fluid className="address-details">
        <Row className="justify-content-center mx-2 mb-5 my-2  quote-calender-component">
          <Col md="6" xs='12'className="text-left ">
            <Label>Shipper Details</Label>
            <Row>
              <Col md="5" xs='12'>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      placeholder="Name"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          sendingName: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      placeholder="Surname"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          sendingSurName: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      placeholder="Contact Number"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          sendingContact: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="email"
                      placeholder="Email Address"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          sendingEmail: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      placeholder="(ID Number / Exporters Code) "
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          idNumber: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
              </Col>

              <Col md="7" xs='12'>
                <Input
                  type="text"
                  placeholder="Address 1 (Street Name and Number)"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      sendingAddress1: e.target.value,
                    })
                  }
                  required
                />
                {/* <Input
                  type="text"
                  placeholder="Address 2"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      sendingAddress2: e.target.value,
                    })
                  }
                /> */}
                <Input
                  type="text"
                  placeholder="Address 2 (Optional)"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      sendingComplex: e.target.value,
                    })
                  }
                />
                <Input
                  type="text"
                  placeholder="Suburb"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      sendingSuburb: e.target.value,
                    })
                  }
                  required
                  // readOnly
                />
                <Input
                  type="text"
                  placeholder="Postal Code / Zip Code"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      sendingPostal: e.target.value,
                    })
                  }
                  value={props.first?.fromCode}
                  readOnly
                  required
                />
                <Input
                  type="text"
                  placeholder="City / Town"
                  className="mt-1"
                  // onChange={e =>
                  // 	props.onChange({
                  // 		sendingCity: e.target.value,
                  // 	})
                  // }
                  value={props.first?.fromCity}
                  readOnly
                  required
                />
                <Input
                  type="text"
                  placeholder="State / Province"
                  className="mt-1"
                  value={
                    props.first?.fromState
                      ? getCountryState(
                          props.first.fromCountry,
                          props.first.fromState
                        )
                      : "-"
                  }
                  readOnly
                  required
                />
                <Input
                  type="text"
                  placeholder="Country"
                  className="mt-1"
                  value={props.first?.fromCountry}
                  readOnly
                  required
                />
              </Col>
            </Row>
          </Col>
          <Col md="6" xs='12' className="border__class text-left mb-5">
            <Label>Consignee Details</Label>
            <Row>
              <Col md="5" xs='12'>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      placeholder="Name"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          ReceivingName: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      placeholder="Surname"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          ReceivingSurName: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="text"
                      placeholder="Contact Number"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          ReceivingContact: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      type="email"
                      placeholder="Email Address"
                      className="mt-1"
                      onChange={(e) =>
                        props.onChange({
                          ReceivingEmail: e.target.value,
                        })
                      }
                      required
                    />
                  </Col>
                </Row>
              </Col>

              <Col md="7" xs='12'>
                <Input
                  type="text"
                  placeholder="Address 1 (Street Name and Number)"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      ReceivingAddress1: e.target.value,
                    })
                  }
                  required
                />
                {/* <Input
                  type="text"
                  placeholder="Address 2"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      ReceivingAddress2: e.target.value,
                    })
                  }
                /> */}
                <Input
                  type="text"
                  placeholder="Address 2 (Optional)"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      ReceivingComplex: e.target.value,
                    })
                  }
                />
                <Input
                  type="text"
                  placeholder="Suburb"
                  className="mt-1"
                  // value={props.first.toCode}
                  onChange={(e) =>
                    props.onChange({
                      ReceivingSuburb: e.target.value,
                    })
                  }
                  required
                  // readOnly
                />
                <Input
                  type="text"
                  placeholder="Postal Code / Zip Code"
                  className="mt-1"
                  onChange={(e) =>
                    props.onChange({
                      ReceivingPostal: e.target.value,
                    })
                  }
                  value={props.first?.toCode}
                  readOnly
                  required
                />
                <Input
                  type="text"
                  placeholder="City / Town"
                  className="mt-1"
                  value={props.first?.toCity}
                  onChange={(e) =>
                    props.onChange({
                      ReceivingCity: e.target.value,
                    })
                  }
                  readOnly
                  required
                />

                <Input
                  type="text"
                  placeholder="State / Province"
                  className="mt-1"
                  value={
                    props.first?.toState
                      ? getCountryState(
                          props.first?.toCountry,
                          props.first.toState
                        )
                      : "-"
                  }
                  // onChange={e =>
                  // 	props.onChange({
                  // 		ReceivingCity: e.target.value,
                  // 	})
                  // }
                  readOnly
                  required
                />

                <Input
                  type="text"
                  placeholder="Country"
                  className="mt-1"
                  value={props.first?.toCountry}
                  // onChange={e =>
                  // 	props.onChange({
                  // 		ReceivingCity: e.target.value,
                  // 	})
                  // }
                  readOnly
                  required
                />

                {/* <Row>

        <Col md='7'>
        
        </Col>
      </Row>
      <Row>
        <Col md='7'>
        
        </Col>
      </Row>
      <Row>
        <Col md='7'>
        
        </Col>
      </Row> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddressDetail;
