import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useEffect } from "react";

const IntlQuoteThankyou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Row className="justify-content-center  m-4 pt-3">
        <Col md="8" className="mx-auto ">
          <span className="text-primary h3 text-center">
            Thank you, a quote will be shared shortly
          </span>
          <hr />
        </Col>
      </Row>
    </Container>
  );
};

export default IntlQuoteThankyou;
