import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SectionTitle from '../components/SectionTitle';
import ContactOne from '../components/ContactOne';
import AccordionComp from '../components/AccordionComp';
import Fade from 'react-reveal/Fade';

function Faq() {
	//Scroll To Top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Container fluid>
			<Row>
				<Col lg='12' className='mb--40 px-0'>
					<div className={`section-title-bg text-center py-2 mb-4`}>
						<Fade bottom>
							<h1 className='title w-600'>
								FREQUENTLY ASKED QUESTIONS
							</h1>
						</Fade>
						<Fade bottom>
							<p className='description  mb-0'>
								Do you have a question regarding the booking of
								your shipment? <br />
								Below you will find answers to frequently asked
								questions from our customers.
							</p>
						</Fade>
					</div>
				</Col>
			</Row>
			<Container>
				<Row>
					<Col lg='12'>
						<AccordionComp customStyle='' />
					</Col>
				</Row>
			</Container>
		</Container>
	);
}

export default Faq;
