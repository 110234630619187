import React from "react";
import { useState } from "react";
import { Col, Row } from "reactstrap";


export const LocalActiveTab = ({ activeTab, setActiveTab }) => {
  const handleSubmit1 = () => {
    setActiveTab(1);
  };
  const [firstCompleted, setFirstCompleted] = useState(false);
  const [secondCompleted, setSecondCompleted] = useState(false);
  const [thirdCompleted, setThirdCompleted] = useState(false);
  const [fourthCompleted, setFourthCompleted] = useState(false);
  const handleSubmit2 = () => {
    if (firstCompleted) {
      setActiveTab(2);
    }
  };
  const handleSubmit3 = () => {
    if (secondCompleted) {
      setActiveTab(3);
    } else {
    }
  };
  const handleSubmit4 = () => {
    if (thirdCompleted) {
      setActiveTab(4);
    }
  };

  return (
    <Row
      className={`justify-content-center mt-3 ${
        activeTab == 8 || activeTab == 9 ? "d-none" : ""
      }`}
    >
      <Col align="center">
        <div className="d-flex service-header__wrapper  ">
          <div
            className={`service-header__wrapper-item border-right ${
              activeTab == 1 ? "active-item" : "inactive-item"
            }`}
            onClick={handleSubmit1}
          >
            <span className="heading">1.</span>
            <p className="d-none d-sm-block text-uppercase mb-0 sub-heading">
              Origin & Destination
            </p>
          </div>
          {/* <div className="service-header__wrapper-hr"></div> */}

          <div
            className={`service-header__wrapper-item border-right ${
              activeTab == 2 ? "active-item" : "inactive-item"
            }`}
            onClick={handleSubmit2}
          >
            <span className="heading">2.</span>
            <p className="d-none d-sm-block text-uppercase mb-0 sub-heading">
              SHIPMENT DETAILS
            </p>
          </div>
          {/* <div className="service-header__wrapper-hr"></div> */}
          <di
            className={`service-header__wrapper-item border-right ${
              activeTab == 3 ? "active-item" : "inactive-item"
            }`}
            onClick={handleSubmit3}
          >
            <span className="heading">3.</span>
            <p className="d-none d-sm-block text-uppercase mb-0 sub-heading ">
              QUOTE ESTIMATES
            </p>
          </di>
          {/* <div className="service-header__wrapper-hr"></div> */}
          <div
            className={`service-header__wrapper-item ${
              activeTab == 4 || activeTab == 5 || activeTab == 6
                ? "active-item"
                : "inactive-item"
            }`}
            onClick={handleSubmit4}
          >
            <span className="heading">4.</span>
            <p className="d-none d-sm-block text-uppercase mb-0 sub-heading">
              CONFIRM BOOKING
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};
