import React, { useState } from "react";
import whiteTape from "../assets/img/phase5/white tape.png";
const DimensionsCard = ({ details }) => {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    if (details.title === "Custom") {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div
      className="dimensions-card py-3 px-2 "
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={hovered && details.title === "Custom" ? whiteTape : details.icon}
        className="w-lg-50 w-sm-25"
      />
      <p className="title mb-0">{details?.title}</p>
      <p className="dimensions mb-0">
        (
        {details?.text ? (
          details?.text
        ) : (
          <>
            {details?.length} X {details?.width} X {details?.height} cm
          </>
        )}
        )
      </p>
    </div>
  );
};

export default DimensionsCard;
