import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import { useSelector } from "react-redux";

const InternationalActiveTab = ({ activeTab, setActiveTab }) => {
  const [firstCompleted, setFirstCompleted] = useState(false);
  const [secondCompleted, setSecondCompleted] = useState(false);
  const [thirdCompleted, setThirdCompleted] = useState(false);
  const [fourthCompleted, setFourthCompleted] = useState(false);
  const handleSubmit1 = () => {
    setActiveTab(1);
  };
  const handleSubmit2 = () => {
    if (firstCompleted) {
      setActiveTab(2);
    }
  };
  const handleSubmit3 = () => {
    if (secondCompleted) {
      setActiveTab(3);
    }
  };
  const handleSubmit4 = () => {
    if (thirdCompleted) {
      setActiveTab(4);
    }
  };
  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  return (
    <Row
      className={`justify-content-center mt-3 ${
        activeTab == 9 || activeTab == 10 ? "d-none" : ""
      }`}
    >
      <Col align="center">
        {selectedCountry == "PT" ? (
          <div className="d-flex service-header__wrapper">
            <div
              className={`portugal_header-item service-header__wrapper-item border-right  ${
                activeTab == 2 ? "active-item" : "inactive-item"
              }`}
              onClick={handleSubmit2}
            >
              <span className="heading">1.</span>
              <p className="d-none d-lg-block text-uppercase mb-0 sub-heading">
                SHIPMENT DETAILS
              </p>
            </div>
            <div
              className={`portugal_header-item service-header__wrapper-item  ${
                activeTab == 1 ? "active-item" : "inactive-item"
              }`}
              onClick={handleSubmit1}
            >
              <span className="heading">2.</span>
              <p className="d-none d-lg-block text-uppercase mb-0 sub-heading">
                Origin & Destination
              </p>
            </div>
          </div>
        ) : (
          <div className="d-flex service-header__wrapper">
            <div
              className={`service-header__wrapper-item border-right ${
                activeTab == 2 ? "active-item" : "inactive-item"
              }`}
              onClick={handleSubmit1}
            >
              <span className="heading">1.</span>
              <p className="d-none d-lg-block text-uppercase mb-0 sub-heading">
                SHIPMENT DETAILS
              </p>
            </div>

            <div
              className={` service-header__wrapper-item border-right ${
                activeTab == 1 ? "active-item" : "inactive-item"
              }`}
              onClick={handleSubmit2}
            >
              <span className="heading">2.</span>
              <p className="d-none d-lg-block text-uppercase mb-0 sub-heading">
                Origin & Destination
              </p>
            </div>
            <div
              className={` service-header__wrapper-item border-right ${
                activeTab == 3 ? "active-item" : "inactive-item"
              }`}
              onClick={handleSubmit3}
            >
              <span className="heading">3.</span>
              <p className="d-none d-lg-block text-uppercase mb-0 sub-heading">
                QUOTE ESTIMATES
              </p>
            </div>
            {/* <div className="service-header__wrapper-hr"></div> */}
            <div
              className={` service-header__wrapper-item ${
                activeTab == 4 ||
                activeTab == 5 ||
                activeTab == 6 ||
                activeTab == 7
                  ? "active-item"
                  : "inactive-item"
              }`}
              onClick={handleSubmit4}
            >
              <span className="heading">4.</span>
              <p className="d-none d-lg-block text-uppercase mb-0 sub-heading">
                CONFIRM BOOKING
              </p>
            </div>
            {/* </> */}
            {/* )} */}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default InternationalActiveTab;
