import React, { useState } from "react";
import SmallImg from '../assets/img/smallcube-min.png';
import MediumImg from '../assets/img/Mediumcube-min.png'
import LargeImg from '../assets/img/Largecube-min.png'
import CustomBikeImg from '../assets/img/bike.png'
import TickImg from '../assets/img/tick.png';
import { Row } from "react-bootstrap";
import { Col } from "reactstrap";

function small() {
  return (
    <Row className="small-main d-flex flex-md-row align-items-md-start">
      <Col md="4" xs="6" className="my-3 my-md-0">
        <img
          className="mr-md-4 genaral-main-image"
          src={SmallImg}
          alt="smallcube-min"
        />
      </Col>
      <Col md="8" className="small-text-section mt-4 mt-md-0">
        <h4>
          Applications
        </h4>
        <div className="my-4 mx-auto">
          
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Small - large sized boxes
          </h5>
        </div>
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Travel suitcases / bags
          </h5>
        </div>
          
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          TV / golf bags / suitcases          </h5>
        </div>

      </div>

        <div className=" small-base-section mb-4 mx-auto"></div>
        <h4 className="small-base px-2">
          General cargo not exceeding the inner dimensions of the move cube:
          <br />
          Height = 1.87m
          <br />
          Length = 1.00m
          <br /> Width = 1.11m <br /> CBM = 2.01m3
        </h4>
      </Col>
    </Row>
  );
}

function Mediums() {
  return (
    <Row className="medium-main d-flex flex-md-row align-items-md-start">
      <Col md="4" xs="6" className="my-3 my-md-0">
        <img
          className="mr-md-4 genaral-main-image"
          src={MediumImg}
          alt="Mediumcube-min"
        />
      </Col>

      <Col md="8" className="medium-text-section mt-4 mt-md-0 ">
        <h4 >
          Applications
        </h4>
        <div className="my-4 mx-auto">
          
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Small to medium sized cabinets
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Bicycles / gym equipment
          </h5>
        </div>
          
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Golf clubs / large bags
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          No additional packaging required
          </h5>
        </div>

        </div>

        <div className="medium-base-section mb-4 mx-auto"></div>
        <h4 className="small-base px-2">
          General cargo not exceeding the inner dimensions of the move cube:
          <br />
          Height = 1.87m
          <br />
          Length = 1.11m
          <br /> Width = 1.46m
          <br /> CBM = 3.01m3
        </h4>
      </Col>
    </Row>
  );
}

function Large() {
  return (
    <Row className="large-main d-flex flex-md-row align-items-md-start">
      <Col md="4" xs="6" className="my-3 my-md-0">
        <img
          className="mr-md-4 genaral-main-image"
          src={LargeImg}
          alt="Largecube-min"
        />
      </Col>

      {/* old code */}

      {/* <Col md="6" className="large-text-section  ">
        <h4 >
          Applications
        </h4>
        <div className="my-4">
          <h5 className="my-1">
            <img className="mr-4 mr-md-7" src={TickImg} alt="tick" />
            Chairs / foldable tables
          </h5>
          <h5 className="my-1">
            <img className="mr-4 mr-md-7" src={TickImg} alt="tick" />
            Queen size bed / large appliances
          </h5>
          <h5 className="my-1">
            <img className="mr-4 mr-md-7" src={TickImg} alt="tick" />
            Couches (2 seater, 1 seater){" "}
          </h5>
          <h5 className="my-1">
            <img className="mr-4 mr-md-7" src={TickImg} alt="tick" />
            No additional packaging required{" "}
          </h5>
        </div>

        <div className="large-base-section mb-4 mx-auto"></div>
        <h4 className="small-base">
          General cargo not exceeding the inner dimensions of the move cube:
          <br />
          Height = 1.87m
          <br />
          Length = 1.46m
          <br /> Width = 2.26m
          <br /> CBM = 6.17m3
        </h4>
      </Col> */}
      <Col md="8" className="large-text-section mt-4 mt-md-0  ">
        <h4 >
          Applications
        </h4>
        <div className="my-4 mx-auto">
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
            Chairs / foldable tables
          </h5>
        </div>
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
              Queen size bed / large appliances
          </h5>
        </div>
          <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className=" text-left text-nowrap mb-0">
          Couches (2 seater, 1 seater){" "}
          </h5>
        </div>
          
          <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className=" text-left text-nowrap mb-0">
          No additional packaging required{" "}
          </h5>
        </div>
          
          {/* <h5 className="my-1">
            <img className="" src={TickImg} alt="tick" />
            No additional packaging required{" "}
          </h5> */}
        </div>

        <div className="large-base-section mb-4 mx-auto "></div>
        <h4 className="small-base px-2">
          General cargo not exceeding the inner dimensions of the move cube:
          <br />
          Height = 1.87m
          <br />
          Length = 1.46m
          <br /> Width = 2.26m
          <br /> CBM = 6.17m3
        </h4>
      </Col>
    </Row>
  );
}

function Custom() {
  return (
    <Row className="custom-main d-flex flex-md-row align-items-md-start">
      <Col md="4" xs="6" className="my-3 my-md-0">
        <img className="mr-md-4 genaral-main-image" src={CustomBikeImg} alt="bike" />
      </Col>
      <Col md="8" className="custom-text-section mt-4 mt-md-0">
        <h4 >
          Applications
        </h4>
        <div className="my-4 mx-auto">
          
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Made to your requirement
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Custom wood options
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Additional features{" "}
          </h5>
        </div>
          
        </div>

        <div className="custom-base-section mb-4 mx-auto"></div>
        <h4 className="small-base px-2">
          The move cube eliminated additional packaging, improved the ease of
          handling
        </h4>
      </Col>
    </Row>
  );
}

const tabs = {
  1: small,
  2: Mediums,
  3: Large,
  4: Custom,
};

function MoveCube({ clickHandle, changeActiveTab }) {
  /*
   *State to Handle Current Tab
   */
  const [tab, setTab] = useState(1);
  const Tab = tabs[tab];

  /*
   *State for Active Tab
   */
  const [activeTab, setActiveTab] = useState({
    small: true,
    Medium: false,
    Large: false,
    custom: false,
    additional: false,
  });
  return (
    <div className="cube-main-section mx-auto">
      <Row className="cube-main-row-section flex-md-row my-md-5">
        <Col md="3" className="cube-button-section justify-content-md-start justify-content-center  my-3 my-md-0 flex-md-column">
          <h3
            onClick={() => {
              setTab(1);
              setActiveTab({
                small: true,
                Medium: false,
                Large: false,
                custom: false,
                additional: false,
              });
            }}
            className={`${
              activeTab.small ? "small-active" : "cube-default-color"
            } cube-btn-default rounded-lg px-3 py-2 mx-2 my-1`}
          >
            Small{" "}
          </h3>
          <h3
            onClick={() => {
              setTab(2);
              setActiveTab({
                small: false,
                Medium: true,
                Large: false,
                custom: false,
                additional: false,
              });
            }}
            className={`${
              activeTab.Medium
                ? "medium-active"
                : "cube-default-color"
            } cube-btn-default rounded-lg px-3 py-2 mx-2 my-1`}
          >
            Medium
          </h3>
          <h3
            onClick={() => {
              setTab(3);
              setActiveTab({
                small: false,
                Medium: false,
                Large: true,
                custom: false,
                additional: false,
              });
            }}
            className={`${
              activeTab.Large ? "large-active" : "cube-default-color"
            } cube-btn-default rounded-lg px-3 py-2 mx-2 my-1`}
          >
            Large
          </h3>
          <h3
            onClick={() => {
              // setTab(4);
              clickHandle(2);
              changeActiveTab({
                cubes: false,
                crating: true,
                motorized: false,
                hazardous: false,
              });
              setActiveTab({
                small: false,
                Medium: false,
                Large: false,
                custom: true,
                additional: false,
              });
            }}
            className={`${
              activeTab.custom
                ? "custom-active"
                : "cube-default-color"
            } cube-btn-default rounded-lg px-3 py-2 mx-2 my-1`}
          >
            Custom
          </h3>
          {/* <h3
          onClick={() => {
            setTab(4);
            setActiveTab({
              small: false,
              Medium: false,
              Large: false,
              custom: false,
              additional: true,
            });
          }}
          className={`${
            activeTab.additional
              ? "bg-[#404B51] text-[#FFFFF4]"
              : "text-[#00B7AE]"
          } rounded-lg px-10 py-2 mx-2 cursor-pointer text-center my-1 font-bold`}
        >
          Additional <br />
          options
        </h3> */}
        </Col>
        <Col md="9">
          <Tab />
        </Col>
      </Row>
    </div>
  );
}

export default MoveCube;
