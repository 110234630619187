import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeaderOne from "../components/HeaderOne";
import HeaderTop from "../components/HeaderTop";
import FooterOne from "../components/FooterOne";
import Button from "reactstrap";
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import WhatsApp from "../assets/img/WhatsApp.png";
import { getCountry } from "../store/actions/getCountries";
import moment from "moment";
import axios from "axios";
import FacebookPixel from "../components/FacebookPixel";
function Main(props) {
  function generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
  }
  const [selectedCountry, setSelectedCountry] = useState("");
  console.log(selectedCountry, "selectedCountry>>>>>>>>>>>");

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem("Country"));
    if (country) {
      setSelectedCountry(country);
    }
    // axios.post(
    //   `https://graph.facebook.com/${process.env.REACT_APP_PIXEL_ID}/events?access_token=${process.env.REACT_APP_ACCESS_TOKEN}`,
    //   {
    //     data: [
    //       {
    //         event_id: generateUniqueId(),
    //         event_name: "test_landingpage",
    //         event_time: 1721043006,
    //         action_source: "website",
    //         user_data: {
    //           em: [
    //             "7b17fb0bd173f625b58636fb796407c22b3d16fc78302d79f0fd30c2fc2fc068",
    //           ],
    //           ph: [null],
    //         },
    //         custom_data: {
    //           visit_detail: "Some one visit your landing page.",
    //           visit_time: moment().unix(),
    //           visit_date: moment().format("YYYY-MM-DD"),
    //         },
    //       },
    //     ],
    //     test_event_code: "TEST27271",
    //   }
    // );
  }, []);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const history = useHistory();
  const { mainLoader } = useSelector((state) => state.countries);
  const myRef = useRef(null);

  const executeScroll = () => {
    myRef.current.scrollIntoView();
  };
  const [showNav, setShowNav] = useState(true);

  const hideNav = () => {
    if (window.scrollY >= 50) {
      setShowNav(false);
    } else {
      setShowNav(true);
    }
  };

  // window.addEventListener('scroll', hideNav);

  const serviceRef = useRef(null);

  const scrollService = () => {
    if (serviceRef.current != null) {
      serviceRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    const fetchData = async () => {
      if (isMounted) {
        dispatch(getCountry());
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false
    };
  }, [dispatch]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleChat = () => {
    setIsOpen(!isOpen);
  };
  const sendMessage = () => {
    const phoneNumber =
      selectedCountry == "PT" ? "+351934106894" : " +27713328391";
    const message = "Hey, there"; // You can change this to your desired phone number
    const whatsappURL = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
    window.open(whatsappURL, "_blank");
  };
  var phoneNumber = selectedCountry == "PT" ? "+351934106894" : "+27713328391";
  return (
    <>
      {/* <Header /> */}
      <FacebookPixel />
      {mainLoader ? (
        <div
          style={{ width: "100%", height: "30rem" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner size="lg" color="primary" className="py-30" />
        </div>
      ) : (
        <>
          <HeaderTop showNav={showNav} executeScroll={executeScroll} />
          <HeaderOne showNav={showNav} scrollService={scrollService} />
          <div className="d-flex">
            <div className="w-100">
              {React.cloneElement(props.children, { serviceRef })}
              <div id="whatsapp-icon">
                <div type="button" onClick={sendMessage}>
                  <img
                    src={WhatsApp}
                    alt="WhatsApp Icon"
                    width="100"
                    height="60"
                  />
                </div>
              </div>
            </div>
          </div>

          <FooterOne />
        </>
      )}

      {/* <Footer /> */}
    </>
  );
}

export default Main;
