import React, { useState } from "react";
import MotercycleImg from '../assets/img/motorcycle-min.png'
import VehicleImg from '../assets/img/VEHICLE-min.png'
import TickImg from '../assets/img/tick.png';
import { Row } from "react-bootstrap";
import { Col } from "reactstrap";



function MotorCycles() {
  return (
    <Row className="motorcycle-main d-flex flex-md-row align-items-md-start ">
      <Col md="4" xs="6" className="my-3 my-md-0">
        <img
          className=" genaral-main-image"
          src={MotercycleImg}
          alt="motorcycle-min"
        />
      </Col>
      <Col md="8" className="large-text-section mt-4 mt-md-0">
        <h4 >
          Applications{" "}
        </h4>
        <div className="my-4 mx-auto">

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
            All motorcycle sizes{" "}
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Crated / strapped{" "}
          </h5>
        </div>

          
        </div>

        <div className="motorcycle-base-section mx-auto mb-4"></div>
        <h4 className="motorcycle-base px-2">
          Average crate size:
          <br />
          Height = 1.35m
          <br />
          Length = 2.5m
          <br />
          Width = 90cm
          <br />
          CBM = 3.03m3
        </h4>
      </Col>
    </Row>
  );
}

function Vehicles() {
  return (
    <Row className="vehicle-main d-flex flex-md-row align-items-md-start">
      <Col md="4" xs="6" className="my-3 my-md-0">
        <img
          className="  genaral-main-image"
          src={VehicleImg}
          alt="VEHICLE-min"
        />
      </Col>
      <Col md="8" className="large-text-section mt-4 mt-md-0">
        <h4>
          Applications{" "}
        </h4>
        <div className="my-4 mx-auto">

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          All standard sized vehicles
          </h5>
        </div>

        

          
        </div>
        <div className="large-base-section mb-4 mx-auto"></div>
        <h4 className="small-base px-2">
          All fluids are drained for international shipping
        </h4>
      </Col>
    </Row>
  );
}

const tabs = {
  1: MotorCycles,
  2: Vehicles,
};

function Motorized() {
  /*
   *State to Handle Current Tab
   */
  const [tab, setTab] = useState(1);
  const Tab = tabs[tab];

  /*
   *State for Active Tab
   */
  const [activeTab, setActiveTab] = useState({
    mootrcycles: true,
    vehicles: false,
  });
  return (
    <div className="motorized-main-section mx-auto">
      <Row className="motorized-main-row-section flex-md-row my-md-5 px-md-2">
        <Col md="3" className="motorized-button-section justify-content-md-start justify-content-center my-3 my-md-0 flex-md-column ">
          <h3
            onClick={() => {
              setTab(1);
              setActiveTab({
                mootrcycles: true,
                vehicles: false,
              });
            }}
            className={`${
              activeTab.mootrcycles
                ? "motorcycle-active"
                : "motorized-default-color"
            } rounded-lg px-5 px-md-3 py-2 mx-2 my-1 motorized-btn-default`}
          >
            Motorcycles
          </h3>
          <h3
            onClick={() => {
              setTab(2);
              setActiveTab({
                mootrcycles: false,
                vehicles: true,
              });
            }}
            className={`${
              activeTab.vehicles
                ? "carbtn-active"
                : "motorized-default-color"
            } rounded-lg px-5 px-md-3 py-2 mx-2 my-1 motorized-btn-default`}
          >
            Vehicles
          </h3>
        </Col>
        <Col md="9">
          <Tab />
        </Col>
      </Row>
    </div>
  );
}

export default Motorized;
