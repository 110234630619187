import React from "react";
import { Container } from "reactstrap";
import { useSelector } from "react-redux";
import Portugal from "../../src/assets/img/portugal.png";
import SouthAfrica from "../../src/assets/img/southafrica.png";
import { useEffect,useRef,useState } from "react";
import telephone from '.././assets/img/telephone.png';
import envelope from '../assets/img/envelope.png';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown
} from "reactstrap";
import { FlagIcon } from 'react-flag-kit';
function HeaderTop({ executeScroll, showNav }) {
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  console.log(selectedCountry,"selectedCountry");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownLanguageOpen, setDropdownLanguageOpen] = useState(false);
  const [googleTranslateRef, setGoogleTranslateRef] = useState(null);
  console.log(googleTranslateRef,"googleTranslateRef>>>>>>>>>>>>>");
  const [selectedLanguage, setSelectedLanguage] = useState('');
  console.log(selectedLanguage,"selectedLanguage>>>>>>>>>>>>>>>");
  const isInitialized = useRef(false);
  const languagesArray = [
    "en",
    "pt",
    "ar",
    "es",
    "af",
    "sq",
    "am",
    "da",
    "dv",
    "doi",
    "hmn",
    "pa",
    "pt",
    "qu",
    "lg",
    "lt",
    "ln",
    "is",
    "hu",
  ];
  const languagesString = languagesArray.join(",");
  let includedLanguages = languagesString;
  console.log(includedLanguages,"includedLanguages>>>>>>>>>>>>>>>>");
  const toggleLanguageDropDown = () =>
    setDropdownLanguageOpen(!dropdownLanguageOpen);
  const languageRef = useRef(null);
  console.log(languageRef,"languageRef>>>>");
  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    toggleLanguageDropDown(); // Close the flag dropdown after selecting a language
    updateGoogleTranslateLanguage(language);

    const translateSelect = document.querySelector(".goog-te-combo");
    if (translateSelect) {
      translateSelect.addEventListener("change", () =>{
        var selectedLanguage = translateSelect.value;
        setSelectedLanguage(selectedLanguage);
      });
    }
    if (translateSelect != undefined) {
      for (let i = 0; i < translateSelect.options.length; i++) {
        if (translateSelect.options[i].value === language) {
          translateSelect.selectedIndex = i;
          break;
        }
      }
    }
  };
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        // pageLanguage: "en",
        includedLanguages: includedLanguages,
        autoDisplay: true,
      },
      "google_translate_element"
    );
  };
  const updateGoogleTranslateLanguage = (language) => {
    if (window.google && window.google.translate) {
      const translateSelect = document.querySelector(".goog-te-combo");
      // Check if translateSelect exists and is not null
      if (translateSelect) {
        for (let i = 0; i < translateSelect.options.length; i++) {
          if (translateSelect.options[i].value === language) {
            translateSelect.selectedIndex = i;
            break;
          }
        }

        // Check if the element has an addEventListener method
        if (translateSelect.addEventListener) {
          translateSelect.addEventListener("change", ()=> {
            var selectedLanguage = translateSelect.value;
            setSelectedLanguage(selectedLanguage);
          });

          // Trigger the change event
          const event = new Event("change");
          translateSelect.dispatchEvent(event);
        }
      }
    }
  };
  useEffect(() => {
    if (!isInitialized.current) {
      const initializeGoogleTranslate = () => {
        if (!document.getElementById("google-translator-script")) {
          var addScript = document.createElement("script");
          addScript.setAttribute(
            "src",
            "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
          );
          addScript.setAttribute("id", "google-translator-script");
          document.body.appendChild(addScript);
          isInitialized.current = true;
        }
        window.googleTranslateElementInit = googleTranslateElementInit;
      };
      initializeGoogleTranslate();
    }
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setDropdownLanguageOpen(false);
      }
    };
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownLanguageOpen]);
  const languageFlags = {
    en: 'US',   // English (USA)
    pt: 'PT',   // Portuguese (Portugal)
    ar: 'SA',   // Arabic (Saudi Arabia)
    es: 'ES',   // Spanish (Spain)
    af: 'ZA',   // Afrikaans (South Africa)
    sq: 'AL',   // Albanian (Albania)
    am: 'ET',   // Amharic (Ethiopia)
    da: 'DK',   // Danish (Denmark)
    dv: 'MV',   // Divehi (Maldives)
    doi: 'IN',  // Dogri (India)
    hmn: 'CN',  // Hmong (China)
    pa: 'IN',   // Punjabi (India)
    qu: 'PE',   // Quechua (Peru)
    lg: 'UG',   // Ganda (Uganda)
    lt: 'LT',   // Lithuanian (Lithuania)
    ln: 'CD',   // Lingala (Congo)
    is: 'IS',   // Icelandic (Iceland)
    hu: 'HU',   // Hungarian (Hungary)
  };
  return (
    <>
      {showNav && (
        <div className="header_top ">
          <Container className="d-flex align-items-center ">
            <div >
              <a href="mailto:info@shipmystuff.co.za" className="text-white" style={{fontSize:'10px'}}>
               <img src={envelope} height={20} width={20} className="mb-1"/>{" "}
                {selectedCountry === "PT"
                  ? "info@shipmystuff.pt"
                  : "info@shipmystuff.co.za"}
              </a>
             
                      <a href={selectedCountry==='PT'?"tel:+351934106894":"tel:  +27713328391"} className="text-white" style={{fontSize:'10px'}}>
                        <img src={telephone} height={15} width={20} className="mb-1 ml-5"/>{" "}
                      {selectedCountry==='PT'?"(+351) 934 106 894":" (+27) 71 332 8391"}
                        </a>
            </div>
            <div className="ml-auto header_top_right">
            <div ref={languageRef}>
                      <Dropdown
                        className="mr-lg-5 "
                        isOpen={dropdownLanguageOpen}
                        toggle={toggleLanguageDropDown}
                      >
                        <DropdownToggle
                          caret
                          color="white"
                          className="notranslate text-white"
                          style={{fontSize:'10px'}}
                        >
                          {(() => {
                            switch (selectedLanguage) {
                              case "en":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>English</span>;
                              case "pt":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Portuguese</span>;
                              case "ar":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Arabic</span>;
                              case "es":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Spanish</span>;
                              case "af":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Afrikaans</span>;
                              case "sq":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Albanian</span>;
                              case "am":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Amharic</span>;
                              case "da":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Danish</span>;
                              case "dv":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Divehi</span>;
                              case "doi":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Dogri</span>;
                              case "hmn":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Hmong</span>;
                              case "pa":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Punjabi</span>;
                              case "qu":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Quechua</span>;
                              case "lg":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Ganda</span>;
                              case "lt":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Lithuanian</span>;
                              case "ln":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Lingala</span>;
                              case "is":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Icelandic</span>;
                              case "hu":
                                return <span><FlagIcon code={languageFlags[selectedLanguage]} size={15} style={{ marginRight: '8px' }}/>Hungarian</span>;
                              default:
                                return "Language";
                            }
                          })()}
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            top: "20px ",
                            zIndex: "9999 !important",
                          }}
                          className="mt-2   text-sm text-white  notranslate"
                          direction="down"
                        >
                          {languagesArray?.map((language, index) => {
                            let languageName = "";
                            switch (language) {
                              case "en":
                                languageName = "English";
                                break;
                              case "pt":
                                languageName = "Portuguese";
                                break;
                              case "ar":
                                languageName = "Arabic";
                                break;
                              case "es":
                                languageName = "Spanish";
                                break;
                              case "af":
                                languageName = "Afrikaans";
                                break;
                              case "sq":
                                languageName = "Albanian";
                                break;
                              case "am":
                                languageName = "Amharic";
                                break;
                              case "da":
                                languageName = "Danish";
                                break;
                              case "dv":
                                languageName = "Divehi";
                                break;
                              case "doi":
                                languageName = "Dogri";
                                break;
                              case "hmn":
                                languageName = "Hmong";
                                break;
                              case "pa":
                                languageName = "Punjabi";
                                break;
                              case "qu":
                                languageName = "Quechua";
                                break;
                              case "lg":
                                languageName = "Ganda";
                                break;
                              case "lt":
                                languageName = "Lithuanian";
                                break;
                              case "ln":
                                languageName = "Lingala";
                                break;
                              case "is":
                                languageName = "Icelandic";
                                break;
                              case "hu":
                                languageName = "Hungarian";
                                break;
                              default:
                                languageName = "Language";
                            }
                            return (
                              <DropdownItem
                                key={index}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize:'10px'
                                  // padding: "2px",
                                }}
                                onClick={() => {
                                  handleLanguageChange(language);
                                }}
                              >
                                <FlagIcon code={languageFlags[language]} size={15} style={{ marginRight: '8px' }} />
                                {languageName}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <div className="d-none">
                        <div
                          id="google_translate_element"
                          ref={(ref) => setGoogleTranslateRef(ref)}
                        ></div>
                      </div>
                      </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default HeaderTop;


