import axios from "axios";
import EuropeCountries from '../../config/EuropeCountriesList.json';
export const getCountry = () => async (dispatch) => {
	try {
		console.log("inside getCountry>>>>>>>>>>");
	  dispatch(CountryFetchLoader(true));
  
	  // Check if the country is already set in localStorage
	  const storedCountry = localStorage.getItem('Country');
	  if (storedCountry) {
		// If the country is already set, use it and skip the API calls
		const country = JSON.parse(storedCountry);
		dispatch({
		  type: 'GET_COUNTRY',
		  payload: country
		});
		dispatch(CountryFetchLoader(false));
		return;
	  }
  
	  // If the country is not set, make the API calls
	  let apiResponse = await axios.get('https://api.ipify.org');
	  console.log(apiResponse,"apiResponse>>>>>>>>>>>>>");
	  let countryDetails = await axios.get(`https://ipinfo.io/${apiResponse?.data}/json?token=5a6086648352e7`);
	  console.log(apiResponse, "apiResponse");
	  console.log(countryDetails, "countryDetails");
	  const matchCountry=countryDetails.data.country;
	  const selectedCountry=matchCountry==='PT'? 'PT':'ZA';
	  console.log(selectedCountry,"EuropeanCountry>>>>>>>>>>>");
	//   const selectedCountry = countryDetails.data.country === "PT" ? 'PT' : 'ZA';
	  localStorage.setItem('Country', JSON.stringify(selectedCountry));
	  dispatch({
		type: 'GET_COUNTRY',
		payload: selectedCountry
	  });
  
	  dispatch(CountryFetchLoader(false));
	} catch (error) {
	  dispatch(CountryFetchLoader(false));
	  console.log(error.message);
	}
  };
  
	export const setCountry=(Value)=>async dispatch=>{
		try{
			dispatch(CountryFetchLoader(true));
			dispatch({
				type:'GET_COUNTRY',
				payload:Value
			})
			localStorage.setItem('Country',JSON.stringify(Value));
			setTimeout(()=>{
				dispatch(CountryFetchLoader(false));
			},1000)
			
		}catch(error){
			dispatch(CountryFetchLoader(false));
			console.log(error.message);
		}
	}
	export const CountryFetchLoader = (val) => async (dispatch) => {
		dispatch({
		  type: "COUNTRY_LOADER",
		  payload: val,
		});
	  };