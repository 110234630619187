import IntlRepository from '../../Repository/IntlRepository';
import firebase from '../../config/firebase';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const qs = require('querystring');

let quoteTableCollection = firebase.firestore().collection('quote_table');

export const getFedexRates =
	(obj, setHide,doc_id, weightObj, baseURL, onSuccess = () => {}) =>
	async dispatch => {
		// const requestBody = {
		// 	grant_type: 'client_credentials',
		// 	client_id: 'l798972868d006406da56ff616e3a60830',
		// 	client_secret: 'e9a798c6c28f422f8677efde82857a96',
		// };

		try {
			const country = JSON.parse(localStorage.getItem('Country'));
			if(country==='PT'){
				let { data } = await IntlRepository.get(`/getFEDEXToken?country='PT'`);
				if (data.success && data.data.access_token) {
					try {
						IntlRepository.defaults.headers.Authorization = `Bearer ${data.data.access_token}`;
						let response = await IntlRepository.post(
							'/ratesQuotes',
							obj
						);
	
						if (response.data.success) {
							console.log('Rees', response.data.data);
							let newArr = {};
	
							let { totWeight, volWeight } = weightObj;
							response.data.data.output.rateReplyDetails.map(itm => {
								if (
									itm.ratedShipmentDetails &&
									itm.ratedShipmentDetails.length > 0
								) {
									let tax = 0;
									let taxPrefferd = 0;
	
									if (totWeight >= volWeight) {
										if (totWeight >= 0.01 && totWeight <= 10) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													16) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													16) /
												100;
										} else if (
											totWeight > 10 &&
											totWeight <= 20
										) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													18) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													18) /
												100;
										} else {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													22) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													22) /
												100;
										}
									} else {
										if (volWeight >= 0.01 && volWeight <= 10) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													16) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													16) /
												100;
										} else if (
											volWeight > 10 &&
											volWeight <= 20
										) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													18) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													18) /
												100;
										} else {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													22) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													22) /
												100;
										}
									}
	
									// (itm.ratedShipmentDetails[0].totalNetCharge * 12) / 100;
									if (
										itm.serviceType ==
										'FEDEX_INTERNATIONAL_PRIORITY'
									) {
										newArr = {
											...newArr,
											priority: {
												type: 'Priority',
												id: uuidv4(),
												rate: (
													itm.ratedShipmentDetails[0]
														.totalNetCharge + tax
												).toFixed('2'),
											},
											uiPreferredPriority: {
												type: 'UIPrefferedPriority',
	
												rate: (
													itm.ratedShipmentDetails[1]
														.totalNetCharge +
													taxPrefferd
												).toFixed('2'),
											},
											apiPreferredPriority: {
												type: 'APIPrefferedPriority',
												rate: itm.ratedShipmentDetails[1].totalNetCharge.toFixed(
													'2'
												),
											},
											apiPriority: {
												type: 'Priority',
	
												rate: itm.ratedShipmentDetails[0].totalNetCharge.toFixed(
													'2'
												),
											},
										};
									} else if (
										itm.serviceType == 'INTERNATIONAL_ECONOMY'
									) {
										newArr = {
											...newArr,
											economy: {
												type: 'Economy',
												id: uuidv4(),
												rate: (
													itm.ratedShipmentDetails[0]
														.totalNetCharge + tax
												).toFixed(2),
											},
											uiPreferredEconomy: {
												type: 'UIPrefferedEconomy',
	
												rate: (
													itm.ratedShipmentDetails[1]
														.totalNetCharge +
													taxPrefferd
												).toFixed('2'),
											},
											apiPreferredEconomy: {
												type: 'APIPrefferedEconomy',
	
												rate: itm.ratedShipmentDetails[1].totalNetCharge.toFixed(
													'2'
												),
											},
											apiEconomy: {
												type: 'Economy',
	
												rate: itm.ratedShipmentDetails[0].totalNetCharge.toFixed(
													'2'
												),
											},
										};
										// newArr.push({
										// 	type: 'Economy',
										// 	id: uuidv4(),
										// 	rate: itm.ratedShipmentDetails[0].totalNetCharge,
										// });
									}
									// itm.ratedShipmentDetails.push({});
	
									// itm.ratedShipmentDetails.forEach(rate => {
									// 	newArr.push({
									// 		exl_insurance: {
									// 			id: uuidv4(),
									// 			rate: rate.totalBaseCharge,
									// 		},
									// 		incl_insurance: {
									// 			id: uuidv4(),
									// 			rate: rate.totalNetCharge,
									// 		},
									// 	});
									// });
								}
							});
							dispatch({
								type: 'GET_INTL_FEDEX_RATES',
								payload: newArr,
							});
							onSuccess(newArr);
							// let document = await quoteTableCollection
							// 	.doc(doc_id)
							// 	.get();
							// if (document && document.exists) {
							// 	quoteTableCollection.doc(doc_id).update({
							// 		fedex: response.data.data,
							// 	});
							// } else {
							// 	quoteTableCollection.doc(doc_id).set({
							// 		fedex: response.data.data,
							// 		type: 'international',
							// 	});
							// }
						}
					} catch (error) {
						setHide(false);
						dispatch({
							type: 'GET_INTL_FEDEX_RATES',
							payload: {},
						});
						onSuccess({});
					}
				} else {
					// setHide(false);
					dispatch({
						type: 'GET_INTL_FEDEX_RATES',
						payload: {},
					});
					onSuccess({});
				}
			}
			else {
				let { data } = await IntlRepository.get('/getFEDEXToken');
				if (data.success && data.data.access_token) {
					try {
						IntlRepository.defaults.headers.Authorization = `Bearer ${data.data.access_token}`;
						let response = await IntlRepository.post(
							'/ratesQuotes',
							obj
						);
	
						if (response.data.success) {
							console.log('Rees', response.data.data);
							let newArr = {};
	
							let { totWeight, volWeight } = weightObj;
							response.data.data.output.rateReplyDetails.map(itm => {
								if (
									itm.ratedShipmentDetails &&
									itm.ratedShipmentDetails.length > 0
								) {
									let tax = 0;
									let taxPrefferd = 0;
	
									if (totWeight >= volWeight) {
										if (totWeight >= 0.01 && totWeight <= 10) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													16) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													16) /
												100;
										} else if (
											totWeight > 10 &&
											totWeight <= 20
										) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													18) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													18) /
												100;
										} else {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													22) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													22) /
												100;
										}
									} else {
										if (volWeight >= 0.01 && volWeight <= 10) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													16) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													16) /
												100;
										} else if (
											volWeight > 10 &&
											volWeight <= 20
										) {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													18) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													18) /
												100;
										} else {
											tax =
												(itm.ratedShipmentDetails[0]
													.totalNetCharge *
													22) /
												100;
											taxPrefferd =
												(itm.ratedShipmentDetails[1]
													.totalNetCharge *
													22) /
												100;
										}
									}
	
									// (itm.ratedShipmentDetails[0].totalNetCharge * 12) / 100;
									if (
										itm.serviceType ==
										'FEDEX_INTERNATIONAL_PRIORITY'
									) {
										newArr = {
											...newArr,
											priority: {
												type: 'Priority',
												id: uuidv4(),
												rate: (
													itm.ratedShipmentDetails[0]
														.totalNetCharge + tax
												).toFixed('2'),
											},
											uiPreferredPriority: {
												type: 'UIPrefferedPriority',
	
												rate: (
													itm.ratedShipmentDetails[1]
														.totalNetCharge +
													taxPrefferd
												).toFixed('2'),
											},
											apiPreferredPriority: {
												type: 'APIPrefferedPriority',
												rate: itm.ratedShipmentDetails[1].totalNetCharge.toFixed(
													'2'
												),
											},
											apiPriority: {
												type: 'Priority',
	
												rate: itm.ratedShipmentDetails[0].totalNetCharge.toFixed(
													'2'
												),
											},
										};
									} else if (
										itm.serviceType == 'INTERNATIONAL_ECONOMY'
									) {
										newArr = {
											...newArr,
											economy: {
												type: 'Economy',
												id: uuidv4(),
												rate: (
													itm.ratedShipmentDetails[0]
														.totalNetCharge + tax
												).toFixed(2),
											},
											uiPreferredEconomy: {
												type: 'UIPrefferedEconomy',
	
												rate: (
													itm.ratedShipmentDetails[1]
														.totalNetCharge +
													taxPrefferd
												).toFixed('2'),
											},
											apiPreferredEconomy: {
												type: 'APIPrefferedEconomy',
	
												rate: itm.ratedShipmentDetails[1].totalNetCharge.toFixed(
													'2'
												),
											},
											apiEconomy: {
												type: 'Economy',
	
												rate: itm.ratedShipmentDetails[0].totalNetCharge.toFixed(
													'2'
												),
											},
										};
										// newArr.push({
										// 	type: 'Economy',
										// 	id: uuidv4(),
										// 	rate: itm.ratedShipmentDetails[0].totalNetCharge,
										// });
									}
									// itm.ratedShipmentDetails.push({});
	
									// itm.ratedShipmentDetails.forEach(rate => {
									// 	newArr.push({
									// 		exl_insurance: {
									// 			id: uuidv4(),
									// 			rate: rate.totalBaseCharge,
									// 		},
									// 		incl_insurance: {
									// 			id: uuidv4(),
									// 			rate: rate.totalNetCharge,
									// 		},
									// 	});
									// });
								}
							});
							dispatch({
								type: 'GET_INTL_FEDEX_RATES',
								payload: newArr,
							});
							onSuccess(newArr);
							// let document = await quoteTableCollection
							// 	.doc(doc_id)
							// 	.get();
							// if (document && document.exists) {
							// 	quoteTableCollection.doc(doc_id).update({
							// 		fedex: response.data.data,
							// 	});
							// } else {
							// 	quoteTableCollection.doc(doc_id).set({
							// 		fedex: response.data.data,
							// 		type: 'international',
							// 	});
							// }
						}
					} catch (error) {
						setHide(false);
						dispatch({
							type: 'GET_INTL_FEDEX_RATES',
							payload: {},
						});
						onSuccess({});
					}
				} else {
					// setHide(false);
					dispatch({
						type: 'GET_INTL_FEDEX_RATES',
						payload: {},
					});
					onSuccess({});
				}
			}
		} catch (error) {
			setHide(false);
			dispatch({
				type: 'GET_INTL_FEDEX_RATES',
				payload: {},
			});
			onSuccess({});
		}
	};

export const getDhlRates =
	(obj, doc_id, weightObj, onSuccess = () => {}) =>
	async dispatch => {
		try {
			let { data } = await IntlRepository.post('/dhlExpress', obj);
			console.log('Dhl....dad', data.data);

			if (data.succes) {
				let weightToUse = weightObj.volWeight;
				if (weightObj.totWeight >= weightObj.volWeight) {
					weightToUse = weightObj.totWeight;
				}
				let dhlData = {};
				if (
					data.data &&
					data.data.products &&
					data.data.products.length > 0 &&
					data.data.products[0].totalPrice &&
					data.data.products[0].totalPrice.length > 0
				) {
					let tax = 0;

					if (weightToUse >= 0.01 && weightToUse <= 10) {
						tax =
							(data.data.products[0].totalPrice[0].price * 16) /
							100;
					} else if (weightToUse > 10 && weightToUse <= 20) {
						tax =
							(data.data.products[0].totalPrice[0].price * 18) /
							100;
					} else {
						tax =
							(data.data.products[0].totalPrice[0].price * 22) /
							100;
					}

					dhlData = {
						economy: {
							type: 'Economy',
							id: uuidv4(),
							rate: (
								data.data.products[0].totalPrice[0].price + tax
							).toFixed(2),
						},
						apiEconomy: {
							type: 'ApiEconomy',
							rate: data.data.products[0].totalPrice[0].price.toFixed(
								2
							),
						},
					};
				}
				dispatch({
					type: 'GET_INTL_DHL_RATES',
					payload: dhlData,
				});
				onSuccess(dhlData);

				// let document = await quoteTableCollection.doc(doc_id).get();
				// if (document && document.exists) {
				// 	quoteTableCollection.doc(doc_id).update({
				// 		dhl: data.data,
				// 	});
				// } else {
				// 	quoteTableCollection.doc(doc_id).set({
				// 		dhl: data.data,
				// 		type: 'international',
				// 	});
				// }
			} else {
				dispatch({
					type: 'GET_INTL_DHL_RATES',
					payload: {},
				});
				onSuccess({});
			}
		} catch (error) {
			dispatch({
				type: 'GET_INTL_DHL_RATES',
				payload: {},
			});
			onSuccess({});
		}
	};
