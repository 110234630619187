import React, { useState, useEffect } from "react";

import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import ScrollAnimation from "react-animate-on-scroll";
import Contact from "../assets/img/Contact.png";
import Follow_Us from "../assets/img/Follow_Us.png";
import Mail from "../assets/img/Mail.png";
import Location from "../assets/img/Location.png";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  TextArea,
  Spinner,
} from "reactstrap";
import Contact_Us_Banner from "../assets/img/Contact_Us_Banner.png";
import Contact_Us_banner_new from "../assets/img/Contact_Us_banner_new.png";
import Contact_us from "../assets/img/Contact_us.png";
import { useSelector } from "react-redux";

import axios from "axios";

function ContactOne() {
  const [selectedCountry, setSelectedCountry] = useState('');
  console.log(selectedCountry,'selectedCountry>>>>>>>>>>>');

  useEffect(() => {
    const country = JSON.parse(localStorage.getItem('Country'));
    if (country) {
      setSelectedCountry(country);
    }
  }, []);
  let [name, setName] = useState("");
  let [surname, setSurName] = useState("");
  let [company_name, setCompanyName] = useState("");
  let [email, setEmail] = useState("");
  let [message, setMessage] = useState("");
  let [subject, setSubject] = useState("");
  let [contact_no, setContactNo] = useState("");
  let [contactLoading, setContactLoading] = useState(false);

  let [showNotification, setShowNotification] = useState(false);

  const clearFields = () => {
    setName("");
    setSurName("");
    setCompanyName("");
    setMessage("");
    setEmail("");
    setContactNo("");
    setSubject("");
  };

  const messageSentNotification = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
    <>
      <Row className="">
        <Col lg="12">
          <div className="rn-contact-address mt_dec--30">
            <Row className="row--15">
              <Col lg="3" md="6">
                <div className="rn-address">
                  <div className="icon">
                    {/* <FiHeadphones /> */}
                    <img src={Contact} className="img-fluid" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Phone Number</h4>
                    <p>
                      <a href={selectedCountry=='PT'?"tel:+351934106894":"tel:  +27713328391"}>
                      {selectedCountry=='PT'?"(+351) 934 106 894":" (+27) 71 332 8391"}
                        </a>
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg="3" md="6">
                <div className="rn-address">
                  <div className="icon">
                    {/* <FiMail /> */}
                    <img src={Mail} className="img-fluid" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Address</h4>
                    <p>
                    <a href={selectedCountry=='PT'? 'mailto:info@shipmystuff.pt':'mailto:info@shipmystuff.co.za'}>
											{selectedCountry=='PT'? "info@shipmystuff.pt":"info@shipmystuff.co.za"}
										</a>
                    </p>
                  </div>
                </div>
              </Col>

              <Col lg="3" md="6">
                <div className="rn-address">
                  <div className="icon">
                    {/* <FiMapPin /> */}
                    <img src={Location} className="img-fluid" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Location</h4>
                    <p>
                      {selectedCountry=='PT'? "Portugal":"South Africa"}
                      {/* Johannesburg, 1724
                      <br /> South Africa */}
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg="3" md="6">
                <div className="rn-address">
                  <div className="icon">
                    {/* <FiMapPin /> */}
                    <img src={Follow_Us} className="img-fluid" />
                  </div>
                  <div className="inner">
                    <h4 className="title">Follow Us</h4>
                    <div className="icon_sm">
                    
                      <a
                      href={selectedCountry=='PT'? 'https://www.facebook.com/shipmystuffPT/':'https://www.facebook.com/shipmystuffZA/'}
                        target="_blank"
                      >
                        <FaFacebookF />
                      </a>

                      <a
                        href="https://www.instagram.com/ship_my_stuff_solutions/"
                        target="_blank"
                        className="ml-2"
                      >
                        <FaInstagram />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/shipmystuff/"
                        target="_blank"
                        className="ml-2"
                      >
                        <FaLinkedinIn />
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="row mt--40 row--15 ">
        <Col lg="6" className="contact_col">
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              setContactLoading(true);
              let obj = {
                selectedCountry,
                name,
                surname,
                company_name,
                contact_no,
                email,
                subject,
                message,
              };

              const { data } = await axios.post(
                `${process.env.REACT_APP_DEVELOPMENT}/sendQuery`,
                obj
              );
              if (data.success) {
                messageSentNotification();
                setContactLoading(false);
                await clearFields();
              } else {
                setContactLoading(false);
                await clearFields();
              }
            }}
            className="contact-form"
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Input
                    type="text"
                    name="surname"
                    value={surname}
                    onChange={(e) => setSurName(e.target.value)}
                    placeholder="Surname"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Input
                type="text"
                name="company_name"
                placeholder="Company Name (Optional)"
                value={company_name}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="Contactnumber"
                placeholder="Contact Number"
                value={contact_no}
                onChange={(e) => setContactNo(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </FormGroup>

            <FormGroup>
              <Input
                type="text"
                name="subject"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </FormGroup>

            <FormGroup>
              <Input
                name="message"
                placeholder="Message"
                value={message}
                required
                onChange={(e) => setMessage(e.target.value)}
                type="textarea"
              ></Input>
            </FormGroup>

            <FormGroup className="mb-0">
              <Button
                type="submit"
                disabled={contactLoading}
                className="btn-default btn-large text-uppercase d-flex justify-content-center align-items-center"
              >
                {contactLoading ? <Spinner size="md" /> : "SUBMIT"}
              </Button>
            </FormGroup>
            {showNotification && (
              <p className="mt-2 contact_notification">
                Message has been sent.
              </p>
            )}
          </Form>
        </Col>
        <Col lg="6" className="contact_col">
          <div className="contact_div">
            <img src={Contact_us} className="img-fluid" />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ContactOne;
