import React from "react";
import { Container, Row, Col } from "reactstrap";
import SectionTitle from "../components/SectionTitle";
import ContactOne from "../components/ContactOne";
import Services from "../components/Services";

function OurSolutions() {
  return (
    <div className="section_gap">
     <Container>
        <Row>
          <Col lg="12" className="mb--40 mt-2">
            <SectionTitle
              textAlign="text-center"
              title="OUR SOLUTIONS"
              description="End-to-end shipping solutions, simplified"
            />
          </Col>
        </Row>
        <Services textAlign="text-start" />
      
     </Container>
    </div>
  );
}

export default OurSolutions;
