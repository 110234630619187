import React from "react";
import { Button, Input } from "reactstrap";
import CustomField from "./CustomField";

const FclContainer = ({
  details,
  handleContainerChange,
  index,
  handleAddClick,
  handleRemoveClick,
  items,
}) => {
  const handlePlusClick = () => {
    handleContainerChange("units", details?.units + 1, index);
  };
  const handleMinusClick = () => {
    if (details?.units > 1) {
      handleContainerChange("units", details?.units - 1, index);
    }
  };
  return (
    <div className="d-flex w-100 align-items-center fclcontainer" >
      <div className="sea-freight__w-219 mr-3 fclbox">
        {index == 0 && <p className="sea-freight__label">Container Type</p>}
        <Input
          type="select"
          value={details?.type}
          onChange={(e) => handleContainerChange("type", e.target.value, index)}
          className={`${index != 0 ? "mt-3" : "mt-0"}`}
          required
        >
          <option value="20 FT STANDARD">20 FT STANDARD</option>
          <option value="40 FT STANDARD">40 FT STANDARD</option>
          <option value="40 FT STANDARD HIGH CUBE">
            40 FT STANDARD HIGH CUBE
          </option>
          <option value="20 FT REEFER">20 FT REEFER</option>
          <option value="40 FT REEFER">40 FT REEFER</option>
          <option value="40 FT REEFER HIGH CUBE">40 FT REEFER HIGH CUBE</option>
        </Input>
      </div>

      <div className="mr-3 fclbox">
        {index == 0 && <p className="sea-freight__label">UNITS</p>}

        <div className={`${index != 0 ? "mt-3" : "mt-0"}`}>
          <CustomField
            val={details?.units}
            handlePlusClick={handlePlusClick}
            handleMinusClick={handleMinusClick}
          />
        </div>
      </div>
      <div className="d-flex align-items-end">
        <div className="sea-freight__btn-group d-flex flex-column">
          <Button onClick={handleAddClick} className="btn-plus  " size="sm">
            <i class="fas fa-plus"></i>
          </Button>

          <Button
            disabled={items.length == 1}
            onClick={() => handleRemoveClick(index)}
            className="btn-minus "
            size="sm"
          >
            <i class="fas fa-minus"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FclContainer;
