import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import Fade from 'react-reveal';
import Button_Airplane from '../assets/img/Button_Airplane.png';
import Button_Truck from '../assets/img/Button_Truck.png';
import Button_Ship from '../assets/img/Button_Ship.png';

function Prohibited() {
	const [active, setActive] = useState(2);
	//Scroll To Top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			{/* <br />
				<br /> */}
			<Container fluid>
				<Row>
					<Col lg='12' className='mb--40 p-0'>
						<div
							className={`section-title-bg text-center py-2 mb-4`}
						>
							<Fade bottom>
								<h1 className='title w-600 mb--20'>
									PROHIBITED ITEMS
								</h1>
							</Fade>
							<Fade bottom>
								<p className='description px-1  mb-0'>
									It is important be aware of your destination
									country rules and regulations.
									<br />
									The following sections below will provide
									guidance on what is allowed to be shipped
									and what is not allowed.
								</p>
							</Fade>
						</div>
					</Col>
				</Row>
				{/* <h2 className='text-center main_heading  text-primary '>
					Globally Prohibited Items
				</h2>
				<hr /> */}
				<Container>
					<div className='mt-4 mb-5 prohibted_guide '>
						<Row className='px-0 px-md-4'>
							<Col lg='4'>
								<Button
									className={`${
										active == 1
											? 'bg-primary'
											: 'bg-secondary'
									} resources_btn  mt-2 mt-lg-0`}
									onClick={() => setActive(1)}
								>
									DOMESTIC COURIER
									<img
										src={Button_Truck}
										className='ml-auto img-fluid'
										width='30'
									/>
								</Button>
							</Col>
							<Col lg='4'>
								<Button
									className={`${
										active == 2
											? 'bg-primary'
											: 'bg-secondary'
									} resources_btn  mt-2 mt-lg-0`}
									onClick={() => setActive(2)}
								>
									INTERNATIONAL COURIER
									<img
										src={Button_Airplane}
										className='ml-auto img-fluid'
										width='30'
									/>
								</Button>
							</Col>
							<Col lg='4'>
								<Button
									className={`${
										active == 3
											? 'bg-primary'
											: 'bg-secondary'
									} resources_btn  mt-2 mt-lg-0`}
									onClick={() => setActive(3)}
								>
									SEA FREIGHT
									<img
										src={Button_Ship}
										className='ml-auto img-fluid'
										width='30'
									/>
								</Button>
							</Col>
						</Row>
						{active == 1 && (
							<div className='mt-4 resources_section'>
								<Row className=''>
									<Col className='' md='12'>
										<h1>Domestic Courier</h1>
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<p>
											<i className='fa fa-times mr-3'></i>
											Artwork / Sculptures / Paintings /
											Picture Frames
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Baked Goods
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Cars
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Precious metals such as gold,
											silver, and platinum in the form of
											bullion, coins, or ingots
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Jewellery
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Hazardous goods such as toxic,
											flammable liquids, non-flammable
											liquids and/or adhesives, aerosol
											cans, medical waste, and/or
											hazardous waste
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Glass Items / Crockery
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Liquids and Alcohol (Wine / Spirits
											/ Liquids)
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Livestock / Animals / Animal furs,
											skins, and remains
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Auto parts with fluids in them
										</p>
									</Col>
									<Col md='6'>
										<p>
											<i className='fa fa-times mr-3'></i>
											Pornography / obscene material
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Contraband includes, but is not
											limited to illicit drugs and
											counterfeit goods
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Flammables with a flashpoint of 140
											degrees Fahrenheit or less, common
											examples can be deodorant and
											perfume
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Human remains
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Furniture
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Perishables items
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Fresh flowers
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Windscreens
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Stamps (postage or revenue);
											vouchers; tokens; tickets of any
											kind; and credit, debit, or cash
											card
										</p>
									</Col>
								</Row>
							</div>
						)}
						{active == 2 && (
							<div className='mt-4 resources_section'>
								<Row>
									<Col className='' md='12'>
										<h1>International Courier</h1>
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<p>
											<i className='fa fa-times mr-3'></i>
											All commodities valued over US
											$20,000 without approval
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											One-of-a-kind/irreplaceable articles
											such as artwork valued over US
											$500,000 each
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Flammables with a flashpoint of 140
											degrees Fahrenheit or less, common
											examples can be deodorant and
											perfume.
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Auto parts with fluids in them
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Fine art
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Fine jewellery
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Animal furs, skins, and remains
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Flammable liquids, non-flammable
											liquids, and/or adhesives
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Medicines
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Medical waste and/or hazardous waste
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Lithium and/or car batteries
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Pornography/obscene material
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Weapons such as knives, swords, and
											firearms
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Precious metals such as gold,
											silver, and platinum in the form of
											bullion, coins, or ingots
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Watches valued over US $1000
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Bullion and money of every
											description such as (but without
											prejudice to the generality of this
											clause) cash, banknotes, coins,
											currency notes, or currency of any
											kind
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Stamps (postage or revenue);
											vouchers; tokens; tickets of any
											kind; and credit, debit, or cash
											cards
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Time-sensitive or critical written
											materials or documents including,
											but not limited to, bids and
											contract proposals
										</p>
									</Col>
									<Col md='6'>
										<p>
											<i className='fa fa-times mr-3'></i>
											Contraband includes, but is not
											limited to illicit drugs and
											counterfeit goods
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Livestock, bloodstock, and living
											animals
										</p>

										<p>
											<i className='fa fa-times mr-3'></i>
											Human remains, including ashes
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Division 1.1 Explosives (e.g., mines
											and nitro-glycerine)
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Division 1.2 Explosives (e.g.,
											rockets and warheads)
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Division 1.3 Explosives (e.g., smoke
											signals, projectiles, and commercial
											fireworks)
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Division 1.5 Explosives (e.g.,
											Blasting agents)
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Division 2.3 Toxic Gas (e.g.,
											aerosols and camping gas cylinders)
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Division 6.2 Infectious Substances
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Class 7 Radioactive Material
											including White I, Yellow II, Yellow
											III, and fissile material
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Air Guns
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Medicinal Cannabis
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Replica Ammunition
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Ivory
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Loose (semi) precious stones
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Activated Credit / Debit / ATM Card
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Toy guns
										</p>
										<p>
											<i className='fa fa-times mr-3'></i>
											Money Orders (NI)
										</p>
									</Col>
								</Row>
							</div>
						)}
						{active == 3 && (
							<div className='mt-4 resources_section'>
								<Row className='mt-3 resources_section '>
									<Col className='' md='12'>
										<h1>Sea Freight</h1>
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Alcoholic beverages are
												prohibited for international Air
												and Sea transport for
												non-commercial shipments.
											</p>
										</p>

										<p className='ml-4  mb-2'>
											Alcohol is prohibited for
											international Air and Sea transport
											for non-commercial shipments.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Aerosols for toiletry,
												medicinal, or any other purposes
												are not prohibited for sea
												freight
											</p>
										</p>
										<p className='ml-4 '>
											(Including deodorants, body sprays,
											air freshener, spray paints, hair
											sprays, shaving and hair removal
											creams, and medicinal aerosols).
										</p>
										<p className='ml-4 mb-2'>
											Aerosols are not prohibited for sea
											freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Ammunition
											</p>
										</p>
										<p className='ml-4 '>
											Ammunition is prohibited for sea
											freight.
										</p>
										<p className='ml-4 mb-2'>
											This excludes paintball or airsoft
											projectiles.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Animal products of any form
											</p>
										</p>
										<p className='ml-4'>
											Any hunting trophies, stuffed
											animals, and items derived from any
											animal are prohibited for sea
											freight.
										</p>
										<p className='ml-4 mb-2'>
											This includes items such as horns,
											animal pelts, furs, and animal
											bones. If such items are included in
											your consignment, please check the
											destination country's permit and
											quarantine requirements.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Batteries and items containing
												batteries
											</p>
										</p>
										<p className='ml-4 resources_section_heading'>
											Batteries that can be sent by Sea
										</p>
										<p className='ml-4'>
											<ul className='mb-0'>
												<li>
													18 Volt 3amp Lithium-Ion
													Battery
												</li>
												<li>
													18 Volt 6amp Lithium-Ion
													Battery
												</li>
												<li>
													2 Volt 4amp Lithium-Ion
													Battery
												</li>
												<li>
													18 Volt 1.5amp Lithium-Ion
													Battery
												</li>
												<li>14.4 Volt 3amp Battery</li>
											</ul>
										</p>
										<p className='ml-4'>
											Household batteries such as AA and
											AAA batteries can be sea via sea
											freight but must be kept in their
											original commercial packaging and
											not be opened.
										</p>
										<p className='ml-4 mb-2'>
											Items such as laptops containing
											lithium batteries are also safe for
											sea freight. Alkaline
											non-rechargeable batteries are
											acceptable.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Biological substances and
												samples
											</p>
										</p>
										<p className='ml-4 mb-2'>
											This includes substances such as
											animal remains, blood, feces, and
											urine are prohibited for sea
											freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												CBD products and
												Cannabis-Derived Products
											</p>
										</p>
										<p className='ml-4 '>
											All-natural and synthetic
											cannabinoids are prohibited from sea
											freight.
										</p>
										<p className='ml-4 mb-2'>
											This includes edibles, oils, plants,
											drinks, or any product that mimics
											THC effects.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Currency /Money
											</p>
										</p>
										<p className='ml-4 '>
											Currency and money are strictly
											prohibited for all forms of
											transport.
										</p>
										<p className='ml-4 mb-2'>
											This includes cash of any currency,
											credit cards, debit cards, vouchers,
											precious coins, tickets, lottery
											tickets, cheques, and Stamps
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Crackers and fireworks
											</p>
										</p>
										<p className='ml-4  mb-2'>
											All crackers and fireworks of any
											size, used for any event, are
											prohibited from sea freight and all
											other modes of transport.
										</p>
										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Controlled drugs and narcotics
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Controlled drugs and narcotics are
											prohibited for sea freight and any
											other mode of transport. This
											includes items such as heroin, LSD,
											cocaine, and cannabis.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Corrosive substances
											</p>
										</p>
										<p className='ml-4 '>
											Corrosives are prohibited for sea
											freight.
										</p>
										<p className='ml-4 mb-2'>
											Includes substances such as dyes,
											mercury, caustic soda, acids,
											corrosive paint and rust removers,
											and gallium metal.
										</p>
										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Counterfeited goods
											</p>
										</p>
										<p className='ml-4  mb-2'>
											All counterfeit goods are
											prohibited.
										</p>
										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Testing Kits & Personal
												Protective Equipment (PPE)
											</p>
										</p>
										<p className='ml-4  mb-2'>
											PPE products are not prohibited for
											sea freight.
										</p>
										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Cigarettes, E-Cigarettes, and
												Vape Pens
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Cigarettes, E-cigarettes, and vape
											pens, including their refills and
											cartridges are prohibited for sea
											freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Firearms and weapons
											</p>
										</p>
										<p className='ml-4'>
											Firearms and weapons are prohibited
											for sea freight and any other mode.
										</p>
										<p className='ml-4 mb-2'>
											This can include weapons such as
											shock batons, gas pistols, stun
											guns, paintball guns, and ant
											components belonging to them.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Flammable liquids
											</p>
										</p>
										<p className='ml-4 '>
											Flammable liquids are not prohibited
											for sea freight, given that the
											items are packaged properly and
											safely.
										</p>
										<p className='ml-4 mb-2'>
											This can include oil-based liquids
											such as cooking oil, suntan oil,
											enamels, lighter fluid, wood
											varnish, adhesives, solvent-based
											paints, acetone, and all nail
											varnish removers.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Flammable solids
											</p>
										</p>
										<p className='ml-4 '>
											Flammable solids are prohibited from
											sea freight and any other form of
											transport.
										</p>
										<p className='ml-4 mb-2'>
											Includes items such as sodium,
											magnesium, firelighters, potassium,
											zinc powder, and phosphorous.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Food and drinks
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Any carbonated drinks,
											confectionaries (chocolates and
											sweets), spices, protein powders,
											syrups, and sauces are prohibited
											for sea freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Gases and Compressed gasses
											</p>
										</p>
										<p className='ml-4 '>
											Gas and compressed air are
											prohibited for sea freight.
										</p>
										<p className='ml-4 mb-2'>
											This includes ANY compressed air or
											gas e.g. nitrogen dioxide, ethane,
											soda streams, fire extinguishers,
											and scuba tanks.
										</p>
									</Col>

									<Col md='6'>
										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Precious stones or high-value
												items{' '}
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Precious stones and metals, gold,
											silver, watches, and jewellery
											valued over 1000 USD are prohibited
											for sea freight. Art valued over 10
											000 USD is also prohibited for sea
											freight.
										</p>
										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Human/animal ashes{' '}
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Human or animal ashes are not
											prohibited for sea freight but will
											require additional documentation
											when transporting.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Liquids
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Liquids that are not considered
											flammable or toxic are not
											prohibited for sea freight. This
											would include liquids like shampoo
											or detergent.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Prescribed medication and OTC
												medicines
											</p>
										</p>
										<p className='ml-4 '>
											Medications are not prohibited for
											sea freight.
										</p>
										<p className='ml-4 mb-2'>
											Important to note that each pack may
											not exceed 50mk/50g per tablet and
											not more than 500ml/500mg net
											quantity may be transported
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Cosmetic nail products
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Cosmetic nail products are not
											prohibited but should be packed
											securely to prevent any leakage.
											Cosmetic nail products can include
											nail varnish, gel, and polish.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Negotiable Documents{' '}
											</p>
										</p>
										<p className='ml-4 mb-2'>
											Importing documents such as
											passports, driving licenses, and
											contracts are prohibited for sea
											freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Pornographic Materials
											</p>
										</p>
										<p className='ml-4 '>
											Pornographic material of any kind is
											prohibited.
										</p>
										<p className='ml-4 mb-2'>
											Indecent and obscene material
											featuring children, such as books,
											magazines, films, videotapes, DVDs,
											laserdiscs, and software is strictly
											prohibited.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Oxidizing materials
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Hair dyes, disinfectants, nitrates,
											and colorants are not prohibited for
											sea freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Perfumes and aftershaves
											</p>
										</p>
										<p className='ml-4 '>
											Perfumes and aftershave are not
											prohibited for sea freight. The
											products must be packed in strong
											outer packaging with cushioned
											inners to prevent breakage. One item
											cannot exceed 150ml and no more than
											two items should be packed together.
										</p>
										<p className='ml-4 mb-2'>
											Packaging guidelines: Volume per
											item must not exceed 150ml. No more
											than two items in any one parcel.
											Must be sent in its original retail
											packaging, placed in strong outer
											packaging, and cushioned to prevent
											breakage.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Pesticides
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Any chemical used to kill pests or
											insects is prohibited for sea
											freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Poisonous or toxic liquids,
												solids, and gases
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Any substance that can cause death
											after being inhaled or swallowed is
											strictly prohibited.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Plants and plant-related produce
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Plants and seeds of vegetables and
											fruit are restricted and may be
											imported with special permits based
											on the destination country.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Radioactive materials
											</p>
										</p>
										<p className='ml-4  mb-2'>
											All radioactive materials and
											substances are prohibited from any
											mode of transport.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Radio Transmitters
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Radio transmitters are not
											prohibited for sea freight. It is
											however important to check if the
											related product is allowed by the
											destination country.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Unlicensed controlled drugs
											</p>
										</p>
										<p className='ml-4 '>
											Any unlicensed drugs are strictly
											prohibited. This includes drugs such
											as opium, MDMA, cocaine heroin,
											morphine, amphetamines, and LSD.
										</p>
										<p className='ml-4 mb-2'>
											Bongs and water pipes are also
											prohibited.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Vehicle parts and car parts that
												contain liquids
											</p>
										</p>
										<p className='ml-4  mb-2'>
											Any large vehicle part or car part
											containing oil or liquid is
											prohibited for sea freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Household waste, dirt, and filth
											</p>
										</p>
										<p className='ml-4 mb-2'>
											Waste, dirt, and filth are
											prohibited from sea freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Bee wax and soy wax
											</p>
										</p>
										<p className='ml-4  mb-2'>
											All products containing bee wax or
											soy wax are prohibited from sea
											freight.
										</p>

										{/* New */}
										<p>
											<i className='fa fa-times mr-3'></i>
											<p className='resources_section_heading'>
												Weapons and Replica Weapons
											</p>
										</p>
										<p className='ml-4 '>
											Weapons and replica weapons are
											prohibited from sea freight.
										</p>
										<p className='ml-4  mb-2'>
											This includes bayonets, swords,
											daggers, martial arts equipment,
											blowguns, pocket knives, flick
											knives, knuckle busters, and
											butterfly knives.
										</p>
									</Col>
								</Row>
							</div>
						)}

						{/* <p className='mb-0'>
						There are international shipping restrictions with each
						carrier, and certain items may be prohibited for
						international shipping. Shipping certain commodities
						such as firearms, living animals, human remains, etc.
						are blanket prohibited categories with most carriers.
					</p>
					<p className='mb-0'>
						More than restrictions, standards for customs differ by
						country. Some countries require stricter and additional
						documentation to pass through customs based on their set
						out standards. Additional documentation can range from
						permits to applying for special permissions depending on
						the commodity.
					</p>
					<p className='mb-0'>
						If you are sending something other than generic
						household items or gifts, it is important to go through
						the destination county’s territory-specific lists, which
						set out requirements that allow the import of certain
						commodities.
					</p>
					<p className='mb-0'>
						Below you will find a carrier-wide prohibited list,
						these items will not be accepted by any courier carrier.
					</p>
					<h4 className='text-primary heading mt-3'>
						Prohibited Items:
					</h4>
					<ul>
						<li>
							All commodities valued over US $20,000 without
							approval
						</li>
						<li>
							One-of-a-kind/irreplaceable article such as artwork
							valued over US $500,000 each
						</li>
						<li>
							Flammables with a flash point of 140 degrees
							Fahrenheit or less
						</li>
						<li>Auto parts with fluids in them</li>
						<li>Fine art</li>
						<li>Fine jewellery</li>
						<li>Animal furs, skins, and remains</li>
						<li>
							Flammable liquids, non-flammable liquids and/or
							adhesives
						</li>
						<li>Medicines</li>
						<li>Medical waste and/or hazardous waste</li>
						<li>Lithium and/or car batteries</li>
						<li>Pornography/obscene material</li>
						<li>Weapons such as knives, swords, and firearms</li>
						<li>
							Precious metals such as gold, silver, and platinum
							in the form of bullion, coins, or ingots
						</li>
						<li>Watches valued over US $1000</li>
						<li>
							Bullion and money of every description such as (but
							without prejudice to the generality of this clause)
							cash, bank notes, coins, currency notes or currency
							of any kind
						</li>
						<li>
							Stamps (postage or revenue); vouchers; tokens;
							tickets of any kind; and credit, debit, or cash
							cards
						</li>
						<li>
							Time-sensitive or critical written materials or
							documents including, but not limited to, bids and
							contract proposals
						</li>
						<li>
							Contraband including, but not limited to illicit
							drugs and counterfeit goods
						</li>
						<li>Livestock, bloodstock and living animals</li>
						<li>Human remains</li>
						<li>
							Division 1.1 Explosives (e.g., mines and
							nitro-glycerine)
						</li>
						<li>
							Division 1.2 Explosives (e.g., rockets and warheads)
						</li>
						<li>
							Division 1.3 Explosives (e.g., smoke signals,
							projectiles, and commercial fireworks)
						</li>
						<li>Division 1.5 Explosives (e.g., Blasting agents)</li>
						<li>
							Division 2.3 Toxic Gas (e.g., aerosols and camping
							gas cylinders)
						</li>
						<li>Division 6.2 Infectious Substances</li>
						<li>
							Class 7 Radioactive Material including White I,
							Yellow II, Yellow III and fissile material
						</li>
					</ul>
					<h4 className='text-primary heading'>Unsure?</h4>
					<p>
						We are always here to advise and guide you through the
						process, contact us at support@shipmystuff.co.za
					</p> */}
					</div>
				</Container>
			</Container>
		</>
	);
}

export default Prohibited;
