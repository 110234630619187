import React from "react";
import { Input } from "reactstrap";

const CustomField = ({ val, handlePlusClick, handleMinusClick }) => {
  return (
    <div className="d-flex custom-plus-minus align-items-center px-2">
      <div className="custom-plus-minus__icon" onClick={handleMinusClick}>
        <i className="fa fa-minus cursor-pointer"></i>
      </div>
      <Input placeholder="01" value={val} disabled />
      <div className="custom-plus-minus__icon">
        <i className="fa fa-plus cursor-pointer" onClick={handlePlusClick}></i>
      </div>
    </div>
  );
};

export default CustomField;
