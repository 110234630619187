import React from "react";
import InternationalPayment from "../International/InternationalPayement";
import { Col, Row } from "reactstrap";
import { useEffect } from "react";

const ThankYou = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Row id="recipientPrint" className="justify-content-center  m-4">
      <Col md="8" className="mx-auto">
        <span className="text-primary h2 text-center">
          {/* Thank you for your booking. */}
          Your message has been sent.
        </span>
        <hr />
        <div className="text-left">
          <br />
          <span className="fw-600">
            One of our consultants will be in touch soon
          </span>
          <br />
          <span className="fw-600">
            In the meantime check out
            <a
              className="text-primary mx-1"
              href="/packing-guidelines"
              target="_blank"
            >
              <u>packaging guide</u>
            </a>
            for international shipments.
          </span>
          <br />
          <br />
          {/* <span className="font-weight-bold">
            Your booking confirmation will be sent shortly.
          </span> */}

          {/* <br /> */}
          <span className="font-weight-bold">
            General inquiries regarding international shipments, contact us at
            info{" "}
            <a href="mailto:info@shipmystuff.co.za" className="text-primary">
              info@shipmystuff.co.za
            </a>
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default ThankYou;
