import { useEffect } from 'react';

const useGtag = (id) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', id);

      // Make gtag globally available
      window.gtag = gtag;
    };
  }, [id]);
};

export default useGtag;
