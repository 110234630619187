import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  CardBody,
  Input,
  FormGroup,
  Label,
  Button,
  Form,
} from "reactstrap";

import DeliveryDestination from "../../assets/img/DeliveryDestination.png";
import Calendar from "../../assets/img/Calendar.png";
import Payment from "../../assets/img/payment.png";
import ProcessList from "../ProcessList";
import { Link } from "react-router-dom";
import paygate_logo from "../../assets/img/paygate_logo.png";
import { LocalActiveTab } from "./LocalActiveTab";
import ServicesHeader from "../ServicesHeader";

function ConfirmBooking(props) {
  const [isMobile, setIsMobile] = useState(false);
  const [email, setEmail] = useState("");

  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingSurName, setBillingSurName] = useState("");
  const [billingContactNo, setBillingCntactNo] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [billingAddress1, setBillingAddress1] = useState("");
  const [billingCompanyName, setBillingCompanyName] = useState("");
  const [billingTown, setBillingTown] = useState("");

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [vatNumber, setVatNumber] = useState("");

  // const [billingAddress2, setBillingAddress2] = useState("");
  const [billingComplexNo, setBillingComplexNo] = useState("");
  const [billingSuburb, setBillingSuburb] = useState("");
  const [billingCountry, setBillingCountry] = useState("South Africa");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  useEffect(() => {
    updatePredicate();
    window.addEventListener("resize", updatePredicate);
    return () => window.removeEventListener("resize", updatePredicate);
  }, []);
  const updatePredicate = () => {
    setIsMobile(window.innerWidth < 767);
  };

  const checkDetails = () => {
    if (
      billingFirstName == "" ||
      billingLastName == "" ||
      billingContactNo == "" ||
      billingPostalCode == "" ||
      // billingComplexNo == '' ||
      billingSuburb == "" ||
      billingTown == "" ||
      billingAddress1 == "" ||
      billingSuburb == "" ||
      acceptTerms == false
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getDimenstions = (data, type) => {
    let totalLength = 0;
    let totalWidth = 0;
    let totalHeight = 0;

    if (data.length > 0) {
      data.map((dt) => {
        totalLength += Number(dt.length);
        totalHeight += Number(dt.height);
        totalWidth += Number(dt.width);
      });
      if (type == "dimensions") {
        return (
          totalLength.toString() +
          "x" +
          totalWidth.toString() +
          "x" +
          totalHeight.toString()
        );
      } else if (type == "weight") {
        return ((totalLength * totalWidth * totalHeight) / 5000).toFixed(2);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  return (
    <div fluid className="confirm-booking-component">
      <div className="mx-4">
        <ServicesHeader value="DOMESTIC COURIER" />
        <LocalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
      </div>

      <ProcessList activeIdx={2} />
      <hr className="process_list_hr" />

      <Form
        onSubmit={(e) => {
          let obj = {
            billingFirstName,
            billingLastName,
            billingContactNo,
            billingEmail,
            billingAddress1,
            billingTown,
            // billingAddress2,
            billingComplexNo,
            billingSuburb,
            billingCountry,
            billingPostalCode,
            billingCompanyName,
            vatNumber,
          };
          localStorage.setItem("local_sixth_page", JSON.stringify(obj));
        }}
        action={props.payment?.redirectUri}
        method="POST"
      >
        <Row className="justify-content-center mx-3 my-2  ">
          <Col md="6" className="mb-5">
            <Row>
              <Col md="5">
                <span className="font-weight-bold text-primary text-start text-uppercase">
                  Billing Information
                </span>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md="6" className="text-left">
                <Input
                  type="text"
                  onChange={(e) => {
                    setBillingFirstName(e.target.value);
                  }}
                  required
                  placeholder="First Name"
                  className="mt-1"
                  value={billingFirstName}
                />
                <Input
                  type="text"
                  placeholder="Last Name"
                  required
                  onChange={(e) => {
                    setBillingLastName(e.target.value);
                  }}
                  className="mt-2"
                  value={billingLastName}
                />
                <Input
                  type="text"
                  placeholder="Company Name (Optional)"
                  onChange={(e) => {
                    setBillingCompanyName(e.target.value);
                  }}
                  className="mt-2"
                  value={billingCompanyName}
                />
                <Input
                  type="text"
                  placeholder="Phone"
                  required
                  className="mt-2"
                  onChange={(e) => {
                    setBillingCntactNo(e.target.value);
                  }}
                  value={billingContactNo}
                />
                <Input
                  type="email"
                  placeholder="Email Address"
                  required
                  onChange={(e) => {
                    setBillingEmail(e.target.value);
                  }}
                  className="mt-2"
                  value={billingEmail}
                />
              </Col>
              <Col md="6" className="text-left">
                <Input
                  type="text"
                  placeholder="Address 1"
                  required
                  onChange={(e) => {
                    setBillingAddress1(e.target.value);
                  }}
                  className="mt-1"
                  value={billingAddress1}
                />

                {/* <Input
									type='text'
									placeholder='Business Park / Estate '
									className='mt-2'
									onChange={e => {
										setBillingComplexNo(e.target.value);
									}}
									value={billingComplexNo}
									required
								/> */}
                <Input
                  type="text"
                  placeholder="Suburb"
                  required
                  onChange={(e) => {
                    setBillingSuburb(e.target.value);
                  }}
                  className="mt-2"
                  value={billingSuburb}
                />

                <Input
                  type="text"
                  placeholder="Postal Code / Zip Code"
                  required
                  onChange={(e) => {
                    setBillingPostalCode(e.target.value);
                  }}
                  className="mt-2"
                  value={billingPostalCode}
                />
                <Input
                  type="text"
                  placeholder="Town / City"
                  className="mt-2"
                  onChange={(e) => {
                    setBillingTown(e.target.value);
                  }}
                  value={billingTown}
                  required
                />

                <Input
                  type="text"
                  placeholder="Country"
                  required
                  onChange={(e) => {
                    setBillingCountry(e.target.value);
                  }}
                  readOnly
                  className="mt-2"
                  value={billingCountry}
                />
                <Input
                  type="text"
                  placeholder="Vat Number (Optional)"
                  onChange={(e) => {
                    setVatNumber(e.target.value);
                  }}
                  className="mt-2"
                  value={vatNumber}
                />
              </Col>
              <input
                hidden
                name="PAY_REQUEST_ID"
                value={props.payment.redirectParams.PAY_REQUEST_ID}
              />
              <input
                hidden
                name="CHECKSUM"
                value={props.payment.redirectParams.CHECKSUM}
              />
            </Row>
            <Row className="mt-3 mb-3">
              <Col>
                <FormGroup check className="d-flex justify-content-start">
                  <Input
                    name="radio2"
                    type="checkbox"
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(!acceptTerms)}
                    required
                  />
                  <Label check className="ml-3 text-decoration-underline">
                    I have read and accept the{" "}
                    <Link
                      to="/terms-and-conditions"
                      target="_blank"
                      className="terms__conditions text-primary"
                    >
                      terms and conditions
                    </Link>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="6" className="text-align-center mb-4 pay_car_main">
            <Card className="pay-card text-center">
              <CardBody>
                <Row className="pay_first_row mx-1">
                  <Col xs="12" align="left">
                    <p className="summary ">Order Summary</p>
                  </Col>
                  <Col xs="12" align="left">
                    <p className="details">Shipment Details</p>
                  </Col>
                </Row>
                <Row className="pay_second_row mx-1 mt-2">
                  <Col xs="4" align="left">
                    <span className="pay_heading">ORIGIN:</span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-uppercase text-nowrap">
                      {props.countryDetails?.fromRegion},{" "}
                      {props.countryDetails?.fromLocalArea},{" "}
                      {props.countryDetails?.fromCode}
                    </span>
                  </Col>
                </Row>
                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading">DESTINATION:</span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-uppercase text-nowrap">
                      {props.countryDetails?.toRegion},{" "}
                      {props.countryDetails?.toLocalArea},{" "}
                      {props.countryDetails?.toCode}
                    </span>
                  </Col>
                </Row>

                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading">SERVICE TYPE:</span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-uppercase text-nowrap">
                      {props.totalDetails?.type}{" "}
                    </span>
                  </Col>
                </Row>

                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading">WEIGHT:</span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">
                      {props.itemDetails?.totalWeight} Kg
                    </span>
                  </Col>
                </Row>
                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading">DIMENSIONS:</span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">
                      {props.itemDetails &&
                        props.itemDetails?.itemDetails &&
                        getDimenstions(
                          props.itemDetails.itemDetails,
                          "dimensions"
                        )}
                    </span>
                  </Col>
                </Row>
                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading text-nowrap">
                      VOLUMETRIC WEIGHT:
                    </span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">
                      {props.itemDetails && props.itemDetails?.volumetricWeight}{" "}
                      Kg
                    </span>
                  </Col>
                </Row>
                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading text-nowrap">ITEMS:</span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">
                      {props.itemDetails?.itemDetails.length}{" "}
                    </span>
                  </Col>
                </Row>
                <br />
                <Row className="pay_details_row mx-1 pb-3">
                  <Col xs="4" align="left">
                    <span className="pay_heading text-nowrap">
                      INCL INSURANCE:
                    </span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">
                      {props.insuranceDetails?.insurance ? "YES" : "NO"}{" "}
                    </span>
                  </Col>
                </Row>

                <Row className="pay_second_row mx-1 mt-2">
                  <Col xs="4" align="left">
                    <span className="pay_heading">VAT:</span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">0</span>
                  </Col>
                </Row>

                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading text-nowrap">
                      SUBTOTAL (ZAR):
                    </span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">
                      {props.totalDetails?.selectedCourier?.BaseRate}{" "}
                    </span>
                  </Col>
                </Row>
                <Row className="pay_second_row mx-1 mt-1">
                  <Col xs="4" align="left">
                    <span className="pay_heading text-nowrap">
                      TOTAL (ZAR):
                    </span>
                  </Col>
                  <Col xs="8" align="right">
                    <span className="pay_value text-nowrap">
                      {props.totalDetails?.selectedCourier?.TotalRate}
                    </span>
                  </Col>
                </Row>
                <Row className="mt-3  mx-1 align-items-center power_by_logo">
                  <Col xs="6" className="text-left">
                    <span className="paygate_text text-uppercase">
                      Powered By
                    </span>
                  </Col>
                  <Col xs="6" className="text-right">
                    <img src={paygate_logo} className="paygate_logo" />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    {checkDetails() ? (
                      <Button type="submit" className="pay-btn">
                        PROCEED WITH PAYMENT
                      </Button>
                    ) : (
                      <Button className="pay-btn2" type="button" readOnly>
                        PROCEED WITH PAYMENT
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>

      {/* <Col className='ml-0 ml-md-5'>
					<Row className='justify-content-between'>
						<Col md='5' className='text-left'>
							<Form
								onSubmit={e => {
									e.preventDefault();

									let obj = { email };
									localStorage.setItem(
										'local_sixth_page',
										JSON.stringify(obj)
									);
									props.setActiveTab('7');
								}}
							>
								<Row>
									<Col md='8'>
										<FormGroup>
											<Label>
												Enter your email address
											</Label>
											<Input
												type='email'
												placeholder='Email Address'
												className=''
												required
												value={props.value.email}
												onChange={e => {
													props.onChange({
														email: e.target.value,
													});
													setEmail(e.target.value);
												}}
											/>
										</FormGroup>
									</Col>
								</Row>

								<Row className='mt-3'>
									<Col>
										<FormGroup
											check
											className='d-flex justify-content-start'
										>
											<Input
												name='radio2'
												type='checkbox'
												required
											/>
											<Label
												check
												className='ml-3 text-decoration-underline'
											>
												I have read and I accept the{' '}
												<Link
													to='/terms-and-conditions'
													target='_blank'
													className='terms__conditions'
												>
													terms and conditions
												</Link>
											</Label>
										</FormGroup>
									</Col>
								</Row>

								<Row className='mt-3'>
									<Col className='text-left'>
										<Button
											type='submit'
											className='pay-btn'
										>
											Pay Now
										</Button>
									</Col>
								</Row>
							</Form>
						</Col>
						<Col md='6' className='mr-0 mr-md-5'>
							<Card className='pay-card' className='text-left'>
								<CardBody>
									Please check your inbox after submit button
									for the following:
									<ul className='text-justify'>
										<li>Quotation & reference number</li>
										<li>Payment link</li>
										<li>
											*Quotation only valid for 10 days
										</li>
									</ul>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Col> */}
    </div>
  );
}

export default ConfirmBooking;
