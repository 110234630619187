import moment from "moment";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../config/firebase";
import {
  Container,
  Spinner,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import Logo from "../../assets/img/logo.png";
import html2canvas from "html2canvas";
import jspdf from "jspdf";

function QuoteInvoice(props) {
  const [loader, setLoader] = useState(false);
  const downloadLicense = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      onclone: (document) => {
        document.getElementById("save-button").style.visibility = "hidden";
      },
      // backgroundColor: null
    }).then(async (canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdf = new jspdf();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    });
  };
  const uploadToStorage = () => {
    setLoader(true);
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      onclone: (document) => {
        document.getElementById("save-button").style.visibility = "hidden";
      },
      // backgroundColor: null
    }).then(async (canvas) => {
      const img = canvas.toDataURL("image/png");
      const pdfJS = new jspdf();
      const pdfWidth = pdfJS.internal.pageSize.getWidth();
      const pdfHeight = pdfJS.internal.pageSize.getHeight();
      pdfJS.addImage(img, "JPEG", 0, 0, pdfWidth, pdfHeight);
      const blob = new Blob([pdfJS.output("blob")], {
        type: "application/pdf",
      });
      const file = new File([blob], `${uuidv4()}.pdf`, {
        type: "application/pdf",
        lastModified: Date.now(),
      });

      let filename = file.name;
      let ext2 = filename.slice(filename.lastIndexOf("."));
      let file_name = uuidv4() + ext2.toLowerCase();
      let storageRef = await firebase
        .storage()
        .ref("recipient/" + file_name)
        .put(file);
      let url = await storageRef.ref.getDownloadURL();
      setLoader(false);

      let obj = { url };
      let seventh = localStorage.setItem(
        "local_seventh_page",
        JSON.stringify(obj)
      );
    });
  };
  useEffect(() => {
    if (props.activeTab == 7) {
      // uploadToStorage();
    }
  }, [props.activeTab]);
  return (
    <div id="divToPrint">
      <Container fluid className="invoice-component">
        <Row className="justify-content-center">
          <Col md="6" className="mt-3">
            <img className="img-fluid invoice-img" src={Logo} />
          </Col>
          <Col md="6" className="mt-3">
            <Card className="invoice-card text-justify px-2">
              <span className="py-3">
                Ship My Stuff (Pty) Ltd
                <br /> Roodepoort, Johannesburg
                <br /> 1724 <br />
                Tel: +27 67 888 7138 <br /> Email: <br />{" "}
                bookings@shipmystuff.co.za
              </span>
            </Card>
          </Col>
        </Row>
        <div className="mx-4">
          <Row className="mt-2 invoice-header text-white">
            <Col className="text-center">
              <span>QUOTE DETAILS</span>
            </Col>
            <Col className="text-center">
              <span>SHIPMENT DETAILS</span>
            </Col>
          </Row>
          <Row className="invoice-data bg-white">
            <Col className="text-left ">
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Invoice Date:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{moment().format("l")}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Name:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingName}</span>
                </Col>
              </Row>
              <Row className="px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Contact Number:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingContact}</span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Email Address:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span> {props.fifth?.sendingEmail}</span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Address 1:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span> {props.fifth?.sendingAddress1}</span>
                </Col>
              </Row>
              <Row>
                <Col lg="5" xs="8" align="end">
                  <span className="text-nowrap">
                    Complex / Business Park / Estate:
                  </span>
                </Col>
                <Col lg="5" xs="4">
                  <span>
                    {props.fifth?.sendingComplexNo
                      ? props.fifth.sendingComplexNo
                      : " "}
                  </span>
                </Col>
              </Row>
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Suburb:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.fromLocalArea}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Postal Code / Zip Code:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.fromCode}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>City:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.sendingCity}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Country:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.fromRegion}</span>
                </Col>
              </Row>
            </Col>
            <Col className="text-left vertical__line">
              <br />
              <Row className=" px-2">
                <Col lg="5" xs="8" align="end">
                  <span>Name:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.RecievingName}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Contact Number:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.RecievingContact}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Email Address:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.RecievingEmail}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Address 1:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.RecievingAddress1}</span>
                </Col>
              </Row>
              <Row>
                <Col lg="5" xs="8" align="end">
                  <span className="text-nowrap">
                    Complex / Business Park / Estate:
                  </span>
                </Col>
                <Col lg="5" xs="4">
                  <span>
                    {props.fifth?.RecievingComplexNo
                      ? props.fifth.RecievingComplexNo
                      : " "}
                  </span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Suburb:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.toLocalArea}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Postal Code / Zip Code:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.toCode}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>City:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.fifth?.RecievingCity}</span>
                </Col>
              </Row>
              <Row className=" px-2 ">
                <Col lg="5" xs="8" align="end">
                  <span>Country:</span>
                </Col>
                <Col lg="5" xs="4">
                  <span>{props.first?.toRegion}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Row className="m-2 invoice-details">
          <Col>
            <Row className="invoice-header">
              <Col className="text-center text-white">BOOKING DETAILS</Col>
            </Row>
            <Row className="invoice-booking-details">
              <Col
                md="5"
                className="invoice-booking-details-col text-left mr-md-2"
              >
                <span>Service: {props.third?.type}</span>
              </Col>
              <Col md="3" className="invoice-booking-details-col text-left">
                <span>
                  Collection Date:{" "}
                  {props.fourth?.date && moment(props.fourth.date).format("l")}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="m-2">
          <Table responsive className="invoice-booking-details-table">
            <thead>
              <th className="invoice-table-border border-left">Item No</th>
              <th className="invoice-table-border "> Item Description</th>
              <th className="invoice-table-border"> Weight </th>
              <th className="invoice-table-border"> Rate</th>
              <th className="invoice-table-border"> Surcharge</th>
              <th className="invoice-table-border border-right">
                Total amount
              </th>
            </thead>
            <tbody>
              {props.second?.itemDetails.map((itd, idx) => (
                <tr>
                  <td className="invoice-table-border-td border-left">
                    {idx + 1}
                  </td>
                  <td className="invoice-table-border-td">{itd.description}</td>
                  <td className="invoice-table-border-td"> {itd.weight}kg</td>
                  <td className="invoice-table-border-td">
                    R{props.third.selectedCourier.BaseRate}{" "}
                  </td>
                  <td className="invoice-table-border-td">
                    R
                    {props.third.selectedCourier.SurchargeRate
                      ? props.third.selectedCourier.SurchargeRate
                      : 0}{" "}
                  </td>
                  <td className="invoice-table-border-td border-right">
                    R
                    {props.third.selectedCourier.BaseRate +
                      (props.third.selectedCourier.SurchargeRate
                        ? props.third.selectedCourier.SurchargeRate
                        : 0)}
                  </td>
                </tr>
              ))}

              {/* <tr>
                <td className="invoice-table-border-td border-left">1</td>
                <td className="invoice-table-border-td">| Bed</td>
                <td className="invoice-table-border-td">| 50kg</td>
                <td className="invoice-table-border-td">| R120 </td>
                <td className="invoice-table-border-td">| R24 </td>
                <td className="invoice-table-border-td border-right">| R144</td>
              </tr> */}
            </tbody>
          </Table>
        </div>

        <Row className="mt-3 mx-2 mb-5">
          <Col md="6" className="text-left">
            <Card className="invoice-card-last text-justify ">
              <Row className="span-row">
                <Col>
                  <span>Courier Name: {props.third?.selectedCourier.Name}</span>
                </Col>
              </Row>
              <Row className="span-row">
                <Col>
                  <span>
                    Reference Number:{" "}
                    {props.payment?.paymentRef &&
                      props.payment.paymentRef.REFERENCE.substring(0, 25)}
                  </span>
                </Col>
              </Row>
              <Row className="span-row">
                <Col>
                  <span>
                    Booking Enquiry: {props.third?.selectedCourier?.Name}
                  </span>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col md="6">
            <Row className="justify-content-end ">
              <Col md="6" className="bg-white invoice-total">
                VAT Amount: R0
              </Col>
            </Row>
            <Row className="justify-content-end mt-1">
              <Col md="6" className="bg-white invoice-total">
                Total Weight: {props.second?.totalWeight}Kg
              </Col>
            </Row>
            <Row className="justify-content-end  mt-1">
              <Col md="6" className="bg-white invoice-total">
                Total Amount: R
                {props.third?.selectedCourier?.BaseRate +
                  (props.third?.selectedCourier?.SurchargeRate
                    ? props.third?.selectedCourier?.SurchargeRate
                    : 0)}
              </Col>
            </Row>
          </Col>
        </Row>
        <div id="save-button" className="m-2 text-left">
          <form action={props.payment?.redirectUri} method="POST">
            <input
              hidden
              name="PAY_REQUEST_ID"
              value={props.payment?.redirectParams.PAY_REQUEST_ID}
            />
            <input
              hidden
              name="CHECKSUM"
              value={props.payment?.redirectParams.CHECKSUM}
            />
            <Button type="submit" className="pay-btn ">
              {loader ? <Spinner /> : "Pay Now"}
            </Button>
            {/* <Button
              onClick={() => {
                // downloadLicense();
                handlePrint("printMe")
              }}
              color="primary"
              className="mx-2"
            >
              Download Invoice <i className="fa fa-file-download ml-2"></i>
            </Button> */}
          </form>
        </div>
      </Container>
    </div>
  );
}

export default QuoteInvoice;
