import React, { useEffect } from 'react';
import { Fade } from 'react-reveal';
import { Container, Row, Col } from 'reactstrap';

function TermsAndConditions() {
	//Scroll To Top
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Container className='terms_privacy' fluid>
				{/* <br />
				<br />
				<h2 className='text-center  text-primary  term_privacy_heading'>
					Terms and Conditions
				</h2>
				<hr /> */}
				<Row>
					<Col lg='12' className='mb--40 p-0'>
						<div className={`section-title-bg text-center py-2 `}>
							<Fade bottom>
								<h1 className='title w-600 '>
									Terms and Conditions
								</h1>
							</Fade>
						</div>
					</Col>
				</Row>
				<Container>
					<Row>
						<Col className={` text-center `}>
							<p className='mb-0 pt-3'>
								These terms and conditions are the contract
								between you and Ship My Stuff (Pty) Ltd (“us”,
								“we”, etc).
							</p>
							<p className='mb-0'>
								By visiting or using Our Website, you agree to
								be bound by them. Our suppliers may also impose
								additional terms and conditions to which your
								contract with them will be subject.
							</p>
							<p className='mb-0'>
								Ship My Stuff (Pty) Ltd, 2021/933469/07
								incorporated in South Africa, whose address is
								at Roodepoort, Johannesburg, South-Africa, 1724.
							</p>
							<p className='mb-0 pb-3'>
								Please read this agreement carefully and save
								it. If you do not agree with it, you should
								leave Our Website immediately.
							</p>
							<hr className='m-0 items_and_conditions' />
						</Col>
					</Row>
					<div className='mt-4 mb-5 prohibted_guide '>
						<div className='mt-4 mb-4'>
							<strong className='text-primary main_heading '>
								1.
							</strong>
							<strong className='ml-lg-4 ml-2 text-primary main_heading '>
								Definitions
							</strong>
						</div>

						{/* <br />
						<br /> */}
						<div className='ml-lg-5 ml-3'>
							<Row>
								<Col md='2'>
									<strong>“Content”</strong>
								</Col>
								<Col md='9'>
									<label className='ml-4 text-start'>
										means the textual, visual or audio
										content that is encountered as part of
										your experience on Our Website. It may
										include, among other things: text,
										images, sounds, videos and animations.
										It includes content Posted by you.
									</label>
								</Col>
							</Row>
							<Row>
								<Col md='2'>
									<strong>“Post”</strong>
								</Col>
								<Col md='9'>
									<label className='ml-4 text-start'>
										means display, exhibit, publish,
										distribute, transmit and/or disclose
										information, details and/or other
										material on Our Website, and the phrases
										"Posted" and "Posting" shall be
										interpreted accordingly.
									</label>
								</Col>
							</Row>
							<Row>
								<Col md='2'>
									<strong>“Service”</strong>
								</Col>
								<Col md='9'>
									<label className='ml-4 text-start'>
										means all of the services offered for
										sale through Our Website by a Provider.
									</label>
								</Col>
							</Row>
							<Row>
								<Col md='2'>
									<strong>“Provider”</strong>
								</Col>
								<Col md='9'>
									<label className='ml-4 text-start'>
										means a person who offers a Service for
										sale on Our Website.
									</label>
								</Col>
							</Row>
							<Row>
								<Col md='2'>
									<strong>“Our Website”</strong>
								</Col>
								<Col md='9'>
									<label className='ml-4 text-start'>
										means any website of ours, and includes
										all web pages controlled by us.
									</label>
								</Col>
							</Row>
							<Row>
								<Col md='2'>
									<strong>“User”</strong>
								</Col>
								<Col md='9'>
									<label className='ml-4 text-start'>
										means any person other than you who uses
										or visits the website for any purpose.
									</label>
								</Col>
							</Row>
							<Row>
								<Col md='2'>
									<strong>"you” “yours” etc</strong>
								</Col>
								<Col md='9'>
									<label className='ml-4 text-start'>
										means you, the party to this agreement.
									</label>
								</Col>
							</Row>
						</div>
						<br />
						<strong className='text-primary main_heading'>
							2.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Our contract
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>2.1</strong> */}
										<p className='ml-lg-3 text-start'>
											Ship My Stuff (Pty) Ltd is neither a
											buyer nor provider of Services
											offered for sale. We are neither a
											principal nor agent in a buying
											transaction.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>2.2</strong> */}
										<p className='ml-lg-3 text-start'>
											Ship My Stuff (Pty) Ltd is a
											marketplace and only provide the
											quotes based on your inputs. We are
											agents of a Provider only to the
											extent of use of Our Website as a
											platform for sale of his Service and
											for collection and forwarding of
											your money.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>2.3</strong> */}
										<p className='ml-lg-3 text-start'>
											We welcome any comment or complaint
											about a Provider, which you make
											through Our Website. We may act upon
											a complaint in our discretion, for
											the benefit of the body of Ship My
											Stuff (Pty) Ltd members.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>2.4</strong> */}
										<p className='ml-lg-3 text-start'>
											We are not responsible for supply of
											any Service you order or for the
											cancellation and refund procedure
											should you decide to cancel a
											Service for any reason.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>2.5</strong> */}
										<p className='ml-lg-3 text-start'>
											You agree and undertake that you are
											responsible to fill in your delivery
											details in correctly as possible and
											in compliance with prohibited item
											list, if not that may result in
											additional charges:
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>2.5.1</strong> */}
												<p className='ml-lg-3 text-start'>
													collection and delivery
													points
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>2.5.2</strong> */}
												<p className='ml-lg-3 text-start'>
													weight of the item/parcel
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>2.5.3</strong> */}
												<p className='ml-lg-3 text-start'>
													size of the item/parcel
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>2.5.4</strong> */}
												<p className='ml-lg-3 text-start'>
													collection and delivery date
												</p>
											</div>
										</li>
									</ul>
								</li>

								<li>
									<div className='d-flex'>
										{/* <strong>2.6</strong> */}
										<p className='ml-lg-3 text-start'>
											These terms and conditions regulate
											the business relationship between
											you and us. By using Our Website
											free of charge, you agree to be
											bound by them.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>2.7</strong> */}
										<p className='ml-lg-3 text-start'>
											We provide a marketplace for the
											supply of Services. We are in no way
											responsible for:
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>2.7.1</strong> */}
												<p className='ml-lg-3 text-start'>
													your locating and ordering a
													Service
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>2.7.2</strong> */}
												<p className='ml-lg-3 text-start'>
													your choice of a Service
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>2.7.3</strong> */}
												<p className='ml-lg-3 text-start'>
													any aspect of the provision
													of the Service
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>2.7.4</strong> */}
												<p className='ml-lg-3 text-start'>
													refund payment for any
													Service
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>2.7.5</strong> */}
												<p className='ml-lg-3 text-start'>
													any complaint about any
													Service
												</p>
											</div>
										</li>
									</ul>
								</li>

								<li>
									<div className='d-flex'>
										{/* <strong>2.8</strong> */}
										<p className='ml-lg-3 text-start '>
											In any dispute with a Provider, you
											should deal only with the Provider.
											We have neither legal obligation nor
											detailed information about the
											Services.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>2.9</strong> */}
										<p className='ml-lg-3 text-start'>
											We may change this agreement in any
											way at any time. The version
											applicable to your contract is the
											version which was Posted on Our
											Website at the time that the
											contract was made.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							3.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Your account and personal information
						</strong>
						<br /> 
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>3.1</strong> */}
										<p className='ml-lg-3 text-start'>
											When you visit Our Website, you
											accept responsibility for any action
											done by any person using your name,
											account or password. You should take
											all necessary steps to ensure that
											the password is kept confidential
											and secure and should inform us
											immediately if you have any reason
											to believe that your password has
											become known to anyone else, or if
											the password is being, or is likely
											to be, used in an unauthorised
											manner.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>3.2</strong> */}
										<p className='ml-lg-3 text-start'>
											You agree that you have provided
											accurate, up to date, and complete
											information about yourself. We are
											not responsible for any error made
											as a result of such information
											being inaccurate.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>3.3</strong> */}
										<p className='ml-lg-3 text-start'>
											You agree to notify us of any
											changes in your information
											immediately it occurs. If you do not
											do so, we may terminate your
											account.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							4.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							The buying procedure
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>4.1</strong> */}
										<p className='ml-lg-3 text-start'>
											Unless it is clear to the contrary,
											you may assume that every sale is
											made by the Provider in the course
											of his business.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.2</strong> */}
										<p className='ml-lg-3 text-start'>
											Prices listed on Our Website by
											Providers are exclusive of any
											applicable sales tax.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.3</strong> */}
										<p className='ml-lg-3 text-start'>
											Services may be offered for sale
											subject to any discount or promotion
											arranged between Ship My Stuff (Pty)
											Ltd and the Provider.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.4</strong> */}
										<p className='ml-lg-3 text-start'>
											Subject to discounts and promotions,
											Services are offered for sale at a
											fixed price. VAT may be due and will
											be either included in the price or
											shown separately.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.5</strong> */}
										<p className='ml-lg-3 text-start'>
											Services will be provided at the
											times and places specified in the
											website or otherwise in terms and
											conditions of each Provider.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.6</strong> */}
										<p className='ml-lg-3 text-start'>
											Once you have made order to purchase
											a Service through Our Website, the
											price cannot be increased for 1
											weeks.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.7</strong> */}
										<p className='ml-lg-3 text-start'>
											If you fail to declare your shipping
											details correctly then it can result
											in additional surcharges by the
											courier, for which you will be
											liable and Ship My Stuff (Pty) Ltd
											will issue a follow up invoice to
											cover the necessary expenses for the
											service rendered. In any event you
											will remain liable for any
											outstanding invoices.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.8</strong> */}
										<p className='ml-lg-3 text-start'>
											You agree and undertake that you
											need to comply with the prohibited
											list of items that are not allowed
											to be shipped, which can result in
											non-delivery and/or additional
											surcharges.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.9</strong> */}
										<p className='ml-lg-3 text-start'>
											Neither we nor the Provider can be
											responsible for action by any
											governmental authority. We do not
											know and are not responsible for
											duties, taxes, delays or impounding
											of any item.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.10</strong> */}
										<p className='ml-lg-3 text-start'>
											You are required to pay in the
											currency in which the Service is
											listed for sale on Our Website.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.11</strong> */}
										<p className='ml-lg-3 text-start'>
											Every sale will be subject to the
											laws applicable but there shall not
											be implied any right which is
											neither a legal right nor set down
											in these terms and conditions.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.12</strong> */}
										<p className='ml-lg-3 text-start'>
											For security purposes (yours and
											ours) we will not permit more than a
											maximum number of transactions with
											you in a given period of time.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>4.13</strong> */}
										<p className='ml-lg-3 text-start'>
											To make future use of Our Website
											easier and faster for you, we will
											retain the personal and delivery
											information you give to us. We will
											not retain information relating to
											your payment or credit card. This
											financial information never comes
											into our control. The information is
											given into a page which is in
											reality a page of our payment
											service provider. For detailed about
											disclosure of personal information
											please see our privacy notice.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							5.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Security of your credit card
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<p>
								We take care to make Our Website safe for you to
								use.
							</p>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>5.1</strong> */}
										<p className='ml-lg-3 text-start'>
											Card payments are not processed
											through pages controlled by us. We
											use one or more online payment
											service providers who will encrypt
											your card or bank account details in
											a secure environment.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>5.2</strong> */}
										<p className='ml-lg-3 text-start'>
											If you have asked us to remember
											your credit card details in
											readiness for your next purchase or
											subscription, we will securely store
											your payment details on our systems.
											These details will be fully
											encrypted and only used to process
											your automatic monthly payments or
											other transactions which you have
											initiated.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							6.
						</strong>
						<strong className='ml-lg-4 ml-2 text-start text-primary main_heading'>
							Cancellation and refunds: Service terms
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<p>
								This paragraph is not contractually part of this
								agreement. These are statement of your rights as
								a consumer as defined under the Electronic
								Communications and Transactions Act 2002 (“the
								Act”) and of the procedures with which all our
								Providers have been asked to comply. We have no
								responsibility if they fail to do so. We are not
								party to your contract with the Provider. Please
								refer any question about a Service to the
								Provider.
							</p>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>6.1</strong> */}
										<p className='ml-lg-3 text-start'>
											As required by the law, each
											Provider will give full information
											about his Service either on the
											pages of his website or in his
											catalogue.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>6.2</strong> */}
										<p className='ml-lg-3 text-start'>
											You may cancel your order at any
											time before the expiry of 7 days
											from the date you order a Service.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>6.3</strong> */}
										<p className='ml-lg-3 text-start'>
											If you want a Provider to supply a
											Service before the 7 days period has
											elapsed, you must opt out of your
											right to cancel, you can do that
											only if you instruct the Provider to
											supply a Service immediately and
											confirm that you understand that, by
											doing so, you will lose your right
											to cancel.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>6.4</strong> */}
										<p className='ml-lg-3 text-start'>
											If you do give up your right to
											cancel, and your Provider starts to
											give you the Service, you can still
											cancel it absolutely any time. But
											in that case your Provider must
											refund your payment only to the
											extent that he has not incurred cost
											to that point.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>6.5</strong> */}
										<p className='ml-lg-3 text-start'>
											Free Services (if any) are not
											covered by the Act.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>6.6</strong> */}
										<p className='ml-lg-3 text-start'>
											The Act does not remove other
											statutory rights you may have.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							7.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							How we handle your Content
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>7.1</strong> */}
										<p className='ml-lg-3 text-start'>
											Our privacy policy is strong and
											precise. It complies fully with
											current privacy law which is at
											[link to privacy policy].
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.2</strong> */}
										<p className='ml-lg-3 text-start'>
											If you Post Content to any public
											area of Our Website it becomes
											available in the public domains. We
											have no control over who sees it or
											what anyone does with it.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.3</strong> */}
										<p className='ml-lg-3 text-start'>
											Even if access to your text is
											behind a user registration it
											remains effectively in the public
											domain because someone has only to
											register and log in, to access it.
											You should therefore avoid Posting
											unnecessary confidential
											information.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.4</strong> */}
										<p className='ml-lg-3 text-start'>
											We need the freedom to be able to
											publicise our Services and your own
											use of them. You therefore now
											irrevocably grant us the right and
											licence to edit, copy, publish,
											distribute, translate and otherwise
											use any Content that you place on
											Our Website, in public domains and
											in any medium. You represent and
											warrant that you are authorised to
											grant all such rights.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.5</strong> */}
										<p className='ml-lg-3 text-start'>
											We will use that licence only for
											commercial purposes of the business
											of Our Website and will stop using
											it after a commercially reasonable
											period of time.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.6</strong> */}
										<p className='ml-lg-3 text-start'>
											You agree to waive your right to
											claim authorship and your right to
											object to any distortion, mutilation
											or other modification of your work
											as provided in the Copyright Act
											1978.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.7</strong> */}
										<p className='ml-lg-3 text-start'>
											You now irrevocably authorise us to
											publish feedback, comments and
											ratings about your activity through
											Our Website, even though it may be
											defamatory or critical.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.8</strong> */}
										<p className='ml-lg-3 text-start'>
											Posting content of any sort does not
											change your ownership of the
											copyright in it. We have no claim
											over it and we will not protect your
											rights for you.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.9</strong> */}
										<p className='ml-lg-3 text-start'>
											You understand that you are
											personally responsible for your
											breach of someone else’s
											intellectual property rights,
											defamation, or any law, which may
											occur as a result of any Content
											having been Posted by you;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.10</strong> */}
										<p className='ml-lg-3 text-start'>
											You accept all risk and
											responsibility for determining
											whether any Content is in the public
											domain and not confidential.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.11</strong> */}
										<p className='ml-lg-3 text-start'>
											Please notify us of any security
											breach or unauthorised use of your
											account.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>7.12</strong> */}
										<p className='ml-lg-3 text-start'>
											We do not solicit ideas or text for
											improvement of our Service, but if
											you do send to us material of any
											sort, you are deemed to have granted
											to us a licence to use it in the
											terms set out at sub paragraph five
											above.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							8.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Restrictions on what you may Post to Our Website
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<p>
								We invite you to Post Content to Our Website in
								several ways and for different purposes. We have
								to regulate your use of Our Website to protect
								our business and our staff, to protect other
								Users of Our Website and to comply with the law.
								These provisions apply to all Users of Our
								Website.
							</p>
							<p>
								We do not undertake to moderate or check every
								item Posted, but we do protect our business
								vigorously. If we believe Content Posted
								breaches the law, we shall co-operate fully with
								the law enforcement authorities in whatever ways
								we can.
							</p>
							<p>
								You agree that you will not use or allow anyone
								else to use Our Website to Post Content or
								undertake any activity which is or may:
							</p>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>8.1</strong> */}
										<p className='ml-lg-3 text-start'>
											be unlawful, or tend to incite
											another person to commit a crime;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.2</strong> */}
										<p className='ml-lg-3 text-start'>
											consist in commercial audio, video
											or music files;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.3</strong> */}
										<p className='ml-lg-3 text-start'>
											be obscene, offensive, threatening,
											violent, malicious or defamatory;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.4</strong> */}
										<p className='ml-lg-3 text-start'>
											be sexually explicit or
											pornographic;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.5</strong> */}
										<p className='ml-lg-3 text-start'>
											be likely to deceive any person or
											be used to impersonate any person,
											or to misrepresent your identity,
											age or affiliation with any person;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.6</strong> */}
										<p className='ml-lg-3 text-start'>
											use a Posting to solicit responses
											unconnected with the purpose of Our
											Website or the terms proposed by
											this agreement;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.7</strong> */}
										<p className='ml-lg-3 text-start'>
											request or collect passwords or
											other personal information from
											another user without his permission,
											nor Post any unnecessary personal
											information about yourself;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.8</strong> */}
										<p className='ml-lg-3 text-start'>
											be used to sell any goods or
											services or for any other commercial
											use not intended by us, for yourself
											or for any other person. Examples
											are: sending private messages with a
											commercial purpose, or collecting
											information with the intention of
											passing it to a third party for his
											commercial use;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.9</strong> */}
										<p className='ml-lg-3 text-start'>
											include anything other than words
											(i.e. you will not include any
											symbols or photographs) except for a
											photograph of yourself in your
											profile in such place as we
											designate;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.10</strong> */}
										<p className='ml-lg-3 text-start'>
											facilitate the provision of
											unauthorised copies of another
											person's copyright work;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.11</strong> */}
										<p className='ml-lg-3 text-start'>
											link to any of the material
											specified in this paragraph;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.12</strong> */}
										<p className='ml-lg-3 text-start'>
											use distribution lists that include
											people who have not given specific
											permission to be included in such
											distribution process;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>8.13</strong> */}
										<p className='ml-lg-3 text-start'>
											send age-inappropriate
											communications or Content to anyone
											under the age of 18.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							9.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Your Posting: restricted content
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<p>
								In connection with the restrictions set out
								below, we may refuse or edit or remove a Posting
								which does not comply with these terms.
							</p>
							<p>
								In addition to the restrictions set out above, a
								Posting must not contain:
							</p>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>9.1</strong> */}
										<p className='ml-lg-3 text-start'>
											hyperlinks, other than those
											specifically authorised by us.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>9.2</strong> */}
										<p className='ml-lg-3 text-start'>
											keywords or words repeated, which
											are irrelevant to the Content
											Posted.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>9.3</strong> */}
										<p className='ml-lg-3 text-start'>
											the name, logo or trademark of any
											organisation other than yours.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>9.4</strong> */}
										<p className='ml-lg-3 text-start'>
											inaccurate, false, or misleading
											information.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							10.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Removal of offensive Content
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>10.1</strong> */}
										<p className='ml-lg-3 text-start'>
											For the avoidance of doubt, this
											paragraph is addressed to any person
											who visits Our Website for any
											purpose.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>10.2</strong> */}
										<p className='ml-lg-3 text-start'>
											We are under no obligation to
											monitor or record the activity of
											any User of Our Website for any
											purpose, nor do we assume any
											responsibility to monitor or police
											Internet-related activities.
											However, we may do so without notice
											to you and without giving you a
											reason.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>10.3</strong> */}
										<p className='ml-lg-3 text-start'>
											If you are offended by any Content,
											the following procedure applies:
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>10.4</strong> */}
										<p className='ml-lg-3 text-start'>
											Your claim or complaint must be
											submitted to us in the form
											available on Our Website, or contain
											the same information as that
											requested in our form. It must be
											sent to us by post or email.
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>10.4.1</strong> */}
												<p className='ml-lg-3 text-start'>
													we shall remove the
													offending Content as soon as
													we are reasonably able;
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>10.4.2</strong> */}
												<p className='ml-lg-3 text-start'>
													after we receive notice of a
													claim or complaint, we shall
													investigate so far as we
													alone decide;
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>10.4.3</strong> */}
												<p className='ml-lg-3 text-start'>
													we may re-instate the
													Content about which you have
													complained or we may not.
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>10.5</strong> */}
												<p className='ml-lg-3 text-start'>
													In respect of any complaint
													made by you or any person on
													your behalf, whether using
													our form of complaint or
													not, you now irrevocably
													grant to us a licence to
													publish the complaint and
													all ensuing correspondence
													and communication, without
													limit.
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>10.6</strong> */}
												<p className='ml-lg-3 text-start'>
													You now agree that if any
													complaint is made by you
													frivolously or vexatiously
													you will repay us the cost
													of our investigation
													including legal fees, if
													any.
												</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							11.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Security of Our Website
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<p>
								If you violate Our Website, we shall take legal
								action against you.
							</p>
							<p>
								You now agree that you will not, and will not
								allow any other person to:
							</p>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>11.1</strong> */}
										<p className='ml-lg-3 text-start'>
											modify, copy, or cause damage or
											unintended effect to any portion of
											Our Website, or any software used
											within it.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>11.2</strong> */}
										<p className='ml-lg-3 text-start'>
											link to Our Website in any way that
											would cause the appearance or
											presentation of Our Website to be
											different from what would be seen by
											a User who accessed Our Website by
											typing the URL into a standard
											browser;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>11.3</strong> */}
										<p className='ml-lg-3 text-start'>
											download any part of Our Website,
											without our express written consent;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>11.4</strong> */}
										<p className='ml-lg-3 text-start'>
											collect or use any product listings,
											descriptions, or prices;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>11.5</strong> */}
										<p className='ml-lg-3 text-start'>
											collect or use any information
											obtained from or about Our Website
											or the Content except as intended by
											this agreement;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>11.6</strong> */}
										<p className='ml-lg-3 text-start'>
											aggregate, copy or duplicate in any
											manner any of the Content or
											information available from Our
											Website, other than as permitted by
											this agreement or as is reasonably
											necessary for your use of the
											Services;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>11.7</strong> */}
										<p className='ml-lg-3 text-start'>
											share with a third party any login
											credentials to Our Website;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>11.8</strong> */}
										<p className='ml-lg-3 text-start'>
											Despite the above terms, we now
											grant a licence to you to:
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>11.8.1</strong> */}
												<p className='ml-lg-3 text-start'>
													create a hyperlink to Our
													Website for the purpose of
													promoting an interest common
													to both of us. You can do
													this without specific
													permission. This licence is
													conditional upon your not
													portraying us or any product
													or service in a false,
													misleading, derogatory, or
													otherwise offensive manner.
													You may not use any logo or
													other proprietary graphic or
													trademark of ours as part of
													the link without our express
													written consent.
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>11.8.2</strong> */}
												<p className='ml-lg-3 text-start'>
													you may copy the text of any
													page for your personal use
													in connection with the
													purpose of Our Website or a
													Service we provide.
												</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<div className='d-flex'></div>
						<strong className='text-primary main_heading'>
							12.
						</strong>
						<strong className='ml-lg-4 ml-2 text-start text-primary main_heading'>
							Copyright and other intellectual property rights
						</strong>
						{/* <br />
						<br /> */}
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>12.1</strong> */}
										<p className='ml-lg-3 text-start'>
											All Content on Our Website, for
											example page text, graphics, logos,
											images, audio clips, digital
											downloads, data compilations, and
											software, is the property of either
											us or our affiliates or suppliers of
											Services for sale. It is all
											protected by international copyright
											laws.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>12.2</strong> */}
										<p className='ml-lg-3 text-start'>
											You may not copy, or in any way
											exploit any of the content, except
											as is expressly permitted in this
											agreement or with our written
											consent. For the sake of good order,
											you should note that copyright
											exists in compilations and graphic
											images, shapes and styles, as well
											as in raw text.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							13.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Interruption to the Ship My Stuff Service
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>13.1</strong> */}
										<p className='ml-lg-3 text-start'>
											We give no warranty that our service
											will be satisfactory to you.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>13.2</strong> */}
										<p className='ml-lg-3 text-start'>
											We will do all we can to maintain
											access to Our Website, but it may be
											necessary for us to suspend all or
											part of our service for repairs,
											maintenance or other reason. We may
											do so without telling you first.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>13.3</strong> */}
										<p className='ml-lg-3 text-start'>
											You acknowledge that our service may
											also be interrupted for reasons
											beyond our control.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>13.4</strong> */}
										<p className='ml-lg-3 text-start'>
											You agree that we are not liable to
											you for any loss whether foreseeable
											or not, arising as a result of
											interruption to Ship My Stuff
											service.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							14.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Indemnity
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<p>
								You agree to indemnify us against any claim or
								demand, including reasonable lawyers’ fees, made
								by any third party due to or arising out of:
							</p>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>14.1</strong> */}
										<p className='ml-lg-3 text-start'>
											your use of the Ship My Stuff
											service
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>14.2</strong> */}
										<p className='ml-lg-3 text-start'>
											the breach or violation of this
											agreement by you;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>14.3</strong> */}
										<p className='ml-lg-3 text-start'>
											the infringement by you of any
											intellectual property or other right
											of any person or entity;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>14.4</strong> */}
										<p className='ml-lg-3 text-start'>
											your failure to comply with any law;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>14.5</strong> */}
										<p className='ml-lg-3 text-start'>
											a contractual claim arising from
											your use of Our Website and purchase
											of Service.
										</p>
									</div>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							15.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Our disclaimers
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>15.1</strong> */}
										<p className='ml-lg-3 text-start'>
											Our Website contains links to other
											Internet websites. We have neither
											power nor control over any such
											website. You acknowledge and agree
											that we shall not be liable in any
											way:
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>15.1.1</strong> */}
												<p className='ml-lg-3 text-start'>
													for the Content of any such
													linked website, nor for any
													loss or damage arising from
													your use of any such
													website; and
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>15.1.2</strong> */}
												<p className='ml-lg-3 text-start'>
													not responsible for any
													event resulting in late or
													non-delivery of the
													item/parcel, such as
													traffic, load shedding, act
													of god, incorrect details
													being supplied, illegal
													items identified on the
													prohibited list which may
													lead to non-delivery.
												</p>
											</div>
										</li>
									</ul>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>15.2</strong> */}
										<p className='ml-lg-3 text-start'>
											We are not liable in any
											circumstances for special, indirect,
											consequential damages or any damages
											whatsoever resulting from loss of
											use, loss of data or loss of
											revenues or profits, whether in an
											action of contract, negligence or
											otherwise, arising out of or in
											connection with your use of Our
											Website.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>15.3</strong> */}
										<p className='ml-lg-3 text-start'>
											The Ship My Stuff Website and Ship
											My Stuff services are provided “as
											is”. We make no representation or
											warranty of any kind, express or
											implied, including, without
											limitation, any warranty that either
											of them will be:
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>15.3.1</strong> */}
												<p className='ml-lg-3 text-start'>
													of satisfactory quality;
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>15.3.2</strong> */}
												<p className='ml-lg-3 text-start'>
													fit for a particular
													purpose;
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>15.3.3</strong> */}
												<p className='ml-lg-3 text-start'>
													available or accessible,
													without interruption, or
													without error;
												</p>
											</div>
										</li>
									</ul>
								</li>
							</ul>
						</div>
						<strong className='text-primary main_heading'>
							16.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Disclaimers about the Service
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>16.1</strong> */}
										<p className='ml-lg-3 text-start'>
											All of the Content on Our Website
											relating to any Service has been
											provided by a Provider. We do not
											accept responsibility for the
											accuracy of any claim or
											advertisement.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>16.2</strong> */}
										<p className='ml-lg-3 text-start'>
											We make no representation, warranty
											or other provision with regard to
											the Services and you acknowledge
											that you do not rely on any made by
											us, but solely on your own risk and
											your contract with a Provider.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>16.3</strong> */}
										<p className='ml-lg-3 text-start'>
											So far as concerns Services you
											purchase through Our Website, we are
											not liable for:
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>16.3.1</strong> */}
												<p className='ml-lg-3 text-start'>
													any Service complying with
													the requirement of any law
													or being available;
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>16.3.2</strong> */}
												<p className='ml-lg-3 text-start'>
													the Provider performing his
													contract;
												</p>
											</div>
										</li>
									</ul>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>16.4</strong> */}
										<p className='ml-lg-3 text-start'>
											We give no warranty, representation
											or undertaking whatever as to the
											continuing business of a Provider or
											that any Service offered for sale by
											a Provider will be useful or
											suitable for you;
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>16.5</strong> */}
										<p className='ml-lg-3 text-start'>
											We and the Provider can take any
											action that may reasonably be
											required from time to time, to
											protect his interests and ours in
											connection with a beach or possible
											breach of any regulation or law.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>16.6</strong> */}
										<p className='ml-lg-3 text-start'>
											You now expressly release us from
											any and all claims and liability
											known and unknown, arising in any
											way from a dispute between you and a
											Provider.
										</p>
									</div>
								</li>
							</ul>
						</div>

						<strong className='text-primary main_heading'>
							17.
						</strong>
						<strong className='ml-lg-4 ml-2 text-primary main_heading'>
							Miscellaneous matters
						</strong>
						<br />
						<br />
						<div className='ml-lg-5 ml-2'>
							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>17.1</strong> */}
										<p className='ml-lg-3 text-start'>
											If any term or provision of this
											agreement is at any time held by any
											jurisdiction to be void, invalid or
											unenforceable, then it shall be
											treated as changed or reduced, only
											to the extent minimally necessary to
											bring it within the laws of that
											jurisdiction and to prevent it from
											being void and it shall be binding
											in that changed or reduced form.
											Subject to that, each provision
											shall be interpreted as severable
											and shall not in any way affect any
											other of these terms.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.2</strong> */}
										<p className='ml-lg-3 text-start'>
											If you are in breach of any term of
											this agreement, we may:
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<div className='d-flex'>
												{/* <strong>17.2.1</strong> */}
												<p className='ml-lg-3 text-start'>
													terminate your account and
													refuse access to Our Website
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>17.2.2</strong> */}
												<p className='ml-lg-3 text-start'>
													remove or edit Content, or
													cancel any order at our
													discretion;
												</p>
											</div>
										</li>
										<li>
											<div className='d-flex'>
												{/* <strong>17.2.3</strong> */}
												<p className='ml-lg-3 text-start'>
													issue a claim in any court.
												</p>
											</div>
										</li>
									</ul>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.3</strong> */}
										<p className='ml-lg-3 text-start'>
											Any obligation in this agreement
											intended to continue to have effect
											after termination or completion
											shall so continue.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.4</strong> */}
										<p className='ml-lg-3 text-start'>
											No failure or delay by us to
											exercise any right, power or remedy
											will operate as a waiver of it nor
											indicate any intention to reduce
											that or any other right in the
											future. When you visit Our Website
											or send messages to us by email, you
											are communicating with us
											electronically. We communicate with
											you by e-mail or by posting notices
											on Our Website. You agree that all
											our electronic communications
											satisfy any legal requirement that
											such communications be in writing.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.5</strong> */}
										<p className='ml-lg-3 text-start'>
											Any communication to be served on
											either of the parties by the other
											shall be delivered by hand or sent
											by fast mail service or recorded
											delivery or by e-mail.
										</p>
									</div>
									<ul className='terms_privacy_sub_ul'>
										<li>
											<p className='ml-lg-3 text-start'>
												It shall be deemed to have been
												delivered:
											</p>
										</li>
										<li>
											<p className='ml-lg-3 text-start'>
												if delivered by hand: on the day
												of delivery;
											</p>
										</li>
										<li>
											<p className='ml-lg-3 text-start'>
												if sent by post to the correct
												address: within 72 hours of
												posting;
											</p>
										</li>
										<li>
											<p className='ml-lg-3 text-start'>
												If sent by e-mail to the address
												from which the receiving party
												has last sent e-mail: within 24
												hours if no notice of
												non-receipt has been received by
												the sender.
											</p>
										</li>
									</ul>
								</li>
							</ul>

							<ul className='terms_privacy_ul'>
								<li>
									<div className='d-flex'>
										{/* <strong>17.6</strong> */}
										<p className='ml-lg-3 text-start'>
											In the event of a dispute between
											the parties to this agreement, then
											they undertake to attempt to settle
											the dispute by engaging in good
											faith with the other in a process of
											mediation before commencing
											arbitration or litigation.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.7</strong> */}
										<p className='ml-lg-3 text-start'>
											So far as the law permits, and
											unless otherwise stated, this
											agreement does not give any right to
											any third party.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.8</strong> */}
										<p className='ml-lg-3 text-start'>
											We shall not be liable for any
											failure or delay in our performance
											of this agreement which is caused by
											circumstances beyond our reasonable
											control, including any labour
											dispute.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.9</strong> */}
										<p className='ml-lg-3 text-start'>
											In the event of any conflict between
											any term of this agreement and the
											provisions of the memorandum of
											incorporation of a limited company
											or any comparable document intended
											to regulate any other corporate or
											collective body, then the terms of
											this agreement shall prevail.
										</p>
									</div>
								</li>
								<li>
									<div className='d-flex'>
										{/* <strong>17.10</strong> */}
										<p className='ml-lg-3 text-start'>
											The validity, construction and
											performance of this agreement shall
											be governed by the laws of the
											Republic of South Africa and you
											agree that any dispute arising from
											it shall be litigated only in that
											country.
										</p>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</Container>
			</Container>
		</>
	);
}

export default TermsAndConditions;
