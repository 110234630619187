import React from "react";
import { Card, CardBody, Label } from "reactstrap";

function ProcessBox({ title, img, isActive, isMobile }) {
  return (
    <div className="d-flex flex-column align-items-center">
      <i
        className={`fas fa-circle ${
          isActive ? "text-danger" : "text-white"
        } fa-2x`}
      />
      <small
        className={
          title === "Checkout" 
            ? " checkoutcircle d-block d-lg-none font-weight-bold processtext text-white"
            : "d-block d-lg-none font-weight-bold processtext text-white"
        }
      >
        {title}
      </small>
      <div className="d-none d-lg-block process_card_wrapper">
        <div className="process_card">
          <Card className="process_card_inner">
            <CardBody className="p-0 d-flex align-items-center justify-content-center">
              <div>
                <img src={img} className="process_card-img img-fluid" />
                <small className="d-block mt-2 text-secondary">{title}</small>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ProcessBox;
