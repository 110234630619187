import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { Col } from "reactstrap";
import AntiquesImg from '../assets/img/Antiques-min.png'
import PaintingImg from '../assets/img/ArtPainting-min.png'
import CustomImg from '../assets/img/CRATES_M-min.png'
import TickImg from '../assets/img/tick.png'
function Antiques() {
  return (
    <Row className=" antiques-main  d-flex flex-md-row align-items-md-start">
      <Col md="4" xs="6" className="my-3 my-md-0">
        <img
          className="mr-md-4 genaral-main-image "
          src={AntiquesImg}
          alt="Antiques-min"
        />
      </Col>
      <Col md="8" className="antiques-text-section mt-4 mt-md-0">
        <h4>
          Applications{" "}
        </h4>
        <div className="my-4 mx-auto">
          
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Sentimental items
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Cabinets / Desks{" "}
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
            Family heirlooms{" "}
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          No additional packaging required
          </h5>
        </div>
      
      </div>

        <div className="antiques-base-text mb-4 mx-auto"></div>
        <h4 className="small-base px-2">Based on requirement.</h4>
      </Col>
    </Row>
  );
}

function Paintings() {
  return (
    <Row className="painting-main flex-md-row align-items-md-start">
      <Col md="3" xs="6" className="my-3 my-md-0 ml-0 ml-md-3">
        <img
          className="mr-md-4 genaral-main-image "
          src={PaintingImg}
          alt="ArtPainting-min"
        />
      </Col>
      <Col md="8" className="painting-text-section mt-4 mt-md-0">
        <h4 >
          Applications{" "}
        </h4>
        <div className="my-4 mx-auto">
         
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
            Paintings{" "}
          </h5>
        </div>
         
        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
          Art sculptures{" "}
          </h5>
        </div>

        <div className="d-flex align-items-center ">
          <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
          <h5 className="text-left text-nowrap mb-0">
            No additional packaging required{" "}
          </h5>
        </div>

        </div>

        <div className="painting-base-text mb-4 mx-auto"></div>
        <h4 className="small-base px-2">Based on requirement. </h4>
      </Col>
    </Row>
  );
}

function Custom() {
  return (
    <Row className="custom-main d-flex flex-md-row align-items-md-start">
    <Col md="4" xs="6" className="my-3 my-md-0">
      <img className="mr-md-4 genaral-main-image" src={CustomImg} alt="CustomImage" />
    </Col>
    <Col md="8" className="custom-text-section mt-4 mt-md-0">
      <h4 >
        Applications
      </h4>
      <div className="my-4 mx-auto">
        
      <div className="d-flex align-items-center ">
        <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
        <h5 className="text-left text-nowrap mb-0">
        Made to your requirement
        </h5>
      </div>

      <div className="d-flex align-items-center ">
        <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
        <h5 className="text-left text-nowrap mb-0">
        Custom wood options
        </h5>
      </div>

      <div className="d-flex align-items-center ">
        <span className="mr-md-3"><img  src={TickImg} alt="tick" /></span>
        <h5 className="text-left text-nowrap mb-0">
        Additional features{" "}
        </h5>
      </div>
        
      </div>

      <div className="custom-base-section mb-4 mx-auto"></div>
      <h4 className="small-base px-2 ">
      Enclosed cargo, no protruding objects, not exceeding the dimensions of the the move cube.
      </h4>
    </Col>
  </Row>
  );
}

const tabs = {
  1: Antiques,
  2: Paintings,
  3: Custom,
};

function Crating() {
  /*
   *State to Handle Current Tab
   */
  const [tab, setTab] = useState(1);
  const Tab = tabs[tab];

  /*
   *State for Active Tab
   */
  const [activeTab, setActiveTab] = useState({
    antique: true,
    paintings: false,
    custom: false,
  });
  return (
    <div className="main-section mx-auto">
      <Row className="main-row-section flex-md-row my-md-5">
        < Col md="3" className="button-section justify-content-md-start justify-content-center  my-3 my-md-0 flex-md-column">
          <h3
            onClick={() => {
              setTab(1);
              setActiveTab({
                antique: true,
                paintings: false,
                custom: false,
              });
            }}
            className={`${
              activeTab.antique
                ? "antique-active "
                : "default-color"
            } crating-btn-default rounded-lg px-3 py-2 mx-2 my-1`}
          >
            Antiques
          </h3>

          <h3
            onClick={() => {
              setTab(2);
              setActiveTab({
                antique: false,
                paintings: true,
                custom: false,
              });
            }}
            className={`${
              activeTab.paintings
                ? "painting-active"
                : "default-color"
            } crating-btn-default rounded-lg px-3 py-2 mx-2 my-1`}
          >
            Paintings / Art
          </h3>

          <h3
            onClick={() => {
              setTab(3);
              setActiveTab({
                antique: false,
                paintings: false,
                custom: true,
              });
            }}
            className={`${
              activeTab.custom
                ? "paintart-active"
                : "default-color"
            } crating-btn-default rounded-lg px-3 py-2 mx-2 my-1 `}
          >
            Custom
          </h3>
        </Col>
        <Col md="9">
          <Tab />
        </Col>
      </Row>
    </div>
  );
}

export default Crating;
