import React from 'react';
import { Button } from 'reactstrap';

function ThemeButton({ onClick, type, icon = 'right' }) {
	type = type ? type : 'button';
	return (
		<Button
			color='secondary'
			className='theme_btn'
			size='lg'
			onClick={onClick}
			type={type}
		>
			{icon == 'right' ? (
				<i className='fas fa-arrow-right'></i>
			) : (
				<i className='fas fa-arrow-left'></i>
			)}
		</Button>
	);
}

export default ThemeButton;
