import React, { useEffect } from "react";
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  Button,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  Table,
} from "reactstrap";

const IncompleteTransaction = (props) => {
  useEffect(() => {}, []);
  return (
    <>
      <Row id="recipientPrint" className="justify-content-center  m-4">
        <Col md="8" className="mx-auto">
          <span className="text-primary h2 text-center">
            Sorry Your Transaction Did not Complete Successfully.
          </span>
          <hr />
          <br />
          <div className="text-left">
            {/* <span>
              Your reference number : <strong>{props.reference}</strong>
            </span>
            <br />
            <br />
            <span className="font-weight-bold">Please check your inbox</span>
            <br />
            <span>
              You will recieve your booking confirmation will be sent shortly
            </span>
            <br />
            <br />
            <span>
              For any enquiries regarding your shipment:{" "}
              <strong>support@shipmystuff.co.za</strong>
            </span> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default IncompleteTransaction;
