import { Col, Row, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TickImg from '../assets/img/tick-green.png';
import Tabs from '../components/Tabs';

const ShippingLarge = () => {
	return (
		<div>
			<div className='pagesHeaderWrapper'>
				<h1 className='packaging-solutions-heading'>
					PACKAGING
					<sapn style={{ color: '#00b7ae' }}> SOLUTIONS</sapn>
				</h1>

				<Container className='d-flex justify-content-around flex-wrap'>
					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>Commercial</h3>
					</div>

					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>
							Personal Effects
						</h3>
					</div>

					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>
							Home Relocations
						</h3>
					</div>
					<div
						className='d-flex align-items-center'
						style={{ width: '250px' }}
					>
						<img src={TickImg} alt='tick' />
						<h3 className='my-2 pagesHeaderText'>
							Customs Clearance
						</h3>
					</div>
				</Container>
			</div>
			<h2 className='font-weight-normal my-3 internationalHeading maxWidthWrapper'>
				<sapn style={{ color: '#00b7ae' }}>
					{' '}
					Packaging solutions for
				</sapn>{' '}
				land, sea and air
			</h2>
			<Container className='my-3'>
				<Tabs />
			</Container>
			<div
				style={{ background: '#404b51' }}
				className='buttonWrapper py-3  d-flex  justify-content-center align-items-center'
			>
				<Link to='/contactus'>
					<Button
						style={{ fontSize: '25px', padding: '4px 40px' }}
						className='font-weight-bold'
					>
						ENQUIRE TODAY!
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default ShippingLarge;
