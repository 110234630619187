import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Label,
  Form,
  Card,
  Input,
  FormGroup,
  Table,
} from "reactstrap";
// import Courierguy from '../../assets/img/Courierguy.png';
import Courierguy from "../../assets/img/Approved_Logo_TheCourierGuy.png";
import Courierit from "../../assets/img/Courierit.png";
// import Dawnwing from '../../assets/img/Dawnwing.png';
import Dawnwing from "../../assets/img/Approved_Logo_Dpd_Laser.png";

import Rating from "@mui/material/Rating";
import { Link } from "react-router-dom";
import Packaging_Guidelines from "../../assets/docs/Packaging_Guidelines.pdf";
import { useSelector, useDispatch } from "react-redux";
import ServicesHeader from "../ServicesHeader";
import { LocalActiveTab } from "./LocalActiveTab";

function QuoteEstimates(props) {
  // console.log(props.query);
  const [service, setService] = useState("");
  const [checkedService, setCheckedService] = useState(0);

  const [sRate, setRate] = useState(0);
  const [surCharge, setSurcharge] = useState(0);
  const [total, setTotal] = useState(0);
  const { courierGuyRates, dawnwingRates } = useSelector(
    (state) => state.local
  );

  let firstPageData = JSON.parse(
    localStorage.getItem("local_country_selection")
  );

  const totalRate = () => {
    let totalBaseRate = 0;
    const rate =
      props.query &&
      props.query.map((qr) => (totalBaseRate = totalBaseRate + qr.BaseRate));
    return totalBaseRate;
  };
  const totalSurcharge = () => {
    let totalBaseRate = 0;
    const rate =
      props.query &&
      props.query.map(
        (qr) => (totalBaseRate = totalBaseRate + qr.SurchargeRate)
      );
    return totalBaseRate;
  };
  const totalAmount = () => {
    let totalBaseRate = 0;
    const rate =
      props.query &&
      props.query.map((qr) => (totalBaseRate = totalBaseRate + qr.TotalRate));
    return Number(totalBaseRate).toFixed(2);
  };

  const checkImages = (name) => {
    if (name == "COURIER_GUY") {
      return <img src={Courierguy} alt={name} width="100px" height="40px" />;
    } else if (name == "DAWNWING") {
      return <img src={Dawnwing} alt={name} width="100px" height="40px" />;
    } else {
      return <img src={Courierit} alt={name} width="100px" height="40px" />;
    }
  };

  const getTotalSurcharge = () => {
    let surcharge = 0;
    firstPageData.opt_in_time_based_rates.forEach(
      (rate) => (surcharge += Number(rate.charge_value))
    );
    firstPageData.opt_in_rates.forEach(
      (rate) => (surcharge += Number(rate.charge_value))
    );
    return surcharge;
  };

  const getServiceTypeData = () => {
    let data = [];
    let newArr = [...courierGuyRates, ...dawnwingRates];
    if (props.firstPageData?.fromRegion == props.firstPageData?.toRegion) {
      if (service == "economy") {
        data = newArr.filter(
          (rate) => rate.code == "ECO" || rate.code == "ECON"
        );

        let economyCheck = data.find(
          (eco) => eco.code == "ECO" && eco.serviceType == "COURIER_GUY"
        );

        if (!economyCheck) {
          data = newArr.filter(
            (rate) =>
              rate.code == "LOX" || rate.code == "ECON" || rate.code == "ECO"
          );
          return data;
        } else {
          return data;
        }
      } else if (service == "overnight") {
        data = newArr.filter((rate) => {
          if (rate.code == "LOX" || rate.code == "ONX") {
            return rate;
          }
        });
        return data;
      } else if (service == "sameday") {
        data = newArr.filter((rate) => {
          if (rate.code == "LSE") {
            return rate;
          }
        });
        return data;
      } else if ("flyer bag") {
        data = courierGuyRates.filter((rate) => {
          if (rate.code == "LOF") {
            return rate;
          }
        });
        return data;
      } else {
        return [];
      }
    } else {
      if (service == "economy") {
        data = newArr.filter(
          (rate) => rate.code == "ECO" || rate.code == "ECON"
        );
        return data;
      } else if (service == "overnight") {
        data = newArr.filter((rate) => {
          if (rate.code == "OVN" || rate.code == "ONX") {
            return rate;
          }
        });
        return data;
      } else if (service == "sameday") {
        data = newArr.filter((rate) => {
          if (rate.code == "LSE" || rate.code == "SDX") {
            return rate;
          }
        });
        return data;
      } else {
        return [];
      }
    }

    // if (service == 'economy') {
    // 	data = newArr.filter(rate => rate.code == 'ECO');
    // 	if (data.length < 1) {
    // 		data = newArr.filter(rate => rate.code == 'LOX');
    // 		return data;
    // 	} else {
    // 		return data;
    // 	}
    // } else if (service == 'overnight') {
    // 	data = newArr.filter(rate => {
    // 		if (
    // 			rate.code == 'LOF' ||
    // 			rate.code == 'ONX' ||
    // 			rate.code == 'OVN'
    // 		) {
    // 			return rate;
    // 		}
    // 	});
    // 	return data;
    // } else if (service == 'sameday') {
    // 	data = newArr.filter(rate => {
    // 		if (
    // 			rate.code == 'LSF' ||
    // 			rate.code == 'SDX' ||
    // 			rate.code == 'LSX'
    // 		) {
    // 			return rate;
    // 		}
    // 	});
    // 	return data;
    // } else {
    // 	return [];
    // }
  };
  useEffect(() => {
    setService("economy");
  }, [courierGuyRates, dawnwingRates]);

  useEffect(async () => {
    if (
      service != "" &&
      (courierGuyRates.length > 0 || dawnwingRates.length > 0)
    ) {
      let data = await getServiceTypeData();
      if (data.length > 0) {
        setCheckedService(0);
        props.handelTypeChange(service);
        props.handleThirdPage({
          BaseRate: Number(data[0].rate_excluding_vat).toFixed(2),
          SurchargeRate: 0,
          TotalRate: Number(data[0].rate).toFixed(2),
          serviceType: data[0].serviceType,
        });
      }
    }
  }, [service, courierGuyRates, dawnwingRates]);

  const formatNumber = (num) => {
    return Number(num).toFixed("2");
    // return num.toLocaleString('en', {
    // 	useGrouping: false,
    // 	minimumFractionDigits: 2,
    // });
  };

  return (
    <>
      <div className="mx-3">
        <ServicesHeader value="DOMESTIC COURIER" />
        <LocalActiveTab
          activeTab={props.activeTab}
          setActiveTab={props.setActiveTab}
        />
        <hr className="service-header__hr-100" />
      </div>

      <Row className="quote-estimates-component m-3">
        <Col>
          <Row
            className={` ${
              service == "economy" || service == "flyer bag"
                ? "d-flex"
                : "d-none"
            }   `}
          >
            <Col md="6" className="" align="start">
              <Label className="mb-2">
                <span className="text-danger important-quote-information">
                  *Important Information
                </span>
              </Label>
              <ul>
                <li className="text-white">
                  Booking deadline{" "}
                  <span className="font-weight-bold text-danger">14:00 pm</span>{" "}
                </li>
                <li className="text-white">
                  Delivery 2-3 working days between centers
                </li>
                <li className="text-white">
                  Delivery 2-3 working days for outlying areas
                </li>
                {/* <li className='text-white'>
									<a href={Packaging_Guidelines} download>
										Download our{' '}
										<strong>
											<u>packing guide</u>
										</strong>
									</a>
								</li> */}
              </ul>
            </Col>
            <Col md="6" className="quote__col "></Col>
          </Row>
          <Row
            className={` ${service == "overnight" ? "d-flex" : "d-none"}   `}
          >
            <Col md="6" className="" align="start">
              <Label className="mb-2">
                <span className="text-danger add-delivery-opt">
                  *Important Information
                </span>
              </Label>
              <ul>
                <li className="text-white">Between major cities only</li>
                <li className="text-white">
                  Booking deadline{" "}
                  <span className="font-weight-bold text-danger">14:00 pm</span>
                </li>
                <li className="text-white">
                  Your package should be ready by{" "}
                  <span className="font-weight-bold text-danger">14:30 pm</span>
                </li>
                {/* <li className='text-white'>
									<a href={Packaging_Guidelines} download>
										Download our{' '}
										<strong>
											<u>packing guide</u>
										</strong>
									</a>
								</li> */}
              </ul>
            </Col>
            <Col md="6" className="quote__col " align="start">
              {/* <Label className='mb-2'>
								<span className='text-danger add-delivery-opt'>
									Additional Delivery Options
								</span>
							</Label>
							<ul>
								<FormGroup
									check
									className='d-flex align-items-start text-white'
								>
									<Input name='radio2' type='checkbox' />
									Dawn Delivery (9:00 AM)
								</FormGroup>
								<FormGroup
									check
									className='d-flex align-items-start text-white'
								>
									<Input name='radio2' type='checkbox' />
									Weekend Delivery (8:00 AM - 11:00 AM)
								</FormGroup>
							</ul> */}
            </Col>
          </Row>

          <Row className={` ${service == "sameday" ? "d-flex" : "d-none"}   p`}>
            <Col md="6" className="" align="start">
              <Label className="mb-2">
                <span className="text-danger add-delivery-opt">
                  *Important Information
                </span>
              </Label>
              <ul>
                <li className="text-white">
                  Johannesburg or Pretoria areas only
                </li>
                <li className="text-white">
                  Booking deadline{" "}
                  <span className="font-weight-bold text-danger">10:00 am</span>
                </li>
                <li className="text-white ">
                  Your package should be ready by{" "}
                  <span className="font-weight-bold text-danger">10:30 am</span>
                </li>
                {/* <li className='text-white'>
									<a href={Packaging_Guidelines} download>
										Download our{' '}
										<strong>
											<u>packing guide</u>
										</strong>
									</a>
								</li> */}
              </ul>
            </Col>
            <Col md="6" className="quote__col " align="start">
              {/* <Label className='mb-2'>
								<span className='text-danger add-delivery-opt'>
									Additional Delivery Options
								</span>
							</Label>
							<ul>
								<FormGroup
									check
									className='d-flex align-items-start text-white'
								>
									<Input name='radio2' type='checkbox' />
									Early Bird / Dawn Delivery (9:00 AM)
								</FormGroup>
							</ul> */}
            </Col>
          </Row>
          <Row>
            <Col md="12" style={{ marginBottom: "5px" }}>
              <hr className="add_hr" />
            </Col>
          </Row>
          <Row className="bg-white pt-2 border-8">
            <Col className="text-left" md="3">
              <h5 className="text-primary font-weight-bold domesticText">
                Service Selected:
              </h5>
            </Col>
            <Col className="text-left" md="2">
              <FormGroup check inline>
                <Input
                  name="radio2"
                  type="radio"
                  onChange={() => {
                    setService("flyer bag");
                    props.handelTypeChange("flyer bag");
                  }}
                  checked={service == "flyer bag"}
                />
                <Label check color="secondary" className="domesticText">
                  Flyer Bag
                </Label>
              </FormGroup>
            </Col>
            <Col className="text-left" md="2">
              <FormGroup check inline>
                <Input
                  name="radio2"
                  type="radio"
                  onChange={() => {
                    setService("economy");
                    props.handelTypeChange("economy");
                  }}
                  checked={service == "economy"}
                />
                <Label check color="secondary" className="domesticText">
                  Economy
                </Label>
              </FormGroup>
            </Col>
            <Col className="text-left" md="2">
              <FormGroup check inline>
                <Input
                  name="radio2"
                  type="radio"
                  onChange={() => {
                    setService("overnight");
                    props.handelTypeChange("overnight");
                  }}
                  checked={service == "overnight"}
                />
                <Label check color="secondary" className="domesticText">
                  Overnight
                </Label>
              </FormGroup>
            </Col>

            <Col className="text-left" md="3">
              <FormGroup check inline>
                <Input
                  name="radio2"
                  type="radio"
                  onChange={() => {
                    setService("sameday");
                    props.handelTypeChange("sameday");
                  }}
                  checked={service == "sameday"}
                />
                <Label check color="secondary" className="domesticText">
                  Sameday Express
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Table responsive className="estimates-table">
                <tbody>
                  {getServiceTypeData().length > 0 &&
                    getServiceTypeData().map((qr, idx) => (
                      <tr key={idx} className="table__row ">
                        <td
                          align="center"
                          className="align__middle border__radius__left"
                        >
                          <input
                            type="radio"
                            name="query"
                            checked={checkedService == idx}
                            className="custom__input__radio mt-2"
                            onChange={(e) => {
                              setCheckedService(idx);
                              props.handleThirdPage({
                                BaseRate: Number(qr.rate_excluding_vat).toFixed(
                                  2
                                ),
                                SurchargeRate: 0,
                                TotalRate: Number(qr.rate).toFixed(2),
                                serviceType: qr.serviceType,
                              });
                            }}
                          />
                        </td>
                        <td className="align__middle" align="center">
                          {qr && checkImages(qr.serviceType)}
                        </td>
                        <td className="align__middle">
                          <Row>
                            <Col align="center">
                              <span className="estimates_amount_heading">
                                RATE
                              </span>
                              <span className="estimates-amount">
                                R
                                {qr && (
                                  <>
                                    {qr.serviceType == "COURIER_GUY"
                                      ? formatNumber(qr.rate_excluding_vat)
                                      : formatNumber(qr.rate_excluding_vat)}
                                  </>
                                )}
                                {/* {qr && 
																	formatNumber(
																		qr.rate_excluding_vat
																	)} */}
                              </span>
                            </Col>
                            {/* <Col
															align='start'
															className='px-0 mt-1'
														>
															<span className='estimates-tax'>
																Excl VAT
															</span>
														</Col> */}
                          </Row>
                        </td>
                        <td className="align__middle text-end" align="end">
                          {qr.serviceType == "COURIER_GUY" ? (
                            <>
                              <span className="estimates_amount_heading">
                                SURCHARGE
                              </span>
                              <span className="estimates-amount">
                                R
                                {qr.serviceType == "COURIER_GUY"
                                  ? formatNumber(qr.surcharges)
                                  : "0.00"}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="estimates_amount_heading">
                                SURCHARGE
                              </span>
                              <span className="estimates-amount">
                                R
                                {qr.serviceType == "DAWNWING"
                                  ? formatNumber(qr.surcharges)
                                  : "0.00"}
                              </span>
                              {/* <span className='estimates_amount_heading'>
																SURCHARGES Incl.
															</span>
															<p className=' m-0 '>
																<small className='text-secondary'>
																	(if
																	applicable)
																</small>
															</p> */}
                            </>
                          )}
                        </td>
                        <td className="align__middle" align="end">
                          <span className="estimates_amount_heading">
                            TOTAL AMOUNT
                          </span>
                          <span className="estimates-amount">
                            R{qr.rate && formatNumber(qr.rate ? qr.rate : 0)}
                          </span>
                        </td>
                        <td
                          className="align__middle border__radius__right"
                          align="center"
                        >
                          <span className="text-danger estimates-offer">
                            {idx == "0" && (
                              <h6 className="mb-0">
                                <strong>Best Rate</strong>
                              </h6>
                            )}
                          </span>
                        </td>
                        {/* <td>
                            <Rating name="simple-controlled" value={3} />
                          </td> */}
                      </tr>
                    ))}

                  {/* <tr className='table__row'>
                    <td>
                      <img src={Courierguy} width="80px" />
                    </td>
                    <td>
                      <span className="estimates-amount">R226.71</span>
                      <span className="estimates-tax">Excl VAT</span>
                    </td>
                    <td>
                      <span className="text-danger estimates-offer">
                        *Cheapest Offer*
                      </span>
                    </td>
                    <td>
                      <Rating name="simple-controlled" value={3} />
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="mb-5">
              <hr className="add_hr" />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default QuoteEstimates;
