import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SectionTitle from './SectionTitle';
import Tabs from './Tabs';

const PackagingSolution = () => {
	return (
		<>
			<div className='section_gap text-center ' id='packaging-section'>
				<Container>
					<Row name='scroll_title'>
						<Col lg='12'>
							<SectionTitle
								textAlign='text-center'
								title='PACKAGING SOLUTIONS'
								description='Cost-effective packaging solutions for any mode of transport, big or small'
							/>
						</Col>
					</Row>

					{/* will call here tabs */}
					<Tabs />
				</Container>
			</div>
		</>
	);
};

export default PackagingSolution;
