const initState = {
	country: null,
	mainLoader:false
};

const countriesReducer = (state = initState, { payload, type }) => {
	switch (type) {
		case 'GET_COUNTRY':
			return {
				...state,
				country:payload,

			};
			case 'COUNTRY_LOADER':
				return {
					...state,
					mainLoader:payload
				}
				default:
			return state;
	}
};

export default countriesReducer;
